import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EvasysStatusModel } from '@evasys/globals/evasys/models/general/evasys-status.model';
import { FacadeService } from '../../../../../../shared/core/src/lib/essential/redux/services/facade.service';
import { EvasysState } from '../../../../../../shared/core/src/lib/essential/redux/states/evasys.state';
import { evasysActions } from '../actions';
import { Observable } from 'rxjs';
import { evasysSelectors } from '../selectors';
import { catchFailure } from '../../../../../../shared/util/src/lib/rxjs/rxjs.functions';
import { loadData } from '@evasys/shared/util';
import { map } from 'rxjs/operators';
import { EvasysLoadingStrategiesEnum } from '@evasys/globals/shared/enums/general/evasys-loadingStrategies.enum';
import { Dictionary } from '@ngrx/entity';
import { EvasysNotificationActionModel } from '@evasys/globals/shared/models/evasys-notification-action.model';
import { Actions } from '@ngrx/effects';

@Injectable({
	providedIn: 'root',
})
export class StatusService extends FacadeService<EvasysStatusModel> {
	constructor(
		private readonly statusStore: Store<EvasysState<EvasysStatusModel>>,
		protected readonly actions: Actions
	) {
		super(statusStore, evasysActions.fromStatus, evasysSelectors.fromStatus, actions);
	}

	public getAllStatuses(): Observable<Dictionary<EvasysStatusModel>> {
		return catchFailure(
			this.store.select(evasysSelectors.fromStatus.selectEntities),
			this.store.select(evasysSelectors.fromStatus.selectError)
		).pipe(
			loadData(
				() =>
					this.store.dispatch(
						evasysActions.fromStatus.LoadAllActionSet.DEFAULT({
							payload: undefined,
						})
					),
				() => this.store.select(evasysSelectors.fromStatus.selectLoading),
				EvasysLoadingStrategiesEnum.APIONLY,
				true
			),
			map((entity) => entity as Dictionary<EvasysStatusModel>)
		);
	}

	public createOneStatus(status: EvasysStatusModel): void {
		this.statusStore.dispatch(evasysActions.fromStatus.createOneStatusActionSet.DEFAULT({ payload: status }));
	}

	public deleteOneStatus(id: string): void {
		this.statusStore.dispatch(evasysActions.fromStatus.deleteOneStatusActionSet.DEFAULT({ payload: id }));
	}

	public acceptEula(action: EvasysNotificationActionModel): void {
		this.statusStore.dispatch(
			evasysActions.fromStatus.EulaAcceptedActionSet.DEFAULT({
				payload: action,
			})
		);
	}

	public sendEvasysUpdateAnswerToBackend() {
		this.statusStore.dispatch(
			evasysActions.fromStatus.transmitEvasysUpdateStatus.DEFAULT({
				payload: undefined,
			})
		);
	}

	public sendEvasysUpdateRentalAnswerToBackend(action: string) {
		this.statusStore.dispatch(
			evasysActions.fromStatus.transmitEvasysUpdateRentalStatus.DEFAULT({
				payload: action,
			})
		);
	}

	public sendExtrasUpdateAnswerToBackend(extrasUpdatesAvailable: number) {
		this.statusStore.dispatch(
			evasysActions.fromStatus.transmitExtrasUpdateStatus.DEFAULT({
				payload: extrasUpdatesAvailable,
			})
		);
	}
}
