import { Component, Input } from '@angular/core';
import { BreadcrumbItem } from '@evasys/shared/ui';

@Component({
	selector: 'evainsights-report-creator-page',
	templateUrl: './report-creator-page.component.html',
})
export class ReportCreatorPageComponent {
	@Input()
	breadItems!: BreadcrumbItem[];

	isConfigurationSidebarCollapsed = false;
}
