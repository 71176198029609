<div class="position-relative" [attr.data-cy]="'showMore-' + id">
	<div #content class="line-truncate">
		<ng-content></ng-content>
	</div>
	<button
		*ngIf="_isOverflow && !isClicked"
		class="btn bg-white p-0 border-0 position-absolute end-0 bottom-0 text-primary blurLeft"
		(click)="onShowMoreClicked()"
		[attr.data-cy]="'showMoreButton-' + id"
	>
		{{ sharedUiConfigurations.translations.showMore.showMore | transloco }}
	</button>
</div>
