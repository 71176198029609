import { Component, Input } from '@angular/core';

@Component({
	selector: 'evasys-sidebar-container',
	templateUrl: './sidebar-container.component.html',
})
export class SidebarContainerComponent {
	@Input()
	scrollbar = true;
}
