import { Component, Input } from '@angular/core';

@Component({
	selector: 'evainsights-empty-content',
	templateUrl: './empty-content.component.html',
})
export class EmptyContentComponent {
	@Input()
	message?: string;
}
