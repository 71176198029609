<div class="card position-relative p-3 mb-3">
	<div class="row mb-1">
		<div class="col d-flex flex-row flex-nowrap">
			<ng-content select="[tableAreaTopLeft]"></ng-content>
		</div>
		<div class="col d-flex flex-row flex-nowrap justify-content-end">
			<ng-content select="[tableAreaTopRight]"></ng-content>
		</div>
	</div>
	<div class="row">
		<div class="c-overflow-y-auto position-relative" [style.max-height]="tableMaxHeight + 'px'">
			<ng-content></ng-content>
		</div>
	</div>
	<ng-content
		*ngIf="(tableAreaService.guiItems | async)?.length <= 0 && !isLoading"
		select="[tableAreaEmpty]"
	></ng-content>
	<div class="row"><ng-content select="[tableAreaPagination]"></ng-content></div>
	<div class="row">
		<div class="col d-flex flex-row flex-nowrap"><ng-content select="[tableAreaBottomLeft]"></ng-content></div>
		<div class="col d-flex flex-row flex-nowrap justify-content-end">
			<ng-content select="[tableAreaBottomRight]"></ng-content>
		</div>
	</div>
	<ng-content select="[tableAreaLoadingOverlay]"></ng-content>
</div>
