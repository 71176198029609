<evainsights-filter-typeahead
	[ngModel]="selectedReportTemplates"
	(ngModelChange)="onSelectReportTemplates($event)"
	[id]="'reportTemplates'"
	[search]="searchReportTemplates"
	[formatter]="formatterTemplate"
	[title]="'reportTemplate.addTemplate' | transloco"
	[searchFailedText]="'error.text' | transloco"
	[emptyResultsText]="'reportTemplate.noneFound' | transloco"
	[design]="chipTypeaheadDesign.LIST"
	[itemIdentifier]="'key'"
	[disabled]="reportId === 0 || reportProcessingService.isProcessingTemplate()"
	[placement]="'bottom-end'"
	[resultTemplate]="templateResultTemplate"
	[filterAreaControls]="filterAreaControls"
	[popoverClass]="'c-resize-horizontal c-direction-rtl'"
	[popoverContainer]="'body'"
></evainsights-filter-typeahead>
<ng-template #templateResultTemplate let-text="text" let-term="term">
	<ngb-highlight [result]="text" [term]="term" class="w-100" evasysTextOverflow> </ngb-highlight>
	<evasys-loading-overlay
		backdropColorClass="c-bg"
		[loading]="reportProcessingService.isProcessingTemplate()"
	></evasys-loading-overlay>
</ng-template>
