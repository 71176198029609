import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { WizardEffects } from '../effects/wizard.effects';
import { wizardReducer } from '../reducers/wizard.reducer';

@NgModule({
	imports: [CommonModule, EffectsModule.forFeature([WizardEffects]), StoreModule.forFeature('wizard', wizardReducer)],
})
export class WizardModule {}
