import { LegacyReportItemContentConfigBase, ReportItemContentBase } from './common';
import { VisualizationType } from '../../../constants/types';
import { Topic } from '../../topic-modeling/topic.model';
import { Item } from '../../item/item.model';
import { WordFrequencyWordcloudConfigFields } from './word-frequency-wordcloud-report-item-content.model';

export type TopicWordcloudContent = ReportItemContentBase<TopicWordcloudConfig, TopicWordcloudData>;

// Content config

export type TopicWordcloudConfig = LegacyReportItemContentConfigBase<VisualizationType.WORDCLOUD> &
	TopicWordcloudConfigFields;

export type TopicWordcloudConfigFields = WordFrequencyWordcloudConfigFields & {
	topicId: string;
};

// Data

export interface TopicWordcloudData {
	topic: Topic;
	data: TopicWordcloudDatum[];
	items: Item[];
}

// Doesn't inherit from WordFrequencyWordcloudDatum even though the two share the name and occurrences properties
// because they also don't inherit in our report service. Adding inheritance here might lead to mismatches if we later
// decide to add new properties to the WordFrequencyWordcloudDatum which would also apply to the TopicWordcloudDatum
// here but not in the backend.
export interface TopicWordcloudDatum {
	name: string;
	occurrences: number;
	value: number;
}
