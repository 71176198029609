import { CentralTendencyMetric, DataIndexDimensionType, PeriodDimensionDomain } from './axis-chart';

export type ReportItemDataContext = { reportId: number } | { reportTemplateId: number };

type AxisChartDataRequest = ReportItemDataContext & {
	dataDimensions: Array<AxisChartDataRequestDimension>;
};

export type AxisChartDataRequestDimension =
	| ItemDataRequestDimension
	| ItemOptionDataRequestDimension
	| PeriodDataRequestDimension
	| TopicDataRequestDimension;

export interface ItemDataRequestDimension {
	type: DataIndexDimensionType.ITEM;
	domain: Array<{ itemIds: number[] }>;
}

export interface ItemOptionDataRequestDimension {
	type: DataIndexDimensionType.ITEM_OPTION;
	domain: Array<{ itemOptionIds: number[] }>;
}

export interface PeriodDataRequestDimension {
	type: DataIndexDimensionType.PERIOD;
	domain: PeriodDimensionDomain;
}

export interface TopicDataRequestDimension {
	type: DataIndexDimensionType.TOPIC;
	domain: Array<{ itemIds: number[]; topicIds: string[] }>;
}

export type CountAxisChartDataRequest = AxisChartDataRequest;
export type CountLineChartDataRequest = AxisChartDataRequest;

export type CentralTendencyLineChartDataRequest = AxisChartDataRequest & {
	aggregation: {
		metric: CentralTendencyMetric;
		sources: Array<{ itemOptionIds: number[] }>;
	};
};

type TopicDataRequest = ReportItemDataContext & {
	topicId: string;
	itemIds: number[];
};

export type TopicWordcloudDataRequest = TopicDataRequest;
export type TopicWordcloudResponsesDataRequest = TopicDataRequest;

export type NoDataRequest = Record<string, never>;

export type ResponsesDataRequest = ReportItemDataContext & {
	itemIds: number[];
};
