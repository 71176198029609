import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { GlobalService } from '@evasys/shared/core';

@Component({
	selector: 'evasys-loading-spinner',
	templateUrl: './loading-spinner.component.html',
	styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy {
	//#region Variables
	public loading: Observable<boolean>;
	private subscriptions: Subscription[] = [];

	//#endregion Variables

	constructor(private readonly globalService: GlobalService) {}

	//#region Events
	ngOnInit(): void {
		this.loading = this.globalService.isLoading();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	//#endregion Events
}
