import { Component, inject, Input, OnInit } from '@angular/core';
import { FilterSelection } from '@evasys/globals/evainsights/models/filter/filter-area-control.model';
import { ActivatedRoute } from '@angular/router';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { FilterAreaControlWriteUrl } from '@evasys/globals/evainsights/models/filter/filter-area-control-write-url.model';
import { getTypeaheadIdentifierValue } from '@evasys/globals/shared/helper/typeahead';
import { isArray } from 'lodash';
import { SearchRequestParamService } from '@evasys/evainsights/shared/core';

@Component({
	selector: 'evainsights-filter-area-write-url',
	templateUrl: './filter-area-write-url.component.html',
})
export class FilterAreaWriteUrlComponent<FilterAreaControls extends readonly FilterAreaControlWriteUrl<unknown>[]>
	implements OnInit
{
	@Input()
	@Required()
	filterAreaControls!: FilterAreaControls;

	@Input()
	@Required()
	filterSelections!: FilterSelection<FilterAreaControls>;

	@Input()
	showTitle = true;

	protected paramService = inject(SearchRequestParamService);
	private activatedRoute = inject(ActivatedRoute);

	ngOnInit() {
		const params = this.activatedRoute.snapshot.queryParams;
		for (const control of this.filterAreaControls) {
			if (params[control.queryParameterName]) {
				control.load(params[control.queryParameterName]).subscribe(
					(content) =>
						(this.filterSelections = {
							...this.filterSelections,
							[control.id]: isArray(content) ? content : [content],
						})
				);
			}
		}
	}

	updateUrlParams(filterSelection: FilterSelection<FilterAreaControls>) {
		this.filterSelections = filterSelection;

		let filterParams = {};
		for (const control of this.filterAreaControls) {
			const controlId = control.id as FilterAreaControls[number]['id'];
			filterParams = {
				...filterParams,
				[control.queryParameterName]: this.filterSelections[controlId].map((value) =>
					getTypeaheadIdentifierValue(value, control.identifier)
				),
			};
		}

		this.paramService.updateParams(filterParams);
	}
}
