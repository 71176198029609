<div class="navbar">
  <div class="col text-start">
    <svg
      class="icon"
      version="1.1"
      id="calendar"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 448 512"
      xml:space="preserve"
    >
      <path
        class="st0"
        d="M400,64h-48V12c0-6.6-5.4-12-12-12h-8c-6.6,0-12,5.4-12,12v52H128V12c0-6.6-5.4-12-12-12h-8
	        c-6.6,0-12,5.4-12,12v52H48C21.5,64,0,85.5,0,112v352c0,26.5,21.5,48,48,48h352c26.5,0,48-21.5,48-48V112C448,85.5,426.5,64,400,64z
	        M48,96h352c8.8,0,16,7.2,16,16v48H32v-48C32,103.2,39.2,96,48,96z M400,480H48c-8.8,0-16-7.2-16-16V192h384v272
	        C416,472.8,408.8,480,400,480z"
      />
    </svg>
    {{ i18nMonth }}
    <select class="form-select-sm select-year" (change)="onChangeYear($event)" data-cy="slector-years">
      <option [value]="year" *ngFor="let year of years" [selected]="year | selectedItem : startDate.year">
        {{ year }}
      </option>
    </select>
  </div>

  <div class="col text-end">
    <div class="btn-group" role="group">
      <div class="btn" (click)="navigatePrev()" data-cy="button-prev">
        <svg
          class="icon"
          version="1.1"
          id="prev"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 192 512"
          xml:space="preserve"
        >
          <path
            class="st0"
            d="M25.1,247.5l117.8-116c4.7-4.7,12.3-4.7,17,0l7.1,7.1c4.7,4.7,4.7,12.3,0,17L64.7,256l102.2,100.4
	                c4.7,4.7,4.7,12.3,0,17l-7.1,7.1c-4.7,4.7-12.3,4.7-17,0L25,264.5C20.4,259.8,20.4,252.2,25.1,247.5z"
          />
        </svg>
      </div>
      <div class="btn" (click)="navigateNext()" data-cy="button-next">
        <svg
          class="icon"
          version="1.1"
          id="next"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 192 512"
          xml:space="preserve"
        >
          <path
            class="st0"
            d="M167,247.5l-117.8-116c-4.7-4.7-12.3-4.7-17,0l-7.1,7.1c-4.7,4.7-4.7,12.3,0,17L127.4,256L25.2,356.4
	                c-4.7,4.7-4.7,12.3,0,17l7.1,7.1c4.7,4.7,12.3,4.7,17,0l117.8-116C171.7,259.8,171.7,252.2,167,247.5z"
          />
        </svg>
      </div>
    </div>
  </div>
</div>

<ngb-datepicker (dateSelect)="onDateChange($event)" data-cy="datapicker-calendar" #dp></ngb-datepicker>
