import { Component, Input } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
	template: '',
})
export abstract class AbstractInputComponent<T> {
	//region Input & Output
	@Input()
	@Required()
	id: string | undefined;

	@Input()
	readonly = false;

	@Input()
	required = false;

	@Input()
	set value(value: T) {
		this.writeValue(value);
	}

	@Input()
	set isDisabled(disabled: boolean) {
		this.setDisabledState(disabled);
	}

	//endregion

	//region Variables
	protected subscriptions: Subscription[] = [];
	protected touched = false;
	public _isDisabled = false;
	public input = new FormControl();
	//endregion

	//region Events
	onInput(): void {
		this._onChange(this.input.value);
	}

	private _onTouched: any = () => {
		//default
	};
	private _onChange: any = () => {
		//default
	};

	protected onWriteValue(value: T) {
		//no implementation needed
	}
	//endregion

	//region ControlValueAccessor

	registerOnChange(fn: (_: any) => void): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this._isDisabled = isDisabled;
		isDisabled ? this.input.disable() : this.input.enable();
	}

	writeValue(value: T): void {
		if (!this.touched) {
			this._onTouched();
			this.touched = true;
		}
		if (value === null) {
			this.input.reset();
		}
		this.input.setValue(value);
		this.onWriteValue(value);
	}

	//endregion
}
