<evainsights-report-item-wrapper
	[reportItemStatus]="reportItemStatus"
	[titleText]="translatedTitle | async"
	[isExportable]="false"
>
	<evainsights-responses-content
		class="c-min-h-0 d-flex flex-column p-2"
		[content]="content"
		[search]="getSearchResponses"
		[filterAreaControls]="filterAreaControls"
		[language]="reportLanguage$ | async"
	>
	</evainsights-responses-content>
</evainsights-report-item-wrapper>
