<evasys-sidebar-container [scrollbar]="false">
	<evainsights-click-sidebar
		[visible]="true"
		[title]="'common.contextMenu' | transloco"
		[(collapsed)]="isConfigurationSidebarCollapsed"
		rightSideBar
	>
		<evainsights-configuration-menu [collapsed]="isConfigurationSidebarCollapsed"></evainsights-configuration-menu>
	</evainsights-click-sidebar>
	<evasys-page class="h-100">
		<evasys-breadcrumb [breadItems]="breadItems" top></evasys-breadcrumb>
		<ng-content></ng-content>
	</evasys-page>
</evasys-sidebar-container>
