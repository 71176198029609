<div class="d-flex justify-content-center">
	<img
		evasysImage
		[alt]="'itemNotFound'"
		[id]="'itemNotFound'"
		[src]="'./assets/images/icons/dellibgroup_x.svg'"
		class="c-w-100px c-h-100px"
	/>
</div>
<p data-cy="noEntriesFound" class="d-flex justify-content-center">{{ message }}</p>
