<div class="card position-relative m-auto shadow-none w-100 pt-1 pb-1 pe-1 h-100" [class.low-opacity]="!showSearchBox">
	<evasys-searchbox
		*ngIf="showSearchBox"
		[id]="'topicResponses'"
		[language]="language"
		class="d-flex justify-content-end mb-3"
		[ngModel]="searchValue"
		(ngModelChange)="onSearch($event)"
	></evasys-searchbox>
	<div class="card-body overflow-auto" evasysShowMoreContainer>
		<evasys-show-more
			[maxLines]="7"
			[id]="'topicText-' + topicText.response.key"
			*ngFor="let topicText of filteredTopicResponses; let i = index"
			class="mb-3"
			[attr.data-cy]="'div-topicText-' + topicText.response.key"
		>
			<strong>{{ topicText.overallTopicAssignmentScore * 100 | number : '1.0-0' }}: </strong>
			<ngb-highlight
				[result]="topicText.response.value"
				[term]="searchValue"
				[title]="topicText.response.value"
			></ngb-highlight>
		</evasys-show-more>
		<div *ngIf="filteredTopicResponses.length === 0">
			<em>{{ language | inlineTransloco : 'reportItem.noResponses' | async }}</em>
		</div>
	</div>
</div>
