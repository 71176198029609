import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { fromEvent, Subscription, throttleTime } from 'rxjs';

@Directive({
	selector: '[evasysHesitate]',
})
export class HesitateDirective implements OnDestroy, AfterViewInit {
	//region Input & Output
	@Input()
	evasysHesitate = 0;

	@Output()
	hesitate = new EventEmitter<boolean>();

	//endregion

	//region Variables
	private timer = null;
	private subscriptions: Subscription[] = [];
	//endregion

	constructor(private readonly element: ElementRef) {}

	//region Events
	ngAfterViewInit() {
		this.subscriptions.push(
			fromEvent(this.element.nativeElement, 'mouseenter')
				.pipe(throttleTime(200))
				.subscribe(() => this.onMouseEnter()),

			fromEvent(this.element.nativeElement, 'mouseleave')
				.pipe(throttleTime(200))
				.subscribe(() => this.onMouseLeave())
		);
	}

	public ngOnDestroy(): void {
		this.clearTimer();
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.subscriptions.length = 0;
	}

	private onMouseEnter() {
		this.clearTimer();
		this.timer = setTimeout(() => {
			this.hesitate.emit(true);
		}, this.evasysHesitate);
	}

	private onMouseLeave() {
		this.clearTimer();
		this.hesitate.emit(false);
	}
	//endregion

	//region Methods

	private clearTimer() {
		if (this.timer != null) {
			window.clearTimeout(this.timer);
			this.timer = null;
		}
	}
	//endregion
}
