import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { ValidationErrorModel } from '@evasys/globals/evasys/models/component/validation-error.model';
import { Subscription } from 'rxjs';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'evasys-dropdown-optional',
	templateUrl: './dropdown-optional.component.html',
	styleUrls: ['./dropdown-optional.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: DropdownOptionalComponent,
			multi: true,
		},
	],
})
export class DropdownOptionalComponent implements OnInit, ControlValueAccessor, OnDestroy {
	// region ViewChild
	@ViewChild('inputElement')
	inputElement?: ElementRef<HTMLInputElement>;

	@ViewChild('dropdownElement')
	dropdownElement?: NgbDropdown;

	// endregion ViewChild

	//#region Input & Output
	@Input()
	@Required()
	id?: string;

	@Input()
	set value(value: string) {
		this.writeValue(value);
		this.labelInside = !value;
	}

	@Input()
	description: string | null = null;

	@Input()
	items: string[] = [];

	@Input()
	required = false;

	@Input()
	maxLength?: number;

	@Input()
	minLength?: number;

	@Input()
	max?: number;

	@Input()
	min?: number;

	@Input()
	set isDisabled(disabled: boolean) {
		this.setDisabledState(disabled);
	}

	@Input()
	type = 'text';

	@Input()
	placement = 'bottom-start bottom-end top-start top-end';

	@Input()
	errors: ValidationErrorModel[] = [];

	@Output()
	focusAction = new EventEmitter<void>();

	@Output()
	blurAction = new EventEmitter<void>();

	@Output()
	pageChange = new EventEmitter<number>();

	//#endregion Input & Output

	//#region Variables
	public subscriptions: Subscription[] = [];

	public focused = false;
	public labelInside = true;
	public inputControl = new FormControl();

	//#endregion Variables

	//#region Getter & Setter
	get height(): string {
		return document.documentElement.style.getPropertyValue('--textbox-height');
	}

	//#endregion Getter & Setter

	//#region Events
	_onChange: any = () => {
		return;
	};

	_onTouched: any = () => {
		return;
	};

	ngOnInit(): void {
		this.subscriptions.push(
			this.inputControl.valueChanges.subscribe((val) => {
				this.determineLabel(val);
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	onFocus(): void {
		this.focusAction.emit();
		this.labelInside = false;
		this.focused = true;
	}

	onBlur(): void {
		this.focused = false;
		this.determineLabel(this.inputControl.value);
		this.blurAction.emit();
	}

	onInput(): void {
		this._onChange(this.inputControl.value);
	}

	onSelect(key: string): void {
		this.inputControl.setValue(key);
		this._onChange(this.inputControl.value);
	}

	onOpenChange(opened: boolean): void {
		if (opened) {
			if (this.inputControl.disabled || !this.items || this.items.length === 0) {
				this.dropdownElement?.close();
			}
		}
	}

	//#endregion Events

	//#region Methods
	isInvalid(): boolean {
		return this.errors.find((error) => error.trigger) !== undefined;
	}

	clear(): void {
		this.inputControl.reset();
	}

	private determineLabel(value: string): void {
		this.labelInside = value === undefined || value === null || (value?.length === 0 && !this.focused);
	}
	//#endregion Methods

	//#region ControlValueAccessor
	registerOnChange(fn: (_: any) => void): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		isDisabled ? this.inputControl.disable() : this.inputControl.enable();
	}

	writeValue(value: string): void {
		if (value === null) {
			this.clear();
		}
		this.inputControl.setValue(value);
	}

	//#endregion ControlValueAccessor
}
