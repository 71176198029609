<div [id]="dropdownComponent.id" class="m-0 w-100">
	<div
		[ngClass]="{
			'bg-muted': dropdownComponent.isOpen(),
			'text-muted': !hasValue()
		}"
		class="p-1 px-2 fw-bold text-primary c-cursor-pointer user-select-none rounded d-flex flex-row flex-nowrap align-items-center"
	>
		<ng-content select="[evasysDropdownToggle]"></ng-content>
		<img
			evasysDropdownToggle
			evasysImage
			class="c-w-20px c-h-20px ms-1 px-1 transition-all rotate-90 user-select-none"
			[id]="'expand'"
			[isIcon]="true"
			src="./assets/images/icons/navigate_right.svg"
		/>
	</div>
	<ng-content></ng-content>
</div>
