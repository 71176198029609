import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { SystemInfoService } from '@evasys/evasys/shared/core';
import { EvasysLoadingStrategiesEnum } from '@evasys/globals/shared/enums/general/evasys-loadingStrategies.enum';
import { NavigateService } from '../../navigate/services/navigate.service';

@Injectable({
	providedIn: 'root',
})
export class SystemActivationGuard {
	constructor(
		private readonly systemInfoService: SystemInfoService,
		private readonly navigationService: NavigateService
	) {}

	canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean | UrlTree> | UrlTree {
		return firstValueFrom(
			this.systemInfoService.getOne('is_system_deactivated', EvasysLoadingStrategiesEnum.STATEFALLBACKAPI)
		)
			.then((info) => {
				if (info.value as boolean) {
					this.navigationService.navigateTo('oadmin.php?mode=einst_config2file');
				}
				return !info.value;
			})
			.catch((err) => {
				console.error('Can not get system deactivation info: ', err);
				return false;
			});
	}
}
