import { Pipe, PipeTransform } from '@angular/core';
import { DatePickerService } from '../services/date-picker.service';

@Pipe({
  name: 'meridiem',
})
export class MeridiemPipe implements PipeTransform {
  constructor(public datePickerService: DatePickerService) {}

  transform(value: number, meridiem: boolean, isHours: boolean): string {
    if (isHours) {
      if (!meridiem) {
        return value.toString().padStart(2, '0');
      }

      if (value === 0) {
        return `12 ${this.datePickerService.getInitFormat()?.includes('aaa') ? 'am' : 'am'.toUpperCase()}`;
      }

      if (value >= 1 && value <= 11) {
        return `${value.toString().padStart(2, '0')} ${
          this.datePickerService.getInitFormat()?.includes('aaa') ? 'am' : 'am'.toUpperCase()
        }`;
      }

      if (value === 12) {
        return `12 ${this.datePickerService.getInitFormat()?.includes('aaa') ? 'pm' : 'pm'.toUpperCase()}`;
      }

      if (value > 12) {
        let calcValue: number = value - 12;
        return `${calcValue.toString().padStart(2, '0')} ${
          this.datePickerService.getInitFormat()?.includes('aaa') ? 'pm' : 'pm'.toUpperCase()
        }`;
      }
    }

    return value.toString().padStart(2, '0');
  }
}
