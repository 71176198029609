<div class="h-100 c-overflow-y-auto c-overflow-x-0" [class.c-hide-scrollbar]="collapsed">
	<ng-container *ngFor="let sidemenuItem of _sidemenuItems; let i = index">
		<evasys-menu
			(activeChange)="onActiveChange(i, $event)"
			*ngIf="sidemenuItem.canAccess | async"
			[active]="active | isMenuActive : i"
			[labelRight]="collapsed"
			[id]="sidemenuItem.id"
			[menuItems]="sidemenuItem.menuitems"
			[title]="sidemenuItem.title"
		></evasys-menu>
	</ng-container>
</div>
