import { AfterContentChecked, AfterContentInit, Component, ContentChildren, OnDestroy, QueryList } from '@angular/core';
import { Subscription } from 'rxjs';
import { isSelectorItem } from '@evasys/shared/util';
import { SelectAreaComponent } from '../../control-components/select-area/select-area.component';

/**
 * Connects multiple select-areas
 */
@Component({
	selector: 'evasys-select-area-pipe',
	templateUrl: './select-area-pipe.component.html',
})
export class SelectAreaPipeComponent implements OnDestroy, AfterContentInit, AfterContentChecked {
	@ContentChildren(SelectAreaComponent)
	public areaQuery?: QueryList<SelectAreaComponent>;

	public get areas(): SelectAreaComponent[] {
		return this._areas;
	}

	private set areas(areas: SelectAreaComponent[]) {
		this.clearAreaSubscriptions();

		this._areas = [];
		for (const [index, area] of areas.entries()) {
			this.areaSubs.push(
				area.focusedChange.subscribe((focused) => {
					if (focused) {
						this.onFocus(index);
					}
				})
			);
			this.areaSubs.push(area.selectionChange.subscribe(() => this.onSelect(index)));

			this._areas.push(area);
		}
		this.resetForm();
	}

	private subscriptions: Subscription[] = [];

	private areaSubs: Subscription[] = [];

	private focusSubs: Subscription[] = [];

	private _areas: SelectAreaComponent[] = [];

	// region Events
	public ngAfterContentInit(): void {
		if (this.areaQuery) {
			this.areas = this.areaQuery.toArray();

			this.subscriptions.push(
				this.areaQuery.changes.subscribe((selectAreas) => {
					this.areas = selectAreas.toArray();
				})
			);
		}
	}

	public ngAfterContentChecked(): void {
		for (const [index, area] of this.areas.entries()) {
			area.canFocus = this.canFocus(index);
		}
	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.clearAreaSubscriptions();
		this.clearFocusSubscriptions();
	}

	/**
	 * onFocus event handler
	 *
	 * Runs if an area get's focused, by the application or user.
	 * If there's only one item in the area, it get's selected automatically.
	 * If the area is single-select, the next area will be focused
	 *
	 * Only runs if given index can be focused (canFocus function)
	 *
	 * @param {number} focusedIndex Index of area to be focused
	 *
	 * @return void
	 */
	private onFocus(focusedIndex: number): void {
		if (focusedIndex in this.areas) {
			const focusedArea = this.areas[focusedIndex];
			if (focusedArea.focused) {
				this.clearFocusSubscriptions();

				for (const [index, area] of this.areas.entries()) {
					if (focusedIndex !== index) {
						area.focused = false;
					}
				}

				if (focusedArea.selectItems.length === 0) {
					this.focusSubs.push(
						focusedArea.itemsChange.subscribe(() => {
							this.selectOneAndOnlyItemForArea(focusedArea);
						})
					);
				} else {
					this.selectOneAndOnlyItemForArea(focusedArea);
				}
			}
		}
	}

	/**
	 * onSelect event handler
	 *
	 * Resets all previous areas.
	 *
	 * @param {number} index
	 *
	 * @return void
	 */
	private onSelect(index: number): void {
		if (index in this.areas) {
			const area = this.areas[index];

			if (this.areaQuery) {
				for (let i = index + 1; i < this.areaQuery.length; i++) {
					this.areas[i]?.input.reset();
				}
			}

			if (!area.multiple && area.input.value?.length === 1) {
				const nextIndex = index + 1;
				if (nextIndex in this.areas) {
					this.areas[nextIndex].focused = true;
				}
			}
		}
	}

	// endregion Events

	// region Methods

	/**
	 * Resets all areas and sets focus to first area.
	 *
	 * @return void
	 */
	public resetForm(): void {
		for (const area of this.areas) {
			area.input.reset();
		}
		this.areas[0].focused = true;
	}

	/**
	 * Checks if the given area (by index) can be focused.
	 *
	 * The requirement for that is, that the previous areas were filled.
	 *
	 * @param {number} index
	 *
	 * @return boolean
	 */
	private canFocus(index: number): boolean {
		if (index >= this.areas.length) {
			return false;
		}

		let lastAreaFilled = true;
		for (const [areaIndex, area] of this.areas.entries()) {
			let areaFilled = area.input.value?.length > 0;

			if (!lastAreaFilled && !areaFilled) {
				break;
			}

			lastAreaFilled = areaFilled;

			if (areaIndex === index) {
				return true;
			}
		}
		return false;
	}

	private clearAreaSubscriptions(): void {
		this.areaSubs.forEach((sub) => sub.unsubscribe());
	}

	private selectOneAndOnlyItemForArea(area: SelectAreaComponent): void {
		let selectorItems = [];
		for (let itemOrGroup of area.selectItems) {
			if (isSelectorItem(itemOrGroup)) {
				selectorItems.push(itemOrGroup);
			} else {
				selectorItems.push(...itemOrGroup.items);
			}
		}

		// If the user has only one item to choose from,
		// select it automatically for him
		if (selectorItems.length === 1) {
			area.writeValue(selectorItems[0].id ?? null);
		}
	}

	private clearFocusSubscriptions(): void {
		this.focusSubs.forEach((sub) => sub.unsubscribe());
	}
	// endregion Methods
}
