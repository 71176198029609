<evainsights-chart-export-menu (selectFormat)="exportAllCharts($event)" optionTranslocoKey="reportItem.export.all">
	<evasys-button
		id="reportExportMenu-toggle"
		[design]="buttonDesign.OUTLINE_LINK"
		title="{{ 'domain.downloadReport.tooltip' | transloco }}"
		class="d-inline-block"
	>
		<img
			evasysImage
			id="download"
			alt="download-icon"
			class="c-w-20px c-h-20px"
			src="./assets/images/fontawesome/download.svg"
		/>
		{{ 'domain.downloadReport' | transloco }}
	</evasys-button>
</evainsights-chart-export-menu>
