<div [attr.data-cy]="'cardSelect-' + id" class="row d-flex w-100 justify-content-center m-0">
	<div *ngFor="let item of items" class="col-md-4 col-sm-6 col-xs-12 g-2 ps-0">
		<div
			class="shadow-sm card overflow-hidden c-cursor-pointer border border-2 shadow-sm p-2"
			(click)="onCardSelect(item)"
			[ngClass]="(selectedItems | has : item) ? 'border-primary' : 'border-white'"
			[attr.data-cy]="'cardSelect-card-' + id + '-' + (item.cyName ? item.cyName : item.id)"
		>
			<ng-container *ngTemplateOutlet="card; context: { $implicit: item }"> </ng-container>
		</div>
	</div>
	<evasys-error class="text-md-center" [errors]="errors" [id]="id"></evasys-error>
</div>
