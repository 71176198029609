import { AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { SameWidthService } from './same-width.service';
import { Subscription } from 'rxjs';

@Directive({
	selector: '[evasysSameWidth]',
})
export class SameWidthDirective implements AfterViewInit, OnInit {
	//region Inputs & Outputs
	@Required()
	@Input('evasysSameWidth')
	group: string;
	//endregion
	//region Variables
	private subscription: Subscription;
	private resizeObserver;
	private animationFrameId: number;
	//endregion
	constructor(
		private renderer: Renderer2,
		private readonly elementRef: ElementRef,
		private readonly sameWidthService: SameWidthService
	) {}

	ngOnInit() {
		this.resizeObserver = new ResizeObserver(() => {
			if (this.animationFrameId) {
				cancelAnimationFrame(this.animationFrameId);
			}
			this.animationFrameId = requestAnimationFrame(() => {
				this.sameWidthService.registerElement(this.elementRef, this.group);
			});
		});
		this.resizeObserver.observe(this.elementRef.nativeElement);
	}

	//region Events
	ngAfterViewInit() {
		this.sameWidthService.registerElement(this.elementRef, this.group);
		this.subscription?.unsubscribe();
		this.renderer.setStyle(
			this.elementRef.nativeElement,
			'min-width',
			this.sameWidthService.width(this.group) + 'px'
		);
		this.subscription = this.sameWidthService.widthsChanges(this.group).subscribe((newWidth) => {
			this.renderer.setStyle(this.elementRef.nativeElement, 'min-width', newWidth + 'px');
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.sameWidthService.deregister(this.elementRef, this.group);
		this.resizeObserver.unobserve(this.elementRef.nativeElement);
	}

	//endregion
}
