import { AfterContentChecked, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { ReportType } from '@evasys/globals/evainsights/constants/types';
import { ActivatedRoute } from '@angular/router';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import {
	getReportIdFromParamMap,
	getReportTemplateIdFromParamMap,
} from '@evasys/globals/evainsights/helper/url-params';
import { paths } from '@evasys/globals/evainsights/constants/paths';
import { RouteDataParams } from '@evasys/globals/evainsights/constants/route-data-params';
import { ReportProcessingService, ReportService } from '@evasys/evainsights/shared/core';
import { finalize } from 'rxjs';
import { ReportAndReportTemplateFacadeService } from '@evasys/evainsights/stores/core';

@Component({
	selector: 'evainsights-configuration-menu',
	templateUrl: './configuration-menu.component.html',
})
export class ConfigurationMenuComponent implements AfterContentChecked {
	private activatedRoute = inject(ActivatedRoute);
	private cdref = inject(ChangeDetectorRef);
	protected reportProcessingService = inject(ReportProcessingService);
	private reportService = inject(ReportService);
	private facadeService = inject(ReportAndReportTemplateFacadeService);

	@Input() collapsed!: boolean;

	reportType!: ReportType;
	ReportTypeREPORT = ReportType.REPORT;
	ReportTypeTEMPLATE = ReportType.TEMPLATE;
	isPublishModalOpen = false;

	reportId: number;
	reportTemplateId: number;

	buttonDesignLink = ButtonDesignEnum.OUTLINE_LINK;

	constructor() {
		this.reportType = this.activatedRoute.snapshot.data[RouteDataParams.REPORT_TYPE];
		this.reportId = getReportIdFromParamMap(this.activatedRoute.snapshot.paramMap);
		this.reportTemplateId = getReportTemplateIdFromParamMap(this.activatedRoute.snapshot.paramMap);
	}

	ngAfterContentChecked() {
		this.cdref.detectChanges();
	}
	getPreviewPage = (id: number) => {
		const path =
			this.reportType == ReportType.REPORT
				? paths.getReport.build({ reportId: id })
				: paths.getReportTemplate.build({ reportTemplateId: id });
		return [path];
	};

	calculateItems = () => {
		if (this.reportProcessingService.isProcessing()) {
			return;
		}

		this.reportProcessingService.isProcessingUpdate.set(true);
		this.reportService
			.calculateItems(this.reportId)
			.pipe(finalize(() => this.reportProcessingService.isProcessingUpdate.set(false)))
			.subscribe((value) => this.facadeService.updateOneLocal(this.reportType, value));
	};
}
