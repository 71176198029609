import { AfterContentInit, Component, ContentChildren, Input, OnDestroy, QueryList } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { TableCellImageComponent } from '../table-cell-image/table-cell-image.component';
import { Subscription } from 'rxjs';

@Component({
	selector: 'evasys-table-cell-clickable',
	templateUrl: './table-cell-clickable.component.html',
	styleUrls: ['./table-cell-clickable.component.scss'],
})
export class TableCellClickableComponent implements AfterContentInit, OnDestroy {
	// region Input & Output

	@Input()
	@Required()
	public id: string;

	@Input()
	public clickable = true;

	@Input()
	public class: string;

	// endregion Input & Output

	// region Content- and ViewChild/ren

	@ContentChildren(TableCellImageComponent)
	public imageCells: QueryList<TableCellImageComponent>;

	// endregion Content- and ViewChild/ren

	// region Variables

	private subscriptions: Subscription[] = [];

	// endregion Variables

	// region Events

	public ngAfterContentInit(): void {
		this.updateImageIds();
		this.subscriptions.push(this.imageCells.changes.subscribe(() => this.updateImageIds()));
	}

	public ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	// endregion Events

	// region Methods

	public updateImageIds(): void {
		for (const cell of this.imageCells) {
			if (!cell.id) {
				cell.id = this.id;
			}
		}
	}

	// endregion Methods
}
