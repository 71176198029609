import { VisualizationType } from '../../../constants/types';
import { LegacyReportItemContentConfigBase, ReportItemContentBase } from './common';
import { TopicWordcloudConfigFields, TopicWordcloudData } from './topic-wordcloud-report-item-content.model';
import { TopicResponse } from '../../search/response.model';

export type TopicWordcloudResponsesContent = ReportItemContentBase<
	TopicWordcloudResponsesConfig,
	TopicWordcloudResponsesData
>;

// Content config

export type TopicWordcloudResponsesConfig = LegacyReportItemContentConfigBase<VisualizationType.WORDCLOUD_RESPONSES> &
	TopicWordcloudConfigFields;

// Data

export interface TopicWordcloudResponsesData {
	wordcloudContent: TopicWordcloudData;
	responses: TopicResponse[];
}

export interface ResponseData {
	text: string;
	score: number;
}
