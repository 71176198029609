<div
	class="d-flex justify-content-start bg-white position-sticky top-0 c-z-index-100 border-bottom flex-wrap border-light pb-1 pt-1"
>
	<evasys-button
		*ngFor="let toolbarItem of toolbarItems"
		[design]="buttonDesign.LINK"
		[id]="'richText-' + toolbarItem.format"
		(click)="onClick(toolbarItem)"
		[ngClass]="toolbarItem.active(toolbarItem.format) ? '' : 'opacity-50 c-gray-scale-100'"
	>
		<img
			evasysImage
			class="c-cursor-pointer m-lg-1 ms-md-0 me-md-0"
			[id]="toolbarItem.format"
			[class]="'c-w-20px c-h-20px'"
			[alt]="'icon-' + toolbarItem.format"
			src="assets/images/{{ toolbarItem.icon }}.svg"
			title="{{ 'reportItem.richTextButton.tooltip.' + toolbarItem.format | transloco }}"
		/>
	</evasys-button>
</div>
