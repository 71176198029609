import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evainsights-click-sidebar',
	templateUrl: './click-sidebar.component.html',
	styleUrls: ['./click-sidebar.component.scss'],
})
export class ClickSidebarComponent {
	@HostBinding('class')
	hostClass = 'col-auto h-100 p-0';

	@Input()
	collapsed = true;
	@Output()
	collapsedChange = new EventEmitter<boolean>();
	@Input()
	@Required()
	title!: string;
	@Input()
	visible = true;

	toggleCollapsed() {
		this.collapsed = !this.collapsed;
		this.collapsedChange.emit(this.collapsed);
	}
}
