import { Component, Input } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';

@Component({
	selector: 'evasys-collapse-button',
	templateUrl: './collapse-button.component.html',
})
export class CollapseButtonComponent {
	//region Input & Output
	@Input()
	@Required()
	public id: string;

	@Input()
	public labelCollapsed: string;

	@Input()
	public labelExpanded: string;

	@Input()
	public collapseComponent: CollapseLike;

	@Input()
	public labelClass?: string = '';

	@Input()
	public set label(label: string) {
		this.labelCollapsed = label;
		this.labelExpanded = label;
	}

	@Input()
	public set collapsed(collapse: boolean) {
		this.collapseComponent.toggle(!collapse);
	}

	//endregion

	//region Getter & Setter

	public get collapsed(): boolean {
		return this.collapseComponent._isCollapsed;
	}

	public get currentLabel(): string {
		return this.collapsed ? this.labelCollapsed : this.labelExpanded;
	}

	//endregion

	//region Variables

	public readonly buttonDesigns = ButtonDesignEnum;
	//endregion
}

interface CollapseLike {
	_isCollapsed: boolean;

	toggle(open?: boolean): void;
}
