import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'evainsights-page-control',
	templateUrl: './page-control.component.html',
})
export class PageControlComponent {
	@Input()
	totalElements = 10;

	@Input()
	set pageSize(pageSize: number) {
		this.pageSizeControl.setValue(pageSize);
	}

	@Input()
	loading = false;
	@Input()
	page = 1;
	@Output()
	pageChange: EventEmitter<number> = new EventEmitter<number>();
	@Output()
	pageSizeChange: EventEmitter<number> = new EventEmitter<number>();
	sizeOptions = new Map([
		[10, '10'],
		[25, '25'],
		[50, '50'],
	]);

	pageSizeControl = new FormControl(10);

	nextPage(newPage: number) {
		if (newPage !== this.page) {
			this.pageChange.emit(newPage);
		}
	}

	setPageSize(newPageSize: number) {
		if (newPageSize !== this.pageSize) {
			this.pageSizeChange.emit(newPageSize);
		}
	}
}
