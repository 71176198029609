import { Injectable } from '@angular/core';
import { EvasysState, FacadeService } from '@evasys/shared/core';
import { Store } from '@ngrx/store';
import { evasysActions } from '../actions';
import { evasysSelectors } from '../selectors';
import { SystemInfoModel } from '@evasys/globals/evasys/models/business/system-info.model';
import { EvasysLoadingStrategiesEnum } from '@evasys/globals/shared/enums/general/evasys-loadingStrategies.enum';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { Actions } from '@ngrx/effects';

@Injectable({
	providedIn: 'root',
})
export class SystemInfoService extends FacadeService<SystemInfoModel> {
	constructor(
		private readonly systemInfoStore: Store<EvasysState<SystemInfoModel>>,
		protected readonly actions: Actions
	) {
		super(systemInfoStore, evasysActions.fromSystemInfo, evasysSelectors.fromSystemInfo, actions);
		this.initialDispatch();
	}

	private initialDispatch() {
		firstValueFrom(this.systemInfoStore.select(evasysSelectors.fromSystemInfo.selectLoadedPages))
			.then((loadedPages) => {
				if (!loadedPages || loadedPages?.length == 0) {
					this.systemInfoStore.dispatch(
						evasysActions.fromSystemInfo.LoadPageActionSet.DEFAULT({
							payload: 1,
						})
					);
				}
			})
			.catch((err) => console.error('Can not initial load Evasys-SystemInfos: ' + err));
	}

	getManySystemInfos<T>(ids: string[], loadingStrategy: EvasysLoadingStrategiesEnum) {
		return this.getMany(ids, 1, loadingStrategy, -1).pipe(
			map(
				(entities: SystemInfoModel[]) =>
					entities.reduce((result, entity) => {
						const key = entity.id;
						result[key] = entity?.value ?? null;
						return result;
					}, {}) as any
			)
		);
	}
}
