import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
	selector: '[evainsightsReplaceIfEmpty]',
})
export class ReplaceIfEmptyDirective<T> implements OnChanges, AfterViewInit, OnDestroy {
	@Input('evainsightsReplaceIfEmpty')
	replaceOnFormControl?: AbstractControl | null;

	@Input()
	language?: string;

	private htmlInputElement?: HTMLInputElement;
	private readonly observer: MutationObserver;
	private defaultValue = '';

	constructor(private readonly el: ElementRef) {
		this.observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.type === 'childList') {
					this.updateEventListener();
				}
			});
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['language'] && !changes['language'].firstChange) {
			this.updateDefault();
		}
	}

	ngAfterViewInit() {
		this.observer.observe(this.el.nativeElement, { childList: true, subtree: true });
		this.updateDefault();
		this.initEventListener();
	}

	ngOnDestroy() {
		this.observer.disconnect();
		if (this.htmlInputElement) {
			this.htmlInputElement.removeEventListener('blur', this.onBlur);
		}
	}

	private updateDefault() {
		this.defaultValue = this.replaceOnFormControl?.value;
	}

	private initEventListener() {
		if (!this.htmlInputElement) {
			this.htmlInputElement = this.getInputElement();
			if (this.htmlInputElement) {
				this.htmlInputElement.addEventListener('blur', this.onBlur);
			}
		}
	}

	private updateEventListener() {
		if (this.htmlInputElement) {
			this.htmlInputElement.removeEventListener('blur', this.onBlur);
			this.htmlInputElement = this.getInputElement();
			if (this.htmlInputElement) {
				this.htmlInputElement.addEventListener('blur', this.onBlur);
			}
		}
	}

	private getInputElement() {
		return this.el.nativeElement.querySelector('input');
	}

	private onBlur = () => {
		if (this.defaultValue && this.replaceOnFormControl?.hasError('required')) {
			this.replaceOnFormControl.setValue(this.defaultValue);
		}
	};
}
