import {
	ApplicationRef,
	ChangeDetectorRef,
	Directive,
	ElementRef,
	Inject,
	Injector,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewContainerRef,
} from '@angular/core';
import { NgbTooltip, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT } from '@angular/common';

@Directive({
	selector: '[evasysTooltip]',
	providers: [NgbTooltipConfig],
})
export class TooltipDirective extends NgbTooltip implements OnInit, OnDestroy {
	constructor(
		private elementRef: ElementRef,
		private renderer: Renderer2,
		private injector: Injector,
		private viewContainerRef: ViewContainerRef,
		private config: NgbTooltipConfig,
		_ngZone: NgZone,
		@Inject(DOCUMENT) _document: any,
		_changeDetector: ChangeDetectorRef,
		private applicationRef: ApplicationRef
	) {
		super(
			elementRef,
			renderer,
			injector,
			viewContainerRef,
			config,
			_ngZone,
			_document,
			_changeDetector,
			applicationRef
		);
		this.addTooltipConfigs();
	}

	//region Input & Output
	@Input()
	set evasysTooltip(value: string) {
		if (value) {
			this.ngbTooltip = value;
			this.disableTooltip = false;
		}
	}
	//endregion

	//region Events
	override ngOnInit() {
		super.ngOnInit();
	}

	override ngOnDestroy() {
		super.ngOnDestroy();
	}

	//endregion

	//region Methods
	private addTooltipConfigs() {
		this.disableTooltip = true;
		this.openDelay = 300;
		this.closeDelay = 200;
		this.placement = 'bottom';
		this.container = 'body';
	}
	//endregion
}
