import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MenuEffects } from '../effects/menu.effects';
import { menuInitialState, menuReducer, menuMetaReducers } from '../reducers/menu.reducer';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		EffectsModule.forFeature([MenuEffects]),
		StoreModule.forFeature('menu', menuReducer, { metaReducers: menuMetaReducers, initialState: menuInitialState }),
	],
})
export class MenuModule {}
