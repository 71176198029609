<div
	[attr.data-cy]="'popper-Menu-' + id"
	*ngIf="(_items && _items.length > 0) || nothingFound"
	class="border p-3 rounded d-inline-flex flex-column card c-overflow-y-auto"
	[ngStyle]="{ 'max-height': maxHeight }"
>
	<button
		(click)="selectedItemAction.emit(item.eventResponse)"
		*ngFor="let item of _items"
		[attr.data-cy]="'popperMenu-' + id + '-' + item.id"
		class="p-2 border-0 text-start align-items-center"
		[ngClass]="{
			'text-primary c-cursor-def': checkedItemIds.includes(item.id)
		}"
	>
		<img
			evasysImage
			*ngIf="checkedItemIds.includes(item.id)"
			[isIcon]="false"
			[alt]="'checked'"
			[id]="'selectedInstructor'"
			class="c-w-20px c-h-20px me-1 my-1"
			src="./assets/images/icons/check2.svg"
		/>
		{{ item.title }}
	</button>
	<div class="p-2 mb-0 border-0 text-start align-items-center" *ngIf="nothingFound">
		{{ sharedUiConfiguration.translations.popperMenu.noItemsFound | transloco }}
	</div>
	<button
		*ngIf="!showAllItems && enableShowAllItemsButton"
		class="text-primary fw-bold p-2 c-cursor-pointer border-0 text-start"
		(click)="onShowAllItems()"
	>
		{{ sharedUiConfiguration.translations.popperMenu.showAll | transloco }}
	</button>
	<evasys-load-component *ngIf="waitForAllItems" [component]="LoadedComponentEnum.TEXT"></evasys-load-component>
</div>
