import { Component, Input } from '@angular/core';

@Component({
	selector: 'td[evasys-table-column]',
	templateUrl: './table-column.component.html',
})
export class TableColumnComponent {
	@Input()
	horizontalPosition: 'center' | 'start' | 'end' = 'center';

	@Input()
	verticalPosition: 'center' | 'start' | 'end' = 'center';
}
