import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'splice',
})
export class SplicePipe implements PipeTransform {
	transform<T>(value: T[], start: number, deleteCount: number, ...items: T[]): T[] {
		return [...value].splice(start, deleteCount, ...items);
	}
}
