import { Pipe, PipeTransform } from '@angular/core';
import { KeyValueModel } from '@evasys/globals/shared/models/component/wizard/key-value.model';

@Pipe({
	name: 'keyValueToMap',
})
export class KeyValueToMapPipe implements PipeTransform {
	transform(arr: KeyValueModel[]) {
		return new Map<any, string>(arr.map((kv) => [kv.key, typeof kv.value === 'object' ? kv.value.name : kv.value]));
	}
}
