import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { TableAreaService } from '../table-area/table-area.service';
import { TableAreaRegisterService } from '../table-area-register/table-area-register.service';

@Directive({
	selector: '[evasysTableAreaList]',
	providers: [TableAreaService, TableAreaRegisterService],
})
export class TableAreaListDirective<T extends { id: I }, I = number | string> implements OnInit, OnDestroy {
	//region Variables
	private context = {
		items: [],
	};

	private subscriptions: Subscription[] = [];

	//endregion
	constructor(
		private viewContainer: ViewContainerRef,
		private templateRef: TemplateRef<any>,
		private readonly tableAreaService: TableAreaService<T, I>
	) {}

	//region Events
	ngOnInit() {
		this.viewContainer.clear();
		this.viewContainer.createEmbeddedView(this.templateRef, this.context);
		this.subscriptions.push(
			this.tableAreaService.guiItems.subscribe((items) => {
				this.context.items = items;
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	//endregion
}
