import { ChartExportFormat } from './types';

export interface FormatInfo {
	mimeType: string;
	extension: string;
}

const formatInfos: Record<ChartExportFormat, FormatInfo> = {
	[ChartExportFormat.PNG]: { mimeType: 'image/png', extension: 'png' },
};

export function getFormatInfo(format: ChartExportFormat): Readonly<FormatInfo> {
	return formatInfos[format];
}
