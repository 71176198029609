import { Component, Input } from '@angular/core';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';

@Component({
	selector: 'evasys-more-menu',
	templateUrl: './more-menu.component.html',
})
export class MoreMenuComponent {
	//region Inputs & Outputs
	@Input()
	id: string;
	//endregion

	//region Variables
	public isMenuOpen = false;
	public buttonDesign = ButtonDesignEnum;
	//endregion

	//region Events
	onToggleMenu() {
		this.isMenuOpen = !this.isMenuOpen;
	}

	//endregion
}
