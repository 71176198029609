import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'sort',
})
export class SortPipe implements PipeTransform {
	transform<T>(array: T[] | undefined, sortKey: keyof T, sortAscending = true): T[] {
		if (array) {
			const sortedArray = [...array]?.sort((entryA: T, entryB: T) => {
				switch (typeof entryA[sortKey]) {
					case 'number':
						return Number(entryA[sortKey]) - Number(entryB[sortKey]);
					case 'string':
						return String(entryA[sortKey]).localeCompare(String(entryB[sortKey]));
					default:
						return 0;
				}
			});

			return sortAscending ? sortedArray : sortedArray?.reverse();
		} else {
			return array;
		}
	}
}
