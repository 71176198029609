import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { getLimits, getLinearToExponentialScale, getLogScale } from '@evasys/globals/evainsights/helper/charts/scales';
import { clamp } from 'lodash';
import { fallback } from '@evasys/evainsights/shared/util';
import { color, quantile, select, Selection } from 'd3';
import { LayoutResult, wordcloud, WordOutput } from 'wordcloud-layout';
import { D3BaseComponent, Size } from '../d3-base/d3-base.component';
import { Word } from '@evasys/globals/evainsights/models/wordcloud/word.model';
import { WordcloudContent } from '@evasys/globals/evainsights/models/report-item';

@Component({
	selector: 'evainsights-wordcloud-content',
	templateUrl: '../d3-base/d3-base.component.html',
})
export class WordcloudContentComponent extends D3BaseComponent implements OnInit, OnChanges, OnDestroy {
	//region Input & Output
	@Input({ required: true })
	content!: WordcloudContent;

	@Input()
	fontSizeRange: [number, number] = [12, 60];

	//endregion

	//region Variables
	public wordText?: Selection<SVGTextElement, WordOutput<Word>, SVGGElement, unknown>;
	//endregion

	//region Variables
	private wordcloud = wordcloud<Word>()
		.padding((d) => clamp(d.size * 0.12, 1.5, 8))
		.fontSize((d) => d.size)
		.text((d) => d.text)
		.size([0, 0]);
	layoutResult: LayoutResult<Word> | undefined = undefined;

	getColor!: (occurrences: number) => string;
	//endregion

	//region Events

	override ngOnInit() {
		super.ngOnInit();
		const font = fallback([
			{ family: 'IBM Plex Sans', weight: 600 },
			{ family: 'IBM Plex Sans', weight: 400 },
			{ family: 'sans-serif', weight: 600 },
			{ family: 'sans-serif', weight: 400 },
		]);

		this.wordcloud = this.wordcloud.fontFamily(font.family).fontWeight(font.weight);
	}

	override ngOnChanges(changes: SimpleChanges): void {
		super.ngOnChanges(changes);

		let doRelayout = false;
		let doRedraw = false;
		if (changes['content'] || changes['fontSizeRange']) {
			doRelayout = true;
			this.updateWordcloudWords();
		}
		if (
			changes['content'] &&
			changes['content'].previousValue?.config.textColor !== this.content.config.textColor
		) {
			doRedraw = true;
			this.getColor = this.getColorFunction();
		}

		// The render element is undefined only for the first ngOnChanges call during initialization before the view
		// exists. The current values will be reflected in the first render after the resize observer connects.
		if (this.renderElement) {
			if (doRelayout) {
				this.relayout();
			} else if (doRedraw) {
				this.tryDraw();
			}
		}
	}

	override ngOnDestroy() {
		super.ngOnDestroy();
	}

	override onResize(size: Size) {
		this.wordcloud = this.wordcloud.size([size.width, size.height]);
		this.updateWordcloudWords();
		this.relayout();
	}

	//endregion

	//region Methods

	relayout() {
		if (this.wordcloud.size().some((dimSize: number) => dimSize === 0)) {
			console.warn(
				'wordcloud.component.ts - render: Cannot render a wordcloud into a container of size 0. Aborting'
			);
			return;
		}

		this.layoutResult = this.wordcloud.start();
		this.tryDraw();
	}

	tryDraw = () => {
		this.ngZone.runOutsideAngular(() => {
			if (!this.renderElement) {
				throw Error('Cannot draw wordcloud without a render element');
			}

			if (!this.layoutResult) {
				throw Error('Wordcloud has not been layouted');
			}

			select(this.renderElement.nativeElement).selectAll('*').remove();

			// prettier-ignore
			this.wordText = select(this.renderElement.nativeElement)
				.append('svg')
				.attr('width', this.wordcloud.size()[0])
				.attr('height', this.wordcloud.size()[1])
				.append('g')
				.attr('transform', `scale(${this.layoutResult.scale})`)
				.selectAll('text')
				.data(this.layoutResult.words)
				.enter()
				.append('text')
				.style('font-size', (d) => `${d.fontSize}px`)
				.style('font-family', (d) => d.fontFamily)
				.style('font-weight', (d) => d.fontWeight)
				.style('fill', (d) => this.getColor(d.datum.occurrences))
				.attr('transform', (d) => `translate(${d.position[0]},${d.position[1]})`)
				.text((d) => d.text)
		});
	};

	updateWordcloudWords() {
		// word count heuristic based on a few sample wordclouds
		// for a wordcloud of size 655×320, around 30 words seem good
		const [width, height] = this.wordcloud.size();
		const wordCount = clamp((40 * (width * height)) / (655 * 320), 10, 60);
		const includedData = this.content.data.data
			.map((datum) => ({
				...datum,
				value: 'value' in datum ? datum.value : datum.occurrences,
			}))
			.sort((a, b) => b.value - a.value)
			.slice(0, wordCount);
		const dataValues = includedData.map((datum) => datum.value);

		const fontSizeScale = getLinearToExponentialScale({
			domain: getLimits(dataValues),
			range: this.fontSizeRange,
			exponentialPortion: 0.4,
			power: 7.0,
		});

		this.wordcloud = this.wordcloud.data(
			includedData.map((datum) => ({
				text: datum.name,
				size: fontSizeScale(datum.value),
				occurrences: datum.occurrences,
			}))
		);
	}

	getColorFunction(): (occurrences: number) => string {
		const dataOccurrences = this.content.data.data.map((datum) => datum.occurrences);
		const positiveOccurrences = dataOccurrences.filter((occurrences) => occurrences > 0).sort((a, b) => a - b);

		const alphaScale = getLogScale({
			domain: getLimits(dataOccurrences),
			range: [0.2, 1.0],
			offset: positiveOccurrences.length === 0 ? 1 : quantile(positiveOccurrences, 0.25),
		});

		return (occurrences: number) =>
			color(this.content.config.textColor)
				?.copy({ opacity: alphaScale(occurrences) })
				.formatRgb() ?? '#000';
	}

	//endregion
}
