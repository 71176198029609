import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ItemService, ReportService, RespondentFilterService } from '@evasys/evainsights/shared/core';
import { first, Observable, Subscription, switchMap } from 'rxjs';
import { Item } from '@evasys/globals/evainsights/models/item/item.model';
import { ItemType } from '@evasys/globals/evainsights/constants/types';
import { FormBuilder, FormControl, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { maxSelectedCheckboxes, minSelectedCheckboxes } from '@evasys/shared/util';
import { ActivatedRoute } from '@angular/router';
import { ReportFacadeService } from '@evasys/evainsights/stores/core';
import { EvasysLoadingStrategiesEnum } from '@evasys/globals/shared/enums/general/evasys-loadingStrategies.enum';
import { getReportIdFromParamMap } from '@evasys/globals/evainsights/helper/url-params';
import { Report, ReportTemplate } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { TranslocoService } from '@ngneat/transloco';
import {
	FilterAreaControl,
	FilterSelection,
} from '@evasys/globals/evainsights/models/filter/filter-area-control.model';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { MultiLangService } from '@evasys/evainsights/shared/util';
import { PagedItemSearchRequest } from '@evasys/globals/evainsights/models/search/ItemSearchRequest';

@Component({
	selector: 'evainsights-respondent-filter-modal',
	templateUrl: './respondent-filter-modal.component.html',
})
export class RespondentFilterModalComponent implements OnInit, OnDestroy {
	@Input()
	public opened!: boolean;

	@Output()
	public openedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	selectedItemKey = 'selectedItem';
	form!: FormGroup;
	report$: Observable<Report | ReportTemplate> | undefined;
	submitted = false;
	awaitBackendResponse = false;
	private selectedItemSubscription: Subscription | undefined = undefined;

	filterAreaControls = [
		{
			id: 'itemTypes' as const,
			items: [ItemType.SINGLE_CHOICE, ItemType.MULTIPLE_CHOICE, ItemType.SCALA],
			icon: { dark: 'text_code_check.svg', light: 'text_code_check_deactivated.svg' },
			identifier: (itemType: ItemType) => itemType,
			name: 'items.itemTypes',
			formatter: (itemType) => this.translocoService.selectTranslate('items.itemType.' + itemType),
			emptyResultsText: 'unit.noneFound',
		} satisfies FilterAreaControl<ItemType>,
	];

	get selectedValuesFormArray(): UntypedFormArray {
		return this.form.controls['selectedValues'] as UntypedFormArray;
	}

	formatterItems = (item: Item) => this.multiLangService.translate(item.text, '');

	constructor(
		private itemService: ItemService,
		private respondentFilterService: RespondentFilterService,
		private formBuilder: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private reportFacadeService: ReportFacadeService,
		readonly multiLangService: MultiLangService,
		private reportService: ReportService,
		private readonly translocoService: TranslocoService
	) {}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			selectedItem: [null, Validators.required],
			selectedValues: new UntypedFormArray([]),
		});
		this.selectedItemSubscription = this.form.controls[this.selectedItemKey].valueChanges.subscribe((item) => {
			if (item !== null) {
				this.selectedValuesFormArray.clearValidators();
				const itemOptionsCount = item.itemOptions.length;
				item.itemOptions.forEach(() => this.selectedValuesFormArray.push(new FormControl(false)));
				this.selectedValuesFormArray.addValidators(minSelectedCheckboxes(1));
				this.selectedValuesFormArray.addValidators(maxSelectedCheckboxes(itemOptionsCount - 1));
				this.submitted = false;
			}
		});
		this.activatedRoute.paramMap.subscribe((paramMap) => {
			const id = getReportIdFromParamMap(paramMap);
			if (id !== 0) {
				this.report$ = this.reportFacadeService.get({
					id: id,
					loadingStrategy: EvasysLoadingStrategiesEnum.STATEONLY,
				});
			}
		});
	}

	searchItems = (
		text: string,
		filter: FilterSelection<typeof this.filterAreaControls>
	): Observable<Page<Item>> | undefined => {
		return this.report$?.pipe(
			switchMap((reportBase) => {
				return this.itemService.getSearchResult({
					reportId: reportBase.id,
					itemTextName: text,
					surveys: 'topSurveys' in reportBase ? reportBase.topSurveys?.content.map(({ id }) => id) : [],
					forms: 'formId' in reportBase ? [reportBase.formId] : [],
					types: filter.itemTypes.length
						? filter.itemTypes
						: [ItemType.SCALA, ItemType.MULTIPLE_CHOICE, ItemType.SINGLE_CHOICE],
					withItemOptions: true,
					page: 0,
					size: 10,
				} satisfies PagedItemSearchRequest);
			})
		);
	};

	addRespondentFilterItemOption() {
		this.submitted = true;
		if (this.form.valid && !this.awaitBackendResponse) {
			this.awaitBackendResponse = true;
			this.report$?.pipe(first()).subscribe((reportBase) => {
				this.respondentFilterService
					.createNewRespondentFilterItemOptionByReportIdAndItemOptionIds(
						reportBase.id,
						this.selectedItem.itemOptions
							.filter((itemOption, index) => this.selectedValuesFormArray.controls[index].value)
							.map((selectedItemOption) => selectedItemOption.id)
					)
					.pipe(first())
					.subscribe(() => {
						this.reportService
							.getById(reportBase.id)
							.pipe(first())
							.subscribe((report) => {
								this.updateOneLocal(report);
							});
					});
			});
		}
	}

	private updateOneLocal(report: Report) {
		this.reportFacadeService.updateOneLocal({
			id: report.id,
			changes: report,
		});
		this.resetSelectedItem();
		this.awaitBackendResponse = false;
		this.opened = false;
	}

	resetSelectedItem() {
		this.selectedValuesFormArray.clearValidators();
		this.form.get(this.selectedItemKey)?.setValue(null);
		this.selectedValuesFormArray.clear();
		this.selectedValuesFormArray.reset();
	}

	openedChangeEmit($event: boolean | undefined) {
		this.resetSelectedItem();
		this.openedChange.emit($event);
		this.submitted = false;
	}

	get selectedItem(): Item {
		return this.form.get(this.selectedItemKey)?.value;
	}

	ngOnDestroy(): void {
		if (this.selectedItemSubscription !== undefined) {
			this.selectedItemSubscription.unsubscribe();
		}
	}
}
