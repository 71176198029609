import { Injectable } from '@angular/core';
import { EvasysState, FacadeService } from '@evasys/shared/core';
import { Store } from '@ngrx/store';
import { evasysActions } from '../actions';
import { evasysSelectors } from '../selectors';
import { WizardModel } from '@evasys/globals/shared/models/component/wizard/wizard.model';
import { Actions } from '@ngrx/effects';

@Injectable({
	providedIn: 'root',
})
export class WizardService extends FacadeService<WizardModel> {
	constructor(private readonly wizardStore: Store<EvasysState<WizardModel>>, protected readonly actions: Actions) {
		super(wizardStore, evasysActions.fromWizard, evasysSelectors.fromWizard, actions);
	}
}
