<div [attr.class]="class">
	<div class="d-flex mb-3" *ngIf="imagePreview && imagePreviewSrc">
		<evasys-overlay [openOnHover]="clearButton">
			<img
				evasysImage
				[isIcon]="true"
				[src]="imagePreviewSrc"
				[id]="id + 'Preview'"
				[class]="'c-h-100px w-auto mw-100 mx-auto'"
			/>
			<div overlayContent class="d-flex h-100 justify-content-center align-items-center">
				<button class="btn" type="button" (click)="onImageClear()">
					<img
						evasysImage
						[src]="'./assets/images/icons/delete2.svg'"
						[class]="'w-50 h-50'"
						[id]="'removeImage'"
						[isIcon]="true"
					/>
				</button>
			</div>
		</evasys-overlay>
	</div>

	<div class="d-flex">
		<evasys-textbox
			#textboxComponent
			[value]="fileName ?? (config?.translations?.uploadBox.noFile | transloco)"
			[readonly]="true"
			[id]="'uploadBox-' + id"
			[clearButton]="(!!fileInputElement.files[0] || input.value) && clearButton && !imagePreview"
			(clearAction)="onClear()"
			[errors]="errors"
			class="flex-fill"
		>
			<ng-content></ng-content>
		</evasys-textbox>
		<evasys-button
			[isDisabled]="disabled"
			class="ms-3"
			(evasysRegulationClick)="fileInputElement.click()"
			[id]="'uploadBox-' + id"
			>{{ config?.translations?.uploadBox.search | transloco }}</evasys-button
		>
		<input
			#fileInputElement
			type="file"
			[name]="formControlName"
			(change)="onFileChange($event)"
			[accept]="accept?.join(',')"
			[attr.data-cy]="'uploadBox-file-' + id"
			hidden
		/>
	</div>
</div>
