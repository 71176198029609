<div class="overflow-hidden">
	<div
		class="p-2 position-relative d-inline-flex flex-row flex-nowrap align-content-center rounded-top border-light"
		[class.border]="isStepFilterItemActive()"
	>
		<ng-content></ng-content>
	</div>

	<div
		class="d-flex flex-column flex-nowrap position-absolute w-50 z-index-fixed eva-topmenu-bg-color border-light rounded-bottom"
		[class.border]="isStepFilterItemActive()"
	>
		<div>
			<ng-container *ngTemplateOutlet="activeStepFilterItem()?.stepContent"></ng-container>
		</div>

		<div
			class="p-2 border-light w-100"
			[class.border-top]="isStepFilterItemActive()"
			*ngIf="isStepFilterItemActive()"
		>
			<evasys-button [id]="'stepFilter-clear'" [design]="'link'" class="me-2" (click)="onClearButtonClicked()">{{
				stepFilterConfig.translation.clear | transloco
			}}</evasys-button>
			<evasys-button
				[isDisabled]="activeStepFilterItem().isFirst()"
				[id]="'stepFilter-back'"
				class="me-2"
				(click)="onBackButtonClicked()"
				>{{ stepFilterConfig.translation.back | transloco }}</evasys-button
			>
			<evasys-button
				[isDisabled]="!activeStepFilterItem().isStepValid()"
				[id]="'stepFilter-next'"
				(click)="onNextButtonClicked()"
				(mouseenter)="onPreloadNextItems()"
				>{{ stepFilterConfig.translation.next | transloco }}</evasys-button
			>
		</div>
	</div>
</div>
