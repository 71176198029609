import { Injectable } from '@angular/core';
import { assertNotNullish, nonNullish } from '@evasys/globals/evainsights/typeguards/common';

@Injectable({
	providedIn: 'root',
})
export class SvgRasterizationService {
	/**
	 * Matches an url expression in css property values such as in
	 * ```
	 * src: url("/assets/font.ttf") format('truetype')
	 *      ^^^^^^^^^^^^^^^^^^^^^^^
	 * ```
	 */
	private urlExprRegExp = /url\(['"]?(.*?)['"]?\)/;
	private standaloneFontCss?: string;

	public async rasterize(svg: SVGSVGElement, imageMimeType: string, scale = 2) {
		const image = new Image();
		image.width = Math.round(scale * svg.width.baseVal.value);
		image.height = Math.round(scale * svg.height.baseVal.value);
		image.src = URL.createObjectURL(this.getSvgBlob(await this.withStandaloneFonts(svg)));

		return await new Promise<Blob>(
			(resolve) =>
				(image.onload = () => {
					const canvas = document.createElement('canvas');
					canvas.width = image.width;
					canvas.height = image.height;

					const ctx = canvas.getContext('2d');
					assertNotNullish(ctx);
					ctx.fillStyle = '#fff';
					ctx.fillRect(0, 0, canvas.width, canvas.height);
					ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
					URL.revokeObjectURL(image.src);

					canvas.toBlob((blob) => {
						assertNotNullish(blob);
						if (blob.type !== imageMimeType) {
							throw Error(`Export as ${imageMimeType} is not supported by the browser`);
						}
						resolve(blob);
					}, imageMimeType);
				})
		);
	}

	/**
	 * Returns a copy of the `svg` node with an additional style sheet that contains all font families used on the site
	 * as standalone data URLs, not requiring any network requests.
	 */
	private async withStandaloneFonts(svg: SVGSVGElement) {
		let copy = svg.cloneNode(true);
		if (!(copy instanceof SVGSVGElement)) {
			throw Error('Clone of SVG element unexpectedly returned a node with different type');
		}

		const defs = document.createElement('defs');
		copy.appendChild(defs);
		const style = document.createElement('style');
		defs.appendChild(style);
		style.innerHTML = await this.getStandaloneFontStyleCss();

		return copy;
	}

	private getSvgBlob(svg: SVGSVGElement) {
		const svgString = new XMLSerializer().serializeToString(svg);
		return new Blob([svgString], {
			type: 'image/svg+xml;charset=utf-8',
		});
	}

	private getStandaloneFontStyleCss = async () => {
		if (this.standaloneFontCss === undefined) {
			this.standaloneFontCss = await this.createStandaloneFontCss();
		}
		return this.standaloneFontCss;
	};

	private createStandaloneFontCss = async () => {
		const tempStyleNode = document.createElement('style');
		document.head.appendChild(tempStyleNode); // necessary because the `sheet` is `null` until attached to a document

		const tempSheet = nonNullish(tempStyleNode.sheet);

		const ruleCssTexts = await Promise.all(
			this.getDocumentFontFaceRules().map(async (rule) => {
				const ruleCopy = this.copyRuleToSheet(rule, tempSheet);
				await this.resolveFontFaceRuleSrcToDataUrl(ruleCopy);
				return ruleCopy.cssText;
			})
		);

		tempStyleNode.remove();
		return ruleCssTexts.join('\n');
	};

	private getDocumentFontFaceRules(): CSSFontFaceRule[] {
		return Array.from(document.styleSheets).flatMap((sheet) => {
			try {
				return Array.from(sheet.cssRules).filter(this.isCssFontFaceRule);
			} catch (error) {
				console.warn("Couldn't access stylesheet rules due to CORS policy:", sheet.href);
				return [];
			}
		});
	}

	private copyRuleToSheet(rule: CSSFontFaceRule, sheet: CSSStyleSheet): CSSFontFaceRule {
		const index = sheet.insertRule(rule.cssText);
		const copy = sheet.cssRules[index];
		if (!this.isCssFontFaceRule(copy)) {
			throw Error('Duplicating css font rule resulted in a rule of a different type');
		}
		return copy;
	}

	private isCssFontFaceRule(value: unknown): value is CSSFontFaceRule {
		return value instanceof CSSFontFaceRule;
	}

	private resolveFontFaceRuleSrcToDataUrl = async (rule: CSSFontFaceRule) => {
		if (!('src' in rule.style) || typeof rule.style.src !== 'string') {
			throw Error('Font face rule is missing expected "src" property');
		}

		const remoteSrc = rule.style.src;
		const urlExprMatch = this.urlExprRegExp.exec(remoteSrc);
		if (urlExprMatch === null) {
			throw Error('Font face rule is missing expected "url(...)" expression in the src property');
		}

		const fontUrl = urlExprMatch[1];
		const fontResponse = await fetch(fontUrl);
		const fontBlob = await fontResponse.blob();
		const fontDataUrl = await this.getBlobAsDataUrl(fontBlob);

		rule.style.src = remoteSrc.replace(this.urlExprRegExp, 'url(' + fontDataUrl + ')');
	};

	private getBlobAsDataUrl(blob: Blob) {
		const fileReader = new FileReader();
		const promise = new Promise<string>((resolve) => {
			fileReader.addEventListener('load', () => {
				resolve(fileReader.result as string);
			});
		});
		fileReader.readAsDataURL(blob);

		return promise;
	}
}
