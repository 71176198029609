import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
	name: 'filter',
})
export class FilterPipe<T> implements PipeTransform {
	transform(
		array: T[] | Observable<T[]>,
		fn: (item: T, ...args: any[]) => boolean,
		...args: any[]
	): T[] | Observable<T[]> {
		if (array instanceof Observable) {
			return array.pipe(map((array) => array.filter((e) => fn(e, ...args))));
		} else {
			return array.filter((e) => fn(e, ...args));
		}
	}
}
