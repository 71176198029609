import { ChangeDetectorRef, Injector, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { NavigateService } from '../services/navigate.service';

@Pipe({
	name: 'multiPageLink',
})
export class MultiPageLinkPipe implements PipeTransform, OnDestroy {
	private asyncPipe: AsyncPipe;

	constructor(private readonly injector: Injector, private readonly navigateService: NavigateService) {
		this.asyncPipe = new AsyncPipe(injector.get(ChangeDetectorRef));
	}

	ngOnDestroy() {
		this.asyncPipe.ngOnDestroy();
	}

	transform(value?: string): string | null {
		return this.asyncPipe.transform(this.navigateService.transformUrlObservable(value ?? ''));
	}
}
