<div
	[evasysHesitate]="openTimeout"
	(hesitate)="onHover($event)"
	*ngIf="visible"
	[@xGrowAnimation]="!_collapsed"
	[style.width]="_collapsed ? '50px' : 'initial'"
	class="h-100 limit-width-15 overflow-visible"
>
	<div class="d-flex flex-column justify-content-between h-100 eva-sidebar-bg-color">
		<div [class.c-hide-scrollbar]="_collapsed" class="mt-3 c-min-h-0">
			<ng-content></ng-content>
		</div>

		<div *ngIf="!_collapsed">
			<evasys-checkbox
				(checkedChange)="onPermanentlyOpen($event)"
				[checked]="_permanentlyOpen"
				class="h5 m-1 text-nowrap p-4 text-center d-flex justify-content-center eva-sidebar-color"
				[id]="'permanentlyOpen'"
				data-cy="button-permanentlyOpen"
				>{{ config?.translations?.sidebar.permanentlyOpen | transloco }}</evasys-checkbox
			>
		</div>
	</div>
</div>
