<div class="d-flex justify-content-center mb-2">
	<evasys-button
		[id]="'openRespondentFilterModal'"
		(click)="openAddRespondentFilterModal()"
		[isDisabled]="(report$ | async) === null"
		[design]="buttonDesign.OUTLINE_SECONDARY"
		>{{ 'report.addRespondentFilterItemOption' | transloco }}
	</evasys-button>
</div>
<div
	*ngFor="
		let respondentFilterItem of (report$ | async)?.respondentFilter.respondentFilterItems;
		let lastRespondentFilterItem = last
	"
	class="w-100 mt-3"
	[ngClass]="lastRespondentFilterItem ? '' : ' border-bottom border-light'"
	data-cy="card-respondentFilter"
>
	<div class="d-flex h4">
		<div class="c-min-w-0">
			<div
				class="text-truncate h4 flex-wrap"
				data-cy="respondentFilter-itemText"
				data-toggle="tooltip"
				title="{{ respondentFilterItem.text | translateMultiLang | async }}"
			>
				{{ respondentFilterItem.text | translateMultiLang | async }}
			</div>
			<div class="d-flex flex-wrap">
				<div
					*ngFor="let itemOption of respondentFilterItem.itemOptions; let last = last"
					class="text-truncate text-secondary h4 m-0"
					data-cy="respondentFilter-itemOption"
					data-toggle="tooltip"
					title="{{ itemOption.text | translateMultiLang | async }}"
				>
					{{ itemOption.text | translateMultiLang | async }}
					<ng-container *ngIf="!last">,&nbsp;</ng-container>
				</div>
			</div>
		</div>
		<evasys-button
			class="ms-auto"
			[id]="'delete-respondentFilter-' + respondentFilterItem.itemId"
			[design]="buttonDesign.LINK"
			(click)="deleteRespondentFilterItem(respondentFilterItem.itemId)"
		>
			<img
				evasysImage
				[alt]="'delete-icon'"
				[class]="'c-w-20px c-h-20px'"
				[id]="'deleteFilterItem'"
				[src]="'./assets/images/icons/navigate_cross.svg'"
			/>
		</evasys-button>
	</div>
	<evasys-loading-overlay [loading]="awaitBackendResponse"></evasys-loading-overlay>
</div>
<evainsights-respondent-filter-modal [(opened)]="isRespondentFilterModalOpen"></evainsights-respondent-filter-modal>
