import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, first, firstValueFrom } from 'rxjs';
import { ProductMenuItem } from '@evasys/shared/ui';
import { EvasysLoadingStrategiesEnum } from '@evasys/globals/shared/enums/general/evasys-loadingStrategies.enum';
import { StatusService } from '@evasys/evasys/shared/core';
import { EvasysStatusModel } from '@evasys/globals/evasys/models/general/evasys-status.model';
import { UserRightsEnum } from '@evasys/globals/shared/enums/business/user-rights.enum';

@Injectable({
	providedIn: 'root',
})
export class ProductService {
	private readonly statusService = inject(StatusService);
	private readonly productsObservable = new BehaviorSubject<ProductMenuItem[]>([]);

	private evaXDataToRequestList(evaXData: EvasysStatusModel) {
		return [
			this.getEvainsightsProduct(
				evaXData?.value.evaInsightsUrl
					? new URL(evaXData?.value.evaInsightsUrl + '?forwardinfo=' + evaXData?.value.forwardKey)
					: undefined
			),
			this.getEvasysPlusProduct(
				'Surveys',
				'surveys',
				'S_EVASYS_PLUS_SURVEYS',
				evaXData?.value.forwardKey,
				[UserRightsEnum.INSTRUCTOR, UserRightsEnum.DEAN_OF_STUDIES, UserRightsEnum.DEAN],
				evaXData?.value.evasysPlusUrl,
				true
			),
			this.getEvasysPlusProduct(
				'Workflows',
				'workflows',
				'S_EVASYS_PLUS_WORKFLOWS',
				evaXData?.value.forwardKey,
				[UserRightsEnum.ADMINISTRATOR, UserRightsEnum.FIRSTADMIN, UserRightsEnum.SUBSECTION_ADMINISTRATOR],
				evaXData?.value.evasysPlusUrl,
				true
			),
			this.getEvasysPlusProduct(
				'SurveyCreator',
				'datasets',
				'S_EVASYS_PLUS_SURVEY_CREATOR',
				evaXData?.value.forwardKey,
				[UserRightsEnum.ADMINISTRATOR, UserRightsEnum.FIRSTADMIN, UserRightsEnum.SUBSECTION_ADMINISTRATOR],
				evaXData?.value.evasysPlusUrl,
				true
			),
			this.getEvasysPlusProduct(
				'Dashboards',
				'dashboards',
				'S_EVASYS_PLUS_DASHBOARDS',
				evaXData?.value.forwardKey,
				[UserRightsEnum.ADMINISTRATOR, UserRightsEnum.FIRSTADMIN, UserRightsEnum.SUBSECTION_ADMINISTRATOR],
				evaXData?.value.evasysPlusUrl,
				evaXData?.value.isDashboardFeatureAvailable ?? false
			),
		];
	}

	private getEvainsightsProduct(url?: URL): ProductMenuItem {
		return {
			name: 'S_INSIGHTS',
			id: 'evainsights',
			url,
			rights: [UserRightsEnum.ADMINISTRATOR, UserRightsEnum.FIRSTADMIN, UserRightsEnum.SUBSECTION_ADMINISTRATOR],
			isActive: true,
		};
	}

	private getEvasysPlusProduct(
		id: string,
		path: string,
		name: string,
		forwardKey: string,
		UserRights: UserRightsEnum[],
		url?: string,
		isActive?: boolean
	): ProductMenuItem {
		const urlString = (url?.endsWith('/') ? url + forwardKey : url + '/' + forwardKey) + '?target=' + path;
		return {
			id: 'evasysPlus' + id,
			name: name,
			url: url ? new URL(urlString) : undefined,
			rights: UserRights,
			isActive,
		};
	}

	public refreshProductUrls = async () => {
		await firstValueFrom(
			this.statusService.get<EvasysStatusModel>({
				loadingStrategy: EvasysLoadingStrategiesEnum.APIONLY,
				id: 'evaxdata',
			})
		).then((refreshedEvaXData) => {
			this.productsObservable.next(this.evaXDataToRequestList(refreshedEvaXData));
			return refreshedEvaXData;
		});
	};

	public getAvailableProducts() {
		this.statusService
			.get<EvasysStatusModel>({
				loadingStrategy: EvasysLoadingStrategiesEnum.STATEFALLBACKAPI,
				id: 'evaxdata',
			})
			.pipe(first())
			.subscribe((evaXData) => {
				this.productsObservable.next(this.evaXDataToRequestList(evaXData));
			});
		return this.productsObservable;
	}
}
