<evasys-modal-multiple-users
	#multipleUsersModal
	(openChange)="onUserSwitchOpenChange($event)"
	(submitChange)="onUserSwitchSubmit($event)"
></evasys-modal-multiple-users>

<evasys-topmenu
	*ngIf="(user | async) && (isNotLoginPage | async)"
	[menuItems]="
		(user | async | canAccess : [userRights.ADMINISTRATOR, userRights.SUBSECTION_ADMINISTRATOR])
			? topMenuitems
			: null
	"
	[notifications]="notifications | async"
	[role]="userRightDesignation[(user | async)?.activeRight] | transloco"
	[roleItems]="(user | async)?.userRights | userRightsDesignation : (user | async)?.activeRight"
	[username]="(user | async)?.fullName"
	[canSwitchUser]="canSwitchUser"
	[showSystemUsage]="showSystemUsage"
	[activeSystemUser]="activeSystemUser"
	[activeOnlineUser]="activeOnlineUser"
	[searchText]="searchText | async | transloco"
	[system]="system"
	[products]="productsObservable | async"
	[productRefreshMethod]="productService.refreshProductUrls"
	[logo]="logo ?? '../../images/logos/organization/system.png'"
	[logoAlt]="logoAlt"
	(menuResponse)="onMenuResponse($event)"
	(roleResponse)="onUserRightChange($event)"
	(logoutResponse)="onLogout()"
	(searchAction)="onSearch($event)"
	(helpAction)="onHelp($event)"
	(deleteNotificationAction)="onNotificationDelete($event)"
	(apiCallAction)="onApiCallAction($event)"
	(openUserSwitch)="onOpenUserSwitchAction()"
	(systemUsageCall)="onSystemUsageAction()"
	(userMenuOpened)="onUserMenuOpenAction()"
	(logoClickedAction)="onLogoClicked()"
></evasys-topmenu>
<div class="h-100">
	<evasys-sidebar-container [scrollbar]="false">
		<evasys-sidebar
			[visible]="(user | async) && (isNotLoginPage | async)"
			[permanentlyOpen]="isSidebarPermanentlyOpen | async"
			(permanentlyOpenChange)="onPermanentlyOpenChange($event)"
			(collapsedChange)="isSidemenuCollapsed = $event"
			[openTimeout]="300"
			leftSideBar
		>
			<evasys-sidemenu
				[(active)]="active"
				[sidemenuItems]="sidemenuItems"
				[collapsed]="isSidemenuCollapsed"
			></evasys-sidemenu>
		</evasys-sidebar>
		<div class="c-mh-0px flex-grow-1 align-self-stretch">
			<ng-content></ng-content>
		</div>
		<evasys-footer [footerText]="footerTextObservable | async">
			<evasys-legal-notice [id]="'dataPrivacyInformation'"></evasys-legal-notice>
		</evasys-footer>
	</evasys-sidebar-container>
</div>
