<div class="h-100 d-flex flex-column py-4 px-2 px-xl-3">
	<div class="px-2 mb-2" evasysIfContent>
		<ng-content select="[top]"></ng-content>
	</div>
	<div class="align-self-stretch h-100 overflow-auto px-2 c-mh-0px">
		<ng-content></ng-content>
	</div>
	<div class="mt-2 px-2" evasysIfContent>
		<ng-content select="[bottom]"></ng-content>
	</div>
</div>
