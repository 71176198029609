import { TranslocoService } from '@ngneat/transloco';

export const getPublishTimeCalendarTranslation = (translocoService: TranslocoService) =>
	JSON.stringify({
		time: translocoService.translate('common.time'),
		weekdays: [
			translocoService.translate('calendar.weekdays.monday'),
			translocoService.translate('calendar.weekdays.tuesday'),
			translocoService.translate('calendar.weekdays.wednesday'),
			translocoService.translate('calendar.weekdays.thursday'),
			translocoService.translate('calendar.weekdays.friday'),
			translocoService.translate('calendar.weekdays.saturday'),
			translocoService.translate('calendar.weekdays.sunday'),
		],
		months: [
			translocoService.translate('calendar.months.january'),
			translocoService.translate('calendar.months.february'),
			translocoService.translate('calendar.months.march'),
			translocoService.translate('calendar.months.april'),
			translocoService.translate('calendar.months.may'),
			translocoService.translate('calendar.months.june'),
			translocoService.translate('calendar.months.july'),
			translocoService.translate('calendar.months.august'),
			translocoService.translate('calendar.months.september'),
			translocoService.translate('calendar.months.october'),
			translocoService.translate('calendar.months.november'),
			translocoService.translate('calendar.months.december'),
		],
		invalidDate: translocoService.translate('domain.publishTime.invalid'),
	});
