import { Component, inject, OnInit } from '@angular/core';
import { getDateTimeInSystemTimezone } from '@evasys/evainsights/shared/util';
import { ReportType } from '@evasys/globals/evainsights/constants/types';
import { ActivatedRoute } from '@angular/router';
import { getReportIdOrReportTemplateIdFromParamMap } from '@evasys/globals/evainsights/helper/url-params';
import { EvasysLoadingStrategiesEnum } from '@evasys/globals/shared/enums/general/evasys-loadingStrategies.enum';
import { Observable } from 'rxjs';
import { ReportAndReportTemplateFacadeService } from '@evasys/evainsights/stores/core';
import { TranslocoService } from '@ngneat/transloco';
import { Report, ReportTemplate } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { RouteDataParams } from '@evasys/globals/evainsights/constants/route-data-params';
import { UiConfigService } from '@evasys/evainsights/shared/core';

@Component({
	selector: 'evainsights-report-metadata',
	templateUrl: './report-metadata.component.html',
})
export class ReportMetadataComponent implements OnInit {
	private activatedRoute = inject(ActivatedRoute);
	private facadeService = inject(ReportAndReportTemplateFacadeService);
	public translocoService = inject(TranslocoService);
	private uiConfigService = inject(UiConfigService);

	reportType!: ReportType;
	report: Observable<Report | ReportTemplate> | undefined;
	timeDateFormat = this.uiConfigService.getTimeDateFormat();
	getDateTimeInSystemTimezone = getDateTimeInSystemTimezone;

	ngOnInit(): void {
		this.reportType = this.activatedRoute.snapshot.data[RouteDataParams.REPORT_TYPE]
			? this.activatedRoute.snapshot.data[RouteDataParams.REPORT_TYPE]
			: ReportType.REPORT;

		this.activatedRoute.paramMap.subscribe((paramMap) => {
			const id = getReportIdOrReportTemplateIdFromParamMap(paramMap, this.reportType);
			if (id !== 0) {
				this.report = this.facadeService.get(id, this.reportType, EvasysLoadingStrategiesEnum.STATEONLY);
			}
		});
	}
}
