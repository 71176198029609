import { inject, Pipe, PipeTransform } from '@angular/core';
import { ReportLanguage } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { LanguageService } from '@evasys/evainsights/shared/core';

@Pipe({
	name: 'reportLanguageCode',
})
export class ReportLanguageCodePipe implements PipeTransform {
	private readonly languageService = inject(LanguageService);

	transform(reportLanguage: ReportLanguage | null | undefined): string {
		return this.languageService.getActiveSystemLanguageCode(reportLanguage?.primaryUserLanguage);
	}
}
