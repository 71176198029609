import { Component, Input } from '@angular/core';
import { AbstractInputComponent } from '../abstract-input/abstract-input.component';
import { ValidationErrorModel } from '@evasys/globals/evasys/models/component/validation-error.model';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'evasys-text-area',
	templateUrl: './text-area.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: TextAreaComponent,
			multi: true,
		},
	],
})
export class TextAreaComponent<T extends string | number | boolean> extends AbstractInputComponent<T> {
	//region Input & Output
	@Input()
	minLength: number;

	@Input()
	maxLength: number;

	@Input()
	min: number;

	@Input()
	max: number;

	@Input()
	rows: number;

	@Input()
	class?: string;

	@Input()
	errors: ValidationErrorModel[] = [];
	//endregion

	//region Variables
	private focused = false;
	public labelInside = true;
	//endregion

	//region Events

	override onWriteValue(value: T) {
		this.determineLabel(value);
	}

	onFocus(): void {
		if (!this.readonly) {
			this.labelInside = false;
			this.focused = true;
		}
	}

	onBlur(): void {
		this.focused = false;
		this.determineLabel(this.input.value);
	}
	//endregion

	//region Methods
	public isInvalid(): boolean {
		return !!this.errors.find((error) => error.trigger);
	}

	private determineLabel(value: T): void {
		if (
			value === undefined ||
			value === null ||
			(value !== null && value?.toString().length === 0 && !this.focused)
		) {
			this.labelInside = true;
		} else {
			this.labelInside = false;
		}
	}
	//endregion
}
