import {
	AfterViewInit,
	Component,
	computed,
	ContentChildren,
	EnvironmentInjector,
	EventEmitter,
	inject,
	Output,
	QueryList,
	runInInjectionContext,
	signal,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { RequiredStepDirective } from '../required-step/required-step.directive';
import { StepFilterComponent } from '../step-filter/step-filter.component';
import { Animations } from '@evasys/globals/shared/animations/animations';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { combineLatest } from 'rxjs';

@Component({
	selector: 'evasys-step-filter-item',
	templateUrl: './step-filter-item.component.html',
	animations: [Animations.yGrowAnimation(), Animations.nestedAnimations],
})
export class StepFilterItemComponent implements AfterViewInit {
	//region Inputs & Outputs

	@Output()
	loadAction = new EventEmitter<void>();

	@Output()
	clearAction = new EventEmitter<void>();

	//endregion

	//region ViewChilds
	@ViewChild('stepContent')
	stepContent: TemplateRef<unknown>;
	//endregion

	//region ContentChildren
	@ContentChildren(RequiredStepDirective)
	requiredStepDirectives: QueryList<RequiredStepDirective>;
	//endregion

	//region Injections
	private readonly stepFilterComponent = inject(StepFilterComponent);
	public readonly environmentInjector = inject(EnvironmentInjector);

	//endregion

	//region Variables

	public readonly isActive = computed(() => this.stepFilterComponent.activeStepFilterItem() === this);
	public readonly isStepValid = signal(false);
	public readonly isFirst = signal(false);
	public readonly canActivate = computed(() => this.stepFilterComponent.canStepFilterItemToBeActivated(this));

	public readonly isPreloaded = signal(false);
	//endregion

	//region Events
	ngAfterViewInit() {
		runInInjectionContext(this.environmentInjector, () => {
			combineLatest(
				this.requiredStepDirectives.map((requiredStepDirective) =>
					toObservable(requiredStepDirective.isControlValid)
				)
			)
				.pipe(takeUntilDestroyed())
				.subscribe((valid) => {
					this.isStepValid.set(valid.every((value) => value === true));
				});
		});
	}

	onActivate() {
		if (this.isActive() && this.stepFilterComponent.isFormValid()) {
			this.stepFilterComponent.activeStepFilterItem.set(null);
		} else if (this.stepFilterComponent.canStepFilterItemToBeActivated(this)) {
			this.stepFilterComponent.activeStepFilterItem.set(this);
		}
	}

	onPreload() {
		if (!this.isPreloaded() && this.canActivate()) {
			this.loadAction.emit();
			this.isPreloaded.set(true);
		}
	}
	//endregion

	//region Methods
	reset() {
		this.isPreloaded.set(false);
		this.clearAction.emit();
		if (this.isStepValid()) {
			this.requiredStepDirectives.forEach((requiredStepDirective) => {
				return requiredStepDirective.clearControl();
			});
		}
	}
	//endregion
}
