<div [class]="'input-group position-relative m-0 flex-nowrap ' + class" (click)="inputElement.focus()">
	<div class="position-relative flex-fill p-0">
		<textarea
			#inputElement
			[formControl]="input"
			class="form-control align-self-stretch c-resize-none"
			(focus)="onFocus()"
			(blur)="onBlur()"
			(input)="onInput()"
			type="text"
			[attr.id]="id"
			[readOnly]="readonly"
			[value]="input.value"
			autocomplete=""
			[attr.data-cy]="'textarea-' + id"
			[attr.maxlength]="maxLength"
			[attr.minlength]="minLength"
			[attr.max]="max"
			[attr.min]="min"
			[attr.rows]="rows"
		></textarea>

		<div
			class="form-label-wrapper overflow-hidden c-l-1 c-r-2 form-label-inside d-flex align-items-center ps-1"
			[class.form-label-inside]="labelInside"
			[style.height.px]="inputElement?.offsetHeight"
		>
			<label
				[attr.data-cy]="'textbox-label-' + id"
				[for]="id"
				class="text-nowrap form-label m-0 position-relative bg-white rounded"
			>
				<ng-content></ng-content>
				<span *ngIf="required">*</span>
			</label>
		</div>
	</div>
</div>
<evasys-error *ngIf="isInvalid()" [id]="'textbox-' + id" [errors]="errors" class="col-12"></evasys-error>
