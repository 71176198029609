import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'isMenuActive',
})
export class IsMenuActivePipe implements PipeTransform {
	transform(value: [number, number], arg: number): number | null {
		if (value) {
			return value[0] === arg ? value[1] + 1 : 0;
		}
		return null;
	}
}
