<div
	#dropdownElement="ngbDropdown"
	ngbDropdown
	class="d-inline-block m-0 w-100 dropdown-optional-container"
	[id]="id"
	[placement]="placement"
	[class.is-invalid]="isInvalid()"
	[class.is-focused]="focused && !isInvalid()"
	(openChange)="onOpenChange($event)"
	display="dynamic"
>
	<div class="btn px-0 py-0 d-inline-flex w-100" [class.disabled]="inputControl.disabled">
		<div class="position-relative h-100 flex-fill input-group">
			<input
				#inputElement
				ngbDropdownToggle
				class="form-control border-0 c-outline-0 p-1"
				[formControl]="inputControl"
				[attr.id]="id"
				[type]="type"
				[value]="inputControl.value"
				[attr.data-cy]="'textbox-' + id"
				(focus)="onFocus()"
				(blur)="onBlur()"
				(input)="onInput()"
				[attr.maxlength]="maxLength"
				[attr.minlength]="minLength"
				[attr.max]="max"
				[attr.min]="min"
				autocomplete="off"
			/>

			<evasys-note
				[note]="description"
				[class]="'position-absolute c-h-20px c-w-20px c-absolute-top c-absolute-right h-100 me-1'"
				class="d-block c-h-20px c-w-20px"
			></evasys-note>
		</div>

		<button
			*ngIf="items?.length > 0"
			ngbDropdownToggle
			class="btn pe-4 position-relative border-0 c-outline-0 shadow-none c-w-30px dropdown-button"
			[disabled]="inputControl.disabled"
			[class.is-invalid]="isInvalid()"
			[attr.data-cy]="'dropdown-optional-toggle-' + id"
			(focus)="onFocus()"
			(blur)="onBlur()"
		>
			<em class="fas fa-chevron-down"></em>
		</button>

		<div
			class="form-label-wrapper overflow-hidden c-l-1 c-r-6 form-label-inside d-flex align-items-center ps-1"
			[class.form-label-inside]="labelInside"
			[style.height.px]="inputElement?.offsetHeight"
		>
			<label [for]="id" class="text-nowrap form-label m-0 position-relative">
				<ng-content></ng-content>
				<span *ngIf="required">*</span>
			</label>
		</div>
	</div>

	<div evasysLazyLoad ngbDropdownMenu (pageChange)="pageChange.emit($event)" class="w-auto mh-200px">
		<button
			*ngFor="let item of items"
			(click)="onSelect(item)"
			ngbDropdownItem
			[disabled]="inputControl.disabled"
			[attr.data-cy]="'dropdown-optional-item-' + id + '-' + item"
			class="w-100"
		>
			{{ item }}
		</button>
	</div>

	<ng-container *ngFor="let error of errors">
		<div *ngIf="error.trigger" [attr.data-cy]="'dropdown-optional-error-' + id" class="invalid-feedback">
			{{ error.text }}
		</div>
	</ng-container>
</div>
