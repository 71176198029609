<ng-template #resultTemplate let-result let-term="term" let-text="text">
	<evainsights-typeahead-filter-result
		[contextInfos]="result | modify : getSurveyContextInfos"
		[text]="text"
		[term]="term"
	>
	</evainsights-typeahead-filter-result>
</ng-template>

<evainsights-filter-typeahead
	(ngModelChange)="onSelectSurveys($event)"
	[ngModel]="selectedSurveys()"
	[placement]="'bottom-end'"
	[maxTotalSelectedElements]="maxReportSurveys"
	[id]="'reportSurveys'"
	[search]="searchSurveys"
	[formatter]="formatterSurvey"
	[searchFailedText]="'error.text' | transloco"
	[emptyResultsText]="'survey.noneFound' | transloco"
	[design]="chipTypeaheadDesign.LIST"
	[itemIdentifier]="'id'"
	[popoverClass]="'c-resize-horizontal c-direction-rtl popover-w-lg'"
	[filterAreaControls]="filterAreaControls"
	[chipContentTemplate]="chipContentTemplate"
	[resultTemplate]="resultTemplate"
	[chipVisibleSize]="5"
	[title]="'survey.addSurvey' | transloco"
	[popoverContainer]="'body'"
	[errors]="[
		{
			id: 'noSurvey',
			trigger: hasValidationErrorRequired(),
			text: 'survey.error.required' | transloco
		},
		{
			id: 'maxSurveysExceeded',
			trigger: hasValidationErrorLimitExceeded(),
			text: 'survey.error.limitExceeded' | transloco : { count: maxReportSurveys }
		}
	]"
></evainsights-filter-typeahead>

<div *ngIf="surveysBelowMinRespondentCount() > 0">
	<hr />
	<ngb-alert
		type="warning"
		[dismissible]="false"
		class="text-wrap text-center"
		data-cy="reportSurveyList-belowMinRespondentCountWarning"
		[title]="'report.surveyRespondentCount.total.hover' | transloco : { count: surveysBelowMinRespondentCount() }"
		>{{ 'report.surveyRespondentCount.total' | transloco : { count: surveysBelowMinRespondentCount() } }}</ngb-alert
	>
</div>

<ng-template #chipContentTemplate let-chip>
	<div class="text-black-50 text-truncate h4 d-flex flex-column gap-1">
		<div
			class="d-flex align-items-center"
			[attr.data-cy]="'chip-selectedTypeaheadValue-reportSurveys-' + chip.id + '-form-' + chip.formShortName"
		>
			<img
				evasysImage
				[class]="'c-w-20px c-h-20px me-2 justify-content-center'"
				[id]="'form-icon'"
				[src]="'./assets/images/icons/desktop_deactivated.svg'"
				[alt]="'form-icon'"
				[title]="'common.form' | transloco"
			/>
			<div class="text-truncate justify-content-center" [title]="chip.formShortName">
				{{ chip.formShortName }}
			</div>
		</div>
		<div
			class="d-flex align-items-center"
			[attr.data-cy]="'chip-selectedTypeaheadValue-reportSurveys-' + chip.id + '-unit-' + chip.unitName"
		>
			<img
				evasysImage
				[class]="'c-w-20px c-h-20px me-2 justify-content-center'"
				[id]="'unit-icon'"
				[src]="'./assets/images/icons/home_deactivated.svg'"
				[alt]="'unit-icon'"
				[title]="'organization.unit' | transloco"
			/>
			<div class="text-truncate justify-content-center" [title]="chip.unitName">{{ chip.unitName }}</div>
		</div>
		<div
			class="d-flex align-items-center"
			[attr.data-cy]="'chip-selectedTypeaheadValue-reportSurveys-' + chip.id + '-leader-' + chip.leaderName"
		>
			<img
				evasysImage
				[class]="'c-w-20px c-h-20px me-2 justify-content-center'"
				[id]="'creator-icon'"
				[src]="'./assets/images/icons/nutzer_deaktiv.svg'"
				[alt]="'leader-icon'"
				[title]="'survey.leader' | transloco"
			/>
			<div class="text-truncate justify-content-center" [title]="chip.leaderName">
				{{ chip.leaderName }}
			</div>
		</div>
		<div class="d-flex align-items-center justify-content-between">
			<div
				class="d-flex align-items-center"
				[attr.data-cy]="'chip-selectedTypeaheadValue-reportSurveys-' + chip.id + '-period-' + chip.periodName"
			>
				<img
					evasysImage
					[class]="'c-w-20px c-h-20px me-2 justify-content-center'"
					[id]="'period-icon'"
					[src]="'./assets/images/icons/calendar_deactivated.svg'"
					[alt]="'period-icon'"
					[title]="'organization.period' | transloco"
				/>
				<div class="text-truncate justify-content-center" [title]="chip.periodName">
					{{ chip.periodName }}
				</div>
			</div>
			<div
				class="d-flex align-items-center"
				[attr.data-cy]="'chip-selectedTypeaheadValue-reportSurveys-' + chip.id + '-responseCount'"
			>
				<img
					evasysImage
					[class]="'c-w-20px c-h-20px me-2 justify-content-center'"
					[id]="'count-icon'"
					[src]="'./assets/images/icons/text_code_check_deactivated.svg'"
					[alt]="'count-icon'"
					[title]="'report.surveyRespondentCount.processed' | transloco"
				/>
				<evainsights-survey-filtered-respondent-count
					[survey]="chip"
					[reportRespondentFilter]="report().respondentFilter"
				></evainsights-survey-filtered-respondent-count>
			</div>
		</div>
		<evasys-loading-overlay
			class="c-z-index-1-important"
			[backdropColorClass]="'c-bg opacity-100'"
			[loading]="
				chip.formShortName === '' && chip.unitName === '' && chip.leaderName === '' && chip.periodName === ''
			"
		></evasys-loading-overlay>
	</div>
</ng-template>
<ng-container>
	<hr />
	<div *ngIf="selectedSurveys().length > maxNumberTopSurveys" class="d-flex justify-content-center mb-2">
		<evasys-button
			[id]="'reportSurveys-showMore'"
			(click)="isSurveysModalOpen = true"
			[design]="buttonDesign.OUTLINE_SECONDARY"
		>
			{{ 'common.showMore' | transloco }}
		</evasys-button>
	</div>
	<evainsights-report-survey-list-modal
		*ngIf="report()"
		[resultTemplate]="resultTemplate"
		[(isOpen)]="isSurveysModalOpen"
		[selectedSurveys]="selectedSurveys()"
		[reportRespondentFilter]="report().respondentFilter"
		(closeWithChanges)="onReportSurveyModalClosed($event)"
	></evainsights-report-survey-list-modal>
</ng-container>
