<nav class="nav mb-2">
	<a
		*ngFor="let item of _items; index as i"
		class="nav-link m-2 ms-4 c-w-50px c-h-50px d-flex align-items-center"
		[ngClass]="selectedFormControl === item.uniqueName ? 'active' : ''"
		(click)="onTopSelect(i)"
	>
		<!--<fa-icon [icon]="item.faIcon"></fa-icon> => module removed-->
	</a>
</nav>
<select
	class="form-select w-100 rounded overflow-auto"
	[multiple]="multiSelect"
	[size]="selectSize"
	[attr.data-cy]="'orderSelector-' + id"
>
	<option
		*ngFor="let item of selectItems; index as i"
		[value]="item.id ? item.id : item.name"
		[attr.selected]="selectionsFormGroup.get(selectedFormControl).value === (item.name || item.id) ? '' : null"
		(click)="onSelect(item.id ? item.id : item.name)"
	>
		{{ item.name }}
	</option>
</select>
