<div
	*ngIf="clickable"
	[id]="id"
	[class]="'d-flex c-cursor-pointer c-mw-0px h-100' + (class ? ' ' + class : '')"
	[attr.data-cy]="'tableColumn-clickable-' + id"
>
	<ng-container *ngTemplateOutlet="content"></ng-container>
</div>
<div *ngIf="!clickable" [class]="'d-flex'">
	<ng-container *ngTemplateOutlet="content"></ng-container>
</div>
<ng-template #content>
	<ng-content></ng-content>
</ng-template>
