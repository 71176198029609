<evasys-dropdown
	*ngIf="reportLanguages$ | async; let reportLanguages"
	class="mb-3"
	[id]="'language'"
	[title]="'domain.languageSelect' | transloco"
	[formControls]="[languageControl]"
>
	<evasys-dropdown-primary evasysDropdownToggle>
		<span evasysDropdownLabel>{{ 'domain.languageSelect' | transloco }}</span>
		<span value *evasysDropdownValue="let value = value; mapping: reportLanguages | toMap : 'id' : 'name'">{{
			value | translateMultiLang | async
		}}</span>
		<div *evasysDropdownMenu>
			<evasys-selector [forceSelection]="true" [formControl]="languageControl" [id]="'language'">
				<evasys-selector-item *ngFor="let language of reportLanguages" [value]="language.id">
					{{ language.name | translateMultiLang | async }}
				</evasys-selector-item>
			</evasys-selector>
		</div>
	</evasys-dropdown-primary>
</evasys-dropdown>
