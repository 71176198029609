import {
	AfterViewChecked,
	AfterViewInit,
	ContentChildren,
	Directive,
	ElementRef,
	forwardRef,
	OnDestroy,
	OnInit,
	QueryList,
} from '@angular/core';
import { ShowMoreComponent } from './show-more.component';
import { BehaviorSubject, fromEvent, merge, Subject, Subscription, throttleTime } from 'rxjs';

@Directive({
	selector: '[evasysShowMoreContainer]',
})
export class ShowMoreContainerDirective implements AfterViewInit, AfterViewChecked, OnDestroy, OnInit {
	@ContentChildren(forwardRef(() => ShowMoreComponent))
	private showMoreComponents!: QueryList<ShowMoreComponent>;

	private visibleComponentsSubject: BehaviorSubject<Array<ShowMoreComponent>> = new BehaviorSubject<
		Array<ShowMoreComponent>
	>([]);
	private resizeObserver: ResizeObserver;
	private animationFrameId: number;
	private resize$ = new Subject<void>();
	private readonly subscriptions: Array<Subscription> = new Array<Subscription>();
	private isFirstCheckDone = false;

	constructor(private elementRef: ElementRef) {}

	ngOnInit() {
		this.listenToResize();
	}

	ngAfterViewInit() {
		this.listenOnVisibleComponentsOverflow();
		this.listenOnVisibleComponents();
	}

	ngAfterViewChecked() {
		if (!this.isFirstCheckDone && this.elementRef.nativeElement.getBoundingClientRect().height) {
			this.checkForVisibleComponents();
			this.isFirstCheckDone = true;
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.resizeObserver.disconnect();
	}

	private listenOnVisibleComponentsOverflow() {
		this.subscriptions.push(
			merge(this.visibleComponentsSubject, this.resize$.pipe(throttleTime(100))).subscribe(() => {
				this.visibleComponentsSubject.value.forEach((component) => {
					component.isOverflow =
						component.content.nativeElement.scrollHeight > component.content.nativeElement.offsetHeight;
					component.updateDOM();
				});
			})
		);
	}

	private listenToResize() {
		this.resizeObserver = new ResizeObserver(() => {
			if (this.animationFrameId) {
				cancelAnimationFrame(this.animationFrameId);
			}
			this.animationFrameId = requestAnimationFrame(() => {
				this.resize$.next();
			});
		});
		this.resizeObserver.observe(this.elementRef.nativeElement);
	}

	listenOnVisibleComponents() {
		this.subscriptions.push(
			merge(fromEvent(this.elementRef.nativeElement, 'scroll'), this.resize$).subscribe(() => {
				this.checkForVisibleComponents();
			})
		);
	}

	private checkForVisibleComponents() {
		const container = this.elementRef.nativeElement;
		const components = this.showMoreComponents.map((component) => component.elementRef.nativeElement);

		const visibleComponents = components.reduce((visibleComponents, currentComponent, currentIndex) => {
			if (this.isVisible(currentComponent, container)) {
				visibleComponents.push(this.showMoreComponents.get(currentIndex));
			}
			return visibleComponents;
		}, []);

		this.visibleComponentsSubject.next(visibleComponents);
	}

	private isVisible(innerElement, container) {
		const { bottom, height, top } = innerElement.getBoundingClientRect();
		const containerRect = container.getBoundingClientRect();

		return top <= containerRect.top ? containerRect.top - top <= height : bottom - containerRect.bottom <= height;
	}
}
