import { ReportMultiLang } from '../../../common/multi-lang';
import { Period } from '../../../organization/period.model';
import { Item } from '../../../item/item.model';
import {
	ColorVisualDimension,
	ColumnVisualDimension,
	GroupVisualDimension,
	ItemDimension,
	ItemOptionDimension,
	PatternVisualDimension,
	PeriodDimension,
	RowVisualDimension,
	TopicDimension,
} from './dimensions';
import { Matrix } from './matrix';
import { NumericalStatisticsConfig } from '../../../report-item-creator';
import { TopicModel } from '../../../topic-modeling/topic-model.model';

// Config

export type AxisChartConfig = {
	title: ReportMultiLang<string>;
	colors: {
		colorSchemeId: number;
		reverse?: boolean;
	};
	statistics: {
		numerical: NumericalStatisticsConfig;
	};
	axes: {
		index: {
			label: ReportMultiLang<string>;
		};
		value: {
			label: ReportMultiLang<string>;
		};
	};
};

export type CountAxisChartConfig = AxisChartConfig & {
	axes: {
		value: {
			normalization: ValueAxisNormalization | null;
		};
	};
	hideZeroCounts?: boolean;
};

export interface ValueAxisNormalization {
	evidenceDimensionIndices: number[];
}

// Dimensions

export type AxisChartVisualDimension =
	| ColorVisualDimension
	| PatternVisualDimension
	| RowVisualDimension
	| ColumnVisualDimension
	| GroupVisualDimension;

export type AxisChartIndexDimension = ItemDimension | ItemOptionDimension | PeriodDimension | TopicDimension;

// Data

export interface AxisChartDataBase {
	isRandom: boolean;

	indexDomains?: Array<{ dimensionIndex: number; periodIds: number[] }>;

	// All the entities that are either directly or implicitly mentioned in the report item configuration.
	// This effectively acts as a sort of report item local "store" where the widget can look up whatever information it
	// needs for the visualization while avoiding as much redundancy as possible.
	// At some point in the future we might decide to move these to not be local to just the report item but rather shared
	// between all report items of a report such that we can e.g. also avoid loading them each time the interactive
	// filters change.
	// Examples:
	// 1. The report item has a dimension mapping based on the item options with IDs 5, 6, 7.
	//    If item options 5 and 6 belong to the item with ID 12 and item option 7 to the item with ID 13, the "items" field
	//    below would contain both items alongside _all_ their options.
	// 2. The report item has a period data dimension set to "present". The "periods" field below would list all periods
	//    that are present in the dataset
	periods?: Period[]; // incl. id and name
	items?: Item[]; // incl. text and option texts + ids for each
	topicModels?: TopicModel[];
}

export interface ResponseCountAxisChartData extends AxisChartDataBase {
	// corresponds to position in the dimension list
	responseCounts: Matrix;

	conditionalEvidences: ConditionalEvidence[];
}

export interface ConditionalEvidence {
	evidenceDimensionIndices: number[];
	evidence: Matrix;
}
