export enum SurveyStatusEnum {
	IN_PROGRESS,
	PROCESSED = 'PROCESSED',
	DATA_DELETED = 'DATA_DELETED',
	DATA_BELOW_MIN_FORM_COUNT = 'DATA_BELOW_MIN_FORM_COUNT',
	VALIDATION = 'VALIDATION',
	VERIFICATION = 'VERIFICATION',
	EXAM_WAIT_FOR_PARTICIPANTS = 'EXAM_WAIT_FOR_PARTICIPANTS',
	EXAM_SCORING = 'EXAM_SCORING',
	SURVEY_STATUS_APPROVAL = 'SURVEY_STATUS_APPROVAL',
	MASK_FAIL_REASON = 'MASK_FAIL_REASON',
	MASK_FAIL_REASON_1 = 'MASK_FAIL_REASON_1',
	MASK_FAIL_REASON_2 = 'MASK_FAIL_REASON_2',
	MASK_FAIL_REASON_3 = 'MASK_FAIL_REASON_3',
	MASK_FAIL_REASON_4 = 'MASK_FAIL_REASON_4',
	MASK_FAIL_REASON_5 = 'MASK_FAIL_REASON_5',
	MASK_FAIL_REASON_6 = 'MASK_FAIL_REASON_6',
	MASK_FAIL_REASON_7 = 'MASK_FAIL_REASON_7',
	MASK_FAIL_REASON_8 = 'MASK_FAIL_REASON_8',
	MASK_FAIL_REASON_MAX = 'MASK_FAIL_REASON_MAX',
}
