import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'in',
	pure: false,
})
export class InPipe implements PipeTransform {
	transform(item: unknown, array: unknown[]): unknown {
		return array?.includes(item);
	}
}
