import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '@evasys/shared/core';

@Injectable({
	providedIn: 'root',
})
export class LogoutGuard {
	public constructor(private readonly authService: AuthService) {}

	public canActivate(): Observable<boolean | UrlTree> {
		return this.authService.hasToken().pipe(
			switchMap((isAuthenticated) => {
				//Hat Token und ist nicht abgelaufen
				if (isAuthenticated) {
					return from(this.authService.logout());
				} else {
					return of(true);
				}
			})
		);
	}
}
