import { Component } from '@angular/core';
import { Size } from '../d3-base/d3-base.component';
import { BarChart } from '../../../charts';
import { AxisChartComponent } from '../axis-chart/axis-chart.component';
import { BarChartContent } from '@evasys/globals/evainsights/models/report-item';

@Component({
	selector: 'evainsights-bar-chart-content',
	templateUrl: '../d3-base/d3-base.component.html',
})
export class BarChartContentComponent extends AxisChartComponent<BarChartContent> {
	override draw(host: HTMLElement, size: Size) {
		new BarChart(host, this.ctx, this.content).render({
			size,
			reportLanguageId: this.language.id,
			decimalFormat: this.decimalFormat,
		});
	}
}
