import { BarChartGroupBy, BarChartOrientation, BarChartVisualizationType, ValueAxisUnit } from '../../constants/types';
import { ReportMultiLangMap } from './report-multi-lang-map';
import { ColorScheme } from '../report-item';

export interface AxisChartDetailConfiguration {
	customTitle?: ReportMultiLangMap<string>;
	title: ReportMultiLangMap<string>;
	indexAxisLabel: ReportMultiLangMap<string>;
	valueAxisLabel: ReportMultiLangMap<string>;
	itemLabels?: ReportMultiLangMap<string>[];
	itemOptionLabels?: ReportMultiLangMap<string>[];
	numericalStatistics: NumericalStatisticsConfig;
	excludeAbstentions?: boolean;
	colors: {
		colorScheme: ColorScheme;
		reverse?: boolean;
	};
	hideZeroCounts?: boolean;
}

export interface NumericalStatisticsConfig {
	showMean?: boolean;
	showMedian?: boolean;
	showStandardDeviation?: boolean;
	showResponseCount: boolean;
	showAbstentionCount?: boolean;
}

export interface VisualStatisticsConfig {
	showMean: boolean;
	showMedian: boolean;
	showStandardDeviation: boolean;
	showQuartile: boolean;
	showRange: boolean;
}

export interface BarChartDetailConfiguration extends AxisChartDetailConfiguration {
	valueAxisUnit: ValueAxisUnit;
	visualization: BarChartVisualizationType;
	orientation: BarChartOrientation;
	groupBy: BarChartGroupBy;
	visualStatistics?: VisualStatisticsConfig;
}
