<div class="d-flex py-2" data-cy="pagination-container" *ngIf="totalElements > 0">
	<div class="c-flex-1"></div>
	<ngb-pagination
		(pageChange)="nextPage($event)"
		[collectionSize]="totalElements"
		[disabled]="loading"
		[pageSize]="pageSizeControl.value"
		[page]="page"
		[maxSize]="5"
		[rotate]="true"
		[boundaryLinks]="true"
	>
	</ngb-pagination>
	<div class="d-flex align-items-center c-flex-1 justify-content-end">
		<span class="me-2">
			{{ 'common.pageSize' | transloco }}
		</span>
		<evasys-dropdown
			[isDisabled]="loading"
			[id]="'page-size'"
			data-cy="page-size-dropdown"
			id="page-size-dropdown"
			[formControls]="[pageSizeControl]"
		>
			<evasys-dropdown-primary evasysDropdownToggle>
				<span value *evasysDropdownValue="let value = value; mapping: sizeOptions">{{ value }}</span>
				<div *evasysDropdownMenu>
					<evasys-selector
						[formControl]="pageSizeControl"
						[forceSelection]="true"
						(selectionChange)="setPageSize($event)"
						[id]="'page-size'"
					>
						<evasys-selector-item
							*ngFor="let sizeOption of sizeOptions | keepMapOrder"
							[value]="sizeOption.key"
						>
							{{ sizeOption.value }}
						</evasys-selector-item>
					</evasys-selector>
				</div>
			</evasys-dropdown-primary>
		</evasys-dropdown>
	</div>
</div>
