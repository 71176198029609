import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, startWith, Subscription } from 'rxjs';
import { NgControl } from '@angular/forms';
import { getTypeaheadIdentifierValue } from '@evasys/globals/shared/helper/typeahead';
import { TypeaheadItemIdentifier } from '@evasys/globals/shared/models/component/typeahead/typeahead.model';

@Directive({
	selector: '[evainsightsOnlyValuesFromTypeaheadItems]',
})
export class OnlyValuesFromTypeaheadItemsDirective<T> implements OnInit, OnDestroy {
	@Input()
	itemIdentifier: TypeaheadItemIdentifier<T> = <TypeaheadItemIdentifier<T>>'id';

	@Input()
	set items(items: T[]) {
		this.validValues.next(items);
	}

	validValues = new BehaviorSubject<T[]>([]);
	subscription?: Subscription;

	constructor(private control: NgControl) {}

	ngOnInit() {
		if (!this.control.control) {
			throw Error('Control does not exist');
		}

		this.subscription = combineLatest([
			this.control.control.valueChanges.pipe(startWith(this.control.value)),
			this.validValues,
		]).subscribe(([value, validValues]) => {
			const validValuesIds = validValues.map((validValue) =>
				getTypeaheadIdentifierValue(validValue, this.itemIdentifier)
			);
			if (
				validValues &&
				value &&
				validValues.length > 0 &&
				!validValuesIds.includes(getTypeaheadIdentifierValue(value as T, this.itemIdentifier))
			) {
				this.control.reset(validValues[0]);
			}
		});
	}

	ngOnDestroy() {
		this.subscription?.unsubscribe();
	}
}
