import { Pipe, PipeTransform } from '@angular/core';
import { hasText } from '@evasys/globals/shared/helper/string';

@Pipe({
	name: 'hasText',
})
export class HasTextPipe implements PipeTransform {
	transform(value: string | null | undefined): boolean {
		return hasText(value);
	}
}
