import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, startWith, Subscription } from 'rxjs';
import { KeyValue } from '@angular/common';

@Directive({
	selector: '[evainsightsOnlyValuesFromItems]',
})
export class OnlyValuesFromItemsDirective<T> implements OnInit, OnDestroy {
	@Input()
	set items(items: Map<T, string> | KeyValue<T, string>[]) {
		if (Array.isArray(items)) {
			this.validValues.next(items.map((item) => item.key));
		} else {
			this.validValues.next(Array.from(items.keys()));
		}
	}

	validValues = new BehaviorSubject<T[] | null>(null);
	subscription?: Subscription;

	constructor(private control: NgControl) {}

	ngOnInit() {
		if (!this.control.control) {
			throw Error('Control does not exist');
		}

		this.subscription = combineLatest([
			this.control.control.valueChanges.pipe(startWith(this.control.value)),
			this.validValues,
		]).subscribe(([value, validValues]) => {
			if (validValues && validValues.length > 0 && !validValues.includes(value)) {
				this.control.reset(validValues[0]);
			}
		});
	}

	ngOnDestroy() {
		this.subscription?.unsubscribe();
	}
}
