import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CalenderTranslationService } from './services/calender-translation.service';
import { DatePickerService } from './services/date-picker.service';
import { DateTime } from './lib/utils/date';
import { Parser } from './lib/parser';
import { datePickerServiceFactory } from './services/date-picker.factory';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { assertNotNullish } from '../../../../../../../../../../../globals/evainsights/typeguards/common';

@Component({
  selector: 'evasys-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: DatePickerService,
      useFactory: datePickerServiceFactory,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CalendarComponent,
      multi: true,
    },
  ],
})
export class CalendarComponent implements OnInit, OnDestroy, ControlValueAccessor {
  //region Input & Output
  @Input()
  public translation?: string;

  @Input()
  public dateFormat?: string;

  @Input()
  public timeFormat?: string;

  @Input()
  public outputFormat: string = 'Y-m-d H:i:s';

  @Input()
  public dataCy: string;

  @Input()
  public label?: string;

  @Input()
  public initDate?: string | null;

  @Input()
  public required = true;

  public _disable?: boolean;
  @Input()
  set disable(disable: boolean | string) {
    disable = typeof disable === 'boolean' ? disable : disable === 'true';
    this._disable = disable ? true : undefined;
  }

  @Output()
  public outputDate: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public outputDisplayDate: EventEmitter<string> = new EventEmitter<string>();

  //endregion

  //region Variables

  isInvalid: boolean = false;

  datePattern: any = '';
  displayDate: string;
  internalDate: Date;
  defaultChar: string;

  //endregion

  constructor(
    public calenderTranslationService: CalenderTranslationService,
    public datePickerService: DatePickerService
  ) {}

  //region Events

  ngOnInit(): void {
    this.checkDataCy();
    this.setTranslation();
    this.setFormat();
    this.setInitDate();

    this.listenInternalDate();
    this.listenDisplayDate();

    this.defaultChar = DateTime.getDefaultChar(this.datePickerService.getInitFormat());
    this.datePattern = DateTime.createDatePattern(this.datePickerService.getInitFormat(), this.defaultChar);
  }

  ngOnDestroy() {
    this.datePickerService.internalDate.unsubscribe();
    this.datePickerService.displayDate.unsubscribe();
    this.datePattern = '';
  }

  onDate(date: Date): void {
    if (this.displayDate === this.datePickerService.outputDisplayFormat(date)) {
      return;
    }

    if (DateTime.dateIsValid(date)) {
      this.datePickerService.internalDate.next(date);
    }
  }

  onDateInputChange(event: any): void {
    const inputDate = event.target.value;

    if (this.displayDate === inputDate) {
      return;
    }

    if (inputDate === '' && this.required) {
      this.isInvalid = true;
      return;
    }

    if (this.displayDate !== '') {
      this.isInvalid = false;
    }

    if (inputDate.match(new RegExp(this.datePattern)) || (inputDate === '' && !this.required)) {
      this.isInvalid = false;
      const date: Date | null = inputDate
        ? Parser.parseDate(inputDate, this.datePickerService.getInitFormat(), this.defaultChar)
        : null;
      this.setState(date);
    } else {
      this.isInvalid = true;
    }
  }

  onKeyup(event: any): void {
    const inputDate = event.target.value;
    const format = this.datePickerService.getInitFormat();
    const nextChar = format[inputDate.length];

    if (event.key === 'Backspace') {
      return;
    }

    switch (nextChar) {
      case '.':
        event.target.value += '.';
        break;
      case '/':
        event.target.value += '/';
        break;
      case '-':
        event.target.value += '-';
        break;
      case ':':
        event.target.value += ':';
        break;
      case ' ':
        event.target.value += ' ';
        break;
    }

    if (inputDate.length > format.length && format.includes('a')) {
      event.target.value = inputDate.slice(0, format.length + 1);
    } else if (inputDate.length > format.length - 1 && format.includes('aaa')) {
      event.target.value = inputDate.slice(0, format.length - 1);
    } else if (inputDate.length > format.length) {
      event.target.value = inputDate.slice(0, format.length);
    }

    if (event.key === 'Enter') {
      this.onDateInputChange(event);
      event.preventDefault();
    }
  }

  //endregion

  //region Methods

  setState(date: Date | null): void {
    if (date === null || DateTime.dateIsValid(date)) {
      this.datePickerService.internalDate.next(date);
      this.isInvalid = false;
    } else {
      this.isInvalid = true;
    }
  }

  private checkDataCy() {
    if (!this.dataCy) {
      throw new Error('Please set the data-cy property of the calender component!');
    }
  }

  private setTranslation(): void {
    if (this.translation != undefined) {
      this.calenderTranslationService.setTranslation(JSON.parse(this.translation));
    } else {
      throw new Error('Input translation not found, please set translation input of calendar compoment.');
    }
  }

  private setFormat(): void {
    this.datePickerService.setOutputFormat(DateTime.toISOFormat(this.outputFormat));

    const timeFormat: string = DateTime.toISOFormat(this.timeFormat);
    const format: string = `${DateTime.toISOFormat(this.dateFormat)} ${timeFormat}`;

    if (timeFormat.includes('hh') && !format.includes('a') && !format.includes('aaa')) {
      this.datePickerService.setInitFormat(`${format} aaa`);
    } else if (timeFormat.includes('HH')) {
      let tmpFormat = format;
      if (format.includes('aaa')) {
        tmpFormat = tmpFormat.replace(' aaa', '');
      }

      if (format.includes('a')) {
        tmpFormat = tmpFormat.replace(' a', '');
      }
      this.datePickerService.setInitFormat(tmpFormat);
    } else {
      this.datePickerService.setInitFormat(format);
    }
  }

  private setInitDate(): void {
    if ((this.initDate?.length ?? 0) > 0) {
      assertNotNullish(this.initDate);
      const date: Date = Parser.parseInitDate(this.initDate);
      this.datePickerService.internalDate.next(date);
    } else if (this.initDate === null) {
      this.datePickerService.internalDate.next(null);
    } else {
      this.datePickerService.internalDate.next(new Date());
    }
  }

  private listenInternalDate(): void {
    this.datePickerService.internalDate.subscribe((value: Date | null) => {
      if (value) {
        const date = this.datePickerService.outputDisplayFormat(value);
        this.datePickerService.displayDate.next(date);

        this.outputDate.emit(this.datePickerService.parseOutputFormat(value));
        this._onChange(this.datePickerService.parseOutputFormat(value));
        this.outputDisplayDate.emit(this.datePickerService.outputDisplayFormat(value));

        this.internalDate = value;
      } else if (!this.required) {
        this._onChange(value);
        this.outputDate.emit('');
        this.outputDisplayDate.emit('');
      }
    });
  }

  private listenDisplayDate(): void {
    this.datePickerService.displayDate.subscribe((value: string) => {
      this.displayDate = value;
    });
  }

  //endregion

  //region ControlValueAccessor
  _onChange: any = () => {
    //default
  };
  _onTouched: any = () => {
    //default
  };

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disable = isDisabled;
  }

  writeValue(date: unknown): void {
    if (date instanceof Date) {
      this.datePickerService.internalDate.next(date);
    }
  }

  //endregion
}
