import { Directive, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { Selection } from 'd3';
import { WordOutput } from 'wordcloud-layout';
import { Word } from '@evasys/globals/evainsights/models/wordcloud/word.model';
import { WordcloudContentComponent } from './wordcloud-content.component';
import { WordClickEvent } from '@evasys/globals/evainsights/models/wordcloud/word-click-event.model';

@Directive({
	selector: '[evainsightsClickableWords]',
})
export class ClickableWordsDirective implements OnInit {
	//region Input & Output
	@Input()
	searchValue?: string;

	@Output()
	searchValueChange = new EventEmitter<string>();

	@Output()
	wordClicked = new EventEmitter<WordClickEvent>();
	//endregion

	constructor(private readonly wordcloudContentComponent: WordcloudContentComponent, public ngZone: NgZone) {}

	//region Events
	ngOnInit() {
		const tryDraw = this.wordcloudContentComponent.tryDraw;
		this.wordcloudContentComponent.tryDraw = () => {
			tryDraw();
			this.onWordClicked(this.wordcloudContentComponent.wordText);
		};
	}

	onWordClicked<T>(wordText?: Selection<SVGTextElement, WordOutput<Word>, SVGGElement, T>) {
		if (wordText) {
			wordText.style('cursor', 'pointer').on('click', (mouseEvent: MouseEvent, wordOutput: WordOutput<Word>) => {
				this.wordClicked.emit({ mouseEvent, wordOutput });
				this.ngZone.run(() => {
					const userAgent = window.navigator.userAgent.toLowerCase();
					const isMacOs = userAgent.indexOf('macintosh') !== -1 || userAgent.indexOf('mac os x') !== -1;
					if (isMacOs ? mouseEvent.metaKey : mouseEvent.ctrlKey) {
						this.toggleAppendingSearchValue(wordOutput.text);
					} else {
						this.searchValueChange.next(wordOutput.text);
						this.searchValue = wordOutput.text;
					}
				});
			});
		}
	}
	//endregion

	//region Methods

	private toggleAppendingSearchValue(value: string) {
		if (this.searchValue?.includes(value)) {
			const newValue = this.searchValue?.replace(value, '').trim();
			this.searchValueChange.next(newValue);
			this.searchValue = newValue;
		} else {
			const newValue = (this.searchValue ?? '') + ` ${value}`;
			this.searchValueChange.next(newValue);
			this.searchValue = newValue;
		}
	}
	//endregion
}
