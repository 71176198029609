<div *ngIf="pageCount > 1" class="d-flex flex-nowrap flex-row justify-content-between">
	<evasys-button
		class="mb-3 text-primary"
		[id]="'pagination-previous-' + id"
		[design]="buttonDesign"
		(click)="pageBackward()"
		[isDisabled]="backwardButtonDisabled()"
	>
		<img
			evasysImage
			[isIcon]="true"
			[id]="'pagination-arrowBack-' + id"
			[src]="'assets/images/icons/arrow_left.svg'"
			[class]="'c-w-20px c-h-20px me-1'"
		/>
		<span>{{ config?.translations?.pagination.backButtonText | transloco }}</span>
	</evasys-button>

	<ngb-pagination
		#ngbPagination
		[collectionSize]="totalItems"
		[pageSize]="pageSize"
		[(page)]="page"
		[maxSize]="maxSize"
		[directionLinks]="false"
		(pageChange)="ngbOnChange($event)"
		class="c-mw-50 overflow-auto m-1"
		[id]="'pagination-' + id"
	></ngb-pagination>

	<evasys-button
		[id]="'pagination-next-' + id"
		class="mb-3 text-primary"
		[design]="buttonDesign"
		(click)="pageForward()"
		[isDisabled]="fowardButtonDisabled()"
	>
		<span>{{ config?.translations?.pagination.nextButtonText | transloco }}</span>
		<img
			evasysImage
			[isIcon]="true"
			[id]="'pagination-arrowForward-' + id"
			[src]="'assets/images/icons/arrow_right.svg'"
			[class]="'c-w-20px c-h-20px ms-1'"
		/>
	</evasys-button>
</div>
