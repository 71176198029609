<evainsights-card-select
	[id]="'visualizationCard'"
	(selectedItemChange)="writeValue($event.visualizationType)"
	[errors]="errors"
	[items]="visualizationItems"
	[selectedItems]="findSelectedObject(selected)"
>
	<ng-template #card let-item>
		<h5 class="card-title">{{ item.labelTranslationKey | transloco }}</h5>
		<div class="card-body mx-auto p-0">
			<img
				alt="{{ item.labelTranslationKey | transloco }}"
				class="card-img c-h-150px c-object-fit-contain"
				src="/assets/images/pictures/{{ item.img }}"
			/>
		</div>
	</ng-template>
</evainsights-card-select>
