import { Component, Input } from '@angular/core';
import { LoadedComponentEnum } from '@evasys/globals/shared/enums/component/loaded-component.enum';

@Component({
	selector: 'evasys-load-component',
	templateUrl: './load-component.component.html',
	styleUrls: ['./load-component.component.scss'],
})
export class LoadComponentComponent {
	//#region Input & Output
	@Input()
	component?: LoadedComponentEnum;

	//#endregion Input & Output

	//#region Variables
	loadedComponent = LoadedComponentEnum;
	//#endregion Variables
}
