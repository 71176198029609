import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuitemModel } from '@evasys/globals/evasys/models/component/menu-item.model';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evasys-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
	//#region Input & Output
	@Input()
	@Required()
	id?: string;

	@Input()
	menuItems: MenuitemModel[] = [];

	@Input()
	title = 'empty';

	@Input()
	active = 0;

	@Output()
	activeChange = new EventEmitter<number>();

	@Input()
	labelRight = false;

	//#endregion Input & Output

	//#region Variables

	//#endregion Variables

	//#region Events

	onActiveChange(id: number) {
		this.activeChange.emit(id);
		//if is function
		if (this.menuItems[id]?.callback && {}.toString.call(this.menuItems[id]?.callback) === '[object Function]') {
			this.menuItems[id].callback();
		}
	}

	//#endregion Events
}
