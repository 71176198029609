import { CompactType, DisplayGrid, GridType } from 'angular-gridster2';
import { EvainsightsGridsterConfig } from '../models/report/grid';

// A grid size of 2*3*2 makes it possible to split the grid width into equally sized halves, thirds, and fourths.
// With 2*3*4=24, users can either place a single widget along the full width of 2*3*2=12 cells, two widgets next to
// each other with 3*2=6 cells, three widgets of 3*2=6 cells, or four widgets of 2*2=4 cells each.
export const GRID_COLUMNS = 2 * 3 * 2;

export function getGridsterConfig(): EvainsightsGridsterConfig {
	return {
		gridType: GridType.VerticalFixed,
		compactType: CompactType.CompactUp,
		displayGrid: DisplayGrid.None,
		pushItems: true,
		pushDirections: { south: true, north: false, east: false, west: false },
		swap: false,
		draggable: {
			enabled: true,
			ignoreContent: true,
			dragHandleClass: 'reportGrid-reportItem-dragHandle',
		},
		resizable: {
			enabled: true,
		},
		minCols: GRID_COLUMNS,
		maxCols: GRID_COLUMNS,
		minRows: 1,
		maxRows: 100,
		maxItemCols: 100,
		minItemCols: 3,
		maxItemRows: 100,
		minItemRows: 1,
		maxItemArea: 2500,
		minItemArea: 1,
		defaultItemCols: 1,
		defaultItemRows: 1,
		addEmptyRowsCount: 0,
		fixedRowHeight: 105,
		keepFixedHeightInMobile: true,
		margin: 5,
		disableScrollHorizontal: true,
		disableScrollVertical: true,
		disableWindowResize: true,
	};
}
