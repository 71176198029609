<evainsights-report-item-wrapper [reportItemStatus]="reportItemStatus" [titleText]="translatedTitle | async">
	<evainsights-wordcloud-content
		*ngIf="reportLanguage$ | async; let reportLanguage"
		class="c-flex-1 c-min-h-0"
		[content]="content"
		[language]="reportLanguage"
		[decimalFormat]="(uiConfig | async).decimalFormat"
		[fontSizeRange]="fontSizeRange"
	></evainsights-wordcloud-content>
</evainsights-report-item-wrapper>
