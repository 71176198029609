export enum EvasysPropertyTypeNameEnum {
	LIST = 'list',
	BUTTON = 'button',
	STRING = 'string',
	BOOL = 'bool',
	TEXT = 'text',
	TEXTAREA = 'textArea',
	IMAGE = 'img',
	INTEGER = 'int',
	COLOR = 'color',
	FLOAT = 'float',
	HEADLINE = 'headline',
}
