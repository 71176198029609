import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { SearchSelection } from '@evasys/globals/evainsights/models/search/search-selection.model';

@Component({
	selector: 'evainsights-button-reset-search-selection',
	templateUrl: './button-reset-search-selection.component.html',
})
export class ButtonResetSearchSelectionComponent {
	buttonDesignLink = ButtonDesignEnum.OUTLINE_LINK;

	@Input()
	hasNoSelection = true;

	@Output()
	searchSelectionChange = new EventEmitter<SearchSelection>();

	resetSelection() {
		if (!this.hasNoSelection)
			this.searchSelectionChange.emit({
				units: [],
				forms: [],
				participationEvents: [],
				periods: [],
				programmes: [],
				leaders: [],
			});
	}
}
