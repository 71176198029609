<div class="c-cursor-pointer">
	<button
		class="p-1 m-0 rounded fw-bold d-flex flex-row flex-nowrap align-items-center border-0 h5"
		(click)="onActivate()"
		type="button"
		[ngClass]="{
			'bg-muted': isActive(),
			'opacity-50': !canActivate(),
			'text-primary': canActivate()
		}"
		(mouseenter)="onPreload()"
	>
		<img
			*ngIf="!isFirst()"
			evasysImage
			class="c-w-20px c-h-20px"
			[id]="'rightArrow'"
			[isIcon]="true"
			[src]="'assets/images/icons/navigate_right.svg'"
		/>
		<ng-content select="[stepFilterHeader]"></ng-content>
	</button>
	<ng-template #stepContent>
		<div @yGrowAnimation>
			<div @nestedAnimations class="position-relative h-auto">
				<ng-content></ng-content>
			</div>
		</div>
	</ng-template>
</div>
