import { Component, forwardRef, inject, Input, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, debounceTime, filter, first, map, Observable, share, startWith } from 'rxjs';
import { Item } from '@evasys/globals/evainsights/models/item/item.model';
import { ItemService } from '@evasys/evainsights/shared/core';
import { ItemType, ReportType } from '@evasys/globals/evainsights/constants/types';
import { ActivatedRoute } from '@angular/router';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import {
	latestData,
	LoadingState,
	trackLoadingStates,
	UiFormElementDescriptorService,
} from '@evasys/evainsights/shared/util';
import { tap } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { ValidationErrorModel } from '@evasys/globals/evasys/models/component/validation-error.model';
import { ReportAndReportTemplateFacadeService } from '@evasys/evainsights/stores/core';
import { getReportIdFromParamMap } from '@evasys/globals/evainsights/helper/url-params';
import { EvasysLoadingStrategiesEnum } from '@evasys/globals/shared/enums/general/evasys-loadingStrategies.enum';
import { Report, ReportTemplate } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { RouteDataParams } from '@evasys/globals/evainsights/constants/route-data-params';
import { PagedItemSearchRequest } from '@evasys/globals/evainsights/models/search/ItemSearchRequest';
import { isNotNullish } from '@evasys/globals/evainsights/typeguards/common';

@Component({
	selector: 'evainsights-batch-add-items',
	templateUrl: './report-batch-add-items.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ReportBatchAddItemsComponent),
			multi: true,
		},
	],
})
export class ReportBatchAddItemsComponent<T extends Report | ReportTemplate> implements OnInit, ControlValueAccessor {
	readonly itemService = inject(ItemService);
	readonly activatedRoute = inject(ActivatedRoute);
	readonly formBuilder = inject(FormBuilder);
	readonly translocoService = inject(TranslocoService);
	readonly facadeService = inject(ReportAndReportTemplateFacadeService);
	readonly uiFormElementDescriptorService = inject(UiFormElementDescriptorService);

	pageNumber = new BehaviorSubject(1);
	itemsRequest: Observable<LoadingState<Page<Item>>> | undefined;
	latestPage: Observable<Page<Item> | undefined> | undefined;
	searchTextItemTypeForm: FormGroup;
	itemTypes: Observable<Map<ItemType, string>>;
	reportType!: ReportType;
	reportId!: number;
	buttonDesign = ButtonDesignEnum;

	checkedItems: Map<number, Item> = new Map<number, Item>();

	@Input()
	errors: ValidationErrorModel[] = [];

	constructor() {
		this.searchTextItemTypeForm = this.formBuilder.group({
			searchText: [''],
			selectedItemType: [ItemType.ALL_QUANTITATIVE],
		});

		this.itemTypes = combineLatest([
			this.translocoService.selectTranslate('common.selectAll'),
			this.translocoService.selectTranslateObject('items.itemType'),
		]).pipe(
			map(([selectAllTranslation, itemTypeTranslations]) => {
				return new Map([
					[ItemType.ALL_QUANTITATIVE, selectAllTranslation],
					...[ItemType.SINGLE_CHOICE, ItemType.MULTIPLE_CHOICE, ItemType.SCALA].map(
						(itemType) => [itemType, itemTypeTranslations[itemType]] as const
					),
				]);
			})
		);
	}

	ngOnInit(): void {
		this.reportType = this.activatedRoute.snapshot.data[RouteDataParams.REPORT_TYPE];
		this.reportId = getReportIdFromParamMap(this.activatedRoute.snapshot.paramMap);
		this.itemsRequest = combineLatest([
			this.pageNumber,
			this.searchTextItemTypeForm.valueChanges.pipe(
				debounceTime(100),
				tap(() => this.pageNumber.next(1)),
				startWith(this.searchTextItemTypeForm.value)
			),
			this.facadeService
				.get(this.reportId, this.reportType, EvasysLoadingStrategiesEnum.STATEONLY)
				.pipe(filter(isNotNullish), first()),
		]).pipe(
			map(([page, { searchText, selectedItemType: selectedItemTypeId }, reportBase]) => {
				return this.itemService.getSearchResult({
					reportId: reportBase.id,
					itemTextName: searchText,
					surveys: 'topSurveys' in reportBase ? reportBase.topSurveys?.content.map(({ id }) => id) : [],
					forms: 'formId' in reportBase ? [reportBase.formId] : [],
					types:
						selectedItemTypeId == ItemType.ALL_QUANTITATIVE
							? [ItemType.MULTIPLE_CHOICE, ItemType.SINGLE_CHOICE, ItemType.SCALA]
							: [selectedItemTypeId],
					withItemOptions: true,
					page: page - 1,
					size: 5,
				} satisfies PagedItemSearchRequest);
			}),
			trackLoadingStates(),
			share()
		);

		this.latestPage = this.itemsRequest.pipe(latestData());
	}

	checkItem(item: Item, checked: boolean): void {
		if (checked) {
			this.checkedItems.set(item.id, item);
		} else {
			this.checkedItems.delete(item.id);
		}
	}

	uncheckAllItems() {
		this.checkedItems.clear();
	}

	isInvalid(): boolean {
		return this.errors.find((error) => error.trigger) !== undefined;
	}

	_onChange: (value: Map<number, Item>) => void = () => {
		// this is intentional
	};

	_onTouched = () => {
		// this is intentional
	};

	onChange() {
		this._onChange(this.checkedItems);
	}

	registerOnChange(fn: (_: Map<number, Item>) => void) {
		this._onChange = fn;
	}

	registerOnTouched(fn: () => void) {
		this._onTouched = fn;
	}

	writeValue(value: Map<number, Item> | null) {
		this.checkedItems = value ?? new Map<number, Item>();
		this.onChange();
	}

	includeItemWithId = (itemId: number): boolean => {
		return this.checkedItems.has(itemId);
	};
}
