import { Component, Input, OnInit } from '@angular/core';
import { LocalStorageSubject } from '@evasys/shared/util';
import { AccordionComponent } from '../accordion.component';
import { Animations } from '@evasys/globals/shared/animations/animations';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'div[evasys-accordion-item]',
	templateUrl: './accordion-item.component.html',
	animations: [Animations.yGrowAnimation('0px', '*'), Animations.noInitialAnimation],
})
export class AccordionItemComponent implements OnInit {
	//region Input & Outputs

	@Required()
	@Input()
	title!: string;

	@Required()
	@Input()
	id!: string;

	@Input()
	disable = false;

	@Input()
	set show(show: boolean) {
		this._show.next(show);
		if (show) {
			this.accordion.activeAccordionItemId = this.id;
		}
	}

	@Input()
	set syncWithLocalStorage(sync: boolean) {
		this._show.sync = sync;
	}

	//endregion

	//Getter & Setter
	get show() {
		return this._show.value;
	}

	get accordionId() {
		return this.accordion.id;
	}
	//endregion

	//region Variables
	private _initialShow = false;
	private _show = new LocalStorageSubject(this._initialShow);
	//endregion

	constructor(private readonly accordion: AccordionComponent) {}

	//region Events
	ngOnInit() {
		this._show.init(`accordion-${this.accordionId}-${this.id}`);
		this.accordion.registerAccordionItem(this);
	}

	onToggle() {
		if (!this.disable) {
			this.show = !this.show;
		}
	}

	//endregion
}
