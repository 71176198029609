import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, shareReplay, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { TableAreaArgs } from '@evasys/globals/shared/models/component/table-area/table-area-args.model';

@Injectable()
export class TableAreaService<T extends { id: I }, I = number | string> {
	//region Variables
	private readonly guiItemsSubject: BehaviorSubject<T[] | undefined> = new BehaviorSubject<T[] | undefined>(
		undefined
	);
	private readonly itemsSubject: BehaviorSubject<T[]> = new BehaviorSubject<T[]>(undefined);

	public guiItems = this.guiItemsSubject.asObservable();
	public items = this.itemsSubject.asObservable();

	//endregion

	//region Methods

	public setItems(items: T[], options?: { onlyInGui?: boolean }) {
		this.guiItemsSubject.next(items);
		if (!options?.onlyInGui) {
			this.itemsSubject.next(items);
		}
	}

	public delete(...ids: I[]) {
		this.guiItemsSubject.next(this.guiItemsSubject.value.filter((item) => !ids.includes(item.id)));
		this.itemsSubject.next(this.guiItemsSubject.value.filter((item) => !ids.includes(item.id)));
	}

	public update(...updates: T[]) {
		const ids = updates.map((update) => update.id);
		this.guiItemsSubject.next([...this.guiItemsSubject.value.filter((item) => !ids.includes(item.id)), ...updates]);
		this.itemsSubject.next([...this.guiItemsSubject.value.filter((item) => !ids.includes(item.id)), ...updates]);
	}

	public load = (tableAreaArgs: TableAreaArgs<T>) => {
		return this.search(tableAreaArgs).pipe(
			switchMap((searchedItems) => {
				this.guiItemsSubject.next(searchedItems);
				return this.sort(tableAreaArgs);
			})
		);
	};

	private sort = (tableAreaArgs: TableAreaArgs<T>): Observable<T[]> => {
		return this.guiItemsSubject.pipe(
			map((items) => this.sortArray(items, tableAreaArgs)),
			shareReplay(1)
		);
	};

	private sortArray(items: T[], tableAreaArgs: TableAreaArgs<T>) {
		return [...items].sort((entityA, entityB) => {
			const sortValueA = entityA[tableAreaArgs.sort.columnField];
			const sortValueB = entityB[tableAreaArgs.sort.columnField];

			if (typeof sortValueA === 'string' && typeof sortValueB === 'string') {
				return tableAreaArgs.sort.sortOrderAscending
					? sortValueA.localeCompare(sortValueB)
					: sortValueB.localeCompare(sortValueA);
			} else if (typeof sortValueA === 'number' && typeof sortValueB === 'number') {
				return tableAreaArgs.sort.sortOrderAscending ? sortValueA - sortValueB : sortValueB - sortValueA;
			}
			return 0;
		});
	}

	private search = (tableAreaArgs: TableAreaArgs<T>): Observable<T[]> => {
		if (this.itemsSubject.value.length === 0) {
			return of([]);
		}
		if (!tableAreaArgs.search.keys) {
			const keys = Object.keys(this.itemsSubject.value[0]).filter((key) => key !== 'id');
			tableAreaArgs.search.keys = keys as (keyof T)[];
		}

		return this.itemsSubject.pipe(
			map((items) =>
				items.filter((entity) => {
					return tableAreaArgs.search.keys.some((key) => {
						return (
							(typeof entity[key] === 'string' &&
								(entity[key] as string).includes(tableAreaArgs.search.value)) ||
							(typeof entity[key] === 'number' &&
								(entity[key] as number).toString().includes(tableAreaArgs.search.value))
						);
					});
				})
			),
			shareReplay(1)
		);
	};

	//endregion
}
