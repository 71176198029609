import {
	Component,
	forwardRef,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { Formatter } from '@evasys/shared/util';
import { ValidationErrorModel } from '@evasys/globals/evasys/models/component/validation-error.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import {
	FilterAreaControl,
	FilterSelection,
} from '@evasys/globals/evainsights/models/filter/filter-area-control.model';
import { buildEmptyFilterSelection } from '@evasys/globals/evainsights/helper/filter-selection';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { ChipTypeaheadComponent, TypeaheadComponent } from '@evasys/shared/ui';
import { ChipTypeaheadDesignEnum } from '@evasys/globals/shared/enums/component/chip-typeahead-design.enum';
import { PageRequest } from '@evasys/globals/shared/types/page-request.types';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evainsights-filter-typeahead',
	templateUrl: './filter-typeahead.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FilterTypeaheadComponent),
			multi: true,
		},
	],
})
export class FilterTypeaheadComponent<T, FilterAreaControls extends readonly FilterAreaControl<unknown>[]>
	implements OnDestroy, OnChanges, ControlValueAccessor
{
	@Input()
	@Required()
	id!: string;

	@Input()
	@Required()
	search!: SearchFunction<T, FilterAreaControls>;

	@Input()
	@Required()
	formatter!: Formatter<T>;

	@Input()
	@Required()
	title!: string;

	@Input()
	filterAreaControls: FilterAreaControls | undefined;

	@Input()
	@Required()
	searchFailedText!: string;

	@Input()
	@Required()
	emptyResultsText!: string;

	@Input()
	resultTemplate!: TemplateRef<ResultTemplateContext<T>>;

	@Input()
	errors: ValidationErrorModel[] = [];

	@Input()
	disabled = false;

	@Input()
	itemIdentifier = <keyof T>'id';

	@Input()
	autoFocus = false;

	@Input()
	isMultiSelect = true;

	@Input()
	design = ChipTypeaheadDesignEnum.DEFAULT;

	@Input()
	placement = 'bottom-start bottom-end top-start top-end';

	@Input()
	popoverContainer? = 'body';

	@Input()
	showSelectAll = true;

	@Input()
	maxTotalSelectedElements?: number;

	@Input()
	popoverClass = '';

	@Input()
	chipContentTemplate?: TemplateRef<T>;

	@Input()
	childItemIdentifier?: string;

	@Input()
	chipVisibleSize?: number;

	selected!: T[] | T;
	filter!: FilterSelection<FilterAreaControls>;
	searchFiltered!: (query: string, pageRequest: PageRequest) => Observable<Page<T>>;
	escapeKeySubscription: Subscription | undefined = undefined;
	resultsSubscription: Subscription | undefined = undefined;
	buttonStyle = ButtonDesignEnum;

	@ViewChild('typeahead') typeahead: TypeaheadComponent<T> | undefined;
	@ViewChild('chipTypeahead') chipTypeahead: ChipTypeaheadComponent<T> | undefined;

	constructor(@Inject(DOCUMENT) private document: Document) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['filterAreaControls'] && this.filterAreaControls) {
			this.filter = buildEmptyFilterSelection(this.filterAreaControls);
		}

		if (changes['filterAreaControls'] || changes['search']) {
			this.updateSearch();
		}
	}

	onFilterChange(filter: FilterSelection<FilterAreaControls>) {
		this.filter = filter;
		this.updateSearch();
	}

	private updateSearch() {
		const filter = this.filter;
		this.searchFiltered = (query, pageRequest) => this.search(query, filter, pageRequest);
	}

	ngOnDestroy() {
		for (const sub of [this.escapeKeySubscription, this.resultsSubscription]) {
			if (sub) {
				sub.unsubscribe();
			}
		}
	}

	select(value: T[]) {
		this.selected = value;
		this._onChange(value);
	}

	_onChange: (value: T[]) => void = () => {
		// this is intentional
	};

	_onTouched = () => {
		//default
	};

	registerOnChange(fn: (value: T[]) => void): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this._onTouched = fn;
	}

	writeValue(value: T[] | T) {
		this.selected = value;
	}
}

export type SearchFunction<T, FilterAreaControls extends readonly FilterAreaControl<unknown>[]> = (
	query: string,
	filter: FilterSelection<FilterAreaControls>,
	pageRequest: PageRequest
) => Observable<Page<T>>;

export interface ResultTemplateContext<T> {
	result: T;
	term: string;
}
