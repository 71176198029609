<div class="progress">
	<div
		class="progress-bar bg-{{ design }}"
		role="progressbar"
		[style]="'width: ' + value + '%;'"
		[attr.aria-valuenow]="value"
		aria-valuemin="0"
		aria-valuemax="100"
		[attr.data-cy]="'progressBar-' + id"
	>
		{{ value | number : '1.0-0' }}%
	</div>
</div>
