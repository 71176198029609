<evasys-modal
	(openedChange)="onCancel()"
	[closeable]="false"
	[id]="'writeUser'"
	[opened]="opened"
	[scrollable]="true"
	[size]="size"
>
	<div class="w-100 px-2 d-flex flex-row flex-nowrap" header>
		<evasys-header [headerLevel]="1" [id]="'wizard-' + id" [text]="headline" class="me-auto"></evasys-header>
		<evasys-progress-steps [current]="step" [total]="totalSteps + startStep"></evasys-progress-steps>
	</div>

	<ng-content></ng-content>

	<form [formGroup]="formGroup" class="px-2">
		<div *ngFor="let group of _propertyGroups; let i = index" [formGroupName]="group.name">
			<div *ngIf="i + startStep === step && !intermediateStep">
				<evasys-header [headerLevel]="2" [id]="group.name" [text]="group.title"></evasys-header>
				<ng-container *ngFor="let componentProperty of group.properties">
					<evasys-header
						*ngIf="componentProperty.type.typename === evasysPropertyTypeNameEnum.HEADLINE"
						[class]="'mt-4'"
						[headerLevel]="3"
						[id]="componentProperty.name"
						[text]="componentProperty.value"
					></evasys-header>
					<evasys-textbox
						*ngIf="
							(componentProperty.type.typename === evasysPropertyTypeNameEnum.STRING ||
								componentProperty.type.typename === evasysPropertyTypeNameEnum.TEXT) &&
							!componentProperty.type.constraints.invisible &&
							!componentProperty.type.constraints.hidden
						"
						[class]="'mt-3'"
						[descriptionAsInnerHTML]="true"
						[description]="componentProperty.toolTip"
						[errors]="[
							{
								trigger: getValidationErrorTrigger(group.name, componentProperty.name, 'required'),
								text: (config?.translations?.wizard.errorMessages.required | transloco),
								id: componentProperty.name
							},
							{
								trigger: getValidationErrorTrigger(
									group.name,
									componentProperty.name,
									'securePassword'
								),
								text: (config?.translations?.wizard.errorMessages.securePassword | transloco),
								id: componentProperty.name
							},
							{
								trigger: getValidationErrorTrigger(group.name, componentProperty.name, 'email'),
								text: (config?.translations?.wizard.errorMessages.emailType | transloco),
								id: componentProperty.name
							},
							{
								trigger: getValidationErrorTrigger(group.name, componentProperty.name, 'minlength'),
								text:
									(config?.translations?.wizard.errorMessages.minLength
									| transloco
										: {
												'0': getValidationError(group.name, componentProperty.name, 'minlength')
													?.requiredLength
										  }),
								id: componentProperty.name
							},
							{
								trigger: getValidationErrorTrigger(group.name, componentProperty.name, 'maxlength'),
								text:
									(config?.translations?.wizard.errorMessages.maxLength
									| transloco
										: {
												'0': getValidationError(group.name, componentProperty.name, 'maxlength')
													?.requiredLength
										  }),
								id: componentProperty.name
							},
							{
								trigger: getValidationErrorTrigger(group.name, componentProperty.name, 'min'),
								text:
									(config?.translations?.wizard.errorMessages.min
									| transloco
										: { '0': getValidationError(group.name, componentProperty.name, 'min') }),
								id: componentProperty.name
							},
							{
								trigger: getValidationErrorTrigger(group.name, componentProperty.name, 'equal', true),
								text: (config?.translations?.wizard.errorMessages.equal | transloco),
								id: componentProperty.name
							},
							{
								trigger: getValidationErrorTrigger(group.name, componentProperty.name, 'max'),
								text:
									(config?.translations?.wizard.errorMessages.max
									| transloco
										: { '0': getValidationError(group.name, componentProperty.name, 'max') }),
								id: componentProperty.name
							}
						]"
						[evasysDisabled]="
							formGroup?.get(group.name)?.get(componentProperty.type.constraints?.disabledIf?.fieldName)
								?.value | in : componentProperty.type.constraints?.disabledIf?.fieldValues
						"
						[formControlName]="componentProperty.name"
						[id]="componentProperty.name"
						[maxLength]="componentProperty.type.constraints.maxlen"
						[max]="componentProperty.type.constraints.max"
						[minLength]="componentProperty.type.constraints.minlen"
						[min]="componentProperty.type.constraints.min"
						[required]="componentProperty.type.constraints.required"
						[type]="componentProperty.type.constraints | constraintsToTextboxType"
						>{{ componentProperty.title }}</evasys-textbox
					>
					<evasys-text-area
						*ngIf="
							componentProperty.type.typename === evasysPropertyTypeNameEnum.TEXTAREA &&
							!componentProperty.type.constraints.invisible &&
							!componentProperty.type.constraints.hidden
						"
						[class]="'mt-3'"
						[errors]="[
							{
								trigger: getValidationErrorTrigger(group.name, componentProperty.name, 'required'),
								text: (config?.translations?.wizard.errorMessages.required | transloco),
								id: componentProperty.name
							},
							{
								trigger: getValidationErrorTrigger(group.name, componentProperty.name, 'minlength'),
								text:
									(config?.translations?.wizard.errorMessages.minLength
									| transloco
										: {
												'0': getValidationError(group.name, componentProperty.name, 'minlength')
													?.requiredLength
										  }),
								id: componentProperty.name
							},
							{
								trigger: getValidationErrorTrigger(group.name, componentProperty.name, 'maxlength'),
								text:
									(config?.translations?.wizard.errorMessages.maxLength
									| transloco
										: {
												'0': getValidationError(group.name, componentProperty.name, 'maxlength')
													?.requiredLength
										  }),
								id: componentProperty.name
							},
							{
								trigger: getValidationErrorTrigger(group.name, componentProperty.name, 'min'),
								text:
									(config?.translations?.wizard.errorMessages.min
									| transloco
										: { '0': getValidationError(group.name, componentProperty.name, 'min') }),
								id: componentProperty.name
							},
							{
								trigger: getValidationErrorTrigger(group.name, componentProperty.name, 'equal', true),
								text: (config?.translations?.wizard.errorMessages.equal | transloco),
								id: componentProperty.name
							},
							{
								trigger: getValidationErrorTrigger(group.name, componentProperty.name, 'max'),
								text:
									(config?.translations?.wizard.errorMessages.max
									| transloco
										: { '0': getValidationError(group.name, componentProperty.name, 'max') }),
								id: componentProperty.name
							}
						]"
						[evasysDisabled]="
							formGroup?.get(group.name)?.get(componentProperty.type.constraints?.disabledIf?.fieldName)
								?.value | in : componentProperty.type.constraints?.disabledIf?.fieldValues
						"
						[formControlName]="componentProperty.name"
						[id]="componentProperty.name"
						[maxLength]="componentProperty.type.constraints.maxlen"
						[max]="componentProperty.type.constraints.max"
						[minLength]="componentProperty.type.constraints.minlen"
						[min]="componentProperty.type.constraints.min"
						[required]="componentProperty.type.constraints.required"
						[rows]="3"
						>{{ componentProperty.title }}</evasys-text-area
					>
					<div
						*ngIf="
							componentProperty.type.typename === evasysPropertyTypeNameEnum.BOOL &&
							!componentProperty.type.constraints.hidden
						"
						class="d-flex flex-row flex-nowrap align-items-center"
					>
						<evasys-checkbox
							[class]="'mt-3'"
							[formControlName]="componentProperty.name"
							[id]="componentProperty.name"
							[isDisabled]="
								formGroup
									?.get(group.name)
									?.get(componentProperty.type.constraints?.disabledIf?.fieldName)?.value
									| in : componentProperty.type.constraints?.disabledIf?.fieldValues
							"
							>{{ componentProperty.title }}</evasys-checkbox
						>
						<evasys-note
							[class]="'ms-2 mt-2 c-w-20px c-h-20px'"
							[note]="componentProperty.toolTip"
							[placement]="'auto'"
						></evasys-note>
					</div>

					<evasys-dropdown
						*ngIf="
							componentProperty.type.typename === evasysPropertyTypeNameEnum.LIST &&
							componentProperty.type.constraints.keyValueList &&
							componentProperty.type.constraints.keyValueList.length < 8 &&
							!componentProperty.type.constraints.multiselect &&
							!componentProperty.type.constraints.hidden &&
							!componentProperty.type.constraints.invisible
						"
						[class]="'mt-3'"
						[formControls]="[formGroup.get(group.name).get(componentProperty.name)]"
						[id]="componentProperty.name"
						[isDisabled]="componentProperty.type.constraints.disabled"
					>
						<evasys-dropdown-primary evasysDropdownToggle>
							<span evasysDropdownLabel>{{ componentProperty.title }}</span>
							<span
								*evasysDropdownValue="
									let value = value;
									mapping: componentProperty.type.constraints.keyValueList
								"
								value
								>{{ value }}</span
							>
							<div *evasysDropdownMenu>
								<evasys-selector
									[formControlName]="componentProperty.name"
									[id]="componentProperty.name"
									[forceSelection]="true"
								>
									<evasys-selector-item
										*ngFor="let item of componentProperty.type.constraints.keyValueList"
										[value]="item.key"
									>
										{{ item.value }}
									</evasys-selector-item>
								</evasys-selector>
							</div>
						</evasys-dropdown-primary>
					</evasys-dropdown>
					<evasys-selector-primary
						*ngIf="
							(componentProperty.type.typename === evasysPropertyTypeNameEnum.LIST &&
								componentProperty.type.constraints.keyValueList &&
								componentProperty.type.constraints.keyValueList.length >= 8) ||
							componentProperty.type.constraints.multiselect
						"
						class="mt-3"
					>
						<p class="p-0 m-0" evasysSelectorLabel>{{ componentProperty.title }}</p>
						<evasys-selector
							[allowMultiSelect]="componentProperty.type.constraints.multiselect"
							[evasysDisabled]="
								formGroup
									?.get(group.name)
									?.get(componentProperty.type.constraints?.disabledIf?.fieldName)?.value
									| in : componentProperty.type.constraints?.disabledIf?.fieldValues
							"
							[formControlName]="componentProperty.name"
							[id]="componentProperty.name"
							[forceSelection]="!componentProperty.type.constraints.multiselect"
							class="w-100 mh-200px"
						>
							<evasys-selector-item
								(forbiddenDeselectionAction)="forbiddenDeselectionAction.emit([selectItem.name])"
								(selectStateChanged)="checkTriggerEvent(selectItem.triggerEvent, selectItem.name)"
								*ngFor="
									let selectItem of componentProperty.type.constraints.keyValueList
										| keyValueToSelectItems
								"
								[allowDeselection]="!selectItem.noDeselection"
								[value]="selectItem.id"
								>{{ selectItem.name }}</evasys-selector-item
							>
						</evasys-selector>
					</evasys-selector-primary>
					<evasys-upload-box
						(fileAcceptError)="onForbiddenFileType()"
						(fileSizeError)="onFileSizeTooBig()"
						*ngIf="
							componentProperty.type.typename === evasysPropertyTypeNameEnum.IMAGE &&
							!componentProperty.type.constraints.hidden
						"
						[accept]="['image/png', 'image/jpeg']"
						[class]="'mt-3'"
						[clearButton]="true"
						[formControlName]="componentProperty.name"
						[id]="componentProperty.name"
						[imagePreview]="true"
						[maxSizeKb]="50"
						[outputType]="'File'"
						>{{ componentProperty.title }}</evasys-upload-box
					>
					<evasys-button
						(evasysRegulationClick)="
							buttonAction.emit({ constraints: componentProperty.type.constraints, group: group.name })
						"
						*ngIf="
							componentProperty.type.typename === evasysPropertyTypeNameEnum.BUTTON &&
							!componentProperty.type.constraints.hidden
						"
						[class]="'mt-3'"
						[id]="componentProperty.name"
						>{{ componentProperty.title }}</evasys-button
					>
				</ng-container>
			</div>
		</div>
	</form>

	<div class="d-flex flex-row flex-nowrap w-100 justify-content-end" footer>
		<evasys-button
			(click)="onCancel()"
			*ngIf="showCancelButton"
			[design]="buttonDesign.LINK"
			[id]="'wizard-cancel-' + id"
			class="me-auto"
			>{{ config?.translations?.wizard.cancel | transloco }}</evasys-button
		>
		<evasys-button
			(click)="onBack()"
			[design]="buttonDesign.SECONDARY"
			[id]="'wizard-back-' + id"
			[isDisabled]="step === 0"
			class="mx-3"
			>{{ config?.translations?.wizard.back | transloco }}</evasys-button
		>
		<evasys-button (evasysRegulationClick)="onNext()" [id]="'wizard-next-' + id" [isDisabled]="disableNextButton">{{
			lastStep() && !intermediateStep
				? (config?.translations?.wizard.submit | transloco)
				: (config?.translations?.wizard.next | transloco)
		}}</evasys-button>
	</div>
</evasys-modal>
