import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import {
	OrderSelectorItemModel,
	SelectorItemModel,
} from '@evasys/globals/evasys/models/component/order-selector-item.model';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import set = Reflect.set;

@Component({
	selector: 'evasys-order-selector',
	templateUrl: './order-selector.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: OrderSelectorComponent,
			multi: true,
		},
	],
})
export class OrderSelectorComponent implements OnDestroy, ControlValueAccessor {
	//#region Input & Output
	@Input()
	@Required()
	id?: string;

	@Input()
	set items(items: OrderSelectorItemModel[] | undefined) {
		if (items && items?.length > 0) {
			this.createFormGroup(items.map((item) => item.uniqueName));
			this._items = items;
			this.selectItems = items[0].items;
			this.selectedFormControl = items[0].uniqueName;
		}
	}

	@Input()
	multiSelect = false;

	@Input()
	selectSize = 10;

	@Output()
	activeSelectChange: EventEmitter<string> = new EventEmitter<string>();

	@Input()
	set activeSelect(select: string | undefined) {
		this.onTopSelect(
			this._items?.findIndex(function (item) {
				return item.uniqueName === select;
			})
		);
	}

	//#endregion Input & Output

	//#region Variables
	public selectedFormControl?: string;
	public selectItems?: SelectorItemModel[];
	public selectionsFormGroup?: FormGroup;
	public _items?: OrderSelectorItemModel[];

	private subscriptions: Subscription[] = [];
	private selectedTopItem = 0;

	//#endregion Variables

	constructor(private formBuilder: FormBuilder) {
		//init
	}

	// region Settable events

	private _onChange: (value: any) => void = () => undefined;

	private _onTouched: any = () => undefined;

	// endregion Settable events

	//#region Methods

	createFormGroup(controlNames: string[]) {
		const controlConfig = {};
		controlNames.forEach((name) => set(controlConfig, name, ['']));
		this.selectionsFormGroup = this.formBuilder.group(controlConfig);
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.subscriptions.push(this.selectionsFormGroup.valueChanges.subscribe((change) => this._onChange(change)));
	}

	clearFollowingSelector() {
		if (this._items) {
			for (let i = this.selectedTopItem + 1; i < this._items?.length; i++) {
				this.selectionsFormGroup?.get(this._items[i].uniqueName)?.setValue(null);
			}
		}
	}

	//#endregion Methods

	//#region Events

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	onSelect(selected: string | number) {
		if (this.selectedFormControl) {
			this.selectionsFormGroup?.get(this.selectedFormControl)?.setValue(selected);

			this.clearFollowingSelector();
			if (this._items && this._items.length > this.selectedTopItem + 1)
				this.onTopSelect(this.selectedTopItem + 1);
		}
	}

	onTopSelect(selectedTopItem: number | undefined) {
		if (
			//are there items?
			this._items &&
			//and is the param not undefined
			selectedTopItem !== undefined &&
			//and is the selected item the first item?
			(selectedTopItem === 0 ||
				//or is the value of the item which is one step before of the selected item set?
				(this._items[selectedTopItem - 1] &&
					this.selectionsFormGroup?.get(this._items[selectedTopItem - 1].uniqueName)?.value))
		) {
			this.selectedTopItem = selectedTopItem;
			this.selectItems = this._items[selectedTopItem].items;
			this.selectedFormControl = this._items[selectedTopItem].uniqueName;
			this.activeSelectChange.emit(this.selectedFormControl);
		}
	}

	//#endregion Events

	//#region ControlValueAccessor

	registerOnChange(fn: (value: any) => void): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	setDisabledState(_isDisabled: boolean): void {
		//TODO: setDisable if necessary
	}

	writeValue(value: any): void {
		if (value) {
			this.selectionsFormGroup?.setValue(value);
		}
	}

	//#endregion ControlValueAccessor
}
