import { Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BreadcrumbItem } from '@evasys/shared/ui';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evainsights-sidebar-common',
	templateUrl: './sidebar-common.component.html',
})
export class SidebarCommonComponent {
	@Input()
	breadcrumbItems: Observable<BreadcrumbItem[]> = of([]);

	@Input()
	@Required()
	heading!: string;

	@Input()
	@Required()
	headingHover!: string;
}
