<evainsights-filter-typeahead
	[id]="'topicsSearch'"
	[searchFailedText]="'error.text' | transloco"
	[emptyResultsText]="'topic.noneFound' | transloco"
	[design]="design"
	[search]="searchTopics"
	[filterAreaControls]="filterAreaControls"
	[formatter]="formatterTopics"
	[title]="'reportItem.selectTopics' | transloco"
	[errors]="errors"
	[formControl]="control"
>
</evainsights-filter-typeahead>
