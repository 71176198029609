import { Directive, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: '[evasysDisabled]',
})
export class DisabledDirective implements OnInit {
	public _disabled;

	@Input()
	set evasysDisabled(disabled: boolean) {
		this._disabled = disabled;
		disabled ? this.control.control?.disable() : this.control.control?.enable();
	}

	constructor(private control: NgControl) {}

	ngOnInit() {
		//for the case that the Input is set before init
		this._disabled ? this.control.control?.disable() : this.control.control?.enable();
	}
}
