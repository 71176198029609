import { Component, Input, OnChanges } from '@angular/core';
import { ReportItemContentStatus } from '@evasys/globals/evainsights/constants/status';
import { TranslocoService } from '@ngneat/transloco';
import { ReportType } from '@evasys/globals/evainsights/constants/types';
import { ActivatedRoute } from '@angular/router';
import { RouteDataParams } from '@evasys/globals/evainsights/constants/route-data-params';

@Component({
	selector: 'evainsights-report-item-wrapper',
	templateUrl: './report-item-wrapper.component.html',
})
export class ReportItemWrapperComponent implements OnChanges {
	@Input()
	id = '';

	@Input()
	titleText!: string;

	@Input()
	isExportable = true;

	@Input()
	reportItemStatus?: ReportItemContentStatus;

	showOverlay = false;

	reportType: ReportType = ReportType.REPORT;

	constructor(readonly translocoService: TranslocoService, private activatedRoute: ActivatedRoute) {}

	ngOnChanges(): void {
		this.reportType = this.activatedRoute.snapshot.data[RouteDataParams.REPORT_TYPE];
		this.showOverlay =
			this.reportItemStatus !== ReportItemContentStatus.PROCESSED &&
			this.reportItemStatus !== undefined &&
			this.reportType === ReportType.REPORT;
	}
}
