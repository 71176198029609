import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ValidationErrorModel } from '@evasys/globals/evasys/models/component/validation-error.model';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';

@Component({
	selector: 'evainsights-editable-text',
	templateUrl: './editable-text.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: EditableTextComponent,
			multi: true,
		},
	],
})
export class EditableTextComponent implements ControlValueAccessor {
	@Input()
	@Required()
	id!: string;

	@Input()
	isEditDisabled = false;

	@Input()
	isReadonly = false;

	@Input()
	errors: ValidationErrorModel[] = [];

	@Input()
	formValid!: boolean;

	@Input()
	textClass = 'h1';

	@Input()
	elementClass = 'justify-content-center';

	@Output()
	editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	input = new FormControl();
	editMode = false;
	buttonLinkDesign = ButtonDesignEnum.LINK;

	_onChange: (value: string) => void = () => {
		//default
	};
	_onTouched = () => {
		//default
	};

	registerOnChange(fn: (value: string) => void) {
		this._onChange = fn;
	}

	registerOnTouched(fn: () => void) {
		this._onTouched = fn;
	}

	writeValue(value: string | null): void {
		if (value === null) {
			this.input.reset();
		}
		this.input.setValue(value);
	}

	onConfirmInputValue() {
		if (this.formValid) {
			this._onChange(this.input.value);
			this.editMode = false;
			this.editModeChange.emit(this.editMode);
		}
	}

	changeEditMode() {
		if (!this.isEditDisabled && this.formValid) {
			this.editMode = !this.editMode;
			this.editModeChange.emit(this.editMode);
		}
	}
}
