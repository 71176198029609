import { Directive, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ButtonToggleComponent } from './button-toggle.component';
import { Subscription } from 'rxjs';
import { Required } from '@evasys/globals/shared/decorators/decorators';

enum StateClasses {
	deactivated = 'px-2 text-nowrap border-0 text-dark',
	activated = 'px-2 text-nowrap bg-primary text-white border-0',
}

@Directive({
	selector: '[evasysToggleButton]',
})
export class ToggleButtonDirective<T> implements OnInit, OnDestroy {
	//region Inputs & Outputs
	@Input('evasysToggleButton')
	value: T;

	@Required()
	@Input()
	id!: string;
	//endregion

	//region HostBindings
	@HostBinding('class')
	elementClass = StateClasses.deactivated;
	//endregion

	//region Variables
	private valueChangeSubsription: Subscription;
	//endregion

	//region Hostlistener
	@HostListener('click', ['$event']) onClick() {
		this.buttonToggleComponent?.writeValue(this.value);
		this.buttonToggleComponent?.onChange();
		this.buttonToggleComponent?.onTouched();
	}
	//endregion

	constructor(private buttonToggleComponent: ButtonToggleComponent<T>, private readonly elementRef: ElementRef) {}

	//region Events
	ngOnInit() {
		this.elementRef.nativeElement.setAttribute(
			'data-cy',
			`buttonToggle-${this.buttonToggleComponent.id}-${this.id}`
		);
		this.checkIsActive(this.buttonToggleComponent?.value);
		this.valueChangeSubsription = this.buttonToggleComponent.valueChanges.subscribe((value) => {
			this.checkIsActive(value);
		});
	}

	ngOnDestroy() {
		this.valueChangeSubsription.unsubscribe();
	}
	//endregion

	//region Methods
	checkIsActive(newValue: T) {
		if (newValue === this.value) {
			this.elementClass = StateClasses.activated;
		} else {
			this.elementClass = StateClasses.deactivated;
		}
	}
	//endregion
}
