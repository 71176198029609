import { Directive, EnvironmentInjector, inject, OnInit, runInInjectionContext, signal } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { isArray } from 'lodash';
import { Validations } from '@evasys/globals/shared/validations/validations';

@Directive({
	selector: '[evasysRequiredStep]',
})
export class RequiredStepDirective implements OnInit {
	//region Injections
	private ngControl = inject(NgControl);
	public readonly environmentInjector = inject(EnvironmentInjector);
	//endregion

	//region Variables
	isControlValid = signal(false);
	private formControl: AbstractControl;
	//endregion

	//region Events
	ngOnInit() {
		this.formControl = this.ngControl?.control;
		if (!this.formControl.hasValidator(Validations.required)) {
			this.formControl.addValidators(Validations.required);
		}
		runInInjectionContext(this.environmentInjector, () => {
			this.formControl.valueChanges
				.pipe(
					startWith(this.formControl.value),
					map((value) => (isArray(value) ? value.length > 0 : !!value)),
					takeUntilDestroyed()
				)
				.subscribe((isControlValid) => {
					this.isControlValid.set(isControlValid);
				});
		});
	}
	//endregion

	//region Methods
	clearControl() {
		if (this.formControl.value !== null) {
			this.formControl.setValue(null);
		}
	}
	//endregion
}
