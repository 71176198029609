import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalConfirmComponent } from './component/modal-confirm.component';
import { ButtonModule } from '../../../../../../../shared/ui/src/lib/components/static-components/button/button.module';
import { TranslocoModule } from '@ngneat/transloco';
import { ModalModule } from '../../../../../../../shared/ui/src/lib/components/dynamic-components/modal/modal.module';

@NgModule({
	declarations: [ModalConfirmComponent],
	imports: [CommonModule, ButtonModule, TranslocoModule, ModalModule],
	exports: [ModalConfirmComponent],
})
export class ModalConfirmModule {}
