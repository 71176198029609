import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { StatusCardEnum } from '@evasys/globals/shared/models/component/status-card/status-card.enum';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evasys-status-card',
	templateUrl: './status-card.component.html',
})
export class StatusCardComponent implements OnChanges {
	//region Input & Output
	@Required()
	@Input()
	public id: string;

	@Input()
	public status: StatusCardEnum = StatusCardEnum.LOADING;

	@Input()
	loadingText: string;

	@Input()
	failedText: string;
	//endregion

	//region Variables
	public statusCardEnum = StatusCardEnum;
	public statusText?: string;
	//endregion

	//region Events
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['status'] || changes['loadingText'] || changes['failedText']) {
			this.onStatusChange();
		}
	}

	onStatusChange() {
		switch (this.status) {
			case StatusCardEnum.LOADING:
				this.statusText = this.loadingText;
				break;
			case StatusCardEnum.FAILED:
				this.statusText = this.failedText;
				break;
		}
	}
	//endregion
}
