import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PopperMenuModel } from '@evasys/globals/shared/models/component/popper-menu/popper-menu.model';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { LoadedComponentEnum } from '@evasys/globals/shared/enums/component/loaded-component.enum';
import { SharedUiConfiguration } from '../../../shared-ui.configuration';

@Component({
	selector: 'evasys-popper-menu',
	templateUrl: './popper-menu.component.html',
})
export class PopperMenuComponent<T> {
	//region Inputs & Outputs
	@Input()
	@Required()
	id: string;

	@Input()
	set items(items: PopperMenuModel<T>[]) {
		this._items = items;
		if (this.waitForAllItems) {
			this.waitForAllItems = false;
		} else {
			this.showAllItems = false;
		}
	}

	@Input()
	enableShowAllItemsButton = true;

	@Input()
	maxHeight = '200px';

	@Input()
	checkedItemIds?: number[];

	@Input()
	nothingFound = false;

	@Output()
	selectedItemAction: EventEmitter<T> = new EventEmitter<T>();

	@Output()
	showAllItemsAction: EventEmitter<void> = new EventEmitter<void>();
	//endregion

	//region Variables
	_items?: PopperMenuModel<T>[] = [];
	public showAllItems = false;
	private waitForAllItems = false;
	public LoadedComponentEnum = LoadedComponentEnum;
	//endregion

	constructor(public readonly sharedUiConfiguration: SharedUiConfiguration) {}

	//region Events

	onShowAllItems() {
		this.showAllItemsAction.emit();
		this.showAllItems = true;
		this.waitForAllItems = true;
	}
	//endregion
}
