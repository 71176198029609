import { Component, OnDestroy, OnInit } from '@angular/core';
import { StatusService } from '@evasys/evasys/shared/core';
import { EvasysLoadingStrategiesEnum } from '@evasys/globals/shared/enums/general/evasys-loadingStrategies.enum';
import { EvasysStatusModel } from '@evasys/globals/evasys/models/general/evasys-status.model';
import { Subscription } from 'rxjs';

@Component({
	selector: 'evasys-modal-update-check',
	templateUrl: './modal-update-check.component.html',
})
export class ModalUpdateCheckComponent implements OnInit, OnDestroy {
	// region variables
	public updateCheckResponse?: EvasysStatusModel;
	public modalOpen = false;
	private subscriptions: Subscription[] = [];

	// endregion

	constructor(private readonly statusService: StatusService) {}

	// region events
	ngOnInit(): void {
		this.subscriptions.push(
			this.statusService
				.get<EvasysStatusModel>({
					id: 'UpdateCheckResponse',
					loadingStrategy: EvasysLoadingStrategiesEnum.STATEONLY,
				})
				.subscribe({
					next: (response) => {
						this.updateCheckResponse = response;
						if (response && this.updateCheckResponse?.value?.message) {
							this.modalOpen = true;
						}
					},
					error: (error) => {
						console.error('Can not show the modal for the update check: Request failed');
					},
				})
		);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	public onClose(opened: boolean) {
		if (!opened) {
			this.modalOpen = false;
			this.close();
		}
	}

	public onAccept() {
		this.modalOpen = false;
		this.close();
	}

	// endregion

	// region methods
	private close() {
		new Promise((resolve) => setTimeout(resolve, 300)).then(() =>
			this.statusService.deleteOneStatus('UpdateCheckResponse')
		);
	}

	// endregion
}
