import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ChipTypeaheadDesignEnum } from '@evasys/globals/shared/enums/component/chip-typeahead-design.enum';
import { FilterService, TopicService } from '@evasys/evainsights/shared/core';
import { Topic } from '@evasys/globals/evainsights/models/topic-modeling/topic.model';
import { Observable } from 'rxjs';
import { FilterSelection } from '@evasys/globals/evainsights/models/filter/filter-area-control.model';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { PageRequest } from '@evasys/globals/shared/types/page-request.types';
import { ValidationErrorModel } from '@evasys/globals/evasys/models/component/validation-error.model';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evainsights-topics-search',
	templateUrl: './topics-search.component.html',
})
export class TopicsSearchComponent {
	//region Input & Output
	@Input()
	@Required()
	errors: ValidationErrorModel[] = [];

	@Input()
	surveys!: number[];

	@Input()
	submitted!: boolean;

	@Input()
	control: AbstractControl | undefined;
	//endregion

	design: ChipTypeaheadDesignEnum = ChipTypeaheadDesignEnum.DEFAULT;
	filterAreaControls = [this.filterService.topicModels];
	constructor(private topicService: TopicService, private filterService: FilterService) {}
	formatterTopics = (topic: Topic) => topic.name;

	searchTopics = (
		query: string,
		filter: FilterSelection<typeof this.filterAreaControls>,
		pageRequest: PageRequest
	): Observable<Page<Topic>> => {
		return this.topicService.getTopics({
			name: query,
			topicModels: filter.topicModels.map((topicModel) => topicModel.id),
			size: pageRequest.size,
			page: pageRequest.page,
		});
	};
}
