<div
	*ngIf="status === statusCardEnum.FAILED || status === statusCardEnum.LOADING"
	[ngClass]="{
		'border border-success': status === statusCardEnum.SUCCEEDED,
		'border border-danger': status === statusCardEnum.FAILED
	}"
	class="card p-3 mb-3 d-flex flex-column align-items-center"
	[attr.data-cy]="'statusCard-' + id"
>
	<p
		class="m-0 text-center"
		[attr.data-cy]="'statusCard-text-' + id + '-status-' + status"
		[class.text-danger]="status === statusCardEnum.FAILED"
	>
		{{ statusText ?? '' }}
	</p>
	<div *ngIf="status === statusCardEnum.LOADING" class="position-relative w-100 c-h-50px mt-3">
		<evasys-loading-overlay [loading]="status === statusCardEnum.LOADING"></evasys-loading-overlay>
	</div>
</div>
