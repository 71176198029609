<evasys-modal
	[closeable]="true"
	[id]="'reportSurveyList'"
	[(opened)]="isOpen"
	[size]="'xl'"
	[scrollable]="false"
	(openedChange)="openedChangeEmit($event)"
>
	<evasys-header
		[headerLevel]="1"
		class="h3 w-100 d-flex justify-content-center"
		[id]="'reportSurveyListModal'"
		[text]="'domain.surveys' | transloco"
		header
	></evasys-header>
	<div *ngLet="surveys$ | async as surveys" class="c-min-h-10rem c-max-h-100vh-20rem d-flex flex-column">
		<ng-container *ngIf="surveys">
			<evainsights-filter-typeahead
				(ngModelChange)="onSelectSurvey($event)"
				[maxTotalSelectedElements]="maxReportSurveys"
				[ngModel]="selectedSurveys"
				[placement]="'bottom-end'"
				[id]="'modal-reportSurveys'"
				[search]="searchSurveys"
				[formatter]="formatterSurvey"
				[searchFailedText]="'error.text' | transloco"
				[emptyResultsText]="'survey.noneFound' | transloco"
				[design]="ChipTypeaheadDesignEnum.NONE"
				[resultTemplate]="resultTemplate"
				[filterAreaControls]="filterAreaControls"
				[popoverContainer]="''"
				[title]="'survey.addSurvey' | transloco"
				[errors]="[
					{
						id: 'noSurvey',
						trigger: hasValidationErrorRequired,
						text: 'survey.error.required' | transloco
					},
					{
						id: 'maxSurveysExceeded',
						trigger: hasValidationErrorLimitExceeded,
						text: 'survey.error.limitExceeded' | transloco
					}
				]"
			></evainsights-filter-typeahead>
			<div class="position-relative overflow-auto c-z-index-0">
				<evasys-table [fixedLayout]="false" [id]="'surveys'" class="overflow-auto c-overflow-y-auto mt-4">
					<thead class="sticky-top bg-white">
						<tr>
							<th
								evasysTableHead
								scope="col"
								position="start"
								class="c-min-w-10rem c-max-w-30rem"
								columnField="surveys.description"
								data-cy="evasysTableHead-reportSurveysTable-survey"
								[title]="'overview.column.surveys.hover' | transloco"
							>
								{{ 'overview.column.surveys' | transloco }}
							</th>
							<th
								evasysTableHead
								scope="col"
								position="start"
								class="c-min-w-10rem c-max-w-25rem"
								columnField="formShortName"
								data-cy="evasysTableHead-reportSurveysTable-form"
								[title]="'common.form' | transloco"
							>
								{{ 'common.form' | transloco }}
							</th>
							<th
								evasysTableHead
								scope="col"
								position="start"
								class="c-max-w-25rem"
								columnField="leaderName"
								data-cy="evasysTableHead-reportSurveysTable-leader"
								[title]="'survey.leader' | transloco"
							>
								{{ 'survey.leader' | transloco }}
							</th>
							<th
								evasysTableHead
								scope="col"
								position="center"
								class="c-max-w-25rem"
								columnField="periodName"
								data-cy="evasysTableHead-reportSurveysTable-period"
								[title]="'organization.period' | transloco"
							>
								<img
									evasysImage
									[class]="'c-w-20px c-h-20px me-2 justify-content-center'"
									[id]="'period-icon'"
									[src]="'./assets/images/icons/calendar.svg'"
									[alt]="'period-icon'"
									[title]="'organization.period' | transloco"
								/>
							</th>
							<th
								evasysTableHead
								scope="col"
								position="center"
								class="c-content-min-width"
								columnField="responseCount"
								data-cy="evasysTableHead-reportSurveysTable-return"
								[title]="'report.surveyRespondentCount.processed' | transloco"
							>
								<img
									evasysImage
									[class]="'c-w-20px c-h-20px me-2 justify-content-center'"
									[id]="'count-icon'"
									[src]="'./assets/images/icons/text_code_check.svg'"
									[alt]="'count-icon'"
									[title]="'report.surveyRespondentCount.processed' | transloco"
								/>
							</th>
							<th
								evasysTableHead
								scope="col"
								position="center"
								class="c-max-w-7rem"
								columnField="responseCount"
								data-cy="evasysTableHead-reportSurveysTable-actions"
								[title]="'overview.column.actions.hover' | transloco"
							>
								{{ 'overview.column.actions' | transloco }}
							</th>
						</tr>
					</thead>
					<tbody class="align-middle">
						<ng-container *ngFor="let survey of surveys.content">
							<tr [attr.data-cy]="'tr-survey-' + survey.id" class="dashboard-table-tr-color border-top">
								<td evasys-table-column horizontalPosition="start">
									<evasys-table-cell-text
										class="c-min-w-10rem c-max-w-30rem"
										[text]="survey.description"
									></evasys-table-cell-text>
								</td>
								<td evasys-table-column horizontalPosition="start">
									<evasys-table-cell-text
										class="c-min-w-10rem c-max-w-25rem"
										[text]="survey.formShortName"
									></evasys-table-cell-text>
								</td>
								<td evasys-table-column horizontalPosition="start">
									<evasys-table-cell-text
										class="c-max-w-25rem"
										[text]="survey.leaderName"
									></evasys-table-cell-text>
								</td>
								<td evasys-table-column horizontalPosition="start">
									<evasys-table-cell-text
										class="c-max-w-25rem"
										[text]="survey.periodName"
									></evasys-table-cell-text>
								</td>
								<td evasys-table-column horizontalPosition="end" class="c-content-min-width">
									<evainsights-survey-filtered-respondent-count
										[survey]="survey"
										[reportRespondentFilter]="reportRespondentFilter"
									></evainsights-survey-filtered-respondent-count>
								</td>
								<td evasys-table-column horizontalPosition="end">
									<evasys-button
										[id]="'remove-survey-' + survey.id"
										[design]="ButtonDesignEnum.LINK"
										(click)="onRemoveSurvey(survey.id)"
										class="d-inline-flex c-max-w-7rem"
									>
										<img
											evasysImage
											[isIcon]="true"
											class="c-h-20px c-w-20px c-cursor-pointer"
											[id]="'delete-icon'"
											[src]="'./assets/images/icons/del_sub.svg'"
											[alt]="'common.delete.hover' | transloco"
											[altTextAsLabel]="true"
										/>
									</evasys-button>
								</td>
							</tr>
						</ng-container>
					</tbody>
				</evasys-table>

				<evasys-loading-overlay [loading]="isLoading"></evasys-loading-overlay>
			</div>
			<evainsights-page-control
				class="position-sticky"
				*ngIf="surveys.totalElements > 0"
				[totalElements]="surveys.totalElements"
				[pageSize]="this.pageSize$.value"
				[page]="this.pageNumber$.value"
				[loading]="isLoading"
				(pageChange)="onPageChange($event)"
				(pageSizeChange)="onPageSizeChange($event)"
			></evainsights-page-control>
		</ng-container>

		<evasys-loading-overlay [loading]="isLoading && !surveys"></evasys-loading-overlay>
	</div>
</evasys-modal>
