export enum ReportItemContentStatus {
	DRAFT = 'DRAFT', // outdated
	PROCESSED = 'PROCESSED',
	NO_RESULTS = 'NO_RESULTS',
	THRESHOLD_NOT_REACHED = 'THRESHOLD_NOT_REACHED',
}

export enum ChartDataStatus {
	CALCULATED = 'CALCULATED',
	RANDOM = 'RANDOM',
}
