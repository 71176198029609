import { Pipe, PipeTransform } from '@angular/core';
import { UserRightsEnum } from '@evasys/globals/shared/enums/business/user-rights.enum';

@Pipe({
	name: 'canAccess',
})
export class CanAccessPipe implements PipeTransform {
	transform(activeUserRight: any, rights: UserRightsEnum[]): boolean {
		return activeUserRight?.isAuthorized(rights);
	}
}
