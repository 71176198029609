import { Component, Input } from '@angular/core';

@Component({
	selector: 'evasys-table-cell-text',
	templateUrl: './table-cell-text.component.html',
	styleUrls: ['./table-cell-text.component.scss'],
})
export class TableCellTextComponent {
	@Input()
	public text;
}
