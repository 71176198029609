<div [class]="'d-flex ' + elementClass">
	<ng-container *ngIf="!editMode">
		<div
			title="{{ input.value }}"
			[class]="'text-primary mb-0 mt-1 ' + textClass"
			[attr.data-cy]="'editableText-value-' + id"
			evasysTextOverflow
		>
			{{ input.value }}
		</div>
		<evasys-button
			*ngIf="!isReadonly"
			(click)="changeEditMode()"
			[design]="buttonLinkDesign"
			[isDisabled]="isEditDisabled"
			[id]="'editableText-toggle-' + id"
		>
			<img
				evasysImage
				[alt]="'edit-icon'"
				[class]="'c-w-20px c-h-20px'"
				[id]="'editTitle'"
				[src]="'./assets/images/icons/edit.svg'"
				title="{{ 'common.edit.hover' | transloco }}"
				[altTextAsLabel]="true"
			/>
		</evasys-button>
	</ng-container>

	<ng-container *ngIf="editMode">
		<evasys-textbox
			[id]="id"
			[errors]="errors"
			[cdkTrapFocusAutoCapture]="editMode"
			[cdkTrapFocus]="editMode"
			class="w-100"
			[formControl]="input"
			(keydown.enter)="onConfirmInputValue()"
			(input)="_onChange(input.value)"
			(focusout)="onConfirmInputValue()"
			><ng-content></ng-content>
		</evasys-textbox>
		<evasys-button
			[isDisabled]="!formValid"
			(click)="changeEditMode()"
			[id]="'editableText-toggle-' + id"
			[design]="buttonLinkDesign"
		>
			<img
				evasysImage
				[alt]="'save-icon'"
				[class]="'c-w-20px c-h-20px'"
				[id]="'editTitle'"
				[src]="'./assets/images/icons/disk_blue.svg'"
				title="{{ 'common.save' | transloco }}"
				[altTextAsLabel]="true"
			/>
		</evasys-button>
	</ng-container>
</div>
