import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { VisualizationItem } from './visualization-item.interface';
import { ValidationErrorModel } from '@evasys/globals/evasys/models/component/validation-error.model';
import { AnalysisType, VisualizationType } from '@evasys/globals/evainsights/constants/types';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'evainsights-visualization-card-menu',
	templateUrl: './visualization-card-menu.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: VisualizationCardMenuComponent,
			multi: true,
		},
	],
})
export class VisualizationCardMenuComponent implements OnChanges, ControlValueAccessor {
	@Input()
	analysisType!: AnalysisType;

	@Input()
	isLongitudinalAnalysis!: boolean;

	@Input()
	isBatchCreator = false;

	selected: VisualizationType | null = null;

	visualizationItems!: VisualizationItem[];

	@Input()
	errors: ValidationErrorModel[] = [];

	ngOnChanges(changes: SimpleChanges) {
		if (changes['analysisType'] || !this.visualizationItems || changes['isLongitudinalAnalysis']) {
			this.visualizationItems = this.getAvailableVisualizationItems(
				this.analysisType,
				this.isLongitudinalAnalysis,
				this.isBatchCreator
			);

			const isCurrentVisualizationTypeAvailable = this.visualizationItems.some(
				(item) => item.visualizationType === this.selected
			);
			if (!isCurrentVisualizationTypeAvailable) {
				this.writeValue(null);
			}
		}
	}

	getAllVisualizationItems(): VisualizationItem[] {
		return [
			{
				cyName: 'barChart',
				visualizationType: VisualizationType.BAR_CHART,
				labelTranslationKey: 'reportItem.visualizationType.barChart',
				img: 'barchartPreview.jpg',
			},
			{
				cyName: 'wordcloud',
				visualizationType: VisualizationType.WORDCLOUD,
				labelTranslationKey: 'reportItem.visualizationType.wordCloud',
				img: 'wordcloudPreview.jpg',
			},
			{
				cyName: 'wordcloudResponses',
				visualizationType: VisualizationType.WORDCLOUD_RESPONSES,
				labelTranslationKey: 'reportItem.visualizationType.wordCloudResponses',
				img: 'wordcloudResponses.png',
			},
			{
				cyName: 'lineChart',
				visualizationType: VisualizationType.LINE_CHART,
				labelTranslationKey: 'reportItem.visualizationType.lineChart',
				img: 'linechartPreview.jpg',
			},
			{
				cyName: 'responses',
				visualizationType: VisualizationType.RESPONSES,
				labelTranslationKey: 'reportItem.visualizationType.responses',
				img: 'responsesPreview.jpg',
			},
			{
				cyName: 'richText',
				visualizationType: VisualizationType.RICH_TEXT,
				labelTranslationKey: 'reportItem.visualizationType.richText',
				img: 'responsesPreview.jpg',
			},
			{
				cyName: 'divider',
				visualizationType: VisualizationType.DIVIDER,
				labelTranslationKey: 'reportItem.visualizationType.divider',
				img: 'dividerPreview.jpg',
			},
		];
	}

	getAvailableVisualizationItems(
		analysisType: AnalysisType,
		isLongitudinalAnalysis: boolean,
		isBatchCreator: boolean
	) {
		return this.getAllVisualizationItems().filter((item) =>
			this.getAvailableVisualizationTypes(analysisType, isLongitudinalAnalysis, isBatchCreator).includes(
				item.visualizationType
			)
		);
	}

	getAvailableVisualizationTypes(
		analysisType: AnalysisType,
		isLongitudinalAnalysis: boolean,
		isBatchCreator: boolean
	): VisualizationType[] {
		switch (analysisType) {
			case AnalysisType.QUANTITATIVE:
				if (isLongitudinalAnalysis) return [VisualizationType.LINE_CHART];
				if (isBatchCreator) return [VisualizationType.BAR_CHART, VisualizationType.LINE_CHART];
				return [
					VisualizationType.BAR_CHART,
					VisualizationType.LINE_CHART,
					VisualizationType.PROFILE_LINE_CHART,
				];
			case AnalysisType.WORD_FREQUENCY:
				return [VisualizationType.WORDCLOUD];
			case AnalysisType.RESPONSES:
				return [VisualizationType.RESPONSES];
			case AnalysisType.TOPIC:
				return isLongitudinalAnalysis
					? [VisualizationType.LINE_CHART]
					: [VisualizationType.WORDCLOUD, VisualizationType.WORDCLOUD_RESPONSES];
			case AnalysisType.STRUCTURAL_ELEMENTS:
				return [VisualizationType.RICH_TEXT, VisualizationType.DIVIDER];
		}
	}

	onChange() {
		try {
			this._onChange(this.selected);
		} catch (e) {
			// Angular will throw an error here in two cases:
			// a) When the listener is not yet registered.
			//    Seeing an error for this is okay as we will emit the value again once the listener is successfully registered
			// b) When removing the form control from the form.
			//    Also okay as we this means that the form does not want to receive further updates from this component
			// We can therefore ignore this exception
		}
	}

	_onChange: (value: VisualizationType | null) => void = () => {
		// this is intentional
	};

	_onTouched = () => {
		// this is intentional
	};

	registerOnChange(fn: (_: VisualizationType | null) => void) {
		this._onChange = fn;

		// emit the currently selected value to the new change listener
		this.onChange();
	}

	registerOnTouched(fn: () => void) {
		this._onTouched = fn;
	}

	writeValue(newSelected: VisualizationType | null) {
		this.selected = newSelected ?? this.getDefaultSelection();
		this.onChange();
	}

	getDefaultSelection() {
		return this.visualizationItems.length === 1 ? this.visualizationItems[0].visualizationType : null;
	}

	findSelectedObject(selected: string): Set<VisualizationItem> {
		const found = this.visualizationItems.find((item) => item.visualizationType === selected);
		return new Set<VisualizationItem>(found ? [found] : []);
	}
}
