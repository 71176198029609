import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'unescape' })
export class UnescapePipe implements PipeTransform {
	constructor(private sanitized: DomSanitizer) {}

	/**
	 * IMPORTANT: never use this for strings from users
	 */
	transform(value: any) {
		return this.sanitized.bypassSecurityTrustHtml(value);
	}
}
