import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Listitem } from './listitem.interface';

@Component({
	selector: 'evasys-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss'],
})
export class ListComponent {
	@Input()
	listitems: Listitem[] | string[] = [{ title: 'empty', response: 'empty' }];

	@Input()
	active?: number;

	@Output()
	response = new EventEmitter<string>();

	respond(value: string, index: number) {
		this.active = index;
		this.response.emit(value);
	}
}
