import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { ChipTypeaheadDesignEnum } from '@evasys/globals/shared/enums/component/chip-typeahead-design.enum';
import {
	FilterAreaControl,
	FilterSelection,
} from '@evasys/globals/evainsights/models/filter/filter-area-control.model';
import { buildEmptyFilterSelection } from '@evasys/globals/evainsights/helper/filter-selection';
import { getTypeaheadIdentifierValue } from '@evasys/globals/shared/helper/typeahead';
import { toObservableFormatter } from '@evasys/shared/util';
import { Observable } from 'rxjs';

@Component({
	selector: 'evainsights-typeahead-filter-area',
	templateUrl: './typeahead-filter-area.component.html',
	styleUrls: ['typeahead-filter-area.component.scss'],
})
export class TypeaheadFilterAreaComponent<T, FilterAreaControls extends readonly FilterAreaControl<unknown>[]> {
	@Input()
	filterAreaControls!: FilterAreaControls;

	@Input()
	filterSelections!: FilterSelection<FilterAreaControls>;

	@Input()
	showTitle = true;

	@Input()
	id!: string;

	@Output()
	filterSelectionsChange = new EventEmitter<FilterSelection<FilterAreaControls>>();

	public readonly designs = ButtonDesignEnum;
	chipTypeaheadDesign = ChipTypeaheadDesignEnum;
	getIdentifier = getTypeaheadIdentifierValue;

	resetFilterSelections(event: MouseEvent) {
		this.filterSelections = buildEmptyFilterSelection(this.filterAreaControls);
		this.emitFilterSelection();
		event.preventDefault();
	}

	removeSearchSelection(filterSelection: T, id: FilterAreaControls[number]['id']) {
		this.filterSelections = {
			...this.filterSelections,
			[id]: this.filterSelections[id].filter((value) => value !== filterSelection),
		};

		this.emitFilterSelection();
	}

	setFilterSelection(filterValues: T[], id: FilterAreaControls[number]['id']) {
		this.filterSelections = {
			...this.filterSelections,
			[id]: filterValues,
		};
		this.emitFilterSelection();
	}

	getSelectedFilterLength(filterSelections: FilterSelection<FilterAreaControls>) {
		return Object.values(filterSelections).flat().length;
	}

	private emitFilterSelection() {
		this.filterSelectionsChange.emit(this.filterSelections);
	}

	formatFilter<F>(filter: F, control: FilterAreaControl<F>): Observable<string> {
		const formatter = toObservableFormatter(control.formatter);
		return formatter(filter);
	}
}
