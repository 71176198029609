<div [attr.data-cy]="'richText-' + id" class="overflow-auto h-100 c-word-wrap-break">
	<evainsights-rich-text-editable
		[editor]="editor"
		[readonly]="true"
		[id]="'richText'"
		[ngModel]="bodyValue | async"
	></evainsights-rich-text-editable>
	<evainsights-text-overlay *ngIf="isEmptyText && (language$ | async); let lang">
		{{ lang | reportLanguageCode | inlineTransloco : 'reportItem.noContentInSelectedLanguage' | async }}
	</evainsights-text-overlay>
</div>
