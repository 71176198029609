import { Component, Input } from '@angular/core';

@Component({
	selector: 'evainsights-report-item-title',
	templateUrl: './report-item-title.component.html',
	styleUrls: ['./report-item-title.component.scss'],
})
export class ReportItemTitleComponent {
	@Input()
	titleText!: string;
}
