import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

const TRANSLATE_Y_REGEX = /translateY\((\d+)px\)/;

const MUTATION_ATTRIBUTES_TYPE: MutationRecordType = 'attributes';

@Directive({
	selector: '[evasysTableSticky]',
})
export class TableStickyDirective implements OnInit, OnDestroy {
	@Input()
	evasysTableSticky: [HTMLElement, 'top' | 'bottom'][];

	private translateYObserver: MutationObserver;

	constructor(private readonly virtualScrollViewPort: ElementRef) {}

	ngOnInit() {
		this.initStickHeader();
	}

	ngOnDestroy() {
		if (this.translateYObserver) {
			this.translateYObserver.disconnect();
		}
	}

	private initStickHeader() {
		const contentWrapper = this.virtualScrollViewPort.nativeElement.firstChild;

		this.translateYObserver = new MutationObserver((mutations) =>
			this.onMutationsChange(mutations, contentWrapper)
		);
		this.translateYObserver.observe(contentWrapper, {
			attributes: true,
		});
	}

	private onMutationsChange(mutations: MutationRecord[], contentWrapper: HTMLElement) {
		mutations
			.filter((it) => it.type === MUTATION_ATTRIBUTES_TYPE)
			.forEach(() => this.setHeaderTopPosition(contentWrapper));
	}

	private setHeaderTopPosition(contentWrapper: HTMLElement) {
		this.evasysTableSticky.forEach(([stickyElement, stickyDirection]) => {
			const match = contentWrapper.style.transform.match(TRANSLATE_Y_REGEX);
			if (!match) {
				return;
			}

			const translate = parseInt(match[1]);
			stickyElement.style[stickyDirection] = `${(stickyDirection === 'top' ? '-' : '') + translate}px`;
		});
	}
}
