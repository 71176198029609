import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Animations } from '@evasys/globals/shared/animations/angular/animations';
import { SharedUiConfiguration } from '../../../shared-ui.configuration';

@Component({
	selector: 'evasys-sidebar',
	templateUrl: './sidebar.component.html',
	animations: [Animations.xGrowAnimation('50px', '*', 200)],
})
export class SidebarComponent implements OnInit {
	@HostBinding('class')
	hostClass = 'col-auto h-100 p-0';

	//#region Input & Output
	@Output()
	collapsedChange = new EventEmitter<boolean>();

	@Input()
	visible = true;

	@Input()
	openTimeout?: number;

	@Input()
	set permanentlyOpen(value: boolean) {
		this._permanentlyOpen = value;
		this.updateCollapsed();
	}

	@Output()
	permanentlyOpenChange = new EventEmitter<boolean>();

	//#endregion Input & Output

	constructor(public readonly config: SharedUiConfiguration) {}

	//#region Variables
	_hover: boolean;
	_permanentlyOpen = false;
	_collapsed: boolean;
	//#endregion Variables

	//#region Events

	onPermanentlyOpen(open: boolean) {
		this._permanentlyOpen = open;
		this.permanentlyOpenChange.emit(open);
		this.updateCollapsed();
	}

	onHover(hover: boolean) {
		this._hover = hover;
		this.updateCollapsed();
	}

	ngOnInit() {
		this.updateCollapsed();
	}

	//#endregion Events

	//region Methods

	updateCollapsed() {
		this._collapsed = !this._permanentlyOpen && !this._hover;
		this.collapsedChange.emit(this._collapsed);
	}
	//endregion
}
