import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'join',
})
export class JoinPipe implements PipeTransform {
	transform(value: unknown[] | undefined | null, seperator: string): string {
		return value?.join(seperator);
	}
}
