import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiPageLinkPipe } from './navigate/pipes/multi-page-link.pipe';
import { NavigateService } from './navigate/services/navigate.service';
import { EvasysSharedFeatureConfiguration } from './evasys-shared-feature.configuration';
import { TranslatePipe } from './transloco/pipes/translate.pipe';
import { AppShellComponent } from './app-shell/components/app-shell/app-shell.component';
import { SharedUiModule } from '@evasys/shared/ui';
import { SharedUtilModule } from '@evasys/shared/util';
import { TranslocoModule, TranslocoPipe } from '@ngneat/transloco';
import { ModalUpdateCheckComponent } from './update-check/modal-update-check/modal-update-check.component';
import { EvasysSharedCoreModule } from '@evasys/evasys/shared/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ResponseNotificationInterceptor } from './interceptors/response-notification.interceptor';
import { EvasysSharedUiModule } from '@evasys/evasys/shared/ui';
import { LoadingSpinnerComponent } from './loading-spinner/components/loading-spinner/loading-spinner.component';
import { PermissionsModule } from './permissions/permissions.module';

@NgModule({
	imports: [
		CommonModule,
		SharedUtilModule,
		SharedUiModule,
		TranslocoModule,
		EvasysSharedCoreModule,
		EvasysSharedUiModule,
		PermissionsModule,
	],
	declarations: [
		MultiPageLinkPipe,
		TranslatePipe,
		AppShellComponent,
		ModalUpdateCheckComponent,
		LoadingSpinnerComponent,
	],
	exports: [
		AppShellComponent,
		TranslatePipe,
		ModalUpdateCheckComponent,
		LoadingSpinnerComponent,
		MultiPageLinkPipe,
		PermissionsModule,
	],
	providers: [
		NavigateService,
		TranslocoPipe,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ResponseNotificationInterceptor,
			multi: true,
		},
	],
})
export class EvasysSharedFeatureModule {
	static forRoot(config: EvasysSharedFeatureConfiguration): ModuleWithProviders<any> {
		return {
			ngModule: EvasysSharedFeatureModule,
			providers: [
				{
					provide: EvasysSharedFeatureConfiguration,
					useValue: config,
				},
			],
		};
	}
}
