import { Component, Input } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evasys-more-menu-item',
	templateUrl: './more-menu-item.component.html',
})
export class MoreMenuItemComponent {
	@Required()
	@Input()
	id: string;
}
