import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { Subject } from 'rxjs';

@Component({
	selector: 'evasys-button-toggle',
	templateUrl: './button-toggle.component.html',
	styleUrls: ['button-toggle.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: ButtonToggleComponent,
			multi: true,
		},
	],
})
export class ButtonToggleComponent<T> implements ControlValueAccessor {
	//region Inputs & Outputs
	@Input()
	@Required()
	id!: string;

	@Input()
	title: string;
	//endregion

	//region Variables
	private _isDisabled = false;
	private _valueChanges: Subject<T> = new Subject<T>();
	private _value: T;
	private _onChange = (value: T) => {
		return;
	};
	private _onTouched = () => {
		return;
	};
	//endregion

	//region Getter & Setter
	get isDisabled(): boolean {
		return this._isDisabled;
	}

	get value() {
		return this._value;
	}

	get valueChanges() {
		return this._valueChanges;
	}

	//endregion

	//region Events
	public onChange() {
		this._onChange(this.value);
	}

	public onTouched() {
		this._onTouched();
	}

	//endregion

	//region ControlValueAccessor

	registerOnChange(fn: any): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this._isDisabled = isDisabled;
	}

	writeValue(obj: any): void {
		this._value = obj;
		this._valueChanges.next(obj);
	}
	//endregion
}
