import { Component, OnChanges, OnDestroy } from '@angular/core';
import { D3BaseComponent, Size } from '../d3-base/d3-base.component';
import { ProfileLineChart } from '../../../charts';
import profileLineFixtureData from '../../../charts/profile-line-chart.tmp.json';
import { DataBasisType, VisualizationType } from '@evasys/globals/evainsights/constants/types';
import { ProfileLineChartContent } from '@evasys/globals/evainsights/models/report-item';

@Component({
	selector: 'evainsights-profile-line-chart-content',
	templateUrl: '../d3-base/d3-base.component.html',
})
export class ProfileLineChartContentComponent extends D3BaseComponent implements OnChanges, OnDestroy {
	private content: ProfileLineChartContent = {
		config: {
			visualizationType: VisualizationType.PROFILE_LINE_CHART,
			dataBasis: DataBasisType.QUANTITATIVE_ANALYSIS,
			items: [],
			title: [],
			showStatistics: true,
			useMedian: false,
			showMean: true,
			showMedian: true,
			fractionDigits: 1,
			fontFamily: 'sans-serif',
			fontSizeStaticValues: '11px',
			fontSizeQuestionText: '14px',
		},
		data: profileLineFixtureData as any,
	};

	override onResize() {
		this.tryDraw();
	}

	tryDraw() {
		if (!this.renderElement) {
			throw Error('Cannot draw profile line chart without a render element');
		}

		const size = this.chartSize.value;
		if (!size) {
			throw Error('Cannot profile line chart without a specified size');
		}
		this.draw(this.renderElement.nativeElement, size);
	}

	draw(host: HTMLElement, size: Size) {
		new ProfileLineChart(host, this.ctx, this.content).render({
			size,
			reportLanguageId: this.language.id,
			decimalFormat: this.decimalFormat,
		});
	}
}
