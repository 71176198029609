import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavigateService } from '../navigate/services/navigate.service';
import { firstValueFrom } from 'rxjs';
import { NotificationEnumMap } from '@evasys/globals/shared/enums/component/notification.enum';
import { AuthService, NotificationService } from '@evasys/shared/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class UserChangeService {
	public constructor(
		private readonly router: Router,
		private readonly navigateService: NavigateService,
		private readonly authService: AuthService,
		private readonly notificationService: NotificationService
	) {}

	public async changeUser(id: string): Promise<void> {
		try {
			await this.authService.logout();
			const authenticated = await this.authService.login({
				// we need to pass some random credentials to be accepted by the backend
				// (these are not used, only the externalId is used for authentication)
				username: 'kF3as7i4fGd$43apW',
				password: 'hSDG3aJFao4kl3GDRhs63rSFG',
				userId: id,
			});
			if (authenticated) {
				this.showLoginMessages();
				this.navigateService.navigateToStartPage();
			}
		} catch (error) {
			console.error('Change the user failed: ', error);
			if (error instanceof HttpErrorResponse && error.error) {
				await this.router.navigate(['']);
			}
		}
	}

	private showLoginMessages() {
		firstValueFrom(this.authService.getLoginMessages())
			.then((notifications) => {
				notifications?.forEach((notification) => {
					this.notificationService.addNotification(
						NotificationEnumMap[notification.severity],
						notification.message,
						notification.id,
						true,
						notification.action
					);
				});
			})
			.catch((err) => console.error('show login messages failed: ', err));
	}
}
