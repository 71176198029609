import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { ReportItemContentStatus } from '@evasys/globals/evainsights/constants/status';
import { TopicResponse } from '@evasys/globals/evainsights/models/search/response.model';

@Component({
	selector: 'evainsights-topic-responses',
	templateUrl: './topic-responses.component.html',
	styleUrls: ['./topic-responses.component.scss'],
})
export class TopicResponsesComponent implements OnChanges {
	@Input()
	@Required()
	topicResponses!: TopicResponse[];

	@Input()
	reportItemStatus!: ReportItemContentStatus;

	@Input()
	language?: string;

	showSearchBox = false;
	filteredTopicResponses: TopicResponse[] = [];

	public searchValue = '';

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['topicResponses']) {
			this.topicResponses = changes['topicResponses'].currentValue;
			this.filterTopicResponses();
			this.showSearchBox = this.reportItemStatus === ReportItemContentStatus.PROCESSED;
		}
	}

	public onSearch(value: string) {
		this.searchValue = value;
		this.filterTopicResponses();
	}

	filterTopicResponses() {
		if (this.searchValue === '') {
			this.filteredTopicResponses = this.topicResponses;
		} else {
			this.filteredTopicResponses = Array.from(this.topicResponses.values()).filter(
				(obj) => RegExp(this.searchValue, 'i').exec(obj.response.value) // case-insensitive matching
			);
		}
	}
}
