import { Component, OnInit } from '@angular/core';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { ReportType } from '@evasys/globals/evainsights/constants/types';
import { ActivatedRoute } from '@angular/router';
import { EvasysLoadingStrategiesEnum } from '@evasys/globals/shared/enums/general/evasys-loadingStrategies.enum';
import { first, Observable } from 'rxjs';
import { ReportService, RespondentFilterService } from '@evasys/evainsights/shared/core';
import { ReportFacadeService } from '@evasys/evainsights/stores/core';
import { getReportIdOrReportTemplateIdFromParamMap } from '@evasys/globals/evainsights/helper/url-params';
import { Report } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { RouteDataParams } from '@evasys/globals/evainsights/constants/route-data-params';

@Component({
	selector: 'evainsights-respondent-filter',
	templateUrl: './respondent-filter.component.html',
})
export class RespondentFilterComponent implements OnInit {
	showFilterList = false;
	reportType!: ReportType;
	isRespondentFilterModalOpen = false;
	report$: Observable<Report> | undefined;
	buttonDesign = ButtonDesignEnum;
	awaitBackendResponse = false;

	constructor(
		readonly respondentFilterService: RespondentFilterService,
		private activatedRoute: ActivatedRoute,
		private reportFacadeService: ReportFacadeService,
		private reportService: ReportService
	) {}

	ngOnInit(): void {
		this.reportType = this.activatedRoute.snapshot.data[RouteDataParams.REPORT_TYPE];

		this.activatedRoute.paramMap.subscribe((paramMap) => {
			const id = getReportIdOrReportTemplateIdFromParamMap(paramMap, this.reportType);
			if (id !== 0) {
				this.report$ = this.reportFacadeService.get({
					id: id,
					loadingStrategy: EvasysLoadingStrategiesEnum.STATEONLY,
				});
			}
		});
	}

	deleteRespondentFilterItem(itemId: number) {
		this.awaitBackendResponse = true;
		this.report$?.pipe(first()).subscribe((report: Report) => {
			this.respondentFilterService
				.deleteRespondentFilterItemOptionForReportIdAndItemId(report.id, itemId)
				.pipe(first())
				.subscribe(() => {
					this.reportType === ReportType.REPORT
						? this.reportService
								.getById(report.id)
								.pipe(first())
								.subscribe(() => {
									this.getReport(report.id);
								})
						: this.getReport(report.id);
				});
		});
	}

	private getReport(id: number) {
		this.report$ = this.reportFacadeService.get({
			id: id,
			loadingStrategy: EvasysLoadingStrategiesEnum.STATETHENAPI,
		});
		this.awaitBackendResponse = false;
	}

	openAddRespondentFilterModal() {
		if (this.report$) this.isRespondentFilterModalOpen = true;
	}
}
