import { ApiService, EvasysEffects } from '@evasys/shared/core';
import { Actions } from '@ngrx/effects';
import { SystemInfoModel } from '@evasys/globals/evasys/models/business/system-info.model';
import { EvasysRequestMethodEnum } from '@evasys/globals/shared/enums/general/evasys-request-method.enum';
import { EvasysApiRequestModel } from '@evasys/globals/shared/models/general/evasys-api-request.model';
import { evasysActions } from '../actions';
import { Injectable } from '@angular/core';
import { EndpointsEnum } from '@evasys/globals/evasys/api/endpoints.enum';

@Injectable()
export class SystemInfoEffects extends EvasysEffects<SystemInfoModel> {
	private API_PATH = EndpointsEnum.INFOS;

	constructor(protected override actions$: Actions, protected readonly apiService: ApiService) {
		super(evasysActions.fromSystemInfo, 'infos', actions$);
	}

	loadPage$ = this.createEffect(
		evasysActions.fromSystemInfo.LoadPageActionSet,
		(action: any) =>
			({
				apiPath: this.API_PATH,
				many: true,
				requestMethod: EvasysRequestMethodEnum.GET,
				page: action.payload,
				fields: 'name,value',
			} as EvasysApiRequestModel)
	);

	loadMany$ = this.createEffect(
		evasysActions.fromSystemInfo.LoadManyActionSet,
		(action: any) =>
			({
				apiPath: this.API_PATH,
				many: true,
				requestMethod: EvasysRequestMethodEnum.GET,
				page: action.payload.page,
				pageSize: action.payload.pageSize,
				filter: 'name in ' + action.payload.ids,
				fields: 'name,value',
			} as EvasysApiRequestModel)
	);

	loadOne$ = this.createEffect(
		evasysActions.fromSystemInfo.LoadOneActionSet,
		(action: any) =>
			({
				apiPath: [this.API_PATH, action.payload].join('/'),
				fields: 'name,value',
				many: false,
				requestMethod: EvasysRequestMethodEnum.GET,
			} as EvasysApiRequestModel)
	);

	protected mapToFrontend(data: any): SystemInfoModel {
		return {
			...data,
			id: data.name,
		} as SystemInfoModel;
	}

	protected mapToBackend(systemInfo: SystemInfoModel): any {
		const newSystemInfo: any = {
			...systemInfo,
		};

		delete newSystemInfo.id;

		return newSystemInfo;
	}
}
