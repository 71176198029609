import { Component, Input, OnInit } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { MarkTypes } from '../constants/mark.types';
import { AngularEditor } from 'slate-angular';
import { Editor, Element, Transforms } from 'slate';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';

type ToolbarItem = {
	format: string;
	icon: string;
	active: (formats: string) => boolean;
	action: (formats: string) => void;
};

@Component({
	selector: 'evainsights-rich-text-editor-toolbar',
	templateUrl: './rich-text-editor-toolbar.component.html',
})
export class RichTextEditorToolbarComponent implements OnInit {
	@Input()
	@Required()
	editor!: AngularEditor;

	buttonDesign = ButtonDesignEnum;
	LIST_TYPES = ['numbered-list', 'bulleted-list'];
	TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

	toolbarItems!: ToolbarItem[];

	ngOnInit() {
		const handleBlockItems = { active: this.isBlockActive, action: this.toggleBlock };
		this.toolbarItems = [
			{
				format: MarkTypes.bold,
				icon: 'fontawesome/bold',
				active: this.isMarkActive,
				action: this.toggleMark,
			},
			{
				format: MarkTypes.italic,
				icon: 'fontawesome/italic',
				active: this.isMarkActive,
				action: this.toggleMark,
			},
			{
				format: MarkTypes.underline,
				icon: 'fontawesome/underline',
				active: this.isMarkActive,
				action: this.toggleMark,
			},
			{
				format: MarkTypes.code,
				icon: 'fontawesome/code',
				active: this.isMarkActive,
				action: this.toggleMark,
			},
			{
				format: 'heading-one',
				icon: 'fontawesome/h1',
				...handleBlockItems,
			},
			{
				format: 'heading-two',
				icon: 'fontawesome/h2',
				...handleBlockItems,
			},
			{
				format: 'heading-three',
				icon: 'fontawesome/h3',
				...handleBlockItems,
			},
			{
				format: 'block-quote',
				icon: 'fontawesome/quote-right',
				...handleBlockItems,
			},
			{
				format: 'numbered-list',
				icon: 'icons/numeration_on',
				...handleBlockItems,
			},
			{
				format: 'bulleted-list',
				icon: 'fontawesome/list',
				...handleBlockItems,
			},
			{
				format: 'left',
				icon: 'fontawesome/align-left',
				...handleBlockItems,
			},
			{
				format: 'center',
				icon: 'fontawesome/align-center',
				...handleBlockItems,
			},
			{
				format: 'right',
				icon: 'fontawesome/align-right',
				...handleBlockItems,
			},
			{
				format: 'justify',
				icon: 'fontawesome/align-justify',
				...handleBlockItems,
			},
		];
	}

	getType(isActive: boolean, isList: boolean, format: string) {
		if (isActive) {
			return 'paragraph';
		}
		return isList ? 'list-item' : format;
	}

	toggleBlock = (format: string) => {
		const isActive = this.isBlockActive(format, this.TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');
		const isList = this.LIST_TYPES.includes(format);

		Transforms.unwrapNodes(this.editor, {
			match: (n) =>
				Element.isElement(n) &&
				this.LIST_TYPES.includes((n as any).type) &&
				!this.TEXT_ALIGN_TYPES.includes(format),
			split: true,
		});
		let newProperties: Partial<Element>;
		if (this.TEXT_ALIGN_TYPES.includes(format)) {
			newProperties = {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				align: isActive ? undefined : format,
			};
		} else {
			newProperties = {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				type: this.getType(isActive, isList, format),
			};
		}
		Transforms.setNodes(this.editor, newProperties);

		if (!isActive && isList) {
			const block = { type: format, children: [] };
			Transforms.wrapNodes(this.editor, block as any);
		}
	};

	isMarkActive = (format: string) => {
		const marks: any = Editor.marks(this.editor);
		return marks ? marks[format] === true : false;
	};

	toggleMark = (format: string) => {
		const isActive = this.isMarkActive(format);

		if (isActive) {
			Editor.removeMark(this.editor, format);
		} else {
			Editor.addMark(this.editor, format, true);
		}
	};

	onClick(toolbarItem: ToolbarItem) {
		toolbarItem.action(toolbarItem.format);
		AngularEditor.focus(this.editor);
	}

	isBlockActive = (format: string, blockType = 'type') => {
		const { selection } = this.editor;
		if (!selection) return false;

		const [match] = Editor.nodes(this.editor, {
			at: Editor.unhangRange(this.editor, selection),
			match: (n) =>
				!Editor.isEditor(n) &&
				Element.isElement(n) &&
				(((n as any).type === format && (n as any)[blockType] === format) || (n as any).align === format),
		});

		return !!match;
	};
}
