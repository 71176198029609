import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StatusEffects } from '../effects/status.effects';
import { statusReducer } from '../reducers/status.reducer';

@NgModule({
	declarations: [],
	imports: [CommonModule, EffectsModule.forFeature([StatusEffects]), StoreModule.forFeature('status', statusReducer)],
})
export class StatusModule {}
