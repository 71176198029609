import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SidemenuItemModel } from '@evasys/globals/evasys/models/component/sidemenu-item.model';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

@Component({
	selector: 'evasys-sidemenu',
	templateUrl: './sidemenu.component.html',
})
export class SidemenuComponent implements OnInit, OnDestroy {
	//#region Input & Output
	@Input()
	active: [number | null, number | null] = [null, null];

	@Output()
	activeChange = new EventEmitter<[number, number]>();

	@Input()
	collapsed = false;

	@Input()
	set sidemenuItems(sideMenuItems: SidemenuItemModel[]) {
		this._sidemenuItems = sideMenuItems;
		if (this._sidemenuItems) {
			this.menuAccess = combineLatest(this._sidemenuItems.map((item) => item.canAccess)).pipe(
				map((accessArr) => accessArr.includes(true))
			);
		}
	}

	//#endregion Variables

	//#region Variables
	_sidemenuItems: SidemenuItemModel[] = [];
	menuAccess?: Observable<boolean>;
	subscriptions: Subscription[] = [];

	//#endregion Variables

	constructor(private readonly router: Router) {
		//init
	}

	//#region Events
	ngOnInit(): void {
		this.handleOutsideRouting();
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	onActiveChange(i: number, $event: number) {
		this.active = [i, $event];
		this.activeChange.emit([i, $event]);
	}

	//#endregion Events

	//#region Methods
	/**
	 * checks if there is an active item in the side menu after navigation.
	 *
	 * If one is found it is set to active.
	 * If not active is set to [null, null].
	 *
	 * @private
	 */
	private handleOutsideRouting() {
		this.subscriptions.push(
			this.router.events.subscribe((event) => {
				if (event instanceof NavigationEnd) {
					// checks if one of the sideMenu items contains the current url as route
					const searchIndex: number | undefined = this._sidemenuItems
						?.map((mItem) => {
							const index = mItem.menuitems.findIndex((item) => item.route === event.url);
							if (index !== -1) {
								const groupIndex = this._sidemenuItems.findIndex((group) => group.id === mItem.id);
								this.active = [groupIndex, index];
								this.activeChange.emit([groupIndex, index]);
							}
							return index;
						})
						.find((result) => result !== -1);

					if (searchIndex === undefined) {
						this.active = [null, null];
						this.activeChange.emit([null, null]);
					}
				}
			})
		);
	}

	//#endregion Methods
}
