import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'IdsToEntities',
	pure: false,
})
export class IdsToEntitiesPipe implements PipeTransform {
	transform<K extends string | number, T extends { id: K }>(ids: K[], allEntities: T[]): T[] {
		return allEntities.filter((entity) => ids.includes(entity.id));
	}
}
