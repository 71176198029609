import { Pipe, PipeTransform } from '@angular/core';
import { UserRightsEnum } from '@evasys/globals/shared/enums/business/user-rights.enum';

@Pipe({
	name: 'userRights',
})
export class UserRightsPipe implements PipeTransform {
	transform(userRightsAsNumber: number): UserRightsEnum[] {
		if (userRightsAsNumber) {
			return Object.values(UserRightsEnum)
				.filter(
					(userRight) =>
						userRight === UserRightsEnum.FIRSTADMIN ||
						userRight === UserRightsEnum.ADMINISTRATOR ||
						userRight === UserRightsEnum.SUBSECTION_ADMINISTRATOR ||
						userRight === UserRightsEnum.WEB_VERIFIER ||
						userRight === UserRightsEnum.DATA_COLLECTOR ||
						userRight === UserRightsEnum.REPORT_CREATOR
				)
				.map((userRight) => {
					if ((userRightsAsNumber & (userRight as number)) === userRight) {
						return userRight;
					} else {
						return undefined;
					}
				})
				.filter((userRight) => userRight !== undefined);
		}
		return [];
	}
}
