import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'has',
})
export class HasPipe implements PipeTransform {
	transform<T>(entities: T[] | Set<T>, value: T): boolean {
		return Array.isArray(entities) ? entities?.includes(value) : entities.has(value);
	}
}
