import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	computed,
	ContentChildren,
	EnvironmentInjector,
	EventEmitter,
	inject,
	Input,
	Output,
	QueryList,
	runInInjectionContext,
	signal,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StepFilterItemComponent } from '../step-filter-item/step-filter-item.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StepFilterConfig } from '../step-filter.config';

@Component({
	selector: 'evasys-step-filter',
	templateUrl: './step-filter.component.html',
})
export class StepFilterComponent implements AfterViewInit {
	//region Inputs & Outputs
	@Input({ required: true })
	id: string;

	@Input()
	formGroup: FormGroup;

	@Output()
	submitAction = new EventEmitter<void>();
	//endregion

	//region ContentChildren
	@ContentChildren(StepFilterItemComponent)
	stepFilterItemComponents: QueryList<StepFilterItemComponent>;
	//endregion

	//region Injections
	public readonly environmentInjector = inject(EnvironmentInjector);
	public readonly stepFilterConfig = inject(StepFilterConfig);
	private readonly changeDetectorRef = inject(ChangeDetectorRef);
	//endregion

	//region Variables
	activeStepFilterItem = signal<StepFilterItemComponent | null>(null);
	isStepFilterItemActive = computed(() => this.activeStepFilterItem() !== null);
	isFormValid = computed(() => this.stepFilterItemComponents.last.isStepValid());

	isOpen = true;
	//endregion

	//region Events
	ngAfterViewInit() {
		runInInjectionContext(this.environmentInjector, () => {
			this.activeStepFilterItem.set(this.stepFilterItemComponents.first);
			this.stepFilterItemComponents.first?.isFirst.set(true);
			this.stepFilterItemComponents.first?.onPreload();
			this.listenToValueChanges();
		});
	}

	onNextButtonClicked() {
		const index = this.stepFilterItemComponents.toArray().indexOf(this.activeStepFilterItem());
		if (index !== -1) {
			if (this.stepFilterItemComponents.length - 1 > index) {
				this.stepFilterItemComponents.get(index + 1).onActivate();
			} else if (this.activeStepFilterItem().isStepValid()) {
				this.activeStepFilterItem.set(null);
				this.submitAction.emit();
			}
		}
	}

	onBackButtonClicked() {
		if (!this.activeStepFilterItem().isFirst()) {
			const index = this.stepFilterItemComponents.toArray().indexOf(this.activeStepFilterItem());
			this.stepFilterItemComponents.get(index - 1).onActivate();
		}
	}

	onPreloadNextItems() {
		const index = this.stepFilterItemComponents.toArray().indexOf(this.activeStepFilterItem());
		if (this.stepFilterItemComponents.length - 1 > index) {
			this.stepFilterItemComponents.get(index + 1).onPreload();
		}
	}

	onClearButtonClicked() {
		this.activeStepFilterItem.set(this.stepFilterItemComponents.first);
		this.stepFilterItemComponents.forEach((stepFilterItemComponent, currentIndex) => {
			stepFilterItemComponent.reset();
		});
		//important! Otherwise some DOM Elements could be duplicated
		this.changeDetectorRef.detectChanges();
	}
	//endregion

	//region Methods
	listenToValueChanges() {
		this.formGroup.valueChanges.pipe(takeUntilDestroyed()).subscribe((changes) => {
			const index = this.stepFilterItemComponents.toArray().indexOf(this.activeStepFilterItem());

			this.stepFilterItemComponents.forEach((stepFilterItemComponent, currentIndex) => {
				if (index < currentIndex) {
					stepFilterItemComponent.reset();
				}
			});
		});
	}

	canStepFilterItemToBeActivated(stepFilterItemComponent: StepFilterItemComponent) {
		if (stepFilterItemComponent.isStepValid() || stepFilterItemComponent.isFirst()) {
			return true;
		}
		let found;
		return this.stepFilterItemComponents.reduce((canBeActivated, currentstepFilterItemComponent) => {
			if (currentstepFilterItemComponent === stepFilterItemComponent) {
				found = true;
			}
			return (found ?? currentstepFilterItemComponent.isStepValid()) && canBeActivated;
		}, true);
	}

	//endregion
}
