import { ApiService, EvasysEffects } from '@evasys/shared/core';
import { Actions } from '@ngrx/effects';
import { EvasysRequestMethodEnum } from '@evasys/globals/shared/enums/general/evasys-request-method.enum';
import { EvasysApiRequestModel } from '@evasys/globals/shared/models/general/evasys-api-request.model';
import { evasysActions } from '../actions';
import { Injectable } from '@angular/core';
import { WizardModel } from '@evasys/globals/shared/models/component/wizard/wizard.model';
import { PropertyTypeConstraintsModel } from '@evasys/globals/shared/models/component/wizard/property-type-constraints.model';
import { EndpointsEnum } from '@evasys/globals/evasys/api/endpoints.enum';

@Injectable()
export class WizardEffects extends EvasysEffects<WizardModel> {
	private API_PATH = EndpointsEnum.WIZARDS;

	constructor(protected override actions$: Actions, protected readonly apiService: ApiService) {
		super(evasysActions.fromWizard, 'wizards', actions$);
	}

	loadOne$ = this.createEffect(
		evasysActions.fromWizard.LoadOneActionSet,
		(action: any) =>
			({
				apiPath: [this.API_PATH, action.payload].join('/'),
				many: false,
				requestMethod: EvasysRequestMethodEnum.GET,
			} as EvasysApiRequestModel)
	);

	loadOneWithParams$ = this.createEffect(evasysActions.fromWizard.LoadOneWithParamsActionSet, (action: any) => {
		return {
			apiPath: [this.API_PATH, action.payload.id].join('/'),
			params: action.payload.params,
			many: false,
			requestMethod: EvasysRequestMethodEnum.GET,
		} as EvasysApiRequestModel;
	});

	protected mapToFrontend(data: any): WizardModel {
		return {
			...data,
			id: data.wizardname,
			show: true,
			wizardData: data.wizardData.map((wData: any) => ({
				usertype: {
					...wData.usertype,
					propertyGroups: wData.usertype.propertyGroups.map((pgroup: any) => ({
						...pgroup,
						properties: pgroup.properties.map((property: any) => ({
							...property,
							type: {
								...property.type,
								constraints: (property.type.constraints as PropertyTypeConstraintsModel[]).reduce(
									(result, item) => {
										const key = Object.keys(item)[0];
										result[key] = item[key];
										return result;
									},
									{}
								),
							},
						})),
					})),
				},
			})),
		} as WizardModel;
	}

	protected mapToBackend(wizard: WizardModel): any {
		const newWizard = {
			...wizard,
		};

		delete newWizard.id;

		return newWizard;
	}
}
