<div class="w-100" *ngIf="filterAreaControls.length" [attr.data-cy]="'typeaheadFilterArea-' + id">
	<div
		class="d-flex justify-content-center align-items-center mb-3"
		*ngIf="showTitle"
		[title]="'common.filter.hover' | transloco"
	>
		<img
			evasysImage
			[id]="'filter'"
			[alt]="'common.filter' | transloco"
			class="c-h-20px c-w-20px mx-1 align-self-center"
			[src]="'./assets/images/icons/filter.svg'"
		/>
		<evasys-header
			[text]="'common.filter' | transloco"
			[headerLevel]="2"
			[id]="'typeaheadFilterArea'"
			[class]="'my-0'"
		></evasys-header>
	</div>
	<div class="filter-area gap-1 d-grid">
		<evasys-chip-typeahead
			*ngFor="let filterAreaControl of filterAreaControls"
			[id]="filterAreaControl.id"
			[searchFailedText]="'error.text' | transloco"
			[items]="filterAreaControl.items"
			[ngModel]="filterSelections[filterAreaControl.id]"
			(ngModelChange)="setFilterSelection($event, filterAreaControl.id)"
			[formatter]="filterAreaControl.formatter"
			[itemIdentifier]="filterAreaControl.identifier"
			[design]="chipTypeaheadDesign.NONE"
			[emptyResultsText]="filterAreaControl.emptyResultsText | transloco"
			[popoverContainer]="'body'"
			[popoverClass]="'popover-z-index-1500'"
			[showSelectAll]="true"
			[title]="filterAreaControl.name + '.hover' | transloco"
		>
			<img
				evasysImage
				[id]="filterAreaControl.id"
				[altTextAsLabel]="true"
				[alt]="filterAreaControl.name | transloco"
				class="c-h-15px c-w-15px mx-2 align-self-center"
				[src]="'./assets/images/icons/' + filterAreaControl.icon.dark"
			/>{{ filterAreaControl.name | transloco }}</evasys-chip-typeahead
		>
	</div>
	<div class="d-flex gap-1 mb-2 mt-2 flex-wrap" data-cy="filter-selected-chips">
		<ng-container *ngFor="let filterAreaControl of filterAreaControls">
			<evasys-chip
				*ngFor="let filter of filterSelections[filterAreaControl.id]"
				[id]="filterAreaControl.id + '-' + (filter | modify : getIdentifier : filterAreaControl.identifier)"
				[imageSrc]="'./assets/images/icons/' + filterAreaControl.icon.light"
				[text]="filter | modify : formatFilter : filterAreaControl | async"
				[imageAlt]="filterAreaControl.name | transloco"
				(removeAction)="removeSearchSelection(filter, filterAreaControl.id)"
			>
			</evasys-chip>
		</ng-container>
		<ng-container *ngIf="filterSelections | modify : getSelectedFilterLength">
			<evasys-button
				class="d-flex"
				[id]="'resetDetailSearch'"
				[design]="designs.LINK"
				title="{{ 'common.resetFilter.tooltip' | transloco }}"
				(click)="resetFilterSelections($event)"
			>
				<img
					evasysImage
					[altTextAsLabel]="true"
					[id]="'resetFilter'"
					[alt]="'organization.period' | transloco"
					class="c-h-15px c-w-15px mx-2 align-self-center"
					[src]="'./assets/images/icons/navigate_cross.svg'"
				/>
				<div>
					{{ 'common.resetFilter' | transloco }} ({{ filterSelections | modify : getSelectedFilterLength }})
				</div>
			</evasys-button>
		</ng-container>
	</div>
</div>
