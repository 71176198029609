import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { ReportService } from '@evasys/evainsights/shared/core';
import { first, Observable } from 'rxjs';

@Component({
	selector: 'evainsights-delete-modal',
	templateUrl: './delete-modal.component.html',
})
export class DeleteModalComponent<T> {
	@Input()
	@Required()
	id!: string;

	@Input()
	@Required()
	opened!: boolean;

	@Input()
	@Required()
	deleteItem: T | undefined;

	@Input()
	@Required()
	deleteFunction!: (deleteItem: T) => Observable<T>;

	@Input()
	modalTitleText?: string;

	@Input()
	modalContentText?: string;

	@Input()
	deleteButtonText?: string;

	@Output()
	public openedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output()
	public itemChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	buttonDesign = ButtonDesignEnum;

	constructor(private reportService: ReportService) {}

	openedChangeEmit($event: boolean) {
		this.openedChange.emit($event);
	}

	permanentlyDelete() {
		if (this.deleteItem !== undefined) {
			this.deleteFunction(this.deleteItem)
				.pipe(first())
				.subscribe(() => this.itemChange.emit(true));
		}
		this.closeModal();
	}

	closeModal() {
		this.opened = false;
	}
}
