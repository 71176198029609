<evasys-modal
	#modal
	[id]="'updateCheck'"
	[opened]="modalOpen"
	[size]="'lg'"
	[closeable]="false"
	(openedChange)="onClose($event)"
>
	<evasys-header
		[headerLevel]="1"
		[id]="'updateCheck-modal'"
		[text]="'S_UPDATE_CHECK_NOTE' | transloco"
		header
	></evasys-header>
	<p [innerHTML]="updateCheckResponse?.value?.message | unescape" class="c-pre-wrap"></p>
	<div footer>
		<evasys-button (click)="this.onAccept()" [id]="'close-updateCheck-modal'" id="close-updateCheck">{{
			'S_BUTTON_ACCEPT' | transloco
		}}</evasys-button>
	</div>
</evasys-modal>
