import { Component } from '@angular/core';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { ChartExportService } from '../../../services/chart-export/chart-export.service';
import { ChartExportFormat } from '@evasys/globals/evainsights/constants/types';

@Component({
	selector: 'evainsights-report-export-menu',
	templateUrl: './report-export-menu.component.html',
})
export class ReportExportMenuComponent {
	buttonDesign = ButtonDesignEnum;

	constructor(private chartExportService: ChartExportService) {}

	exportAllCharts(format: ChartExportFormat) {
		this.chartExportService.exportAllCharts(format);
	}
}
