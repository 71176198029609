import { Pipe, PipeTransform } from '@angular/core';

import { CalenderTranslationService } from '../services/calender-translation.service';

@Pipe({
  name: 'translation',
})
export class TranslationPipe implements PipeTransform {
  constructor(public calenderTranslationService: CalenderTranslationService) {}

  transform(value: any): string {
    return this.calenderTranslationService.listTranslation[value];
  }
}
