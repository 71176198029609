import { ModelId } from '../constants/model-ids';
import { ParamMap } from '@angular/router';
import { ReportType } from '../constants/types';

function getIdFromParamMap(paramMap: ParamMap, modelId: ModelId): number {
	const id = Number(paramMap.get(modelId));
	return isNaN(id) ? 0 : id;
}

export function getReportIdFromParamMap(paramMap: ParamMap): number {
	return getIdFromParamMap(paramMap, ModelId.REPORT_ID);
}

export function getReportItemIdFromParamMap(paramMap: ParamMap): number {
	return getIdFromParamMap(paramMap, ModelId.REPORT_ITEM_ID);
}

export function getReportTemplateIdFromParamMap(paramMap: ParamMap): number {
	return getIdFromParamMap(paramMap, ModelId.REPORT_TEMPLATE_ID);
}

export function getReportIdOrReportTemplateIdFromParamMap(paramMap: ParamMap, reportType: ReportType): number {
	return reportType === ReportType.REPORT
		? getReportIdFromParamMap(paramMap)
		: getReportTemplateIdFromParamMap(paramMap);
}
