import { Pipe, PipeTransform } from '@angular/core';
import { PropertyTypeConstraintsModel } from '@evasys/globals/shared/models/component/wizard/property-type-constraints.model';

@Pipe({
	name: 'constraintsToTextboxType',
})
export class ConstraintsToTextboxTypePipe implements PipeTransform {
	transform(constraints: PropertyTypeConstraintsModel): string {
		let type = 'text';
		if (constraints.numeric) type = 'number';
		if (constraints.emailType) type = 'email';
		if (constraints.password) type = 'password';
		return type;
	}
}
