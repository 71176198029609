import { Injectable } from '@angular/core';
import { HashMap, TranslateParams, TranslocoService } from '@ngneat/transloco';

@Injectable({
	providedIn: 'root',
})
export class TranslateService {
	public constructor(private service: TranslocoService) {}

	public translate(key: string, params?: HashMap, lang?: string): string;
	public translate(key: string[], params?: HashMap, lang?: string): string[];
	public translate(key: TranslateParams, params?: HashMap, lang?: string): TranslateParams;
	public translate(map: Map<any, TranslateParams>, params?: HashMap, lang?: string): Map<any, TranslateParams>;
	public translate(keyOrMap: MaybeMap<TranslateParams>, params?: HashMap, lang?: string): MaybeMap<TranslateParams> {
		if (keyOrMap instanceof Map) {
			const map = new Map(keyOrMap);
			map.forEach((value, key) => {
				map.set(key, this.service.translate(value, params, lang));
			});
			return map;
		}

		return this.service.translate(keyOrMap, params, lang);
	}
}

type MaybeMap<T> = T | Map<any, T>;
