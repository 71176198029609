import { Pipe, PipeTransform } from '@angular/core';
import { HashMap, TranslocoPipe } from '@ngneat/transloco';

@Pipe({
	name: 'translate',
})
export class TranslatePipe implements PipeTransform {
	public constructor(private translocoPipe: TranslocoPipe) {}

	public transform(key: string, params?: HashMap, inlineLang?: string): string;
	public transform<T>(map: Map<T, string>, params?: HashMap, inlineLang?: string): Map<T, string>;
	public transform<T>(keyOrMap: KeyOrMap<T>, params?: HashMap, inlineLang?: string): KeyOrMap<T> {
		if (keyOrMap instanceof Map) {
			const map = new Map(keyOrMap);
			map.forEach((value, key) => {
				map.set(key, this.translocoPipe.transform(value, params, inlineLang));
			});
			return map;
		}

		return this.translocoPipe.transform(keyOrMap, params, inlineLang);
	}
}

type KeyOrMap<T> = string | Map<T, string>;
