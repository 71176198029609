import { AfterContentChecked, Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { NgbDatepicker, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Range } from '../lib/utils';
import { DatepickerI18n } from './datepicker-i18n.service';
import { DatePickerService } from '../services/date-picker.service';

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [{ provide: NgbDatepickerI18n, useClass: DatepickerI18n }],
})
export class DatePickerComponent implements OnDestroy, AfterContentChecked {
  @Output()
  date: EventEmitter<Date> = new EventEmitter<Date>();

  initDate: Date = new Date();
  startDate?: NgbDateStruct;
  years: number[] = [];
  i18nMonth: string;

  @ViewChild('dp', { static: true }) datepicker: NgbDatepicker;

  constructor(public i18n: NgbDatepickerI18n, public datePickerService: DatePickerService) {
    this.datePickerService.internalDate.subscribe((value) => {
      if (value) {
        this.startDate = {
          year: value.getFullYear(),
          month: value.getMonth() + 1,
          day: value.getDate(),
        };
        this.years = Range.createArray(value.getFullYear() - 15, value.getFullYear() + 20);
        this.initDate = value;
      }
    });
  }

  ngAfterContentChecked(): void {
    if (this.startDate) {
      this.i18nMonth = this.i18n.getMonthFullName(this.startDate.month);
      this.datepicker.focusDate(this.startDate);
      this.datepicker.focusSelect();
    }
  }

  ngOnDestroy() {
    this.datePickerService.internalDate.unsubscribe();
  }

  navigatePrev() {
    const date = this.datepicker.calendar.getNext(this.datepicker.state.firstDate, 'm', -1);
    this.datepicker.navigateTo({ year: date.year, month: date.month, day: date.day });
    this.datepicker.focusSelect();
  }

  navigateNext() {
    const date = this.datepicker.calendar.getNext(this.datepicker.state.firstDate, 'm', +1);
    this.datepicker.navigateTo({ year: date.year, month: date.month, day: date.day });
    this.datepicker.focusSelect();
  }

  onChangeYear(year: any): void {
    this.datepicker.navigateTo({ year: Number(year.target.value), month: this.startDate.month });
    this.datepicker.focusSelect();
  }

  onDateChange(currentDate: NgbDateStruct): void {
    const dateTmp: Date = this.initDate;
    if (this.startDate !== currentDate) {
      dateTmp.setFullYear(currentDate.year, currentDate.month - 1, currentDate.day);
      this.date.emit(dateTmp);
    }
  }
}
