<evasys-sidebar-container>
	<evasys-page class="overflow-auto h-100">
		<evasys-breadcrumb [breadItems]="breadcrumbItems | async"></evasys-breadcrumb>
		<evasys-header
			top
			[id]="'sidebarCommon'"
			[headerLevel]="1"
			[text]="heading"
			[title]="headingHover"
			class="text-center"
		></evasys-header>
		<ng-content></ng-content>
		<div bottom>
			<ng-content select="[bottom]"></ng-content>
		</div>
	</evasys-page>
</evasys-sidebar-container>
