import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CalenderTranslationService } from '../services/calender-translation.service';

@Injectable()
export class DatepickerI18n extends NgbDatepickerI18n {
  constructor(public calenderTranslationService: CalenderTranslationService) {
    super();
  }

  getWeekdayLabel(weekday: number): string {
    const shortDay: string = this.calenderTranslationService.listTranslation['weekdays'][weekday - 1];
    return shortDay.slice(0, 2);
  }

  getMonthShortName(month: number): string {
    const shortMonth: string = this.calenderTranslationService.listTranslation['months'][month - 1];
    return shortMonth.slice(0, 2);
  }

  getMonthFullName(month: number): string {
    return this.calenderTranslationService.listTranslation['months'][month - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.year}-${date.month}-${date.day}`;
  }
}
