import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectedItem',
})
export class SelectedItemPipe implements PipeTransform {
  transform(value: number, state: number | undefined): unknown {
    return value === state;
  }
}
