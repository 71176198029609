import { Component, Input } from '@angular/core';
import { DashboardTableDataProvider } from '@evasys/evainsights/shared/core';

@Component({
	selector: 'evainsights-overview-table',
	templateUrl: './overview-table.component.html',
})
export class OverviewTableComponent {
	@Input()
	dashboardTableDataProvider!: DashboardTableDataProvider<unknown, string>;
	@Input()
	tableId!: string;
}
