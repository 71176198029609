import { Component, Input } from '@angular/core';

@Component({
	selector: 'evasys-table-cell-image',
	templateUrl: './table-cell-image.component.html',
})
export class TableCellImageComponent {
	@Input()
	public id: string;

	@Input()
	public src: string;

	@Input()
	public alt?: string = '';

	@Input()
	public isIcon?: boolean = true;

	@Input()
	public tooltip?: string;

	@Input()
	public class: string;

	@Input()
	altTextAsLabel = false;
}
