import { BarChartContent, LineChartContent } from './axis-chart';
import { ProfileLineChartContent } from './profile-line-chart-report-item-content.model';
import { RichTextContent } from './rich-text-report-item-content.model';
import { TopicWordcloudContent } from './topic-wordcloud-report-item-content.model';
import { TopicWordcloudResponsesContent } from './topic-wordcloud-responses-report-item-content.model';
import { ResponsesContent } from './responses-report-item-content.model';
import { WordFrequencyWordcloudContent } from './word-frequency-wordcloud-report-item-content.model';
import { DividerContent } from './divider-report-item-content.model';

export type WordcloudContent = TopicWordcloudContent | WordFrequencyWordcloudContent;

export type ReportItemContent =
	| BarChartContent
	| LineChartContent
	| ProfileLineChartContent
	| RichTextContent
	| DividerContent
	| WordcloudContent
	| TopicWordcloudResponsesContent
	| ResponsesContent;

export type ReportItemContentWithoutData = Omit<ReportItemContent, 'data'>;
