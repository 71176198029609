import { Component, computed, ElementRef, EventEmitter, Input, Output, signal, ViewChild } from '@angular/core';
import { ChartExportFormat, VisualizationType } from '@evasys/globals/evainsights/constants/types';
import { ReportItem } from '@evasys/globals/evainsights/models/report-item';
import { ChartExportMenuComponent } from '../../chart-export-menu/chart-export-menu.component';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { ReportLanguage } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';

@Component({
	selector: 'evainsights-report-item',
	templateUrl: './report-item.component.html',
	styleUrls: ['./report-item.component.scss'],
})
export class ReportItemComponent {
	@Input({ required: true })
	set reportItem(value: ReportItem) {
		this._reportItem.set(value);
	}

	@Input()
	editable = false;

	@Input()
	language?: ReportLanguage;

	@Output()
	selectExportFormat = new EventEmitter<ChartExportFormat>();

	@Output()
	configure = new EventEmitter<void>();

	@Output()
	delete = new EventEmitter<void>();

	@ViewChild('exportMenu')
	exportMenu?: ChartExportMenuComponent;

	_reportItem = signal<ReportItem>(null as unknown as ReportItem);
	isCard = computed(() => this._reportItem().content.config.visualizationType !== VisualizationType.DIVIDER);
	bgClass = computed(() => (this.isCard() ? 'c-bg-white' : 'c-bg'));
	isConfigurable = computed(() => this._reportItem().content.config.visualizationType !== VisualizationType.DIVIDER);
	isDownloadable = computed(() =>
		[
			VisualizationType.WORDCLOUD,
			VisualizationType.BAR_CHART,
			VisualizationType.LINE_CHART,
			VisualizationType.RADAR_CHART,
			VisualizationType.WORDCLOUD_RESPONSES,
			VisualizationType.PROFILE_LINE_CHART,
			VisualizationType.RESPONSES,
		].includes(this._reportItem().content.config.visualizationType)
	);

	buttonLinkDesign = ButtonDesignEnum.LINK;

	protected readonly visualizationType = VisualizationType;

	constructor(public elementRef: ElementRef) {
		/* this is intentional */
	}
}
