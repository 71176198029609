import { Component, Input } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { AccordionItemComponent } from './accordion-item/accordion-item.component';

@Component({
	selector: 'evasys-accordion',
	templateUrl: './accordion.component.html',
	styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
	//region Input & Outputs
	@Required()
	@Input()
	id!: string;

	@Input()
	alwaysOpen: boolean;
	//endregion

	//region Variables
	private _activeAccordionItemId: string;
	private accordionItems: Array<AccordionItemComponent> = new Array<AccordionItemComponent>();
	//endregion

	//region Getter & Setter
	set activeAccordionItemId(activeAccordionItemId: string) {
		this._activeAccordionItemId = activeAccordionItemId;
		if (!this.alwaysOpen) {
			this.accordionItems.forEach((accordionItem) => {
				if (accordionItem.id !== this._activeAccordionItemId) {
					accordionItem.show = false;
				}
			});
		}
	}
	//endregion

	//region Methods
	registerAccordionItem(accordionItem: AccordionItemComponent) {
		if (!this.accordionItems.includes(accordionItem)) {
			this.accordionItems.push(accordionItem);
		}
	}
	//endregion
}
