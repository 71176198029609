import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EvasysLoadingStrategiesEnum } from '@evasys/globals/shared/enums/general/evasys-loadingStrategies.enum';
import { ReportTemplate } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { Observable } from 'rxjs';
import { ReportTemplateFacadeService } from '@evasys/evainsights/stores/core';
import { getReportTemplateIdFromParamMap } from '@evasys/globals/evainsights/helper/url-params';

@Component({
	selector: 'evainsights-report-template-form',
	templateUrl: './report-template-form.component.html',
})
export class ReportTemplateFormComponent implements OnInit {
	reportTemplate!: Observable<ReportTemplate | undefined>;
	showForm = false;

	constructor(
		private activatedRoute: ActivatedRoute,
		private reportTemplateFacadeService: ReportTemplateFacadeService
	) {}

	ngOnInit(): void {
		this.activatedRoute.paramMap.subscribe((paramMap) => {
			let reportTemplateId = getReportTemplateIdFromParamMap(paramMap);
			if (isNaN(reportTemplateId)) reportTemplateId = 0;
			this.reportTemplate = this.reportTemplateFacadeService.get<ReportTemplate>({
				id: reportTemplateId,
				loadingStrategy: EvasysLoadingStrategiesEnum.STATEONLY,
			});
		});
	}
}
