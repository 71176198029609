import {
  AfterViewChecked,
  OnDestroy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DatePickerService } from '../../services/date-picker.service';
import { Range } from '../../lib/utils';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'time-select',
  templateUrl: './time-select.component.html',
  styleUrls: ['./time-select.component.scss'],
})
export class TimeSelectComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input()
  value: number = 0;

  @Output()
  selectedValue: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  active: number | undefined;

  @Input()
  isHours: boolean = false;

  @Input()
  isMeridiem: boolean = false;

  items: number[] = [];

  private isOpen = false;

  @ViewChild('list') timeSelectList: ElementRef<HTMLElement>;

  constructor(public datePickerService: DatePickerService, private dropdown: NgbDropdown) {}

  ngOnInit(): void {
    this.items = Range.createArray(0, this.value - 1);
    this.dropdown.openChange.subscribe((opened: boolean) => {
      this.isOpen = opened;
    });
  }

  ngAfterViewChecked(): void {
    let timeSelectListElement: HTMLElement = this.timeSelectList.nativeElement;
    if (this.isOpen && timeSelectListElement && timeSelectListElement.scrollHeight > 0) {
      this.scrollTo(timeSelectListElement);
    }
  }

  ngOnDestroy(): void {
    this.dropdown.openChange.unsubscribe();
  }

  scrollTo(el: HTMLElement): void {
    if (this.active ?? 0 > 5) {
      // itemHeigh * (itemCount - (2,5 => items))
      const val = 37.125 * ((this.active ?? 0) - 2.5);
      el.scroll({
        top: val,
        left: 0,
        behavior: 'smooth',
      });
    }

    this.isOpen = false;
  }

  onSelect(item: any): void {
    this.selectedValue.emit(item);
    this.active = item;
  }
}
