import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
	name: 'toMap',
})
export class ToMapPipe implements PipeTransform {
	transform<T>(entities: T[] | undefined, key: keyof T, value: keyof T): Map<number | string, string> {
		if (entities && entities?.length > 0) {
			return entities.reduce((map, entity) => {
				map.set(entity[key], entity[value]);
				return map;
			}, new Map());
		} else {
			return new Map<number | string, string>();
		}
	}
}
