import { Component } from '@angular/core';
import { Size } from '../d3-base/d3-base.component';
import { AxisChartComponent } from '../axis-chart/axis-chart.component';
import { LineChart } from '../../../charts';
import { LineChartContent } from '@evasys/globals/evainsights/models/report-item';

@Component({
	selector: 'evainsights-line-chart-content',
	templateUrl: '../d3-base/d3-base.component.html',
})
export class LineChartContentComponent extends AxisChartComponent<LineChartContent> {
	override draw(host: HTMLElement, size: Size) {
		new LineChart(host, this.ctx, this.content).render({
			size,
			reportLanguageId: this.language.id,
			decimalFormat: this.decimalFormat,
		});
	}
}
