import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { SharedUiConfiguration } from '../../../shared-ui.configuration';
import { FileReaderOutputType } from '@evasys/globals/shared/types/file-reader-output.types';
import { ValidationErrorModel } from '@evasys/globals/evasys/models/component/validation-error.model';
import { TextboxComponent } from '../textbox/textbox/textbox.component';

@Component({
	selector: 'evasys-upload-box',
	templateUrl: './upload-box.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: UploadBoxComponent,
			multi: true,
		},
	],
})
export class UploadBoxComponent implements ControlValueAccessor {
	//region ViewChilds
	@ViewChild('fileInputElement')
	fileInputElement: ElementRef<HTMLInputElement>;

	@ViewChild('textboxComponent')
	textboxComponent: TextboxComponent;
	//endregion

	//region Input & Output
	@Input()
	@Required()
	public id?: string;

	@Input()
	public outputType: FileReaderOutputType = 'File';

	@Input()
	public class: string;

	@Input()
	public formControlName?: string;

	@Input()
	public accept: string[] | null;

	@Input()
	disabled = false;

	@Input()
	maxSizeKb: number | null;

	@Input()
	clearButton = false;

	@Input()
	imagePreview = false;

	@Input()
	errors: ValidationErrorModel[] = [];

	@Output()
	public file = new EventEmitter<File>();

	//fileContent is not emit an event if outputType is 'File'
	@Output()
	public fileContent = new EventEmitter<string | ArrayBuffer>();

	@Output()
	public readFileError = new EventEmitter<DOMException>();

	@Output()
	public fileSizeError = new EventEmitter<number>();

	@Output()
	public fileAcceptError = new EventEmitter<string>();
	//endregion Input & Output

	//region Variables
	public input = new FormControl();
	public imagePreviewSrc?: string;
	public fileName?: string;

	//endregion Variables

	public constructor(public readonly config: SharedUiConfiguration) {}

	//region Events
	_onChange: any = () => {
		//default
	};
	_onTouched: any = () => {
		//default
	};

	public onFileChange(event: any): void {
		const file = event.target.files[0];
		const fileType = file.type;

		//check file size
		if (this.maxSizeKb != null && file.size > this.maxSizeKb * 1000) {
			this.fileSizeError.emit(file.size);
			this.fileInputElement.nativeElement.value = null;
			//check file accepted type
		} else if (this.accept != null && !this.accept.includes(fileType)) {
			this.fileAcceptError.emit(fileType);
			this.fileInputElement.nativeElement.value = null;
		} else {
			//success
			this.file.emit(file);
			this.emitFileContent(file);
		}
	}

	onClear() {
		this.clear();
		this.file.emit(null);
		this.emitFileContent(null);
	}

	onImageClear() {
		this.textboxComponent.clear();
		this.clear();
		this.file.emit(null);
		this.emitFileContent(null);
	}

	//endregion Events

	//region Methods
	private createFilePreview(file: File) {
		if (file && file['type'].split('/')[0] === 'image') {
			const reader = new FileReader();
			reader.onload = () => {
				if (typeof reader.result === 'string') {
					this.imagePreviewSrc = reader.result;
				}
			};
			reader.onerror = () => {
				this.readFileError.emit(reader.error ?? undefined);
			};
			reader.readAsDataURL(file);
		}
	}

	private clear() {
		this.input.reset();
		this.fileInputElement.nativeElement.value = '';
		this.imagePreviewSrc = undefined;
		this.fileName = undefined;
	}

	private emitFileContent(file: File): void {
		if (this.outputType !== 'File') {
			const reader = new FileReader();

			reader.onload = () => {
				this.fileName = file?.name;

				this._onChange(reader.result);
				this.writeValue(reader.result);
				this.fileContent.emit(reader.result);
			};

			reader.onerror = () => {
				this.readFileError.emit(reader.error ?? undefined);
			};

			switch (this.outputType) {
				case 'ArrayBuffer':
					reader.readAsArrayBuffer(file);
					break;
				case 'BinaryString':
					reader.readAsBinaryString(file);
					break;
				case 'DataURL':
					reader.readAsDataURL(file);
					break;
				case 'Text':
					reader.readAsText(file);
					break;
			}
		} else {
			this._onChange(file);
			this.writeValue(file);
		}
	}
	//endregion

	//#region ControlValueAccessor

	registerOnChange(fn: (_: any) => void): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this._onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		isDisabled ? this.input.disable() : this.input.enable();
	}

	writeValue(value: string | File | ArrayBuffer): void {
		if (value === '') {
			value = null;
		}
		if (value === null) {
			this.input.reset();
			if (this.fileInputElement) {
				this.fileInputElement.nativeElement.value = '';
			}
		} else if (value instanceof File) {
			this.createFilePreview(value);
			this.fileName = value?.name;
		} else if (typeof value === 'string') {
			this.imagePreviewSrc = value;
		}
		this.input.setValue(value);
	}

	//#endregion ControlValueAccessor
}
