<div class="position-relative" (evasysClickAway)="isMenuOpen = false">
	<evasys-button [id]="'moreButton-' + id" [design]="buttonDesign.OUTLINE_LINK" (click)="onToggleMenu()">
		<img
			evasysImage
			[id]="'openMoreMenu'"
			[class]="'c-w-25px c-h-25px'"
			[src]="'./assets/images/icons/ebene.svg'"
			[isIcon]="true"
		/>
	</evasys-button>
	<div *ngIf="isMenuOpen" class="position-absolute card c-z-index-3 end-0">
		<ng-content></ng-content>
	</div>
</div>
