<evasys-button
	[id]="'collapse-' + id"
	[design]="buttonDesigns.LINK"
	[attr.aria-expanded]="!collapsed"
	(click)="collapseComponent.toggle()"
>
	<span [class]="labelClass">
		{{ currentLabel }}
	</span>
	<img
		evasysImage
		[class]="'c-w-15px ms-2'"
		[isIcon]="true"
		[id]="'severityIcon'"
		[src]="'./assets/images/icons/' + (collapsed ? 'angle-down' : 'angle-up') + '.svg'"
	/>
</evasys-button>
