export class Parser {
  public static parseInitDate(value: string): Date {
    let date = Date.parse(value.replace(' ', 'T'));
    return new Date(date);
  }

  public static parseDate(value: string, initalFormat: string, defaultChar: string): Date {
    try {
      const dateTimeFormatSpilt: string[] = initalFormat.split(' ');
      const dateFormatSplit: string[] = dateTimeFormatSpilt[0].split(defaultChar);

      const valueDateTimeSplit: string[] = value.split(' ');
      const dateSplit: string[] = valueDateTimeSplit[0].split(defaultChar);
      const timeSplit: string[] = valueDateTimeSplit[1].split(':');

      let date: Date = new Date();

      for (let i = 0; i < dateSplit.length; i++) {
        switch (dateFormatSplit[i]) {
          case 'dd':
            date.setDate(Number(dateSplit[i]));
            break;
          case 'MM':
            date.setMonth(Number(dateSplit[i]) - 1);
            break;
          case 'MMM':
            date.setMonth(Number(dateSplit[i]) - 1);
            break;
          case 'yyyy':
            date.setFullYear(Number(dateSplit[i]));
            break;
          case 'yy':
            date.setFullYear(Number(dateSplit[i]));
            break;
        }
      }

      if (valueDateTimeSplit[1] !== undefined) {
        if (timeSplit[0] !== undefined) {
          date.setHours(Number(timeSplit[0]));
        }

        if (timeSplit[1] !== undefined) {
          date.setMinutes(Number(timeSplit[1]));
        }

        if (timeSplit[2] !== undefined) {
          date.setSeconds(Number(timeSplit[2]));
        }
      }

      return date;
    } catch {
      return new Date();
    }
  }
}
