<label for="date" class="form-label" [class.hidden]="!label">{{ label }}</label>
<div class="input-group d-flex flex-row flex-nowrap" ngbDropdown>
  <input
    class="form-control w-100"
    id="date"
    name="date"
    [attr.disabled]="_disable"
    [value]="displayDate ?? ''"
    (keyup)="onKeyup($event)"
    (change)="onDateInputChange($event)"
    [class.is-invalid]="isInvalid"
    data-cy="textbox-calendar"
  />
  <button
    type="button"
    class="btn btn-primary bg-primary border-primary z-index-unset w-auto"
    id="dropdownMenuDate"
    [disabled]="_disable"
    data-cy="dropdown-toggle-calendar"
    ngbDropdownToggle
  >
    <svg
      class="icon"
      version="1.1"
      id="calendar"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 448 512"
      xml:space="preserve"
    >
      <path
        class="st0"
        d="M400,64h-48V12c0-6.6-5.4-12-12-12h-8c-6.6,0-12,5.4-12,12v52H128V12c0-6.6-5.4-12-12-12h-8
	        c-6.6,0-12,5.4-12,12v52H48C21.5,64,0,85.5,0,112v352c0,26.5,21.5,48,48,48h352c26.5,0,48-21.5,48-48V112C448,85.5,426.5,64,400,64z
	        M48,96h352c8.8,0,16,7.2,16,16v48H32v-48C32,103.2,39.2,96,48,96z M400,480H48c-8.8,0-16-7.2-16-16V192h384v272
	        C416,472.8,408.8,480,400,480z"
      />
    </svg>
  </button>

  <div ngbDropdownMenu aria-labelledby="dropdownMenuDate">
    <div class="container">
      <div>
        <date-picker (date)="onDate($event)"></date-picker>
      </div>
      <div class="divider"></div>
      <div class="time-picker">
        <time-picker (date)="onDate($event)"></time-picker>
      </div>
    </div>
  </div>
</div>
<div data-cy="calendar-invalidDate" class="error" [class.hidden]="!isInvalid">
  {{ 'invalidDate' | translation }}
</div>
