import { LegacyReportItemContentConfigBase, ReportItemContentBase } from './common';
import { VisualizationType } from '../../../constants/types';
import { Item } from '../../item/item.model';
import { ReportMultiLang } from '../../common/multi-lang';
import { ChartDataStatus } from '../../../constants/status';

export type ProfileLineChartContent = ReportItemContentBase<ProfileLineChartConfig, ProfileLineChartData>;

// Config
export interface ProfileLineChartConfig
	extends LegacyReportItemContentConfigBase<VisualizationType.PROFILE_LINE_CHART> {
	items: Item[];

	title: ReportMultiLang<string>;
	showStatistics: boolean;

	useMedian: boolean;
	showMedian: boolean;
	showMean: boolean;
	fractionDigits: number;
	fontFamily: string;
	fontSizeStaticValues: string;
	fontSizeQuestionText: string;
}

// Data

export interface ProfileLineChartData {
	lines: LineData[];
	statisticData: StatisticalData[];
	items: ItemData[];
	status?: ChartDataStatus;
	calculatedOn?: string;
}

export interface LineData {
	title: ReportMultiLang<string>;
	surveyId: number;
	color: string;
}

export interface StatisticalData {
	surveyId: number;
	data: Data[];
}

export interface ItemData {
	itemId: string;
	itemTitle: ReportMultiLang<string>;
	leftPolText: ReportMultiLang<string>;
	rightPolText: ReportMultiLang<string>;
	answerOptions: number;
}

export interface Data {
	itemId: string;
	responseCount: string;
	mean: string;
	stdDev: string;
	median: string;
}
