import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
	selector: '[evasysLazyLoad]',
})
export class LazyLoadDirective {
	//region Listener
	@HostListener('scroll')
	scrolling() {
		this.onScroll();
	}
	//endregion

	//region Input & Output
	@Input()
	triggerArea = 50;

	@Output()
	pageChange: EventEmitter<number> = new EventEmitter<number>();
	//endregion

	//region Variables
	private newPage = 1;
	private scrollMarker: number = 0;
	//endregion

	constructor(private readonly elementRef: ElementRef) {}

	//region Methods
	onScroll() {
		if (this.elementRef.nativeElement.scrollHeight !== this.scrollMarker) {
			const scrollBottom =
				this.elementRef.nativeElement.scrollHeight -
				this.elementRef.nativeElement.scrollTop -
				this.elementRef.nativeElement.clientHeight;
			if (scrollBottom < this.triggerArea) {
				this.scrollMarker = this.elementRef.nativeElement.scrollHeight;
				this.onNewPage();
			}
		}
	}

	onNewPage() {
		this.newPage++;
		this.pageChange.emit(this.newPage);
	}

	//endregion
}
