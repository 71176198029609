import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValidationErrorModel } from '@evasys/globals/evasys/models/component/validation-error.model';
import { LabelPositionEnum } from '@evasys/globals/shared/enums/component/label-position.enum';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { Subscription } from 'rxjs';

@Component({
	selector: 'evasys-radio-button',
	templateUrl: './radio-button.component.html',
	styleUrls: ['./radio-button.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: RadioButtonComponent,
			multi: true,
		},
	],
})
export class RadioButtonComponent implements ControlValueAccessor, OnDestroy {
	//region ViewChilds
	@ViewChild('labelElement')
	labelElement: ElementRef<HTMLLabelElement>;
	//endregion

	//#region Input & Output
	@Input()
	@Required()
	id: string | undefined;

	@Input()
	name: string | undefined;

	@Input()
	lablePosition: LabelPositionEnum = LabelPositionEnum.RIGHT;

	@Input()
	set value(value: string | number | null) {
		if (value !== null && typeof value === 'number') {
			value = value.toString();
		}
		this._value = value;
	}

	@Input()
	errors: ValidationErrorModel[] = [];

	@Input()
	set isDisabled(disabled: boolean) {
		this.setDisabledState(disabled);
	}

	@Input()
	public set checked(checked: boolean) {
		if (checked) {
			this.onChecked();
		}
	}

	@Output()
	checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	//#endregion Input & Output

	//#region Variables
	public _isDisabled = false;
	_value: string | number | undefined;
	public labelPosition = LabelPositionEnum;
	public input = new FormControl();

	private readonly subscriptions: Subscription[] = [];
	//#endregion Variables

	//#region Events
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	onChecked(): void {
		this.input.setValue(this._value);
		this.checkedChange.emit(this.input.value);
	}

	//#endregion Events

	//#region Methods
	isInvalid(): boolean {
		return this.errors.find((error) => error.trigger) != null;
	}

	isChecked() {
		return this.input.value === this._value;
	}

	//#endregion Methods

	//#region ControlValueAccessor

	registerOnChange(fn: (_: any) => void): void {
		this.subscriptions.push(this.input.valueChanges.subscribe(fn));
	}

	registerOnTouched(_fn: any): void {
		// default
	}

	setDisabledState(isDisabled: boolean): void {
		this._isDisabled = isDisabled;
		isDisabled ? this.input.disable() : this.input.enable();
	}

	public writeValue(value: string | number | null): void {
		if (value !== null) {
			if (typeof value === 'number') {
				value = value.toString();
			}
			this.input.setValue(value);
		}
	}

	//#endregion ControlValueAccessor
}
