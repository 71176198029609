import { Component, Input } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evainsights-icon-popover',
	templateUrl: './icon-popover.component.html',
})
export class IconPopoverComponent {
	@Input()
	@Required()
	id: string | undefined;

	@Input()
	@Required()
	src!: string;

	@Input()
	@Required()
	text!: string;

	@Input()
	title = '';

	@Input()
	popoverClass = '';

	@Input()
	placement: 'top' | 'bottom' | 'start' | 'end' = 'end';
}
