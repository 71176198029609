import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'entitiesToIds',
	pure: false,
})
export class EntitiesToIdsPipe implements PipeTransform {
	transform<T extends { id: number }>(ids: number[], entities: T[]): T[] {
		return entities?.filter((entity) => ids?.includes(entity?.id)) ?? [];
	}
}
