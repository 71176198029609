<div
	class="d-flex flex-column w-100 h-100"
	[attr.data-evainsights-chart-title]="titleText"
	[attr.data-evainsights-chart-isExportable]="isExportable"
>
	<!-- CHART HEADER COMES HERE -->
	<div class="d-flex flex-row justify-content-center">
		<div #popoverRef [ngClass]="popoverRef.children.length ? 'me-auto' : ''">
			<ng-content select="[infoPopover]"></ng-content>
		</div>
		<evainsights-report-item-title
			[ngClass]="popoverRef.children.length ? 'm-auto' : ''"
			[titleText]="titleText"
		></evainsights-report-item-title>
		<div *ngIf="popoverRef.children.length" class="invisible ms-auto"></div>
	</div>

	<!-- CHART CONTENT COMES HERE -->
	<ng-content></ng-content>

	<!-- CHART OVERLAY COMES HERE -->
	<evainsights-text-overlay *ngIf="showOverlay">
		{{ 'reportItem.status.' + reportItemStatus | transloco }}
	</evainsights-text-overlay>
</div>
