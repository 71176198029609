<ngb-alert *ngIf="!hidden" class="border" type="custom" (closed)="onClosed()">
	<div class="d-flex flex-row flex-nowrap">
		<img
			evasysImage
			[class]="'c-w-23px c-w-23px'"
			[ngClass]="asCssClass(notification.severity)"
			class="col-auto p-0 m-0 me-3 align-self-center"
			[isIcon]="true"
			[id]="'severityIcon'"
			[src]="'./assets/images/icons/' + notification.severity + '.svg'"
		/>
		<span class="m-0 p-0 col">{{ notification.message }}</span>
	</div>
</ngb-alert>
