import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'hasOnly',
})
export class HasOnlyPipe implements PipeTransform {
	transform<T>(entities: T[], property: keyof T, value: any): boolean {
		return entities?.map((entity) => entity[property]).every((currentValue) => currentValue === value);
	}
}
