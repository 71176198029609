import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ReportLanguage } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';

@Pipe({
	name: 'getLanguageControl',
})
export class GetLanguageControlPipe implements PipeTransform {
	transform(formGroup: FormGroup, language: ReportLanguage): AbstractControl | null {
		return formGroup.get(language.id.toString());
	}
}
