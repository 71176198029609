import { Component, Input } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { DesignEnum } from '@evasys/globals/shared/enums/component/design.enum';

@Component({
	selector: 'evasys-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
	@Input()
	@Required()
	public id?: string;

	@Input()
	public value?: number;

	@Input()
	public design: DesignEnum = DesignEnum.SECONDARY;
}
