import { Component, computed, inject, ViewEncapsulation } from '@angular/core';
import { DropdownComponent } from '../../dropdown/dropdown.component';

@Component({
	selector: 'evasys-dropdown-filter',
	templateUrl: './dropdown-filter.component.html',
	styleUrls: ['./dropdown-filter.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DropdownFilterComponent {
	//region Injections
	public dropdownComponent = inject(DropdownComponent);
	//endregion

	//region Variables
	public hasValue = computed(() => {
		if (this.dropdownComponent.splitControlValues) {
			return (
				this.dropdownComponent._value() &&
				(this.dropdownComponent._value() satisfies []).filter((item) => !!item).length > 0
			);
		} else {
			return (
				this.dropdownComponent._value() &&
				(!Array.isArray(this.dropdownComponent._value()) || this.dropdownComponent._value().length > 0)
			);
		}
	});

	//endregion
}
