import { AfterViewInit, Component, ContentChild, Input, OnDestroy, OnInit } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { EvasysSortingModel } from '@evasys/globals/shared/models/general/evasys-sorting.model';
import { BehaviorSubject, filter, first, merge, Observable, shareReplay, Subscription, withLatestFrom } from 'rxjs';
import { SearchboxComponent } from '../../../app-shell-components/searchbox/searchbox.component';
import { LoadingOverlayComponent } from '../../../dynamic-components/loading-overlay/loading-overlay.component';
import { TableComponent } from '../../table/table/table.component';
import { TableAreaService } from './table-area.service';
import { TableAreaArgs } from '@evasys/globals/shared/models/component/table-area/table-area-args.model';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { TableAreaRegisterService } from '../table-area-register/table-area-register.service';

enum SortEnum {
	ASC = 'asc',
	DESC = 'desc',
}

@Component({
	selector: 'evasys-table-area',
	templateUrl: './table-area.component.html',
})
export class TableAreaComponent<T extends { id: I }, I = number | string> implements AfterViewInit, OnDestroy, OnInit {
	//region ContentChilds
	@ContentChild(SearchboxComponent, { descendants: true })
	set searchboxComponent(searchboxComponent: SearchboxComponent | undefined) {
		if (searchboxComponent) {
			searchboxComponent.id = this.id;
		}
		this._searchboxComponent = searchboxComponent;
	}

	@ContentChild(LoadingOverlayComponent, { descendants: true })
	set loadingOverlayComponent(loadingOverlayComponent: LoadingOverlayComponent | undefined) {
		if (loadingOverlayComponent) {
			loadingOverlayComponent.loading = false;
		}
		this._loadingOverlayComponent = loadingOverlayComponent;
	}

	@ContentChild(TableComponent, { descendants: true })
	set tableComponent(tableComponent: TableComponent | undefined) {
		this._tableComponent = tableComponent;
	}
	//endregion

	//region Input & Output
	@Required()
	@Input()
	id: string;

	@Input()
	set loadMethod(loadMethod: (tableAreaArgs?: TableAreaArgs<T>) => Observable<T[]>) {
		this.load = loadMethod;
	}

	@Input()
	set items(items: T[] | null) {
		if (items !== null) {
			this.tableAreaService.setItems(items);
		}
	}

	@Input()
	minMaxHeight = 300;

	@Input()
	searchKeys?: (keyof T)[];

	@Input()
	loadOnInit = false;
	//endregion

	//region Variables

	private load = this.tableAreaService.load;
	private subscriptions?: Subscription[] = [];
	private _searchboxComponent?: SearchboxComponent;
	private _loadingOverlayComponent?: LoadingOverlayComponent;
	private _tableComponent?: TableComponent;
	private tableAreaArgsChanges = new BehaviorSubject<TableAreaArgs<T>>(undefined);
	private tableAreaArgsObservable = merge(
		this.getTableAreaArgsFromQueryParams().pipe(first()),
		this.tableAreaArgsChanges.pipe(filter((tableAreaArgs) => !!tableAreaArgs))
	);
	private _isLoading = false;
	public tableMaxHeight = Math.max(this.minMaxHeight, window.screen.height / 3);

	//endregion

	//region Getter & Setter
	set isLoading(isLoading: boolean) {
		if (this._loadingOverlayComponent) {
			this._loadingOverlayComponent.loading = isLoading;
		}
		this._isLoading = isLoading;
	}

	get isLoading() {
		return this._loadingOverlayComponent?.loading;
	}
	//endregion

	constructor(
		public readonly tableAreaService: TableAreaService<T, I>,
		private readonly tableAreaRegisterService: TableAreaRegisterService,
		private readonly router: Router,
		private readonly activatedRoute: ActivatedRoute
	) {}

	//region Events
	ngOnInit() {
		this.tableAreaArgsObservable.subscribe((tableAreaArgs) => {
			this.tableAreaService.items.pipe(first()).subscribe((items) => {
				if (this.loadOnInit) {
					this.load(tableAreaArgs)
						.pipe(first())
						.subscribe((items) => this.tableAreaService.setItems(items));
				}
			});
		});
	}

	ngAfterViewInit() {
		this.isLoading = true;
		this.addSortEvent();
		this.addSearchEvent();
		this.handleItemChanges();
		this.tableAreaService.items
			.pipe(filter((items) => !!items))
			.pipe(withLatestFrom(this.tableAreaArgsObservable), first())
			.subscribe(([items, tableAreaArgs]) => {
				this.handleTableArgsFromQueryParams(tableAreaArgs, items);
			});
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	private onSort(sortingModel: EvasysSortingModel) {
		this.tableAreaArgsObservable.pipe(first()).subscribe((tableAreaArgs) => {
			const newTableAreaArgs = { ...tableAreaArgs, sort: sortingModel };
			this.tableAreaArgsChanges.next(newTableAreaArgs);
			const sortingParams = `${sortingModel.columnField},${
				sortingModel.sortOrderAscending ? SortEnum.ASC : SortEnum.DESC
			}`;
			this.isLoading = true;
			this.router.navigate([], {
				queryParams: { sort: sortingParams },
				queryParamsHandling: 'merge',
			});

			this.load(newTableAreaArgs)
				.pipe(first())
				.subscribe((items) => this.tableAreaService.setItems(items, { onlyInGui: true }));
		});
	}

	private onSearch(searchValue: string) {
		this.tableAreaArgsObservable.pipe(first()).subscribe((tableAreaArgs) => {
			const newTableAreaArgs = { ...tableAreaArgs, search: { value: searchValue, keys: this.searchKeys } };
			this.tableAreaArgsChanges.next(newTableAreaArgs);
			this.isLoading = true;
			this.router.navigate([], {
				queryParams: {
					searchText: searchValue.length ? searchValue : null,
				},
				queryParamsHandling: 'merge',
			});
			this.load(newTableAreaArgs)
				.pipe(first())
				.subscribe((items) => this.tableAreaService.setItems(items, { onlyInGui: true }));
		});
	}
	//endregion

	//region Methods
	private handleItemChanges() {
		this.subscriptions.push(
			this.tableAreaService.guiItems.subscribe((items) => {
				this.isLoading = false;
			})
		);
	}

	private handleTableArgsFromQueryParams(tableAreaArgs: TableAreaArgs<T>, items: T[]) {
		if (tableAreaArgs.search.value) {
			this._searchboxComponent?.writeValue(tableAreaArgs.search.value);
			if (this.load === this.tableAreaService.load) {
				this.onSearch(tableAreaArgs.search.value);
			}
		}

		if (tableAreaArgs.sort) {
			if (this.load === this.tableAreaService.load) {
				if (this.tableAreaRegisterService.getComponent<TableComponent>(TableComponent.name).length > 0) {
					const table = this.tableAreaRegisterService.getComponent<TableComponent>(TableComponent.name)[0];
					const defaultTableHeadSorted = table.tableHeads.find(
						(tableHead) => tableHead.columnField === tableAreaArgs.sort.columnField
					);
					if (defaultTableHeadSorted) {
						defaultTableHeadSorted.setSorting(tableAreaArgs.sort.sortOrderAscending);
					}
				}
				this.onSort(tableAreaArgs.sort);
			}
		}
	}

	private getTableAreaArgsFromQueryParams() {
		return this.activatedRoute.queryParams.pipe(
			shareReplay(1),
			map((params) => {
				const sortInfos = params.sort?.split(',');
				return {
					search: {
						value: params.searchText ?? '',
					},
					sort: Array.isArray(sortInfos)
						? {
								columnField: sortInfos[0],
								sortOrderAscending: sortInfos[1] === SortEnum.ASC,
						  }
						: undefined,
				} satisfies TableAreaArgs<T>;
			})
		);
	}

	private addSearchEvent() {
		this._searchboxComponent?.registerOnChange((value) => {
			this.onSearch(value);
		});
	}

	private addSortEvent() {
		if (
			!this._tableComponent &&
			this.tableAreaRegisterService.getComponent<TableComponent>(TableComponent.name)?.length > 0
		) {
			this._tableComponent = this.tableAreaRegisterService.getComponent<TableComponent>(TableComponent.name)[0];
		}
		this.subscriptions.push(this._tableComponent?.sortAction?.subscribe((sortInfos) => this.onSort(sortInfos)));
	}
	//endregion
}
