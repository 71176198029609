import * as fromSystemInfo from './system-info.selectors';
import * as fromMenu from './menu.selectors';
import * as fromWizard from './wizard.selectors';
import * as fromStatus from './status.selectors';

export const evasysSelectors = {
	fromSystemInfo,
	fromMenu,
	fromWizard,
	fromStatus,
};
