<div class="d-flex justify-content-between">
	<ng-content select="[header]"></ng-content>
	<div></div>
	<evasys-searchbox
		[id]="tableId"
		[ngModel]="
			(dashboardTableDataProvider.filterParams | async)?.description ?? dashboardTableDataProvider.searchText
		"
		(ngModelChange)="dashboardTableDataProvider.setSearchText($event)"
	></evasys-searchbox>
</div>
<ng-content></ng-content>
<ng-container *ngIf="(dashboardTableDataProvider.latestPage | async)?.empty">
	<evainsights-empty-content
		[attr.data-cy]="'overviewTable-empty-' + tableId"
		[message]="tableId + '.noneFound' | transloco"
	></evainsights-empty-content>
</ng-container>
