import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'invoke',
})
export class InvokePipe implements PipeTransform {
	transform<T extends unknown[], R>(func: (...args: T) => R, ...args: T): R {
		return func(...args);
	}
}
