<evasys-modal
	[closeable]="true"
	[id]="id"
	[opened]="opened"
	[scrollable]="false"
	(openedChange)="openedChangeEmit($event)"
>
	<evasys-header [id]="'deleteTitle'" [headerLevel]="2" [text]="modalTitleText" header></evasys-header>
	<p>{{ modalContentText }}</p>
	<strong *ngIf="deleteItem !== undefined">{{ deleteItem.title }}</strong>
	<div footer class="d-flex gap-2">
		<evasys-button [id]="'cancel-' + id" [design]="buttonDesign.SECONDARY" (click)="closeModal()"
			>{{ 'common.cancel' | transloco }}
		</evasys-button>
		<evasys-button
			[id]="'permanently-' + id"
			[type]="'submit'"
			[design]="buttonDesign.DANGER"
			(click)="permanentlyDelete()"
			>{{ deleteButtonText }}
		</evasys-button>
	</div>
</evasys-modal>
