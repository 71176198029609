import {
	AxisChartIndexDimension,
	AxisChartVisualDimension,
	CountAxisChartConfig,
	ResponseCountAxisChartData,
} from './axis-chart-report-item-content.model';
import { ReportItemContentBase } from '../common';
import { BarChartOrientation, VisualizationType } from '../../../../constants/types';
import { DimensionMappingConfig, StackVisualDimension } from './dimensions';
import { VisualStatisticsConfig } from '../../../report-item-creator';

export type BarChartContent = ReportItemContentBase<BarChartConfig, BarChartData>;

export type BarChartConfig = CountAxisChartConfig & {
	visualizationType: VisualizationType.BAR_CHART;
	orientation: BarChartOrientation;
	dimensionMappings: Array<BarChartDimensionMapping>;
	statistics: {
		visual?: VisualStatisticsConfig;
	};
};

export type BarChartDimensionMapping = DimensionMappingConfig<BarChartIndexDimension, BarChartVisualDimension>;
export type BarChartIndexDimension = AxisChartIndexDimension;
export type BarChartVisualDimension = AxisChartVisualDimension | StackVisualDimension;

export type BarChartData = ResponseCountAxisChartData;
