<ul class="list-group">
	<li
		*ngFor="let listitem of listitems; let i = index; let last = last"
		class="list-group-item"
		[class.border-0]="last"
		[class.active]="i === active"
	>
		<a *ngIf="listitem.response" (click)="respond(listitem.response, i)" class="list-group-link">{{
			listitem.title
		}}</a>
		<p class="m-0" *ngIf="!listitem.response">{{ listitem }}</p>
	</li>
</ul>
