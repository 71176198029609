import { ReportItemContentStatus } from '../../constants/status';
import { ReportItemPlacement } from '../report/report-item-placement.model';
import { ReportItemContent, ReportItemContentWithoutData } from './content';

export interface ReportItem<Content = ReportItemContent> {
	id: number;
	status: ReportItemContentStatus;
	placement: ReportItemPlacement;
	content: Content;
}

export type ReportItemWithoutData = Omit<ReportItem, 'content'> & { content: ReportItemContentWithoutData };
