<div
	@noInitialAnimation
	class="accordion-item my-2 border-0 overflow-visible card"
	[class.opacity-75]="disable"
	[id]="id"
	[attr.data-cy]="'accordion-' + accordionId + '-' + id"
>
	<h2 class="accordion-header mt-0">
		<button
			class="accordion-button rounded-0 border-0 p-2 shadow-none"
			[class.collapsed]="!show"
			[ngClass]="show ? 'bg-light' : 'bg-white eva-card-bg'"
			[attr.data-cy]="'accordionButton-' + accordionId + '-' + id"
			type="button"
			(click)="onToggle()"
		>
			{{ title }}
		</button>
	</h2>
	<div
		@yGrowAnimation
		*ngIf="show"
		[attr.data-cy]="'accordionContainer-' + accordionId + '-' + id"
		class="accordion-collapse collapse show"
	>
		<div class="accordion-body eva-card-bg bg-white">
			<ng-content></ng-content>
		</div>
	</div>
</div>
