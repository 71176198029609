<div class="fw-bold p-2 mt-2 ml-2 m-0 text-nowrap h3 eva-submenu-heading">
	<p class="c-transition-all mb-0" [ngClass]="labelRight ? 'ms-5' : 'ms-2'" [attr.data-cy]="'menu-title-' + id">
		{{ title }}
	</p>
</div>

<ul
	ngbNav
	#nav="ngbNav"
	[activeId]="active"
	(activeIdChange)="activeChange.emit($event)"
	class="nav-pills p-0"
	[orientation]="'vertical'"
>
	<li *ngFor="let menuitem of menuItems; let id = index" [ngbNavItem]="id + 1">
		<div class="row align-items-center m-0 text-nowrap">
			<a
				ngbNavLink
				*ngIf="!menuitem.multiPage; else multiPage"
				(click)="onActiveChange(id)"
				[routerLink]="menuitem.route"
				[attr.data-cy]="'menuItem-' + menuitem.id"
				class="p-2 pe-5 col-auto"
			>
				<img
					evasysImage
					[id]="'menuItem-' + menuitem.id"
					[src]="menuitem.icon"
					[alt]="'Icon'"
					[isIcon]="true"
					[class]="'c-w-20px-i c-h-20px-i ms-2 me-4'"
				/>
				<span>{{ menuitem.title }}</span>
			</a>

			<ng-template #multiPage>
				<a
					[attr.href]="menuitem.route"
					[attr.data-cy]="'menuItem-' + menuitem.id"
					class="p-2 pe-5 col-auto nav-link"
				>
					<img
						evasysImage
						[id]="'menuItem-' + menuitem.id"
						[src]="menuitem.icon"
						[alt]="'Icon'"
						[isIcon]="true"
						[class]="'c-w-20px-i c-h-20px-i col-auto ms-2 me-4'"
					/>
					<span>{{ menuitem.title }}</span>
				</a>
			</ng-template>
		</div>
	</li>
</ul>
