<evasys-modal [opened]="opened" (openedChange)="openChange.emit($event)" [id]="'selectUserModal'">
	<evasys-header [id]="'selectUser'" [headerLevel]="1" [text]="name" header> </evasys-header>
	<div>
		<p>{{ 'S_USERSELECTIONSCREEN_TITLE' | transloco }}</p>
		<div class="border">
			<evasys-table [id]="'levelselection'">
				<thead>
					<tr>
						<th evasysTableHead [position]="'start'" scope="col">{{ 's_teilbereich' | transloco }}</th>
						<th evasysTableHead [position]="'start'" scope="col">
							{{ 'S_SYSTEMUSAGE_USERLEVEL' | transloco }}
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						class="border-top"
						*ngFor="let user of externalIdUsers"
						class="c-cursor-pointer"
						(click)="onSubmit(user.encryptedId)"
					>
						<td evasys-table-column [horizontalPosition]="'start'">
							<img
								evasysImage
								[id]="'subunitLogo'"
								[isIcon]="true"
								[src]="user.subunitLogo"
								[class]="'me-2 c-w-25px c-h-25px my-auto'"
							/>
							<evasys-table-cell-text
								[text]="user.subunitName ? user.subunitName : '-'"
							></evasys-table-cell-text>
						</td>
						<td evasys-table-column [horizontalPosition]="'start'">
							<evasys-table-cell-text [text]="user.level"></evasys-table-cell-text>
						</td>
					</tr>
				</tbody>
			</evasys-table>
		</div>
	</div>
</evasys-modal>
