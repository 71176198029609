<evasys-modal
	[closeable]="true"
	[id]="'respondentFilter'"
	[opened]="opened"
	[scrollable]="false"
	[size]="'lg'"
	(openedChange)="openedChangeEmit($event)"
>
	<evasys-header
		[headerLevel]="1"
		[id]="'response-filter'"
		[text]="'report.respondentFilterHeader' | transloco"
		class="ui-priority-primary"
		header
	></evasys-header>
	<form [formGroup]="form">
		<evainsights-filter-typeahead
			#filterTypeahead
			[id]="'itemsDropdown'"
			[search]="searchItems"
			[title]="'item.item' | transloco"
			[formControlName]="selectedItemKey"
			[formatter]="formatterItems"
			[errors]="[
				{
					id: 'noItemSelected',
					trigger: submitted && form.controls['selectedItem'].errors?.required,
					text: 'item.required' | transloco
				}
			]"
			[searchFailedText]="'error.text' | transloco"
			[emptyResultsText]="'items.noneFound' | transloco"
			[filterAreaControls]="filterAreaControls"
			[isMultiSelect]="false"
		></evainsights-filter-typeahead>
		<div *ngIf="selectedItem" class="pt-1">
			<div
				formArrayName="selectedValues"
				*ngFor="let itemOption of selectedItem.itemOptions; let i = index"
				class="card m-3"
			>
				<div class="card-body">
					<div class="ms-auto">
						<evasys-checkbox [id]="'itemOption-' + itemOption.id" [formControlName]="i">
							{{ itemOption.text | translateMultiLang | async }}
						</evasys-checkbox>
					</div>
				</div>
			</div>
		</div>
		<evasys-error
			*ngIf="selectedItem"
			[errors]="[
				{
					id: 'itemOptionSelectionCount',
					trigger: submitted && !form.controls.selectedValues.valid,
					text:
						'error.minAndMaxSelectedCount'
						| transloco
							: {
									min: 1,
									max: selectedItem.itemOptions.length - 1
							  }
				}
			]"
			[id]="'respondentFilter-modal'"
		></evasys-error>
	</form>
	<evasys-header
		[headerLevel]="5"
		[id]="'response-filter'"
		[text]="'report.createWideFilterCalculateWidget' | transloco"
		class="text-sm-center pt-2"
	></evasys-header>
	<div footer class="d-flex justify-content-end">
		<evasys-button
			[id]="'addRespondentFilterItemOption'"
			[isDisabled]="awaitBackendResponse"
			(click)="addRespondentFilterItemOption()"
			[type]="'submit'"
			class="d-flex justify-content-center"
			>{{ 'common.create' | transloco }}
		</evasys-button>
	</div>
	<evasys-loading-overlay [loading]="awaitBackendResponse"></evasys-loading-overlay>
</evasys-modal>
