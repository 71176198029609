<slate-editable
	[readonly]="readonly"
	[editor]="editor"
	[ngModel]="value"
	(ngModelChange)="_onChange($event)"
	[renderElement]="renderElement"
	[renderText]="renderText"
	[placeholder]="placeholder"
	[attr.data-cy]="'richTextEditable-' + id"
	class="p-3 m-0"
>
	<ng-template #paragraph let-context="context" let-viewContext="viewContext">
		<p slateElement [context]="context" [evainsightsElementStyle]="context" [viewContext]="viewContext"></p>
	</ng-template>
	<ng-template #heading_1 let-context="context" let-viewContext="viewContext">
		<h1
			slateElement
			class="m-3 ms-0"
			[context]="context"
			[evainsightsElementStyle]="context"
			[viewContext]="viewContext"
		></h1>
	</ng-template>
	<ng-template #heading_2 let-context="context" let-viewContext="viewContext">
		<h2
			slateElement
			class="pt-2 m-3 ms-0"
			[context]="context"
			[evainsightsElementStyle]="context"
			[viewContext]="viewContext"
		></h2>
	</ng-template>
	<ng-template #heading_3 let-context="context" let-viewContext="viewContext">
		<h3
			slateElement
			class="pt-2 m-3 ms-0"
			[context]="context"
			[evainsightsElementStyle]="context"
			[viewContext]="viewContext"
		></h3>
	</ng-template>
	<ng-template #blockquote let-context="context" let-viewContext="viewContext">
		<blockquote
			class="ps-2 text-secondary"
			slateElement
			[context]="context"
			[evainsightsElementStyle]="context"
			[viewContext]="viewContext"
		></blockquote>
	</ng-template>
	<ng-template #ul let-context="context" let-viewContext="viewContext">
		<ul slateElement [context]="context" [evainsightsElementStyle]="context" [viewContext]="viewContext"></ul>
	</ng-template>
	<ng-template #ol let-context="context" let-viewContext="viewContext">
		<ol slateElement [context]="context" [evainsightsElementStyle]="context" [viewContext]="viewContext"></ol>
	</ng-template>
	<ul>
		<ng-template #li let-context="context" let-viewContext="viewContext">
			<li slateElement [context]="context" [evainsightsElementStyle]="context" [viewContext]="viewContext"></li>
		</ng-template>
	</ul>
</slate-editable>
