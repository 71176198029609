import { defaults, groupBy } from 'lodash';

export function downloadUrlContent(url: string, fileName?: string) {
	const anchor = document.createElement('a');
	anchor.href = url;
	if (fileName !== undefined) {
		anchor.download = fileName;
	}
	anchor.click();
}

export function downloadBlobContent(blob: Blob, fileName: string) {
	const blobUrl = URL.createObjectURL(blob);
	downloadUrlContent(blobUrl, fileName);
	URL.revokeObjectURL(blobUrl);
}

// File names

interface FileName {
	stem: string;
	extension: string;
}

export function makeFileNamesUnique(names: FileName[]) {
	const group = (fileName: FileName) => getFileNameAsString(fileName).toLowerCase();
	const namesByGroup = groupBy(names, group);
	return names.map((name) => {
		const collidingNames = namesByGroup[group(name)];
		if (collidingNames.length === 1) {
			return name;
		}

		const uniqueSuffix = ` (${1 + collidingNames.indexOf(name)})`;
		const truncatedNameWithoutSuffix = truncateFileName(name, maxFileNameBytes - getStringByteLength(uniqueSuffix));
		return {
			stem: truncatedNameWithoutSuffix.stem + uniqueSuffix,
			extension: name.extension,
		};
	});
}

const maxFileNameBytes = 254;
const illegalCharacters = /[\/?<>\\:*|"]/g;
const controlCharacters = /[\x00-\x1f\x80-\x9f]/g;
const windowsReservedStem = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])$/i;

export interface SanitizeOptions {
	emptyStemFallback: string;
	replacement: string;
}

const defaultSanitizeOptions: SanitizeOptions = {
	emptyStemFallback: '',
	replacement: '_',
};

// Sanitize a file name to make it valid on windows and unix systems.
// Based on the sanitize-filename package implementation but handles the file
// extension independently of the file stem to make sure the extension does not
// get truncated
export function sanitizeFileName({ stem, extension }: FileName, options?: Partial<SanitizeOptions>) {
	return truncateFileName(
		{ stem: sanitizeFileNameStem(stem, defaults({}, options, defaultSanitizeOptions)), extension },
		maxFileNameBytes
	);
}

export function getFileNameAsString({ stem, extension }: FileName) {
	return `${stem}.${extension}`;
}

function sanitizeFileNameStem(stem: string, options: SanitizeOptions) {
	const validCharacterStem = stem
		.replace(illegalCharacters, options.replacement)
		.replace(controlCharacters, options.replacement);

	if (validCharacterStem.match(windowsReservedStem)) {
		return options.emptyStemFallback;
	}

	return validCharacterStem || options.emptyStemFallback;
}

function truncateFileName({ stem, extension }: FileName, maxBytes = 255): FileName {
	const extensionBytes = getStringByteLength(`.${extension}`);
	return {
		stem: truncateStringToByteLength(stem, maxBytes - extensionBytes),
		extension,
	};
}

function getStringByteLength(s: string) {
	return new TextEncoder().encode(s).byteLength;
}

function truncateStringToByteLength(s: string, byteLength: number): string {
	const encoder = new TextEncoder();
	// One char is at least one byte. We have space for `byteLength` bytes and
	// thus for at most `byteLength` characters.
	let end = byteLength;
	let numBytes = encoder.encode(s.slice(0, end)).byteLength;
	while (numBytes > byteLength) {
		// remove one char
		end -= 1;
		numBytes -= encoder.encode(s[end]).byteLength;
	}
	return s.slice(0, end);
}
