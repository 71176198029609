import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { format } from 'date-fns';

@Injectable()
export class DatePickerService {
  public internalDate: Subject<Date | null> = new Subject<Date | null>();
  public displayDate: Subject<string> = new Subject<string>();

  private outputFormat: string;
  private initFormat: string;

  public setOutputFormat(value: string): void {
    this.outputFormat = value;
  }

  public setInitFormat(value: string): void {
    this.initFormat = value;
  }

  public getOutputFormat(): string {
    return this.outputFormat;
  }

  public getInitFormat(): string {
    return this.initFormat;
  }

  public parseOutputFormat(value: Date): string {
    return format(value, this.getOutputFormat());
  }

  public outputDisplayFormat(value: Date): string {
    return format(value, this.getInitFormat());
  }
}
