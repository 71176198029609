import { Component, Input, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MarkTypes } from '../constants/mark.types';
import { TextMarkComponent } from '../text-mark/text-mark.component';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { AngularEditor, SlateElementComponent } from 'slate-angular';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'evainsights-rich-text-editable',
	templateUrl: './rich-text-editable.component.html',
	styleUrls: ['./rich-text-editable.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: RichTextEditableComponent,
			multi: true,
		},
	],
})
export class RichTextEditableComponent implements ControlValueAccessor {
	@Input()
	@Required()
	editor!: AngularEditor;

	@Input({ required: true })
	id!: string;

	@Input()
	readonly = false;

	value: SlateElementComponent[] = [];

	@Input()
	placeholder?: string;

	@ViewChild('paragraph', { read: TemplateRef, static: true })
	paragraphTemplate!: TemplateRef<any>;

	@ViewChild('heading_1', { read: TemplateRef, static: true })
	headingOneTemplate!: TemplateRef<any>;

	@ViewChild('heading_2', { read: TemplateRef, static: true })
	headingTwoTemplate!: TemplateRef<any>;

	@ViewChild('heading_3', { read: TemplateRef, static: true })
	headingThreeTemplate!: TemplateRef<any>;

	@ViewChild('blockquote', { read: TemplateRef, static: true })
	blockquoteTemplate!: TemplateRef<any>;

	@ViewChild('ul', { read: TemplateRef, static: true })
	ulTemplate!: TemplateRef<any>;

	@ViewChild('ol', { read: TemplateRef, static: true })
	olTemplate!: TemplateRef<any>;

	@ViewChild('li', { read: TemplateRef, static: true })
	liTemplate!: TemplateRef<any>;

	renderElement = (element: any) => {
		if (element.type === 'paragraph') {
			return this.paragraphTemplate;
		}
		if (element.type === 'heading-one') {
			return this.headingOneTemplate;
		}
		if (element.type === 'heading-two') {
			return this.headingTwoTemplate;
		}
		if (element.type === 'heading-three') {
			return this.headingThreeTemplate;
		}
		if (element.type === 'block-quote') {
			return this.blockquoteTemplate;
		}
		if (element.type === 'numbered-list') {
			return this.olTemplate;
		}
		if (element.type === 'bulleted-list') {
			return this.ulTemplate;
		}
		if (element.type === 'list-item') {
			return this.liTemplate;
		}
		return null;
	};

	renderText = (text: any) => {
		if (text[MarkTypes.bold] || text[MarkTypes.italic] || text[MarkTypes.code] || text[MarkTypes.underline]) {
			return TextMarkComponent;
		}
		return null;
	};

	// Control Value Accessor

	writeValue(value: SlateElementComponent[]) {
		this.value = value;
	}

	registerOnChange(fn: (value: SlateElementComponent[]) => void): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this._onTouched = fn;
	}

	_onChange: (value: SlateElementComponent[]) => void = () => {
		// this is intentional
	};

	_onTouched = () => {
		// this is intentional
	};
}
