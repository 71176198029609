import { LegacyReportItemContentConfigBase, ReportItemContentBase } from './common';
import { VisualizationType } from '../../../constants/types';
import { ReportMultiLang } from '../../common/multi-lang';
import { Element } from 'slate';

export type RichTextContent = ReportItemContentBase<RichTextConfig, null>;

export interface RichTextConfig extends LegacyReportItemContentConfigBase<VisualizationType.RICH_TEXT> {
	body: ReportMultiLang<RichTextBody>;
}

export type RichTextBody = Element[];
