import { Pipe, PipeTransform } from '@angular/core';
import { KeyValueModel } from '@evasys/globals/shared/models/component/wizard/key-value.model';
import { SelectorItemModel } from '@evasys/globals/evasys/models/component/order-selector-item.model';
import { SelectorChangeabilityEnum } from '@evasys/globals/shared/enums/component/selector-changeability.enum';

@Pipe({
	name: 'keyValueToSelectItems',
})
export class KeyValueToSelectItemsPipe implements PipeTransform {
	transform(value: KeyValueModel[]): SelectorItemModel[] {
		return value.map(
			(item) =>
				({
					id: item.key,
					name: typeof item.value === 'object' ? item.value?.name : item.value,
					...(typeof item.value === 'object' && {
						noDeselection: item.value.changeability === SelectorChangeabilityEnum.NO_DESELECTION,
					}),
					...(typeof item.value === 'object' && {
						triggerEvent: item.value.changeability === SelectorChangeabilityEnum.CHANGE_OTHERS_RIGHTS,
					}),
				} as SelectorItemModel)
		);
	}
}
