<ng-template #popoverTitle>
	<ng-container *ngIf="title">
		<div [innerHTML]="title"></div>
	</ng-container>
</ng-template>
<ng-template #popoverContent>
	<ng-container *ngIf="text">
		<div [innerHTML]="text"></div>
	</ng-container>
</ng-template>
<img
	evasysImage
	[isIcon]="true"
	[id]="'iconPopOver-' + id"
	[class]="'ms-2 c-h-20px c-w-20px c-cursor-pointer'"
	[src]="'./assets/images/icons/information2.svg'"
	[placement]="placement"
	[ngbPopover]="popoverContent"
	[popoverTitle]="popoverTitle"
	[popoverClass]="popoverClass"
	[container]="'body'"
	alt="information-icon"
/>
