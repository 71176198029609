import { Element } from 'slate';
import { ParagraphElement } from '../../../../libs/evainsights/shared/ui/src/lib/components/rich-text/constants/slate.types';
import { ReportMultiLangMap } from './report-multi-lang-map';
import { RichTextBody } from '../report-item';

export const defaultRichTextBodyValue: Element[] = [
	{
		type: 'paragraph',
		children: [{ text: '' }],
	},
] as ParagraphElement[];

export interface RichTextDetailConfiguration {
	body: ReportMultiLangMap<RichTextBody>;
}
