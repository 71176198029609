import { inject, Injectable } from '@angular/core';
import { EvasysState } from '../essential/redux/states/evasys.state';
import { EvasysTranslationModel } from '@evasys/globals/evasys/models/general/evasys-translation.model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { loadData } from '@evasys/shared/util';
import { map } from 'rxjs/operators';
import { sharedSelectors } from '../selectors/index';
import { sharedActions } from '../actions/index';
import { EvasysLoadingStrategiesEnum } from '@evasys/globals/shared/enums/general/evasys-loadingStrategies.enum';

@Injectable({
	providedIn: 'root',
})
export class TranslateService {
	//region Injections
	private readonly translationStore = inject(Store<EvasysState<EvasysTranslationModel>>);

	public getTranslation(language: string, prefix: string[] = []): Observable<any> {
		return this.translationStore.select(sharedSelectors.fromTranslate.selectEntities).pipe(
			map((translations) => {
				//go through all ids and check whether every prefix is contained in an id
				const areAllFilesLoaded = (prefixes, translations, language) => {
					const ids = Object.keys(translations);

					return prefixes.every((prefix) => !!ids.find((id) => id.includes(prefix) && id.includes(language)));
				};

				if (areAllFilesLoaded(prefix, translations, language)) {
					return translations;
				} else {
					return {};
				}
			}),
			loadData(
				() =>
					this.translationStore.dispatch(
						sharedActions.fromTranslate.LoadTranslationSet.DEFAULT({
							payload: {
								language,
								prefix,
								page: 1,
							},
						})
					),
				() => this.translationStore.select(sharedSelectors.fromTranslate.selectLoading),
				EvasysLoadingStrategiesEnum.STATEFALLBACKAPI
			),
			map((data) => Object.assign({}, ...Object.values(data).map((translation) => translation.translations)))
		);
	}

	public clear() {
		this.translationStore.dispatch(sharedActions.fromTranslate.ClearActionSet.DEFAULT(undefined));
	}
}
