import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { PropertyGroupModel } from '@evasys/globals/shared/models/component/wizard/property-group.model';
import { EvasysPropertyTypeNameEnum } from '@evasys/globals/shared/enums/general/evasys-property-type-name.enum';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { PropertyTypeConstraintsModel } from '@evasys/globals/shared/models/component/wizard/property-type-constraints.model';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, ValidatorFn } from '@angular/forms';
import { Validations } from '@evasys/globals/shared/validations/validations';
import { Subscription } from 'rxjs';
import { SharedUiConfiguration } from '../../../shared-ui.configuration';
import { sizesType } from '@evasys/globals/shared/types/sizes.type';
import { NotificationEnum } from '@evasys/globals/shared/enums/component/notification.enum';
import { TranslocoService } from '@ngneat/transloco';
import { NotificationService } from '@evasys/shared/core';
import { PropertyModel } from '@evasys/globals/shared/models/component/wizard/property.model';
import { equalValidator } from '@evasys/globals/shared/validations/equal.validator';
import { WizardButtonActionModel } from '@evasys/globals/evasys/models/component/wizard-button-action.model';

@Component({
	selector: 'evasys-wizard',
	templateUrl: './wizard.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: WizardComponent,
			multi: true,
		},
	],
})
export class WizardComponent implements ControlValueAccessor, OnDestroy {
	//region Variables
	public evasysPropertyTypeNameEnum = EvasysPropertyTypeNameEnum;
	public buttonDesign = ButtonDesignEnum;
	public _propertyGroups?: PropertyGroupModel[];
	public formGroup: FormGroup = new FormGroup({});
	_step = 0;

	private subscriptions: Subscription[] = [];
	private showValidationErrors = false;
	//endregion

	//region Input & Output
	@Input()
	@Required()
	id?: string;

	@Input()
	set propertyGroups(propertyGroups: PropertyGroupModel[] | undefined) {
		this._propertyGroups = propertyGroups;
		this.createDynamicForm();
	}

	@Input()
	disableNextButton = false;

	@Input()
	headline: string;

	//set startStep, if u want to show the wizard content from another step count
	@Input()
	startStep = 0;

	@Input()
	set step(step: number) {
		this._step = step;
		this.stepChange.emit(this.step);
	}

	@Input()
	opened = false;

	@Input()
	intermediateStep = false;

	@Input()
	size: sizesType = 'lg';

	@Input()
	showCancelButton = true;

	@Output()
	buttonAction: EventEmitter<WizardButtonActionModel> = new EventEmitter<WizardButtonActionModel>();

	@Output()
	openedChange = new EventEmitter<boolean>();

	@Output()
	stepChange = new EventEmitter<number>();

	@Output()
	cancelAction = new EventEmitter<void>();

	@Output()
	backAction = new EventEmitter<void>();

	@Output()
	nextAction = new EventEmitter<void>();

	@Output()
	submitAction = new EventEmitter<void>();

	@Output()
	forbiddenDeselectionAction: EventEmitter<string[]> = new EventEmitter<string[]>();

	@Output()
	eventTriggerItemChangeAction: EventEmitter<string[]> = new EventEmitter<string[]>();

	//endregion

	//Getter & Setter

	get totalSteps() {
		return this._propertyGroups?.length ?? 0;
	}

	get step() {
		return this._step;
	}

	//endregion

	constructor(
		private readonly formBuilder: FormBuilder,
		public readonly config: SharedUiConfiguration,
		private readonly translocoService: TranslocoService,
		private readonly notificationService: NotificationService
	) {}

	//region Events

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	onCancel() {
		this.clear();
	}

	onBack() {
		if (this.step !== 0 && !this.intermediateStep) {
			this.step -= 1;
		}
		this.backAction.emit();
	}

	onNext() {
		if (this.groupInvalid() && !this.intermediateStep) {
			this.showValidationErrors = true;
			this.notificationService.addNotification(
				NotificationEnum.ERROR,
				this.translocoService.translate('S_BAD_REQUEST'),
				'checkForm',
				false
			);
		} else if (!this.intermediateStep) {
			this.showValidationErrors = false;
			if (this.lastStep()) {
				this.submitAction.next();
			} else {
				this.step += 1;
			}
		}
		this.nextAction.emit();
	}

	onFileSizeTooBig() {
		this.notificationService.addNotification(
			NotificationEnum.ERROR,
			this.translocoService.translate('S_PROFILEIMAGE_ERROR') +
				'<br>' +
				this.translocoService.translate('S_PROFILEIMAGE_ERROR_WRONG_SIZE'),
			'FileSizeTooBig'
		);
	}

	onForbiddenFileType() {
		this.notificationService.addNotification(
			NotificationEnum.ERROR,
			this.translocoService.translate('S_PROFILEIMAGE_ERROR') +
				'<br>' +
				this.translocoService.translate('S_PROFILEIMAGE_ERROR_WRONG_TYPE'),
			'FileSizeTooBig'
		);
	}

	//endregion

	//region Methods

	clear() {
		this.opened = false;
		return new Promise((resolve) => setTimeout(resolve, 300)).then(() => {
			this.step = 0;
			this.openedChange.next(false);
			this.showValidationErrors = false;
			this._propertyGroups?.forEach((propertyGroup) => {
				this.formGroup.removeControl(propertyGroup.name);
			});
			this._propertyGroups = null;
			this.cancelAction.emit();
		});
	}

	createDynamicForm() {
		this._propertyGroups?.forEach((propertyGroup) => {
			const formControls = {};
			propertyGroup.properties.forEach((property) => {
				const controlValidations = this.createControlValidations(property.type.constraints);
				formControls[property.name] = [
					this.formGroup?.get(propertyGroup.name)?.get(property.name)?.value ??
						property.value ??
						property.defaultValue,
					...controlValidations,
				];
			});
			const groupValidators = this.createGroupValidations(propertyGroup.properties);
			const newGroup = this.formBuilder.group({ ...formControls }, { validators: groupValidators });
			this.formGroup.setControl(propertyGroup.name, newGroup);
		});
	}

	createControlValidations(constraints: PropertyTypeConstraintsModel) {
		const validations = [];
		const asyncValidations = [];
		if (constraints.required) validations.push(Validations.required);
		if (constraints.minlen) validations.push(Validations.minLength(constraints.minlen));
		if (constraints.maxlen) validations.push(Validations.maxLength(constraints.maxlen));
		if (constraints.emailType) validations.push(Validations.email);
		if (constraints.min) validations.push(Validations.min(constraints.min));
		if (constraints.max) validations.push(Validations.max(constraints.max));
		if (constraints.secure) validations.push(Validations.securePassword);
		return [validations, asyncValidations];
	}

	private createGroupValidations(properties: PropertyModel[]): ValidatorFn[] {
		const equals = [];

		properties.forEach((property) => {
			if (property.type.constraints.equalToField) {
				if (
					!equals.find(
						(equal) =>
							equal.includes(property.name) && equal.includes(property.type.constraints.equalToField)
					)
				) {
					equals.push([property.name, property.type.constraints.equalToField]);
				}
			}
		});

		return equals.map((equal) => equalValidator(equal[0], equal[1]));
	}

	lastStep() {
		return this.step - this.startStep === this._propertyGroups?.length - 1;
	}

	private groupInvalid() {
		return (
			this.step - this.startStep >= 0 &&
			!!this._propertyGroups[this.step - this.startStep]?.name &&
			this.formGroup.controls[this._propertyGroups[this.step - this.startStep].name]?.invalid
		);
	}

	getValidationErrorTrigger(
		groupName: string,
		controlName: string,
		validationName: string,
		isGroupValidation?: boolean
	): boolean {
		if (isGroupValidation) {
			if (this.formGroup.get(groupName)?.errors) {
				return (
					this.showValidationErrors &&
					(this.getValidationError(groupName, controlName, validationName, isGroupValidation) ?? false)
				);
			} else {
				return false;
			}
		} else if (this.formGroup.get(groupName)?.get(controlName)?.errors) {
			return (
				this.showValidationErrors &&
				(this.getValidationError(groupName, controlName, validationName, isGroupValidation) ?? false)
			);
		} else {
			return false;
		}
	}

	getValidationError(groupName: string, controlName: string, validationName: string, isGroupValidation?: boolean) {
		if (isGroupValidation) {
			if (this.formGroup.get(groupName)?.errors) {
				const validationError = this.formGroup.get(groupName).errors[validationName];
				if (Array.isArray(validationError)) {
					return validationError.includes(controlName);
				} else {
					return validationError;
				}
			}
		} else if (this.formGroup.get(groupName)?.get(controlName)?.errors) {
			return this.formGroup.get(groupName).get(controlName).errors[validationName];
		}
	}

	checkTriggerEvent(triggerEvent: boolean, name: string) {
		if (triggerEvent) {
			this.eventTriggerItemChangeAction.emit([name]);
		}
	}

	//endregion

	//region ControlValueAccessor

	writeValue(value: any): void {
		if (value) {
			this.formGroup.setValue(value);
		}
	}
	registerOnChange(fn: (value: any) => void): void {
		this.subscriptions.push(this.formGroup.valueChanges.subscribe(fn));
	}
	registerOnTouched(_fn: any): void {
		//default
	}

	//endregion
}
