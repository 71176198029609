import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { defaultColor, defaultPresets } from '@evasys/globals/evainsights/helper/charts/color';

@Component({
	selector: 'evainsights-color-picker',
	templateUrl: './color-picker.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: ColorPickerComponent,
			multi: true,
		},
	],
})
export class ColorPickerComponent implements ControlValueAccessor {
	color = defaultColor;
	presets = defaultPresets;

	registerOnTouched(): void {
		// this is intentional
	}

	_onTouched = () => {
		// this is intentional
	};

	_onChange: (value: string) => void = () => {
		// this is intentional
	};

	onChange() {
		this._onChange(this.color);
	}

	registerOnChange(fn: (_: string) => void) {
		this._onChange = fn;
	}

	onColorChanged() {
		this._onChange(this.color);
	}

	writeValue(newColor: string) {
		this.color = newColor;
		this.onChange();
	}
}
