import { Observable } from 'rxjs';
import { QueryParamsHandling } from '@angular/router';

export interface Tabitem<T> extends TabitemBase<T> {
	isMultiPage?: boolean;
	subItems?: TabitemBase<T>[];
	mark?: boolean;
}

export interface TabitemBase<T> {
	id: T;
	title: string | Observable<string>;
	href?: Observable<string>;
	queryParamsHandling?: QueryParamsHandling;
	isTitleImage?: boolean;
}
