<div class="position-relative">
	<img
		evasysImage
		*ngIf="!value.length"
		[id]="'search-icon'"
		[isIcon]="true"
		[src]="'assets/images/icons/find.svg'"
		[alt]="'search-icon'"
		class="c-w-20px h-100 position-absolute c-z-index-2 c-r-1 input-group-addon"
		data-cy="search-icon"
		title="{{ _language() | inlineTransloco : 'common.search.hover' | async }}"
	/>
	<evasys-textbox
		[id]="'search-items-' + id"
		[thinStyle]="thinStyle"
		[(ngModel)]="value"
		[clearButton]="true"
		(ngModelChange)="onChange()"
		(blurAction)="_onTouched()"
		class="input-group"
		title="{{ _language() | inlineTransloco : 'common.search.hover' | async }}"
	>
		{{ _language() | inlineTransloco : 'common.search' | async }}
	</evasys-textbox>
</div>
