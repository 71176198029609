import { Component, forwardRef, Input, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evasys-searchbox',
	templateUrl: './searchbox.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SearchboxComponent),
			multi: true,
		},
	],
})
export class SearchboxComponent implements ControlValueAccessor {
	@Required()
	@Input()
	id!: string;

	_language = signal<string | undefined>(undefined);
	@Input()
	set language(language: string) {
		this._language.set(language);
	}

	@Input()
	thinStyle = false;

	value = '';

	_onChange: (value: string) => void = () => {
		// this is intentional
	};

	_onTouched = () => {
		// this is intentional
	};

	onChange() {
		this._onChange(this.value);
	}

	registerOnChange(fn: (_: string) => void) {
		this._onChange = fn;
	}

	registerOnTouched(fn: () => void) {
		this._onTouched = fn;
	}

	writeValue(value: string | null) {
		this.value = value ?? '';
		this.onChange();
	}
}
