import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, first, Observable } from 'rxjs';
import { paths } from '@evasys/globals/evainsights/constants/paths';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportType } from '@evasys/globals/evainsights/constants/types';
import { Report, ReportTemplate } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { Update } from '@ngrx/entity';
import { cloneDeep } from 'lodash';
import { FacadeService, NotificationService } from '@evasys/shared/core';
import { EvasysLoadingStrategiesEnum } from '@evasys/globals/shared/enums/general/evasys-loadingStrategies.enum';
import { RouteDataParams } from '@evasys/globals/evainsights/constants/route-data-params';
import { maxLengthReportTitle } from '@evasys/globals/evainsights/validations/validations';
import { noWhitespaceValidator } from '@evasys/evainsights/shared/util';
import { ReportAndReportTemplateFacadeService } from '@evasys/evainsights/stores/core';
import { NotificationEnum } from '@evasys/globals/shared/enums/component/notification.enum';

@Component({
	selector: 'evainsights-report-grid-creator',
	templateUrl: './report-grid-creator.component.html',
})
export class ReportGridCreatorComponent<T extends Report | ReportTemplate> implements OnInit {
	public translocoService = inject(TranslocoService);
	private router = inject(Router);
	private activatedRoute = inject(ActivatedRoute);
	private reportAndReportTemplateFacadeService = inject(ReportAndReportTemplateFacadeService);
	private notificationService = inject(NotificationService);

	@Input()
	reportCreatorForm!: FormGroup;

	@Input()
	report!: Observable<T>;

	@Input()
	facadeService!: FacadeService<T>;

	@Output()
	isLoaded = new EventEmitter<boolean>(false);

	saving = new BehaviorSubject(false);
	buttonDesign = ButtonDesignEnum;
	reportType!: ReportType;

	readonly maxLengthReportTitle = maxLengthReportTitle;

	ngOnInit(): void {
		this.reportType = this.activatedRoute.snapshot.data[RouteDataParams.REPORT_TYPE];
		this.report.pipe(first()).subscribe((value) => {
			this.reportCreatorForm.controls['title'].patchValue(value.title);
			if (value.id === 0 && this.reportCreatorForm.valid && !this.saving.value) {
				this.saveReport(value);
			}
		});
		this.reportCreatorForm.controls['title'].addValidators([
			Validators.maxLength(maxLengthReportTitle),
			noWhitespaceValidator(),
		]);
	}

	onReportTitleEditModeChange(isEditing: boolean): void {
		if (!isEditing && this.reportCreatorForm.valid && !this.saving.value) {
			this.report.pipe(first()).subscribe(this.patchTitle);
		}
	}

	patchTitle = (report: T) => {
		const title = this.reportCreatorForm.controls['title'].value?.trim();

		const updatedReport = { ...report, title };
		const update: Update<T> = {
			id: report.id,
			changes: updatedReport,
		};
		this.facadeService.updateOneLocal(update);

		this.reportAndReportTemplateFacadeService
			.patchTitle(this.reportType, report.id, title)
			.pipe(first())
			.subscribe({
				error: () => {
					this.notificationService.addNotification(
						NotificationEnum.ERROR,
						this.translocoService.translate('report.error.patchTitle'),
						'patchTitleFailed',
						false
					);

					const revert: Update<T> = {
						id: report.id,
						changes: report,
					};
					this.facadeService.updateOneLocal(revert);
				},
			});
	};

	saveReport = (report: T) => {
		if (!('surveyIds' in report) || report.surveyIds.length > 0) {
			const title = this.reportCreatorForm.controls['title'].value?.trim();
			this.saving.next(true);
			if (report.id === 0) {
				this.saveNew(title);
			}
		}
	};

	private saveNew(title: string) {
		this.facadeService
			.get<T>({
				id: 0,
				loadingStrategy: EvasysLoadingStrategiesEnum.STATEONLY,
			})
			.pipe(first())
			.subscribe((localReport) => {
				const report = cloneDeep(localReport);
				report.title = title;
				this.facadeService.deleteOneLocal(0);
				this.facadeService.createOne(report).then((createdReport) => {
					const path =
						this.reportType === ReportType.REPORT
							? paths.getReportCreatorReport.build({ reportId: createdReport.id })
							: paths.getReportTemplateCreatorReportTemplate.build({
									reportTemplateId: createdReport.id,
							  });
					this.router.navigate([path], {
						queryParamsHandling: 'preserve',
					});
					this.saving.next(false);
				});
			});
	}

	getReportItemAndReportTemplateItemCreatorPage = (reportId: number): string[] => {
		if (this.reportCreatorForm.valid && reportId !== 0) {
			const path =
				this.reportType === ReportType.REPORT
					? paths.getReportCreatorReportItem.build({ reportId })
					: paths.getReportTemplateCreatorReportTemplateItem.build({ reportId });
			return [path];
		}
		return [''];
	};

	getReportItemAndReportTemplateItemBatchCreatorPage = (reportId: number): string[] => {
		if (this.reportCreatorForm.valid && reportId !== 0) {
			const path =
				this.reportType === ReportType.REPORT
					? paths.getReportCreatorReportItemsBatchCreator.build({ reportId })
					: paths.getReportTemplateCreatorReportTemplateItemsBatchCreator.build({
							reportId,
					  });
			return [path];
		}
		return [''];
	};

	isReportInvalid(report: T) {
		return 'surveyIds' in report && report.surveyIds.length === 0;
	}
}
