import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { EvasysPageConfigModel } from '@evasys/globals/shared/models/general/evasys-page-config.model';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { SharedUiConfiguration } from '../../../shared-ui.configuration';

@Component({
	selector: 'evasys-pagination',
	templateUrl: './pagination.component.html',
})
export class PaginationComponent implements OnInit {
	//region In- and Outputs
	@Input()
	@Required()
	id?: string;

	@Input()
	pageConfig?: EvasysPageConfigModel;

	@Input()
	maxSize?: number;

	@Input()
	pageNumber?: number;

	@Output()
	pageNumberChange: EventEmitter<number> = new EventEmitter<number>();

	//endregion In- and Outputs

	buttonDesign: ButtonDesignEnum = ButtonDesignEnum.LINK;

	//region getter and setter

	get page(): number | undefined {
		return this.pageConfig?.page;
	}

	set page(page: number | undefined) {
		if (this.pageConfig && page) {
			this.pageConfig = { ...this.pageConfig, page };
		}
	}

	get pageSize() {
		return this.pageConfig?.pageSize;
	}

	get totalItems() {
		return this.pageConfig?.totalItems;
	}

	get pageCount() {
		return this.pageConfig?.pageCount;
	}

	//endregion getter and setter

	constructor(public readonly config: SharedUiConfiguration) {}

	ngOnInit(): void {
		//if the collection page count is one, then ngb-pagination is not created in the DOM.
		//nevertheless to trigger the page loading process, the pagination component emits a change.
		if (this.pageConfig?.pageCount && this.pageConfig?.pageCount <= 1) {
			this.pageNumberChange.emit(1);
		}
	}

	pageForward(): void {
		if (this.page && this.pageSize && this.totalItems && this.page < this.totalItems / this.pageSize) {
			this.page += 1;
			this.pageNumberChange.emit(this.pageConfig?.page);
		}
	}

	pageBackward(): void {
		if (this.page && this.page > 1) {
			this.page -= 1;
			this.pageNumberChange.emit(this.page);
		}
	}

	fowardButtonDisabled(): boolean {
		return (this.page ?? 0) >= (this.totalItems ?? 0) / (this.pageSize ?? 0);
	}

	backwardButtonDisabled(): boolean {
		return !!this.page && this.page === 1;
	}

	ngbOnChange(page: number) {
		if (this.pageConfig) {
			this.pageNumberChange.emit(page);
		}
	}
}
