<div class="time-picker" #list>
  <div
    *ngFor="let item of items"
    (click)="onSelect(item)"
    [id]="item"
    [class.active]="item | selectedItem : active"
    class="nav-time-item"
    data-cy="timepicker-item"
  >
    {{ item | meridiem : isMeridiem : isHours }}
  </div>
</div>
