import {
	AfterViewInit,
	Component,
	ElementRef,
	inject,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, Subscription, tap } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { ReportItemContentStatus } from '@evasys/globals/evainsights/constants/status';
import { pick } from 'lodash';
import { isEqual } from '@evasys/globals/shared/helper/object';
import { ReportLanguage } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { CANVAS_RENDERING_CONTEXT_2D } from '@evasys/shared/util';

@Component({
	selector: 'evainsights-d3-base',
	templateUrl: './d3-base.component.html',
})
export abstract class D3BaseComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
	translocoService = inject(TranslocoService);
	ngZone = inject(NgZone);
	ctx = inject(CANVAS_RENDERING_CONTEXT_2D);

	@Input()
	reportItemStatus?: ReportItemContentStatus;

	@Input({ required: true })
	language!: ReportLanguage;

	@Input({ required: true })
	decimalFormat!: string;

	@ViewChild('mount')
	renderElement?: ElementRef<HTMLDivElement>;

	chartSize = new BehaviorSubject<Size | undefined>(undefined);
	chartSizeSubscription!: Subscription;
	resizeObserver!: ResizeObserver;

	ngOnChanges(changes: SimpleChanges) {
		if (
			(changes['content'] || changes['language'] || changes['decimalFormat']) &&
			this.renderElement !== undefined
		) {
			this.tryDraw();
		}
	}

	ngOnInit(): void {
		this.chartSizeSubscription = this.chartSize
			.pipe(
				filter((size: Size | undefined): size is Size => !!size),
				tap(() => this.onWillResize()),
				debounceTime(50),
				distinctUntilChanged((prev, curr) => isEqual(prev, curr))
			)
			.subscribe((size) => this.onResize(size));

		this.resizeObserver = this.ngZone.runOutsideAngular(
			() =>
				new ResizeObserver((entries) => {
					this.chartSize.next(pick(entries[0].contentRect, ['width', 'height']));
				})
		);
	}

	ngAfterViewInit() {
		if (this.renderElement) {
			this.resizeObserver.observe(this.renderElement.nativeElement);
		}
	}

	ngOnDestroy() {
		this.resizeObserver.disconnect();
	}

	onWillResize() {
		// Implement in subclasses e.g. to abort running
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onResize(size: Size) {
		throw Error('Missing implementation for onResize');
	}

	abstract tryDraw(): void;
}

export interface Size {
	width: number;
	height: number;
}

export interface Margin {
	top: number;
	bottom: number;
	left: number;
	right: number;
}

export interface RenderConfig {
	size: Size;
	reportLanguageId: number;
	decimalFormat: string;
}
