import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
	selector: '[evainsightsElementStyle]',
})
export class ElementStyleDirective implements OnChanges {
	@Input()
	evainsightsElementStyle: any;

	constructor(private el: ElementRef) {}

	ngOnChanges() {
		this.el.nativeElement.style.textAlign = this.evainsightsElementStyle.element.align;
	}
}
