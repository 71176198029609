import { Component, computed, inject } from '@angular/core';
import {
	FilterService,
	ReportService,
	searchRequestFilterParams,
	SurveyService,
} from '@evasys/evainsights/shared/core';
import { catchError, filter, map, Observable, of, pipe, scan, Subject, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ChipTypeaheadDesignEnum } from '@evasys/globals/shared/enums/component/chip-typeahead-design.enum';
import { Report } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { EvasysLoadingStrategiesEnum } from '@evasys/globals/shared/enums/general/evasys-loadingStrategies.enum';
import { Update } from '@ngrx/entity';
import { ReportFacadeService } from '@evasys/evainsights/stores/core';
import { getReportIdFromParamMap } from '@evasys/globals/evainsights/helper/url-params';
import { Survey } from '@evasys/globals/evainsights/models/survey/survey.entity';
import { FilterSelection } from '@evasys/globals/evainsights/models/filter/filter-area-control.model';
import { PageRequest } from '@evasys/globals/shared/types/page-request.types';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { SurveyTypeEnum } from '@evasys/globals/evasys/enums/survey-type.enum';
import { SurveyStatusEnum } from '@evasys/globals/evainsights/models/survey/survey-status';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { isNotNullish, nonNullish } from '@evasys/globals/evainsights/typeguards/common';
import { TranslocoService } from '@ngneat/transloco';
import { exhaustAllWithTrailing } from '@evasys/shared/util';
import { buildPage } from '@evasys/globals/evainsights/helper/pagination';
import { ReportSurveyChanges } from '@evasys/globals/evainsights/models/report/report-survey-changes.model';
import { MAX_REPORT_SURVEYS } from '@evasys/globals/evainsights/constants/report-surveys';
import { NotificationService } from '@evasys/shared/core';
import { NotificationEnum } from '@evasys/globals/shared/enums/component/notification.enum';

@Component({
	selector: 'evainsights-report-survey-list',
	templateUrl: './report-survey-list.component.html',
})
export class ReportSurveyListComponent {
	reportService = inject(ReportService);
	surveyService = inject(SurveyService);
	activatedRoute = inject(ActivatedRoute);
	reportFacadeService = inject(ReportFacadeService);
	filterService = inject(FilterService);
	notificationService = inject(NotificationService);
	translocoService = inject(TranslocoService);

	protected readonly maxReportSurveys = MAX_REPORT_SURVEYS;
	protected readonly buttonDesign = ButtonDesignEnum;
	maxNumberTopSurveys = 5;

	report = toSignal(
		this.activatedRoute.paramMap.pipe(
			map(getReportIdFromParamMap),
			switchMap((reportId) =>
				this.reportFacadeService.get<Report>({
					id: reportId,
					loadingStrategy: EvasysLoadingStrategiesEnum.STATEONLY,
				})
			)
		)
	);
	selectedSurveys = computed(() => {
		const report = this.report();
		if (report === undefined) {
			return [];
		}
		return [...report.topSurveys.content, ...this.createMockSurveys(report)];
	});
	hasValidationErrorRequired = computed(() => this.selectedSurveys()?.length === 0);
	hasValidationErrorLimitExceeded = computed(() => this.selectedSurveys()?.length > MAX_REPORT_SURVEYS);

	surveysBelowMinRespondentCount = computed(() => {
		return this.report()?.surveysBelowMinRespondentCount;
	});

	reportSurveyUpdates = new Subject<Observable<Report>>();

	chipTypeaheadDesign = ChipTypeaheadDesignEnum;
	isSurveysModalOpen = false;
	reportId!: number;
	filterAreaControls = [
		this.filterService.units,
		this.filterService.forms,
		this.filterService.participationEvents,
		this.filterService.periods,
		this.filterService.programmes,
		this.filterService.leaders,
	];

	constructor() {
		this.reportSurveyUpdates
			.pipe(
				takeUntilDestroyed(),
				map(pipe(catchError(() => of(null)))),
				exhaustAllWithTrailing(),
				scan<Report | null, { persisted: Report | null; optimistic: Report | null }>(
					(acc, newUpdate) =>
						newUpdate === null
							? // request failed => discard all optimistic updates and revert to the last persisted state
							  { persisted: acc.persisted, optimistic: acc.persisted }
							: // request succeeded => use as most recent persisted update and merge response with
							  // pending optimistic changes that have been made since the request was enqueued
							  { persisted: newUpdate, optimistic: this.mergeWithCurrentlySelectedSurveys(newUpdate) },
					{ persisted: null, optimistic: null }
				),
				map((acc) => acc?.optimistic),
				filter(isNotNullish)
			)
			.subscribe((report) => {
				this.reportFacadeService.updateOneLocal({ id: report.id, changes: report });
			});
	}

	formatterSurvey = (survey: Survey) => survey.description;

	/*
	 * this is a temporary solution for the filter typeahead as we require only the first n elements to be rendered in the frontend
	 * but also all survey Ids for the checkboxes to work
	 */
	private createMockSurveys(report: Report): Survey[] {
		return [...report.surveyIds]
			.filter((surveyId) => !report.topSurveys.content.find((s) => s.id === surveyId))
			.map((surveyId) => {
				return {
					id: surveyId,
					formShortName: '',
					description: '',
					formId: -1,
					unitName: '',
					leaderName: '',
					periodName: '',
					responseCount: -1,
					minRespondentCount: 0,
					type: SurveyTypeEnum.HYBRID,
					status: SurveyStatusEnum.PROCESSED,
					periodId: -1,
				} as Survey;
			});
	}

	searchSurveys = (
		description: string,
		filter: FilterSelection<typeof this.filterAreaControls>,
		pageRequest: PageRequest
	): Observable<Page<Survey>> => {
		return this.surveyService.getSearchResult({
			description: description,
			...searchRequestFilterParams(
				{ ...this.activatedRoute.snapshot.queryParams, pageSize: pageRequest.size, page: pageRequest.page },
				filter
			),
		});
	};

	onSelectSurveys(surveys: Survey[]) {
		this.updateReportSurveys(surveys);
	}

	private updateReportSurveys(surveys: Survey[]) {
		this.updateLocalReportStore(surveys);

		const report = nonNullish(this.report());
		const surveyIds = surveys.map((s) => s.id);
		if (surveyIds.length > 0) {
			this.reportSurveyUpdates.next(
				this.reportService.putReportSurveys(report.id, surveyIds).pipe(
					catchError(() => {
						this.notificationService.addNotification(
							NotificationEnum.ERROR,
							this.translocoService.translate('survey.error.limitExceeded'),
							'surveyLimitExceeded',
							false
						);
						return of(report);
					})
				)
			);
		}
	}

	onReportSurveyModalClosed(reportSurveyChanges: ReportSurveyChanges) {
		const report = nonNullish(this.report());
		if (
			reportSurveyChanges.numberChanges > 0 &&
			report.id !== undefined &&
			reportSurveyChanges.selectedSurveys.length > 0
		) {
			if (reportSurveyChanges.selectedSurveys.length <= MAX_REPORT_SURVEYS) {
				this.updateLocalReportStore(reportSurveyChanges.selectedSurveys);
			}
			this.reportSurveyUpdates.next(this.reportService.getById(report.id));
		}
	}

	updateLocalReportStore(surveys: Survey[]) {
		const report = nonNullish(this.report());
		const surveyIds = surveys.map((s) => s.id);
		const sortedSurveys = [...surveys].sort((a, b) => b.id - a.id);
		const topSurveys = buildPage(sortedSurveys, { number: 0, size: 5 });
		const update: Update<Report> = {
			id: report.id,
			changes: { ...report, surveyIds, topSurveys },
		};
		this.reportFacadeService.updateOneLocal(update);
	}

	private mergeWithCurrentlySelectedSurveys(report: Report): Report {
		// If the user changes the report surveys faster than the backend can respond, it might be that the
		// backend response is already outdated, containing surveys different from those the user has currently selected.
		// We thus use the currently selected surveys as the source of truth for the new report state, only
		// using the updatedReport.surveys to fill in the extended survey DTO objects.

		return {
			...report,
			surveyIds: this.report()?.surveyIds ?? report.surveyIds,
		};
	}

	getSurveyContextInfos = (survey: Survey): string[] => {
		return [survey.formShortName, survey.periodName, survey.leaderName];
	};
}
