import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { NavigateService } from '../../../navigate/services/navigate.service';
import {
	AuthService,
	AuthUserService,
	ConfigService,
	FacadeService,
	NotificationService,
	UserModelClass,
} from '@evasys/shared/core';
import { MenuService, StatusService, SystemInfoService } from '@evasys/evasys/shared/core';
import { UserRightDesignation, UserRightsEnum } from '@evasys/globals/shared/enums/business/user-rights.enum';
import { filter, map } from 'rxjs/operators';
import { SidemenuItemModel } from '@evasys/globals/evasys/models/component/sidemenu-item.model';
import { EvasysLoadingStrategiesEnum } from '@evasys/globals/shared/enums/general/evasys-loadingStrategies.enum';
import { EvasysFooterModel } from '@evasys/globals/evasys/models/general/evasys-footer.model';
import { LegalNoticeService, ProductMenuItem, Tabitem } from '@evasys/shared/ui';
import { EvasysNotificationModel } from '@evasys/globals/shared/models/evasys-notification.model';
import { HttpClient } from '@angular/common/http';
import { EvasysSharedFeatureConfiguration } from '../../../evasys-shared-feature.configuration';
import { ServiceInjectorMapper } from '@evasys/globals/evasys/mapper/general/service-injector-mapper';
import { Update } from '@ngrx/entity';
import { EvasysNotificationActionModel } from '@evasys/globals/shared/models/evasys-notification-action.model';
import { EvasysMenuModel } from '@evasys/globals/evasys/models/general/evasys-menu.model';
import { LevelEnum } from '@evasys/globals/shared/enums/business/level.enum';
import { ModalMultipleUsersComponent } from '@evasys/evasys/shared/ui';
import { ExternalIdUserModel } from '@evasys/globals/evasys/models/business/external-id-user.model';
import { UserChangeService } from '../../../services/user-change.service';
import { NotificationEnum } from '@evasys/globals/shared/enums/component/notification.enum';
import { TranslocoService } from '@ngneat/transloco';
import { EndpointsEnum } from '@evasys/globals/evasys/api/endpoints.enum';
import {
	UpdateErrorMessageMapper,
	UpdateSuccessMessageMapper,
} from '@evasys/globals/evasys/api/mapper/update-message.mapper';
import { NavigationEnd, Router } from '@angular/router';
import { SystemInfoModel } from '@evasys/globals/evasys/models/business/system-info.model';
import { ProductService } from '../../../../../../core/src/lib/services/product.service';

@Component({
	selector: 'evasys-app-shell',
	templateUrl: './app-shell.component.html',
})
export class AppShellComponent implements OnInit, OnDestroy {
	//region ViewChilds
	@ViewChild('multipleUsersModal')
	multipleUsersModal: ModalMultipleUsersComponent;

	//endregion

	//region Variables
	//region Topmenu-Variables
	//region Private
	private subscriptions: Subscription[] = [];
	private menuItems: EvasysMenuModel[] = [];
	private userSwitchData: ExternalIdUserModel[];

	//endregion
	//region Public
	public userRightDesignation = UserRightDesignation;
	public title = 'evasys';
	public topMenuitems: Tabitem<string>[] = [];
	public notifications: Observable<EvasysNotificationModel[]>;
	public user: Observable<UserModelClass>;
	public userRights = UserRightsEnum;
	public authenticated: Observable<boolean>;
	public loading: boolean;
	public isSidebarPermanentlyOpen: Observable<boolean>;
	public isNotLoginPage: Observable<boolean>;
	public activeSystemUser: number = 0;
	public activeOnlineUser: number = 0;
	public system: 'evasys' | 'evaexam';
	public showSystemUsage: boolean;
	public canSwitchUser: boolean = false;
	public logo?: string;
	public logoAlt?: string;
	public searchText?: Observable<string>;
	public productsObservable: Observable<ProductMenuItem[]>;
	//endregion

	//region Sidemenu-Variables

	//region Public
	public active: [number, number] = [0, 0];
	public isSidemenuCollapsed = true;
	public sidemenuItems: SidemenuItemModel[] = null;
	public footerTextObservable: Observable<string>;
	//endregion

	//region Getter & Setter

	//endregion

	constructor(
		private readonly router: Router,
		private readonly navigateService: NavigateService,
		private readonly authService: AuthService,
		private readonly authUserService: AuthUserService,
		private readonly systemInfoService: SystemInfoService,
		private readonly configService: ConfigService,
		private readonly menuService: MenuService,
		private readonly notificationService: NotificationService,
		private readonly httpService: HttpClient,
		private readonly sharedFeatureConfiguration: EvasysSharedFeatureConfiguration,
		private readonly injector: Injector,
		private readonly statusService: StatusService,
		private readonly userChangeService: UserChangeService,
		private readonly translateService: TranslocoService,
		private readonly legalNoticeService: LegalNoticeService,
		private readonly productService: ProductService
	) {}

	//region Events

	//region Ng-Events
	ngOnInit(): void {
		this.authenticated = this.authService.hasValidToken();
		this.user = this.authUserService.authenticatedUserObservable;
		this.getSystem();
		this.declareFooter();
		this.declareIsNotLoginPage();
		this.isSidebarPermanentlyOpen = this.menuService.getPermanentlyMenuOpenState();
		this.notifications = this.notificationService.getAllForCollection();
		this.loadMenus();
		this.loadOrganisationLogo();

		this.setMenuItems();
		this.searchText = this.menuService.getSearchTextState();
		this.initLegalNotice();
		this.handleSystemSwitch();
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	//endregion

	//region Topmenu-Events

	onLogout() {
		this.authService
			.logout()
			.then(() => this.router.navigate(['']))
			.catch((err) => console.error('Can not Logout on event: ', err));
	}

	onMenuResponse(response: string) {
		this.changeActiveSideMenuItems(response);
	}

	onUserRightChange(right: number) {
		firstValueFrom(this.user).then(() => {
			this.authUserService.authenticatedUserPromise.then((authUser) => {
				authUser.changeActiveRole(right);
				const lastviewedpage = this.router.url
					.split('?')[0]
					.split('/')
					.filter((element) => element)
					.join(',');
				this.navigateService.navigateWithUserRightAndLevel(right, authUser.level, [
					{ key: 'lastviewedpage', value: lastviewedpage },
				]);
			});
		});
	}

	onSearch(searchValue: string) {
		this.navigateService.navigateTo(
			'oadmin.php?search_field=' +
				searchValue +
				'&table_list%5B%5D=benutzer&mode=stat_search&table_list%5B%5D=zent_lv&table_list%5B%5D=umfragen&table_list%5B%5D=zent_zustellungen&search=Suchen'
		);
	}

	onHelp(searchValue: string) {
		firstValueFrom(this.systemInfoService.getOne('helplink', EvasysLoadingStrategiesEnum.APIONLY))
			.then((link) => {
				window.open(link.value + '#Help&rhsearch=' + searchValue + '&ux=search');
			})
			.catch((err) => {
				console.error('fail to help: ', err);
			});
	}

	onNotificationDelete(notificationId: string) {
		this.notificationService.deleteOne(notificationId);
	}

	onApiCallAction(action: EvasysNotificationActionModel) {
		const service = this.injector.get<FacadeService<any>>(ServiceInjectorMapper[action.apiRoute]);

		const update: Update<any> = {
			id: action.endpoint,
			changes: action.queryParams,
		};

		service
			.updateOne(update)
			.then((response) => {
				this.notificationService.addNotification(
					NotificationEnum.SUCCESS,
					this.translateService.translate(UpdateSuccessMessageMapper.get(action.apiRoute as EndpointsEnum)),
					response.configid,
					false
				);
			})
			.catch(() => {
				this.notificationService.addNotification(
					NotificationEnum.WARNING,
					this.translateService.translate(UpdateErrorMessageMapper.get(action.apiRoute as EndpointsEnum)),
					'update_error',
					false
				);
			});
	}

	onOpenUserSwitchAction() {
		this.multipleUsersModal.open(this.userSwitchData);
	}

	onSystemUsageAction() {
		this.navigateService.navigateTo('public/default/Performance/index');
	}

	onLogoClicked() {
		this.router.navigate(['/subunits']);
	}

	onUserMenuOpenAction() {
		this.refreshUserSwitch();

		firstValueFrom(this.statusService.getOne('systemusageblock', EvasysLoadingStrategiesEnum.APIONLY)).then(
			(systemUsage) => {
				this.activeSystemUser = systemUsage.value.users;
				this.activeOnlineUser = systemUsage.value.onlinesurveys;
			}
		);
	}

	public onUserSwitchOpenChange(isOpen: boolean) {
		if (!isOpen) {
			this.multipleUsersModal.close();
		}
	}

	public onUserSwitchSubmit(userId: string) {
		this.userChangeService.changeUser(userId);
	}
	//endregion

	//region Sidemenu-Events

	onPermanentlyOpenChange(open: boolean) {
		this.menuService.openMenuPermanently(open);
	}

	//endregion

	//endregion

	//region methods
	private initLegalNotice() {
		this.legalNoticeService.isScantronObservable = this.systemInfoService
			.get<SystemInfoModel>({
				loadingStrategy: EvasysLoadingStrategiesEnum.APIONLY,
				id: 'is_scantron',
			})
			.pipe(map<SystemInfoModel, boolean>((systemInfo) => systemInfo.value));
	}

	private getSystem() {
		this.subscriptions.push(
			this.systemInfoService
				.getOne('is_evaexam', EvasysLoadingStrategiesEnum.APIONLY)
				.subscribe((isExam) => (this.system = isExam?.value ? 'evaexam' : 'evasys'))
		);
	}

	private declareIsNotLoginPage(): void {
		this.isNotLoginPage = this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			map((event: any) => {
				return event.url !== '/';
			})
		);
	}

	private handleSystemSwitch() {
		this.subscriptions.push(
			this.authService.hasValidToken().subscribe((hasValidToken) => {
				if (hasValidToken) {
					this.productsObservable = this.productService.getAvailableProducts();
				}
			})
		);
	}

	private declareFooter() {
		this.footerTextObservable = this.systemInfoService
			.getOne('copyright', EvasysLoadingStrategiesEnum.STATETHENAPI)
			.pipe(
				filter((config) => !!config),
				map((config) => config.value),
				map((footer: EvasysFooterModel) => {
					return footer.customer + footer.reseller + footer.version + '&nbsp;-&nbsp;' + footer.copyright;
				})
			);
	}

	private loadMenus() {
		let userRightPointer = null;
		this.subscriptions.push(
			this.user.subscribe((user) => {
				if (user && userRightPointer !== user.activeRight) {
					userRightPointer = user.activeRight;
					this.menuService.clearAll();
					if (user.level !== LevelEnum.DEAN_OF_STUDIES) {
						this.menuService.loadMenus();
					}
					this.refreshUserSwitch();
					this.changeSystemUsageRights(user);
				} else if (!user) {
					userRightPointer = null;
				}
			})
		);
	}

	private changeSystemUsageRights(user: UserModelClass) {
		firstValueFrom(
			this.configService.getOneConfigObservable<boolean>(
				'SESSIONLOGGING_SHOWQUICKINFO',
				EvasysLoadingStrategiesEnum.APIONLY
			)
		)
			.then((systemUsageSettingActivated) => {
				this.showSystemUsage =
					[LevelEnum.ADMINISTRATOR, LevelEnum.SUBSECTION_ADMINISTRATOR].includes(user.level) &&
					systemUsageSettingActivated;
			})
			.catch((err) => console.error('Failed to load configuration', err));
	}

	private setMenuItems(): void {
		this.subscriptions.push(
			this.menuService.getMenuItems().subscribe((menus: EvasysMenuModel[]) => {
				this.topMenuitems = [];
				this.menuItems = menus;
				menus.forEach((menu) => {
					this.addTopmenuItems(menu);
				});
				this.changeActiveSideMenuItems('Headmenu_Subunits');
			})
		);
	}

	private addTopmenuItems(menu: EvasysMenuModel): void {
		this.topMenuitems.push({
			title: menu.title,
			id: menu.id,
			href: of(menu.link),
			isMultiPage: menu.link?.includes('TOKEN'),
			mark: menu.mark,
		} as Tabitem<string>);
	}

	private changeActiveSideMenuItems(activeSubmenu: string): void {
		this.sidemenuItems = this.menuItems
			.find((menu) => menu.id === activeSubmenu)
			?.submenus?.map(
				(submenu: SidemenuItemModel) =>
					({
						id: submenu.id,
						title: submenu.title,
						menuitems: submenu.menuitems.map((item) => ({
							...item,
							icon: item.icon,
							route: item.route,
						})),
						tooltip: submenu.tooltip,
						canAccess: of(true),
					} as SidemenuItemModel)
			);
	}

	private loadOrganisationLogo() {
		firstValueFrom(
			//stateonly beacause the login-resolver is still loading the infos from the api
			this.systemInfoService.getManySystemInfos(
				['logo_key', 'logo_file', 'login_alt_text'],
				EvasysLoadingStrategiesEnum.STATEONLY
			)
		)
			.then((systemInfos) => {
				if (systemInfos.logo_key && systemInfos.logo_file) {
					this.logo = '../' + systemInfos.logo_key + '/' + systemInfos.logo_file;
				}
				this.logoAlt = systemInfos.login_alt_text;
			})
			.catch(() => {
				console.error('Could not get the organization logo.');
			});
	}

	private refreshUserSwitch() {
		firstValueFrom(this.user).then((authUser) => {
			this.authUserService.getUserForSwitch(authUser.id).then((users) => {
				if (users?.length > 0) {
					this.canSwitchUser = true;
					this.userSwitchData = users;
				} else {
					this.canSwitchUser = false;
				}
			});
		});
	}

	//endregion
}
