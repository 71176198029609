import { ChangeDetectorRef, Component, ElementRef, Renderer2 } from '@angular/core';
import { BaseTextComponent } from 'slate-angular';

@Component({
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'span[textMark]',
	template: `<slate-leaves [context]="context" [viewContext]="viewContext"></slate-leaves>`,
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: {
		'data-slate-node': 'text',
	},
})
export class TextMarkComponent extends BaseTextComponent {
	constructor(public override elementRef: ElementRef, public renderer2: Renderer2, cdr: ChangeDetectorRef) {
		super(elementRef, cdr);
	}

	applyTextMark() {
		const styleClasses = {
			bold: 'fw-bold',
			italic: 'fst-italic',
			underlined: 'text-decoration-underline',
			'code-line': 'bg-light m-0 ml-1 p-1 border-info text-secondary rounded font-monospace',
		};

		const classes = Object.entries(styleClasses)
			.filter(([key]) => (this.text as any)[key])
			.map(([, value]) => value)
			.join(' ');

		this.renderer2.setAttribute(this.elementRef.nativeElement, 'class', classes);
	}

	override onContextChange() {
		super.onContextChange();
		this.applyTextMark();
	}
}
