import { Component, inject, OnInit } from '@angular/core';
import { first, Observable } from 'rxjs';
import { ChipTypeaheadDesignEnum } from '@evasys/globals/shared/enums/component/chip-typeahead-design.enum';
import {
	FilterService,
	ReportProcessingService,
	ReportService,
	ReportTemplateService,
} from '@evasys/evainsights/shared/core';
import { ActivatedRoute } from '@angular/router';
import { Report } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { cloneDeep } from 'lodash';
import { EvasysLoadingStrategiesEnum } from '@evasys/globals/shared/enums/general/evasys-loadingStrategies.enum';
import { map } from 'rxjs/operators';
import { KeyValueModel } from '@evasys/globals/evainsights/models/common/key-value.model';
import { ReportFacadeService } from '@evasys/evainsights/stores/core';
import { getReportIdFromParamMap } from '@evasys/globals/evainsights/helper/url-params';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';

@Component({
	selector: 'evainsights-report-template-list',
	templateUrl: './report-template-list.component.html',
})
export class ReportTemplateListComponent implements OnInit {
	protected reportTemplateService = inject(ReportTemplateService);
	protected reportService = inject(ReportService);
	protected reportProcessingService = inject(ReportProcessingService);
	protected activatedRoute = inject(ActivatedRoute);
	protected reportFacadeService = inject(ReportFacadeService);
	protected filterService = inject(FilterService);

	reportId = 0;
	selectedReportTemplates: KeyValueModel[] = [];
	reportSurveysFormIds: Set<number> = new Set<number>();
	preSelectedReportTemplates: KeyValueModel[] = [];
	chipTypeaheadDesign = ChipTypeaheadDesignEnum;
	filterAreaControls = [this.filterService.forms];

	formatterTemplate = (items: KeyValueModel) => items.value;

	ngOnInit(): void {
		this.activatedRoute.paramMap.subscribe((paramMap) => {
			this.reportId = getReportIdFromParamMap(paramMap);
			if (this.reportId !== 0) {
				this.reportFacadeService
					.get<Report>({
						id: this.reportId,
						loadingStrategy: EvasysLoadingStrategiesEnum.STATEONLY,
					})
					.subscribe((report) => {
						if (report?.usedReportTemplates) {
							this.preSelectedReportTemplates = report.usedReportTemplates;
							this.reportSurveysFormIds = new Set(report.formIds);
							this.selectedReportTemplates = cloneDeep(report.usedReportTemplates);
						}
					});
			}
		});
	}

	searchReportTemplates = (text: string): Observable<Page<KeyValueModel>> => {
		return this.reportTemplateService
			.getSearchResult({
				title: text,
				forms: Array.from(this.reportSurveysFormIds),
			})
			.pipe(
				map((reportTemplates) => {
					return {
						...reportTemplates,
						content: reportTemplates.content.map((reportTemplate) => ({
							key: reportTemplate.id,
							value: reportTemplate.title,
						})),
					};
				})
			);
	};

	onSelectReportTemplates(updatedSelection: KeyValueModel[]) {
		//Template was added
		if (updatedSelection.length > this.selectedReportTemplates.length) {
			this.reportProcessingService.isProcessingTemplate.set(true);
			this.reportService
				.putReportTemplate(this.reportId, updatedSelection[updatedSelection.length - 1].key)
				.pipe(first())
				.subscribe({
					next: (report) => {
						this.updateOneLocal(report);
						this.reportProcessingService.isProcessingTemplate.set(false);
					},
					error: () => this.reportProcessingService.isProcessingTemplate.set(false),
				});
		}
		//Template was removed
		else {
			this.selectedReportTemplates.forEach((reportTemplate) => {
				const index = updatedSelection.findIndex((x) => x.key === reportTemplate.key);
				if (index === -1) this.remove(reportTemplate.key);
			});
		}
		this.selectedReportTemplates = cloneDeep(updatedSelection);
	}

	private remove(reportTemplateId: number) {
		this.reportProcessingService.isProcessingTemplate.set(true);
		this.reportService
			.removeReportTemplate(this.reportId, reportTemplateId)
			.pipe(first())
			.subscribe({
				next: (report) => {
					this.updateOneLocal(report);
					this.reportProcessingService.isProcessingTemplate.set(false);
				},
				error: () => this.reportProcessingService.isProcessingTemplate.set(false),
			});
	}

	private updateOneLocal(report: Report) {
		this.reportFacadeService.updateOneLocal({
			id: this.reportId,
			changes: report,
		});
	}
}
