import { Injectable } from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationEnum } from '@evasys/globals/shared/enums/component/notification.enum';
import { NotificationService } from '@evasys/shared/core';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class ResponseNotificationInterceptor implements HttpInterceptor {
	constructor(
		private readonly notificationService: NotificationService,
		private readonly translocoService: TranslocoService
	) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			tap((event) => {
				if (event instanceof HttpResponse && event?.body?.id !== 'S_DELETE_SUCCESSFUL') {
					this.handleSuccessResponse(event);
				}
			}),
			tap({
				error: (error) => {
					if (error instanceof HttpErrorResponse) {
						if (error instanceof HttpErrorResponse) {
							this.handleErrorResponse(error);
						}
					}
				},
			})
		);
	}

	private handleSuccessResponse(response: HttpResponse<any>) {
		if (response?.body?.id && response?.body?.severity && response?.body?.message) {
			this.getMessage(response?.body, NotificationEnum.SUCCESS);
		}

		return response;
	}

	private handleErrorResponse(errorResponse: HttpErrorResponse) {
		this.getMessage(errorResponse?.error, NotificationEnum.ERROR);
	}

	/**
	 * @param response in case of error it is the response.error content, otherwise wordcloud-response.body
	 * @param type
	 * @private
	 */
	private getMessage(response: any, type: NotificationEnum) {
		try {
			if (response?.message !== undefined && response?.message !== '') {
				let responseCode: string = '';
				let translatedResponse = '';

				if (response.id) {
					responseCode = response.id;
					translatedResponse = this.translocoService.translate(responseCode.toUpperCase());

					if (responseCode.toUpperCase() === translatedResponse && response.message) {
						translatedResponse = response.message;
					} else if (response.placeholder && Array.isArray(response.placeholder)) {
						response.placeholder.forEach((placeholder: string, index: string) => {
							let translatedPlaceholder = this.translocoService.translate(placeholder.toUpperCase());
							translatedResponse = translatedResponse.replace(index, translatedPlaceholder);
						});
					}
				} else {
					translatedResponse = this.translocoService.translate('S_UNKNOWN_ERROR');
					responseCode = 'responseUnknown';
				}

				if (translatedResponse !== '') {
					this.notificationService.addNotification(type, translatedResponse, responseCode);
				}
			}
		} catch (error) {
			console.error(this.translocoService.translate('S_UNKNOWN_ERROR'), ': responseUnknown');
		}
	}
}
