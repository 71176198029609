import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'valueByMap',
})
export class ValueByMapPipe implements PipeTransform {
	transform(value: unknown, map: Map<unknown, unknown>): unknown {
		if (!map?.has(value)) {
			return null;
		}

		return map.get(value);
	}
}
