import { Component, EventEmitter, Input, Output } from '@angular/core';
import { opacityAnimation } from '@evasys/globals/shared/animations/opacity.animation';

@Component({
	selector: 'evasys-overlay',
	templateUrl: './overlay.component.html',
	animations: [opacityAnimation()],
})
export class OverlayComponent {
	//region Input & Output

	@Input()
	open = false;

	@Output()
	openChange = new EventEmitter<boolean>();

	@Input()
	openOnHover = false;

	//endregion

	//region Events
	onMouseEnter() {
		if (this.openOnHover) {
			this.open = true;
			this.openChange.emit(this.open);
		}
	}

	onMouseLeave() {
		if (this.openOnHover) {
			this.open = false;
			this.openChange.emit(this.open);
		}
	}

	//endregion
}
