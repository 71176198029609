/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Margin, RenderConfig, Size } from '../components/widgets/d3-base/d3-base.component';
import {
	BaseType,
	EnterElement,
	InternSet,
	line,
	map,
	max,
	ScaleBand,
	scaleBand,
	ScaleLinear,
	scaleLinear,
	select,
	Selection,
} from 'd3';
import { wrap, WrapOptions, WrapVerticalAlign } from './util';
import { isNotNullish } from '@evasys/globals/evainsights/typeguards/common';
import { dataFontSize, statisticsFontSize } from '@evasys/globals/evainsights/helper/charts/defaults';
import { translateReportMultiLangString } from '@evasys/evainsights/shared/util';
import {
	Data,
	ItemData,
	LineData,
	ProfileLineChartConfig,
	ProfileLineChartContent,
	StatisticalData,
} from '@evasys/globals/evainsights/models/report-item';

// Configuration
const margin: Margin = {
	top: 0,
	bottom: 0,
	left: 2,
	right: 0,
};
const strokeDashArrayPatterns = [
	'10 2',
	'2 1',
	'5 2',
	'4 2 2 2 1 2 2 2',
	'4 2 4 2 1 2 1 2',
	'4 2 4 2 1 2',
	'10 2 5 2',
	'1 1 1 3',
	'6 4 2 1',
	'1 2 3 2 1',
	'3 7 5 3',
];
export class ProfileLineChart {
	constructor(
		private host: HTMLElement,
		private ctx: CanvasRenderingContext2D,
		private content: ProfileLineChartContent
	) {}

	/**
	 * Main render function to draw profile line
	 * @param { size, language }
	 */
	// prettier-ignore
	render({size, reportLanguageId, decimalFormat}: RenderConfig) {
		select(this.host).selectAll('*').remove();
		const data = this.content.data;

		// Draw SVG parent
		const svg = select(this.host)
			.append('svg')
			.attr('width', size.width)
			.attr('height', size.height)
			.attr('viewbox', `0 0 ${size.width} ${size.height}`);

		// for inner functions/callbacks with a different "this"-reference
		const config = this.content.config;
		const buildProfilePath = this.buildProfilePath;
		const drawAutoFitText = this.drawAutoFitText;

		// Init chart configuration by new size
		const maxResponses: number = Math.max(...map(data.statisticData, datum => max(datum.data, d => parseInt(d.responseCount))).filter(isNotNullish));
		const {statisticWidth, xStatisticScale} = this.calculateStatisticScaleByTextLength(svg, size, maxResponses, decimalFormat);
		const width = this.calculateWidth(size, statisticWidth);
		const offset = this.calculateOffset(width);
		const paddingPolText = 5;

		// Domains
		const surveyDomain = new InternSet(data.lines.map((line: LineData) => line.surveyId));
		const yDomain = data.items.map((item: ItemData) => item.itemId);

		// Scales
		const yScale = scaleBand(yDomain, [margin.top, size.height - margin.bottom]).padding(0.05);
		const xScales = Object.fromEntries(
			data.items.map((i) => [
				i.itemId,
				scaleLinear()
					.domain([1, i.answerOptions])
					.range([offset.profileLine, offset.profileLine + width.profileLine]),
			])
		);
		const yStatisticScale = scaleBand(surveyDomain, [0, yScale.bandwidth()]).padding(0.1);

		// Draw Question Text
		svg.append('g')
			.attr('data-cy', 'question-text-group')
			.selectAll<SVGTextElement, unknown>('text')
			.data(data.items)
			.enter()
			.each(function (dataItem: ItemData) {
				drawAutoFitText(
					select(this),
					{
						x: offset.questionText,
						y: yScale(dataItem.itemId)! + yScale.bandwidth() / 2,
						width: width.questionText,
						height: yScale.bandwidth(),
						fontSize: dataFontSize,
						text: translateReportMultiLangString(dataItem.itemTitle, reportLanguageId)}
				);
			});

		// Draw PolTexts
		svg.append('g')
			.attr('data-cy', 'pol-text-group')
			.selectAll<SVGTextElement, unknown>('text')
			.data(data.items)
			.enter()
			.each(function (dataItem: ItemData) {
				// Left PolText
				drawAutoFitText(
					select(this),
					{
						x: offset.leftPolText + width.leftPolText - paddingPolText,
						y: yScale(dataItem.itemId)! + yScale.bandwidth() / 2,
						width: width.leftPolText - paddingPolText * 2,
						height: yScale.bandwidth(),
						fontSize: dataFontSize,
						text: translateReportMultiLangString(dataItem.leftPolText, reportLanguageId),
						anchor: 'end'}
				);
				// Right PolText
				drawAutoFitText(
					select(this),
					{
						x: offset.rightPolText + paddingPolText,
						y: yScale(dataItem.itemId)! + yScale.bandwidth() / 2,
						width: width.rightPolText - paddingPolText * 2,
						height: yScale.bandwidth(),
						fontSize: dataFontSize,
						text: translateReportMultiLangString(dataItem.rightPolText, reportLanguageId)}
				);
			});

		// Draw Axis / ProfileLine-Grid
		const axisGroup = svg.append('g').attr('data-cy', 'axis-group');
		axisGroup.append('rect')
			.attr('x', offset.profileLine)
			.attr('width', width.profileLine)
			.attr('y', margin.top)
			.attr('height', size.height - margin.bottom)
			.attr('fill', 'rgba(0, 0, 0, 0.07)');
		axisGroup.selectAll('item-axis')
			.data(data.items)
			.enter()
			.append('g')
			.each(function (dataItem: ItemData) {
				const g = select(this);
				const yMid = yScale(dataItem.itemId)! + yScale.bandwidth() / 2;
				const yTop = yScale(dataItem.itemId)! - (yScale.bandwidth() * yScale.paddingOuter()) / 2;
				// Item separator line
				g.append('line')
					.attr('x1', margin.left)
					.attr('x2', offset.profileLine)
					.attr('y1', yTop)
					.attr('y2', yTop)
					.attr('stroke', '#DDD');
				g.append('line')
					.attr('x1', offset.rightPolText)
					.attr('x2', offset.rightMargin)
					.attr('y1', yTop)
					.attr('y2', yTop)
					.attr('stroke', '#DDD');
				// Horizontal Line
				g.append('line')
					.attr('x1', offset.profileLine)
					.attr('x2', offset.profileLine + width.profileLine)
					.attr('y1', yMid)
					.attr('y2', yMid)
					.attr('stroke', 'grey');
				for (let i = 1; i <= dataItem.answerOptions; i++) {
					const x = xScales[dataItem.itemId](i);
					// Vertical Line for each AnswerOption
					g.append('line')
						.attr('x1', x)
						.attr('x2', x)
						.attr('y1', yMid - yScale.bandwidth() / 2)
						.attr('y2', yMid + yScale.bandwidth() / 2)
						.attr('stroke', 'grey');
				}
			});

		// Draw Lines & Statistics
		const allSurveys = svg.append('g').attr('data-cy', 'profile-lines-group');
		allSurveys
			.selectAll('g')
			.data(data.statisticData)
			// Add group for each profile line
			.join('g')
			.each(function (statData: StatisticalData, index: number) {
				const surveyNode = select(this);
				const color = data.lines.find((value) => value.surveyId === statData.surveyId)!.color;

				// Draw Profile Line
				surveyNode.append('path')
					.attr('d', buildProfilePath(statData.data, xScales, yScale, surveyNode, color, config))
					.attr('stroke-dasharray', strokeDashArrayPatterns[index])
					.style('fill', 'none')
					.style('stroke-width', '1.5')
					.style('stroke', color);

				// prepare statisticalValues
				const flatStatisticData: {itemId: string, statValues: {key: string, value: string }[]}[] = map(statData.data, d => {
					const statValues = [{key: 'n', value: 'n=' + d.responseCount}]
					if (config.showMean) {
						statValues.push({key: 'mw',value: 'mw=' + parseFloat(d.mean).toFixed(config.fractionDigits).replace('.', decimalFormat)});
					}
					if (config.showMedian) {
						statValues.push({key: 'md',value: 'md=' + parseFloat(d.median).toFixed(config.fractionDigits).replace('.', decimalFormat)});
					}
					statValues.push({key: 's', value: 's=' + parseFloat(d.stdDev).toFixed(config.fractionDigits).replace('.', decimalFormat)});
					return {itemId: d.itemId, statValues}
				});

				// Add statistical values
				surveyNode
					.selectAll('text')
					.data(flatStatisticData)
					.enter()
					.append('text')
					.attr('dominant-baseline', 'middle')
					.attr('y', d => yStatisticScale(statData.surveyId)! + yStatisticScale.bandwidth() / 2 + yScale(d.itemId)!)
					.style('fill', color)
					.style('font-size', statisticsFontSize)
					.style('font-family', config.fontFamily)
					.each (function(data) {
						select(this)
							.selectAll('tspan')
							.data(data.statValues)
							.join('tspan')
							.attr('x', d => xStatisticScale(d.key)!)
							.text(d => d.value);
					});
			});
	}

	private drawAutoFitText(
		parentElement: Selection<EnterElement, unknown, null, undefined>,
		{ x, y, width, height, fontSize, text, anchor }: AutoFitOptions
	) {
		if (anchor === undefined) {
			anchor = 'start';
		}
		parentElement
			.append('text')
			.attr('x', x)
			.attr('y', y)
			.attr('dy', '0em') // used for wrap()
			.attr('dominant-baseline', 'middle')
			.attr('text-anchor', anchor)
			.style('font-family', 'sans-serif')
			.style('font-size', fontSize)
			.text(text)
			.call(wrap, {
				ctx: this.ctx,
				width: width,
				height: height,
				lines: 3,
				verticalAlign: WrapVerticalAlign.MIDDLE,
			} satisfies WrapOptions);
	}

	private buildProfilePath(
		dataValues: Data[],
		xScales: { [p: string]: ScaleLinear<number, number> },
		yScale: ScaleBand<string>,
		surveyNode: Selection<BaseType | SVGGElement, unknown, null, undefined>,
		color: string,
		config: ProfileLineChartConfig
	) {
		return line()(
			dataValues
				.filter((dataValue: Data) => {
					return parseInt(dataValue.responseCount) > 0; // filter items without responses
				})
				.map(function (dataValue: Data) {
					const pathValue = parseFloat(config.useMedian ? dataValue.median : dataValue.mean);
					// Draw dots on intersection of profileLine with axis-grid
					surveyNode
						.append('rect')
						.attr('x', xScales[dataValue.itemId](pathValue) - 2)
						.attr('width', 4)
						.attr('y', yScale(dataValue.itemId)! + yScale.bandwidth() / 2 - 2)
						.attr('height', 4)
						.attr('fill', color);
					// return path-points for profileLine
					return [xScales[dataValue.itemId](pathValue), yScale(dataValue.itemId)! + yScale.bandwidth() / 2];
				})
		);
	}

	private calculateWidth(size: Size, statisticValues: number): ProfileLineWidth {
		const staticWidth = {
			statisticValues: statisticValues,
			profileLine: 180,
		};
		const staticWidthSum = margin.left + staticWidth.statisticValues + staticWidth.profileLine + margin.right;
		const dynamicWidth = {
			questionText: (size.width - staticWidthSum) * 0.5,
			poleText: (size.width - staticWidthSum) * (0.5 / 2),
		};
		return {
			leftMargin: margin.left,
			questionText: dynamicWidth.questionText,
			leftPolText: dynamicWidth.poleText,
			profileLine: staticWidth.profileLine,
			rightPolText: dynamicWidth.poleText,
			statisticValues: staticWidth.statisticValues,
			rightMargin: margin.right,
		};
	}

	private calculateOffset(width: ProfileLineWidth): ProfileLineOffset {
		let offset = 0;
		return {
			questionText: (offset += width.leftMargin), // nosonar
			leftPolText: (offset += width.questionText), // nosonar
			profileLine: (offset += width.leftPolText), // nosonar
			rightPolText: (offset += width.profileLine), // nosonar
			statisticValues: (offset += width.rightPolText), // nosonar
			rightMargin: offset + width.statisticValues,
		};
	}

	private calculateStatisticScaleByTextLength(
		svg: Selection<SVGSVGElement, unknown, null, undefined>,
		size: Size,
		maxN: number,
		decimalFormat: string
	) {
		// shadow node on svg to calculate real text width for statistical data;
		const shadowNode = svg
			.append('text')
			.style('font-size', statisticsFontSize)
			.style('font-family', this.content.config.fontFamily);
		const innerPadding = 5;
		const offset = { n: 0, mw: 0, md: 0, s: 0 };

		// calculate max with of text
		shadowNode.text('s=' + (0.0).toFixed(this.content.config.fractionDigits).replace('.', decimalFormat));
		offset.s = size.width - margin.right - shadowNode.node()!.getComputedTextLength();
		offset.md = offset.s;
		if (this.content.config.showMedian) {
			shadowNode.text('md=' + (0.0).toFixed(this.content.config.fractionDigits).replace('.', decimalFormat));
			offset.md -= shadowNode.node()!.getComputedTextLength() + innerPadding;
		}
		offset.mw = offset.md;
		if (this.content.config.showMean) {
			shadowNode.text('mw=' + (0.0).toFixed(this.content.config.fractionDigits).replace('.', decimalFormat));
			offset.mw -= shadowNode.node()!.getComputedTextLength() + innerPadding;
		}
		shadowNode.text(`n=${maxN}`);
		offset.n = offset.mw;
		offset.n -= shadowNode.node()!.getComputedTextLength() + innerPadding;

		// remove shadow node
		shadowNode.remove();
		return {
			statisticWidth: size.width - margin.right - offset.n,
			xStatisticScale: (statValue: string) => {
				switch (statValue) {
					case 'n':
						return offset.n;
					case 'mw':
						return offset.mw;
					case 'md':
						return offset.md;
					case 's':
						return offset.s;
					default:
						return 0;
				}
			},
		};
	}
}

// ProfileLine Interface
interface ProfileLineWidth {
	leftMargin: number;
	questionText: number;
	leftPolText: number;
	profileLine: number;
	rightPolText: number;
	statisticValues: number;
	rightMargin: number;
}

interface ProfileLineOffset {
	questionText: number;
	leftPolText: number;
	profileLine: number;
	rightPolText: number;
	statisticValues: number;
	rightMargin: number;
}

interface AutoFitOptions {
	x: number;
	y: number;
	width: number;
	height: number;
	fontSize: number;
	text: string;
	anchor?: string;
}
