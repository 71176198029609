import { ElementRef, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SameWidthService {
	//region Variables
	private widths: Map<string, number> = new Map<string, number>();
	private registeredElements: Map<string, Array<HTMLElement>> = new Map<string, Array<HTMLElement>>();
	private _widthsChanges = new Map<string, Subject<number>>();
	//endregion

	//region Getter & Setter
	public widthsChanges(group: string) {
		if (!this._widthsChanges.get(group)) {
			this._widthsChanges.set(group, new Subject<number>());
		}
		return this._widthsChanges.get(group).asObservable();
	}

	public width(group: string) {
		return this.widths.get(group);
	}

	//region Methods
	public registerElement(elementRef: ElementRef, group: string) {
		const element = elementRef.nativeElement;
		if (!this.registeredElements.get(group)) {
			this.registeredElements.set(group, []);
		}
		if (!this._widthsChanges.get(group)) {
			this._widthsChanges.set(group, new Subject<number>());
		}
		if (!this.registeredElements.get(group).includes(element)) {
			this.registeredElements.get(group).push(element);
		}
		if (!this.widths.has(group) || this.widths.get(group) < elementRef.nativeElement.offsetWidth) {
			this.widths.set(group, elementRef.nativeElement.offsetWidth);
			this._widthsChanges.get(group)?.next(elementRef.nativeElement.offsetWidth);
		}
	}

	deregister(elementRef: ElementRef, group: string) {
		const element = elementRef.nativeElement;
		if (this.widths.has(group) && this.registeredElements.get(group)?.length <= 1) {
			this.widths.delete(group);
			this.registeredElements.delete(group);
		} else {
			const index = this.registeredElements.get(group)?.indexOf(element, 0);
			if (index > -1) {
				this.registeredElements.get(group)?.splice(index, 1);
			}
		}
	}
	//endregion
}
