import { Component, Input } from '@angular/core';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { maxSizePopperOptions } from '@evasys/shared/ui';

@Component({
	selector: 'evainsights-info-popover',
	templateUrl: './wordcloud-info-popover.component.html',
})
export class WordcloudInfoPopoverComponent {
	@Input()
	@Required()
	id!: string;

	@Input()
	language?: string;

	buttonDesignEnum = ButtonDesignEnum;

	popperOptions = maxSizePopperOptions({ minWidth: 200, minHeight: 200 });
}
