import { Actions } from '@ngrx/effects';
import { EvasysApiRequestModel } from '@evasys/globals/shared/models/general/evasys-api-request.model';
import { EvasysRequestMethodEnum } from '@evasys/globals/shared/enums/general/evasys-request-method.enum';
import { EvasysStatusModel } from '@evasys/globals/evasys/models/general/evasys-status.model';
import { Injectable } from '@angular/core';
import { ApiService, EvasysEffects } from '@evasys/shared/core';
import { evasysActions } from '../actions';
import { EndpointsEnum } from '@evasys/globals/evasys/api/endpoints.enum';

@Injectable()
export class StatusEffects extends EvasysEffects<EvasysStatusModel> {
	private API_PATH = EndpointsEnum.STATUS;

	constructor(protected override actions$: Actions, protected readonly apiService: ApiService) {
		super(evasysActions.fromStatus, 'status', actions$);
	}

	loadAll$ = this.createEffect(
		evasysActions.fromStatus.LoadAllActionSet,
		() =>
			({
				apiPath: this.API_PATH,
				many: true,
				requestMethod: EvasysRequestMethodEnum.GET,
				pageSize: -1,
			} as EvasysApiRequestModel)
	);

	transmitEvasysUpdateStatus$ = this.createEffect(
		evasysActions.fromStatus.transmitEvasysUpdateStatus,
		() =>
			({
				apiPath: this.API_PATH + '/lastUsageAutoUpdate',
				many: false,
				requestMethod: EvasysRequestMethodEnum.PATCH,
				body: {},
			} as EvasysApiRequestModel)
	);

	transmitEvasysUpdateRentalStatus$ = this.createEffect(
		evasysActions.fromStatus.transmitEvasysUpdateRentalStatus,
		(action: any) =>
			({
				apiPath: this.API_PATH + '/lastUsageRentalSystem',
				many: false,
				requestMethod: EvasysRequestMethodEnum.PATCH,
				body: {
					rentalSystemAction: action.payload,
				},
			} as EvasysApiRequestModel)
	);

	transmitExtrasUpdateStatus$ = this.createEffect(
		evasysActions.fromStatus.transmitExtrasUpdateStatus,
		(action: any) =>
			({
				apiPath: this.API_PATH + '/extrasUpdateCheck',
				many: false,
				requestMethod: EvasysRequestMethodEnum.PATCH,
				body: {
					extrasUpdateCheck: action.payload,
				},
			} as EvasysApiRequestModel)
	);

	loadOne$ = this.createEffect(
		evasysActions.fromStatus.LoadOneActionSet,
		(action: any) =>
			({
				apiPath: [this.API_PATH, action.payload].join('/'),
				fields: 'name,value',
				many: false,
				requestMethod: EvasysRequestMethodEnum.GET,
			} as EvasysApiRequestModel)
	);

	acceptEula$ = this.createEffect(
		evasysActions.fromStatus.EulaAcceptedActionSet,
		(action: any) =>
			({
				apiPath: [this.API_PATH, action.payload.endpoint].join('/'),
				many: false,
				requestMethod: EvasysRequestMethodEnum.PATCH,
				body: action.payload.queryParams,
			} as EvasysApiRequestModel)
	);

	protected mapToBackend(data: EvasysStatusModel): any {
		return data;
	}

	protected mapToFrontend(data: any): EvasysStatusModel {
		return {
			...data,
			id: data?.name,
		} as EvasysStatusModel;
	}
}
