import { Component, Input } from '@angular/core';

@Component({
	selector: 'evasys-progress-steps',
	templateUrl: './progress-steps.component.html',
})
export class ProgressStepsComponent {
	//region Input & Output

	@Input()
	set total(total: number) {
		this._total = new Array(total);
	}

	@Input()
	current = 0;

	//endregion

	//region Variables
	_total: Array<any>;
	//endregion
}
