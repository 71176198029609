import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUiModule } from '@evasys/shared/ui';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedUtilModule } from '@evasys/shared/util';
import { ModalMultipleUsersComponent } from './components/modal-multiple-users/modal-multiple-users.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ModalConfirmModule } from './components/modal-confirm/modal-confirm.module';

@NgModule({
	imports: [
		CommonModule,
		NgbModule,
		ReactiveFormsModule,
		RouterModule,
		SharedUtilModule,
		SharedUiModule,
		TranslocoModule,
		ModalConfirmModule,
	],
	declarations: [ModalMultipleUsersComponent],
	exports: [ModalConfirmModule, ModalMultipleUsersComponent],
})
export class EvasysSharedUiModule {}
