import { FilterAreaControl, FilterSelection } from '../models/filter/filter-area-control.model';

export function buildEmptyFilterSelection<FilterAreaControls extends readonly FilterAreaControl<unknown>[]>(
	filterAreaControls: FilterAreaControls
): FilterSelection<FilterAreaControls> {
	let filterSelection: Partial<FilterSelection<FilterAreaControls>> = {};
	for (const filterAreaControl of filterAreaControls) {
		filterSelection = { ...filterSelection, [filterAreaControl.id]: [] };
	}
	return filterSelection as FilterSelection<FilterAreaControls>;
}
