import { Component, Input } from '@angular/core';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';

@Component({
	selector: 'evasys-anchor',
	templateUrl: './anchor.component.html',
})
export class AnchorComponent {
	//#region Input & Output
	@Input({ required: true })
	id: string;

	@Input({ required: true })
	link: string[];

	@Input()
	design: ButtonDesignEnum | string = ButtonDesignEnum.LINK;

	@Input()
	class?: string;

	@Input()
	isDisabled = false;

	//#endregion Input & Output
}
