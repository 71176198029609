<evasys-modal
	[closeable]="true"
	[id]="'reportPublish'"
	[(opened)]="isOpen"
	[size]="'lg'"
	[scrollable]="false"
	(openedChange)="openedChangeEmit($event)"
>
	<div class="w-100" header>
		<img
			evasysImage
			class="d-flex justify-content-center"
			[id]="'info'"
			[alt]="'info-icon'"
			[class]="'c-w-30px c-h-30px'"
			[src]="'./assets/images/icons/about.svg'"
		/>
		<p class="h3 d-flex justify-content-center">{{ 'report.notEditRetrospectively' | transloco }}</p>
	</div>
	<form [formGroup]="form" class="d-flex flex-column gap-3">
		<evasys-chip-typeahead
			[id]="'units'"
			[items]="searchUnits"
			[formatter]="formatterUnits"
			formControlName="units"
			[searchFailedText]="'error.text' | transloco"
			[emptyResultsText]="'unit.noneFound' | transloco"
			[itemIdentifier]="'key'"
			[errors]="[
				{
					id: 'noUnits',
					trigger: submitted && form.controls.units.errors?.required,
					text: 'unit.required' | transloco
				}
			]"
			>{{ 'organization.unit' | transloco }}</evasys-chip-typeahead
		>
		<evasys-checkbox [id]="'publishedLater'" (checkedChange)="setPublishLater($event)">{{
			'report.publish.later' | transloco
		}}</evasys-checkbox>
		<ng-container *ngIf="form.controls.dateTime">
			<h3>{{ 'domain.publishTime' | transloco }}:</h3>
			<evasys-calendar
				[id]="'date'"
				[dataCy]="'date'"
				[dateFormat]="(uiConfig | async).dateFormat"
				[timeFormat]="(uiConfig | async).timeFormat"
				[translation]="calendarPublishTranslation"
				formControlName="dateTime"
				[required]="false"
				data-cy="publishModal-dateTimePicker"
			></evasys-calendar>
			<evasys-error
				[errors]="[
					{
						id: 'expiredTime',
						trigger: submitted && form.controls.dateTime.errors?.expired,
						text: 'error.publicationTimeInPast' | transloco
					},
					{
						id: 'noDateTime',
						trigger: submitted && form.controls.dateTime.errors?.required,
						text: 'domain.publishTime.required' | transloco
					}
				]"
			></evasys-error>
		</ng-container>
	</form>
	<div footer class="d-flex justify-content-end w-100 gap-3">
		<evasys-button
			[id]="'publish'"
			[type]="'submit'"
			[design]="buttonDesign.PRIMARY"
			(click)="publishReport()"
			[title]="'report.publish.hover' | transloco"
			>{{ 'report.publish.title' | transloco }}
		</evasys-button>
	</div>
</evasys-modal>
