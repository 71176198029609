<div
	class="form-check"
	[ngClass]="lablePosition === labelPosition.LEFT ? 'd-inline-flex align-items-center flex-row-reverse' : ''"
>
	<input
		#inputViewChild
		class="form-check-input"
		type="radio"
		[formControl]="input"
		[name]="name"
		[value]="_value"
		[attr.value]="_value"
		[attr.id]="id"
		[attr.disabled]="_isDisabled ? true : undefined"
		[checked]="isChecked()"
		(change)="onChecked()"
		[class.is-invalid]="isInvalid()"
		[ngClass]="lablePosition === labelPosition.LEFT ? 'ms-3 mt-0 mb-1' : null"
		[attr.data-cy]="'radioButton-' + id"
	/>
	<label #labelElement class="form-check-label d-inline" [for]="id">
		<ng-content></ng-content>
	</label>
	<evasys-error *ngIf="isInvalid()" [id]="'radioButton-' + id" [errors]="errors"></evasys-error>
</div>
