import { ChangeDetectorRef, Injector, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { UserRightsEnum } from '@evasys/globals/shared/enums/business/user-rights.enum';
import { AuthUserService } from '@evasys/shared/core';
import { AsyncPipe } from '@angular/common';
import { map } from 'rxjs/operators';

@Pipe({
	name: 'permission',
	pure: false,
})
export class PermissionPipe implements PipeTransform, OnDestroy {
	private asyncPipe: AsyncPipe;

	constructor(private userService: AuthUserService, private injector: Injector) {
		this.asyncPipe = new AsyncPipe(injector.get(ChangeDetectorRef));
	}

	ngOnDestroy() {
		this.asyncPipe.ngOnDestroy();
	}

	/**
	 * checks the permission of the current user and only returns the string value again
	 * when at least one of the given permissions match
	 *
	 * @param value
	 * @param userRights
	 */
	transform(value: string | boolean, userRights: UserRightsEnum[]): string | undefined {
		return this.asyncPipe.transform(
			this.userService.authenticatedUserObservable.pipe(
				map((user) => {
					let pipedValue = undefined;

					userRights.forEach((userRight) => {
						if (userRight === user?.activeRight) {
							pipedValue = value;
						}
					});

					return pipedValue;
				})
			)
		);
	}
}
