import { Component, Input } from '@angular/core';
import { BehaviorSubject, filter, Observable, switchAll } from 'rxjs';
import { ReportLanguage } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';

@Component({
	template: '',
})
export abstract class WidgetBaseComponent {
	@Input()
	set language(value: ReportLanguage | undefined) {
		this.reportLanguage$.next(value);
	}

	reportLanguage$ = new BehaviorSubject<ReportLanguage | undefined>(undefined);

	titleObservables = new BehaviorSubject<Observable<string> | undefined>(undefined);
	translatedTitle = this.titleObservables.pipe(
		filter((obs): obs is Observable<string> => !!obs),
		switchAll()
	);
}
