import { Component, Input } from '@angular/core';
import { paths } from '@evasys/globals/evainsights/constants/paths';
import { ActivatedRoute, Router } from '@angular/router';
import { Report, ReportTemplate } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { RouteDataParams } from '@evasys/globals/evainsights/constants/route-data-params';
import { ReportType } from '@evasys/globals/evainsights/constants/types';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evainsights-report-reader-menu',
	templateUrl: './report-reader-menu.component.html',
})
export class ReportReaderMenuComponent {
	@Input()
	@Required()
	id!: string;

	@Input()
	@Required()
	title!: string;

	@Input()
	collapsed!: boolean;

	@Input()
	report!: Report | ReportTemplate;

	reportType: ReportType;
	reportTypeEnum = ReportType;
	buttonDesignLink = ButtonDesignEnum.OUTLINE_LINK;

	constructor(private router: Router, private activatedRoute: ActivatedRoute) {
		this.reportType = this.activatedRoute.snapshot.data[RouteDataParams.REPORT_TYPE];
	}

	getReportAndReportTemplatePage = (id: number) => {
		const path =
			this.reportType === ReportType.REPORT
				? paths.getReportCreatorReport.build({ reportId: id })
				: paths.getReportTemplateCreatorReportTemplate.build({ reportTemplateId: id });
		return [path];
	};

	isReportStatusDraft() {
		return this.reportType === ReportType.TEMPLATE || ('isPublished' in this.report && !this.report.isPublished);
	}
}
