import { Component, Input } from '@angular/core';
import { BreadcrumbItem } from '@evasys/shared/ui';

@Component({
	selector: 'evasys-breadcrumb',
	templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent {
	@Input()
	breadItems: BreadcrumbItem[] = [];

	@Input()
	maxBreadCrumbLength = 30;

	getBreadCrumbText(item): string {
		return (
			item.name.substring(0, this.maxBreadCrumbLength) +
			(item.name.length > this.maxBreadCrumbLength ? '...' : '')
		);
	}
}
