import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationEnum } from '@evasys/globals/shared/enums/component/notification.enum';
import { EvasysNotificationModel } from '@evasys/globals/shared/models/evasys-notification.model';

@Component({
	selector: 'evasys-static-notification',
	templateUrl: './static-notification.component.html',
	styleUrls: ['./static-notification.component.scss'],
})
export class StaticNotificationComponent {
	@Input()
	notification: EvasysNotificationModel;

	@Input()
	hidden = false;

	@Output()
	hiddenChange = new EventEmitter<boolean>();

	asCssClass(type: NotificationEnum) {
		const keys = Object.keys(NotificationEnum).filter((x) => NotificationEnum[x] == type);
		return keys.length > 0 ? keys[0] : null;
	}

	onClosed() {
		this.hidden = true;
		this.hiddenChange.emit(this.hidden);
	}
}
