import { AfterViewInit, Directive, Optional } from '@angular/core';
import { TableComponent } from '../../table/table/table.component';
import { TableAreaRegisterService } from './table-area-register.service';

@Directive({
	selector: '[evasysTableAreaNestedElement]',
})
export class TableAreaNestedElementDirective<T extends { id: I }, I = number | string> implements AfterViewInit {
	constructor(
		@Optional() private readonly tableAreaRegisterService: TableAreaRegisterService,
		@Optional() private readonly tableComponent: TableComponent
	) {}

	ngAfterViewInit() {
		if (this.tableComponent) {
			this.tableAreaRegisterService?.registerComponent(TableComponent.name, this.tableComponent);
		}
	}
}
