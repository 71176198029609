<div class="p-2">
	<ng-container *ngIf="itemsRequest | async; let request">
		<div class="d-flex gap-2 align-items-center mx-1" [formGroup]="searchTextItemTypeForm">
			<div class="card">
				<evasys-button
					[id]="'clearItemSelection'"
					[design]="buttonDesign.LINK"
					class="text-black"
					disabled="checkedItemIds.size > 0"
					(click)="uncheckAllItems()"
				>
					{{ 'common.selectedCount' | transloco : { count: checkedItems.size } }}
					<img
						evasysImage
						*ngIf="checkedItems.size > 0"
						[id]="'clearItemSelection'"
						[alt]="'common.clearSelection' | transloco"
						[class]="'c-w-14px mb-1'"
						[src]="'./assets/images/icons/navigate_cross.svg'"
					/>
				</evasys-button>
				<evasys-error [errors]="errors" [id]="'reportBatchAddItems'"></evasys-error>
			</div>
			<evasys-dropdown
				[formControls]="[searchTextItemTypeForm.get('selectedItemType')]"
				[id]="'itemTypes'"
				data-cy="'dropdown-itemTypes'"
				class="ms-auto bg-white"
			>
				<evasys-dropdown-primary evasysDropdownToggle>
					<span evasysDropdownLabel>{{ 'items.itemTypes' | transloco }}</span>
					<span value *evasysDropdownValue="let value = value; mapping: itemTypes | async">{{ value }}</span>
					<div *evasysDropdownMenu>
						<evasys-selector [forceSelection]="true" formControlName="selectedItemType" [id]="'itemTypes'">
							<evasys-selector-item
								*ngFor="let itemType of itemTypes | async | keepMapOrder"
								[value]="itemType.key"
							>
								{{ itemType.value }}
							</evasys-selector-item>
						</evasys-selector>
					</div>
				</evasys-dropdown-primary>
			</evasys-dropdown>
			<evasys-searchbox
				[id]="'addReportItems'"
				formControlName="searchText"
				class="c-bg-white"
			></evasys-searchbox>
		</div>
		<div class="position-relative">
			<ng-container *ngIf="latestPage | async; let page">
				<div class="card m-1" *ngFor="let item of page.content">
					<div
						class="d-flex justify-content-between align-items-center btn text-start"
						(mouseup)="
							inputCheckbox.checked = !inputCheckbox._checked; checkItem(item, inputCheckbox._checked)
						"
					>
						<evasys-checkbox
							#inputCheckbox
							arrange="center"
							(mouseup)="inputCheckbox.checked = !inputCheckbox._checked"
							[checked]="item.id | modify : includeItemWithId"
							[id]="'selectOne-' + item.id"
							(checkedChange)="checkItem(item, $event)"
						>
							<div class="c-cursor-pointer ps-2">
								{{ item | modify : uiFormElementDescriptorService.selectItemDescriptor | async }}
							</div>
						</evasys-checkbox>
					</div>
				</div>
				<evainsights-empty-content
					*ngIf="page.empty"
					[message]="'items.noneFound' | transloco"
				></evainsights-empty-content>
			</ng-container>
			<evasys-loading-overlay [loading]="request.isLoading"></evasys-loading-overlay>
		</div>
		<div class="d-flex justify-content-md-end mt-3" *ngIf="latestPage | async; let page">
			<ng-container *ngIf="!page.empty">
				<ngb-pagination
					class="mx-auto"
					data-cy="pagination-container"
					(pageChange)="pageNumber.next($event)"
					[page]="pageNumber.value"
					[collectionSize]="page.totalElements"
					[pageSize]="5"
					[maxSize]="5"
					[rotate]="true"
					[boundaryLinks]="true"
					[disabled]="request.isLoading"
				>
				</ngb-pagination>
			</ng-container>
		</div>
	</ng-container>
</div>
