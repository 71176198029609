import { AfterViewInit, Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ScreenService, ScreenSizes } from '../services/screen.service';
import { fromEvent, Subscription, throttleTime } from 'rxjs';

@Directive({
	selector: '[evasysScreen]',
})
export class ScreenDirective implements OnInit, AfterViewInit, OnDestroy {
	//region Variables
	private subscriptions: Subscription[] = [];
	//endregion

	constructor(private readonly element: ElementRef, private readonly screenService: ScreenService) {}

	//region Events
	ngAfterViewInit() {
		this.subscriptions.push(
			fromEvent(window, 'resize')
				.pipe(throttleTime(100))
				.subscribe(() => this.determineScreenSize())
		);
	}

	ngOnInit(): void {
		this.determineScreenSize();
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.subscriptions.length = 0;
	}

	//endregion

	//region Methods

	private determineScreenSize(): void {
		let screenSize;
		const screenPxSize = window.innerWidth;
		this.screenService.screenWidth.set(window.innerWidth);
		this.screenService.screenHeight.set(window.innerHeight);
		switch (true) {
			case screenPxSize < ScreenSizes.S:
				screenSize = ScreenSizes.XS;
				break;
			case screenPxSize >= ScreenSizes.S && screenPxSize < ScreenSizes.M:
				screenSize = ScreenSizes.S;
				break;
			case screenPxSize >= ScreenSizes.M && screenPxSize < ScreenSizes.L:
				screenSize = ScreenSizes.M;
				break;
			case screenPxSize >= ScreenSizes.L && screenPxSize < ScreenSizes.XL:
				screenSize = ScreenSizes.L;
				break;
			case screenPxSize >= ScreenSizes.XL && screenPxSize < ScreenSizes.XXL:
				screenSize = ScreenSizes.XL;
				break;
			case screenPxSize >= ScreenSizes.XXL:
				screenSize = ScreenSizes.XXL;
				break;
			default:
				screenSize = ScreenSizes.NONE;
				break;
		}
		this.screenService.size = screenSize;
	}
	//endregion
}
