import { Component, Input } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evasys-meta-info',
	templateUrl: './meta-info.component.html',
})
export class MetaInfoComponent {
	@Required()
	@Input()
	public id: string;

	@Input()
	public key?: string;

	@Input()
	public value?: string | number;
}
