import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'nameViaIdInList',
})
export class NameViaIdInListPipe implements PipeTransform {
	transform(value: string, list: any[]): string {
		return list?.find((item) => item.id === value).name;
	}
}
