import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';
import { first } from 'rxjs';
import { ReportService, UiConfigService, UnitService } from '@evasys/evainsights/shared/core';
import { KeyValueModel } from '@evasys/globals/evainsights/models/common/key-value.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { getReportIdFromParamMap } from '@evasys/globals/evainsights/helper/url-params';
import { ReportType } from '@evasys/globals/evainsights/constants/types';
import { expiredTimeDateValidator } from '@evasys/globals/evainsights/validations/validations';
import { pageContent } from '@evasys/evainsights/shared/util';
import { TypeaheadItemSearch } from '@evasys/globals/shared/models/component/typeahead/typeahead.model';
import { ReportPublishingInfo } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';
import { KeyValue } from '@angular/common';
import { paths } from '@evasys/globals/evainsights/constants/paths';
import { NotificationEnum } from '@evasys/globals/shared/enums/component/notification.enum';
import { NotificationService } from '@evasys/shared/core';
import { TranslocoService } from '@ngneat/transloco';
import { getPublishTimeCalendarTranslation } from '@evasys/globals/evainsights/helper/getPublishTimeCalendarTranslation';

@Component({
	selector: 'evainsights-report-publish-modal',
	templateUrl: './report-publish-modal.component.html',
})
export class ReportPublishModalComponent implements OnInit {
	private readonly router = inject(Router);
	private activatedRoute = inject(ActivatedRoute);
	private readonly unitService = inject(UnitService);
	private formBuilder = inject(FormBuilder);
	public calender = inject(NgbCalendar);
	private reportService = inject(ReportService);
	private readonly notificationService = inject(NotificationService);
	private readonly translocoService = inject(TranslocoService);
	private uiConfigService = inject(UiConfigService);

	@Input()
	isOpen!: boolean;
	@Output()
	public isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	buttonDesign = ButtonDesignEnum;
	form: FormGroup;
	submitted = false;
	reportType!: ReportType;
	uiConfig = this.uiConfigService.getUiConfig();
	calendarPublishTranslation = getPublishTimeCalendarTranslation(this.translocoService);

	constructor() {
		this.form = this.formBuilder.group({
			units: [null, Validators.required],
		});
	}

	ngOnInit() {
		const params = this.activatedRoute.snapshot.queryParams;
		if (params?.['unitId']?.length) {
			this.unitService
				.getUnits({ ids: params['unitId'] })
				.pipe(pageContent())
				.pipe(first())
				.subscribe((units) => this.form.patchValue({ units: units }));
		}
		this.reportType = this.activatedRoute.snapshot.data['reportType'];
	}

	formatterUnits = (units: KeyValueModel) => units.value;

	searchUnits: TypeaheadItemSearch<KeyValueModel> = (query, pageRequest) => {
		return this.unitService.getUnits({ name: query, ...pageRequest });
	};

	openedChangeEmit($event: boolean | undefined) {
		this.isOpenChange.emit($event);
	}

	setPublishLater(publishLater: boolean) {
		if (publishLater) {
			this.form.addControl(
				'dateTime',
				this.formBuilder.nonNullable.control(new Date(), [Validators.required, expiredTimeDateValidator()])
			);
		} else {
			this.form.removeControl('dateTime');
		}
	}

	publishReport() {
		this.submitted = true;
		if (this.form.valid) {
			const reportId = getReportIdFromParamMap(this.activatedRoute.snapshot.paramMap);
			const unitIds = this.form.controls['units'].value.map((units: KeyValue<number, string>) => units.key);
			const publishedOn = this.getPublishOnDate(this.form).toISOString();

			const reportPublishingInfo: ReportPublishingInfo = {
				reportId,
				unitIds,
				publishedOn,
			};
			this.isOpen = false;
			this.reportService
				.publishReport(reportPublishingInfo)
				.pipe(first())
				.subscribe({
					next: () => {
						this.router.navigate([paths.getReports.build()], { queryParamsHandling: 'preserve' });
					},
					error: () => {
						this.notificationService.addNotification(
							NotificationEnum.ERROR,
							this.translocoService.translate('report.publish.notification.failed'),
							'publishReportFailed',
							false
						);
					},
				});
		}
	}

	private getPublishOnDate(group: FormGroup): Date {
		return group.value.dateTime ? new Date(group.value.dateTime) : new Date();
	}
}
