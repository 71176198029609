import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CalenderTranslationService } from '../services/calender-translation.service';
import { DatePickerService } from '../services/date-picker.service';

@Component({
  selector: 'time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit {
  initDate: Date = new Date();

  @Output()
  date: EventEmitter<Date> = new EventEmitter<Date>();

  meridiem = false;

  constructor(
    public readonly calenderTranslationService: CalenderTranslationService,
    public datePickerService: DatePickerService
  ) {
    this.datePickerService.internalDate.subscribe((value) => {
      if (value) {
        this.initDate = value;
      }
    });
  }

  ngOnInit(): void {
    if (this.datePickerService.getInitFormat()?.includes('aaa')) {
      this.meridiem = true;
    }

    if (this.datePickerService.getInitFormat()?.includes('a')) {
      this.meridiem = true;
    }
  }

  onSelectedHours(hours: number): void {
    const date: Date = this.initDate;
    date.setHours(hours);
    this.date.emit(date);
  }

  onSelectedMinute(minutes: any): void {
    const date: Date = this.initDate;
    date.setMinutes(minutes);
    this.date.emit(date);
  }
}
