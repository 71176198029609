<img
	evasysImage
	*ngIf="tooltip"
	[id]="id"
	[src]="src"
	[alt]="alt"
	[isIcon]="isIcon ?? true"
	[evasysTooltip]="tooltip"
	[class]="class"
	class="d-flex align-content-center"
	[altTextAsLabel]="altTextAsLabel"
/>
<img
	evasysImage
	*ngIf="!tooltip"
	[id]="id"
	[src]="src"
	[alt]="alt"
	[isIcon]="isIcon ?? true"
	[class]="class"
	class="d-flex align-content-center"
	[altTextAsLabel]="altTextAsLabel"
/>
