import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { SystemInfoEffects } from '../effects/system-info.effects';
import { StoreModule } from '@ngrx/store';
import { metaReducers, systemInfoInitialState, systemInfoReducer } from '../reducers/system-info.reducer';

@NgModule({
	imports: [
		CommonModule,
		EffectsModule.forFeature([SystemInfoEffects]),
		StoreModule.forFeature('systemInfos', systemInfoReducer, {
			metaReducers: metaReducers,
			initialState: systemInfoInitialState,
		}),
	],
})
export class SystemInfoModule {}
