import { AnalysisType, DataBasisType } from '../../../constants/types';

export interface ReportItemContentBase<Config, Data> {
	config: Config;
	data: Data;
}

export interface LegacyReportItemContentConfigBase<VisualizationType> {
	visualizationType: VisualizationType;

	// originally from the content config shared by all report item types
	dataBasis: DataBasisType;
	analysisType?: AnalysisType;
	isLongitudinalAnalysis?: boolean;
}
