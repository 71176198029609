import { Injectable } from '@angular/core';
import { KeyValue } from '@angular/common';

@Injectable()
export class TableAreaRegisterService {
	//region Variables
	private readonly componentRegister: KeyValue<string, unknown>[] = [];
	//endregion

	//region Methods

	public registerComponent(componentName: string, component: unknown) {
		this.componentRegister.push({ key: componentName, value: component });
	}

	getComponent<U>(componentName: string): U[] {
		return this.componentRegister
			.filter((components) => components.key === componentName)
			.map((components) => {
				return components.value;
			}) as U[];
	}

	//endregion
}
