import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChartExportFormat } from '@evasys/globals/evainsights/constants/types';
import { getFormatInfo } from '@evasys/globals/evainsights/constants/data-types';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { Options } from '@popperjs/core';
import { ButtonDesignEnum } from '@evasys/globals/shared/enums/component/button-design.enum';

@Component({
	selector: 'evainsights-chart-export-menu',
	templateUrl: './chart-export-menu.component.html',
})
export class ChartExportMenuComponent {
	@Input()
	@Required()
	optionTranslocoKey!: string;

	@Output()
	selectFormat = new EventEmitter<ChartExportFormat>();

	// read-only
	isOpen = false;

	buttonDesign = ButtonDesignEnum;
	chartExportFormat = ChartExportFormat;

	onClick(format: ChartExportFormat) {
		this.selectFormat.emit(format);
	}

	getFormatExtension(format: ChartExportFormat) {
		return getFormatInfo(format).extension;
	}

	popperOptions = (options: Partial<Options>) => {
		options.onFirstUpdate = (state) => {
			if (state.elements?.arrow) {
				state.elements.arrow.style.display = 'none';
			}
		};
		for (const modifier of options.modifiers || []) {
			if (modifier.name === 'offset' && modifier.options) {
				modifier.options['offset'] = () => [0, 0];
			}
		}
		return options;
	};
}
