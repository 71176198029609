import { clamp } from 'lodash';

/**
 * A number scaling function that maps one value to another,
 * e.g. numbers from the domain (100, 500) into the range (0.0, 1.0)
 */
export type Scale = (value: number) => number;

export type Limits = [number, number];

export const getLimits = (values: number[]): Limits => [Math.min(...values), Math.max(...values)];

export interface ScaleOptions {
	domain: Limits; // possible input values
	range: Limits; // target output values
}

export interface LogScaleOptions extends ScaleOptions {
	offset?: number;
}

export interface LinearToExponentialScale extends ScaleOptions {
	power: number; // the power of the exponential part
	exponentialPortion: number; // a number between 0 and 1 that determines how much the output follows the exponential
}

/**
 * Get a scale that maps values in a linear fashion
 * The midpoint in the domain is thus mapped to the midpoint of the range
 */
export const getLinearScale = ({ domain, range }: ScaleOptions): Scale => {
	return (value) => {
		// value normalized to values in the range 0 to 1
		const normalizedValue = clamp((value - domain[0]) / (domain[1] - domain[0]), 0, 1);
		return range[0] + normalizedValue * (range[1] - range[0]);
	};
};

/**
 * Get a scale that maps values in a logarithmic function.
 * It is thus well suited e.g. to map values that are exponential in nature (like occurrence counts of words) onto a
 * uniform distribution.
 */
export const getLogScale = ({ domain, range, offset: specifiedOffset }: LogScaleOptions): Scale => {
	const offset = specifiedOffset ?? 0;

	if (domain[0] + offset <= 0) {
		throw Error('The input domain of a logarithmic scale should be strictly positive considering the offset');
	}

	const logMap = (value: number) => Math.log(value + offset);
	const linearScale = getLinearScale({
		domain: [logMap(domain[0]), logMap(domain[1])],
		range,
	});

	return (value) => linearScale(logMap(value));
};

/**
 * Get a scale that combines a linear and an exponential scaling with a given portion
 */
export const getLinearToExponentialScale = ({
	domain,
	range,
	power,
	exponentialPortion,
}: LinearToExponentialScale): Scale => {
	const inputScale = getLinearScale({ domain, range: [0, 1] });
	const outputScale = getLinearScale({ domain: [0, 1], range });

	return (value) => {
		const normalizedValue = inputScale(value);
		return outputScale(
			exponentialPortion * Math.pow(normalizedValue, power) + (1 - exponentialPortion) * normalizedValue
		);
	};
};
