import { Injectable } from '@angular/core';
import { EvasysState, FacadeService } from '@evasys/shared/core';
import { EvasysMenuModel } from '@evasys/globals/evasys/models/general/evasys-menu.model';
import { Store } from '@ngrx/store';
import { MenuState } from '@evasys/globals/evasys/models/states/menu.state';
import { evasysActions } from '../actions';
import { evasysSelectors } from '../selectors';
import { Observable } from 'rxjs';
import { Actions } from '@ngrx/effects';

@Injectable({
	providedIn: 'root',
})
export class MenuService extends FacadeService<EvasysMenuModel, MenuState> {
	constructor(
		private readonly menuStore: Store<EvasysState<EvasysMenuModel, MenuState>>,
		protected readonly actions: Actions
	) {
		super(menuStore, evasysActions.fromMenu, evasysSelectors.fromMenu, actions);
	}

	public getMenuItems() {
		return this.store.select(evasysSelectors.fromMenu.selectAll);
	}

	public loadMenus() {
		this.store.dispatch(
			evasysActions.fromMenu.LoadAllActionSet.DEFAULT({
				payload: undefined,
			})
		);
	}

	public setMenuMark(menuItemName: string, showMark: boolean) {
		this.menuStore.dispatch(
			evasysActions.fromMenu.SetMenuMarkActionSet.DEFAULT({ payload: { key: menuItemName, value: showMark } })
		);
	}

	public openMenuPermanently(open: boolean): void {
		this.menuStore.dispatch(evasysActions.fromMenu.OpenPermanentlyActionSet.DEFAULT({ payload: open }));
	}

	public getPermanentlyMenuOpenState(): Observable<boolean> {
		return this.menuStore.select(evasysSelectors.fromMenu.selectPermanentOpenState);
	}

	public setSearchText(searchText: string) {
		this.menuStore.dispatch(evasysActions.fromMenu.SetSearchTextActionSet.DEFAULT({ payload: searchText }));
	}

	public getSearchTextState(): Observable<string> {
		return this.menuStore.select(evasysSelectors.fromMenu.selectSearchTextState);
	}
}
