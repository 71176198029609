import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ValidationErrorModel } from '@evasys/globals/evasys/models/component/validation-error.model';

@Component({
	selector: 'evainsights-card-select',
	templateUrl: './card-select.component.html',
})
export class CardSelectComponent<T> {
	@Input()
	id!: string;

	@Input()
	errors: ValidationErrorModel[] = [];

	@Input()
	items!: T;

	@Input()
	selectedItems = new Set();

	@Input()
	isMultiSelect = false;

	@ContentChild('card') card!: TemplateRef<T>;

	@Output() selectedItemChange = new EventEmitter();

	onCardSelect = (item: Set<T>) => {
		if (this.isMultiSelect) {
			if (this.selectedItems.has(item)) {
				this.selectedItems.delete(item);
			} else {
				this.selectedItems.add(item);
			}
		} else {
			this.selectedItems = item;
		}
		this.selectedItemChange.emit(this.selectedItems);
	};
}
