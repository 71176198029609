import { Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ReportItemContentStatus } from '@evasys/globals/evainsights/constants/status';
import { filter, map } from 'rxjs';
import { translateReportMultiLangString } from '@evasys/evainsights/shared/util';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';
import { LineChartContent } from '@evasys/globals/evainsights/models/report-item';
import { UiConfigService } from '@evasys/evainsights/shared/core';
import { isNotNullish } from '@evasys/globals/evainsights/typeguards/common';

@Component({
	selector: 'evainsights-line-chart-widget',
	templateUrl: './line-chart-widget.component.html',
})
export class LineChartWidgetComponent extends WidgetBaseComponent implements OnChanges {
	private uiConfigService = inject(UiConfigService);

	@Input()
	id = '';

	@Input()
	content!: LineChartContent;

	@Input()
	reportItemStatus?: ReportItemContentStatus;

	uiConfig = this.uiConfigService.getUiConfig();

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['content']) {
			this.titleObservables.next(
				this.reportLanguage$.pipe(
					filter(isNotNullish),
					map((reportLanguage) =>
						translateReportMultiLangString(this.content.config.title, reportLanguage.id)
					)
				)
			);
		}
	}
}
