<nav *ngIf="breadItems.length > 1" aria-label="breadcrumb" class="d-md-flex">
	<ol class="breadcrumb">
		<li *ngFor="let item of breadItems; let last = last" [ngClass]="{ active: last }" class="breadcrumb-item">
			<a
				*ngIf="!last && item.href"
				[attr.data-cy]="'breadItem-' + item.id"
				[routerLink]="item.href"
				[queryParamsHandling]="item.queryParamsHandling"
				>{{ item.name }}</a
			>
			<span *ngIf="last || !item.href" [attr.data-cy]="'breadItem-' + item.id">{{ item.name }}</span>
		</li>
	</ol>
</nav>
