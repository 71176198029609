import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { ShowMoreContainerDirective } from './show-more-container.directive';
import { SharedUiConfiguration } from '../../../shared-ui.configuration';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evasys-show-more',
	templateUrl: './show-more.component.html',
	styleUrls: ['show-more.component.scss'],
})
export class ShowMoreComponent implements AfterViewInit {
	@Required()
	@Input()
	id!: string;

	@Input()
	maxLines = 3;

	@ViewChild('content', { static: true })
	content: ElementRef<HTMLDivElement>;

	_isOverflow = false;
	isClicked = false;

	set isOverflow(isOverflow: boolean) {
		this._isOverflow = isOverflow;
	}

	constructor(
		private readonly showMoreContainerDirective: ShowMoreContainerDirective,
		private readonly renderer: Renderer2,
		private readonly sharedUiConfigurations: SharedUiConfiguration,
		public readonly elementRef: ElementRef,
		public readonly changeDetectorRef: ChangeDetectorRef
	) {}

	ngAfterViewInit() {
		this.renderer.setStyle(this.content.nativeElement, '-webkit-line-clamp', `${this.maxLines}`);
	}

	onShowMoreClicked() {
		this.isClicked = true;
		this.renderer.setStyle(this.content.nativeElement, '-webkit-line-clamp', '');
	}

	updateDOM() {
		this.changeDetectorRef.detectChanges();
	}
}
