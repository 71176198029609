import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'toString',
})
export class ToStringPipe implements PipeTransform {
	transform<T>(value?: T | T[], property?: keyof T, seperator?: string): string | undefined {
		if (value && Array.isArray(value)) {
			return property
				? value?.map((item) => item[property]?.toString()).join(seperator ?? ', ')
				: value?.map((item) => item.toString()).join(seperator ?? ', ');
		} else if (value) {
			return property ? (value as T)[property]?.toString() : value.toString();
		} else {
			return undefined;
		}
	}
}
