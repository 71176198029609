import { Component, ElementRef, inject, Input, OnChanges, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';
import {
	FilterAreaControl,
	FilterSelection,
} from '@evasys/globals/evainsights/models/filter/filter-area-control.model';
import { buildEmptyFilterSelection } from '@evasys/globals/evainsights/helper/filter-selection';
import { ResponsesContent } from '@evasys/globals/evainsights/models/report-item/content/responses-report-item-content.model';
import { ResponseSentiment } from '@evasys/globals/evainsights/models/search/response.model';
import { BehaviorSubject, combineLatest, filter, Observable, startWith, Subject, switchMap } from 'rxjs';
import { Page } from '@evasys/globals/evainsights/models/pagination/page.model';
import { isNotNullish } from '@evasys/globals/evainsights/typeguards/common';

type ResponseSentimentSearch<FilterAreaControls extends readonly FilterAreaControl<unknown>[]> = (
	query: string,
	filter: FilterSelection<FilterAreaControls>,
	pageRequest: { size: number; page: number }
) => Observable<Page<ResponseSentiment>>;

@Component({
	selector: 'evainsights-responses-content',
	templateUrl: './responses-content.component.html',
})
export class ResponsesContentComponent<FilterAreaControls extends readonly FilterAreaControl<unknown>[]>
	extends WidgetBaseComponent
	implements OnChanges
{
	@Input({ required: true })
	content!: ResponsesContent;

	@Input({ required: true })
	filterAreaControls!: FilterAreaControls;

	@Input({ required: true })
	set search(search: ResponseSentimentSearch<FilterAreaControls>) {
		this.search$.next(search);
	}

	protected renderer = inject(Renderer2);

	filterSelections$ = new BehaviorSubject<FilterSelection<FilterAreaControls> | null>(null);
	search$ = new BehaviorSubject<ResponseSentimentSearch<FilterAreaControls> | null>(null);
	searchValue$ = new BehaviorSubject('');
	contentChanged$ = new Subject<void>();
	pageNumber$ = new BehaviorSubject(1);
	pageSize = 10;
	results$: Observable<Page<ResponseSentiment>> = combineLatest([
		this.search$.pipe(filter(isNotNullish)),
		this.filterSelections$.pipe(filter(isNotNullish)),
		this.searchValue$,
		this.pageNumber$,
		this.contentChanged$.pipe(startWith(null)),
	]).pipe(
		switchMap(([search, filterSelections, searchValue, pageNumber]) =>
			search(searchValue, filterSelections, { size: this.pageSize, page: pageNumber })
		)
	);

	@ViewChild('scrollContainer') scrollContainer!: ElementRef;

	ngOnChanges(changes: SimpleChanges) {
		if (changes['content']) {
			this.contentChanged$.next();
		}
		if (changes['filterAreaControls'] && this.filterAreaControls) {
			this.filterSelections$.next(buildEmptyFilterSelection(this.filterAreaControls));
		}
	}

	public onSearch(value: string) {
		this.searchValue$.next(value);
		this.pageNumber$.next(1);
		this.setScrollbarToTop();
	}

	public onFilterSelection(value: FilterSelection<FilterAreaControls>) {
		this.filterSelections$.next(value);
		this.pageNumber$.next(1);
		this.setScrollbarToTop();
	}

	public onPageSizeChange(value: number) {
		this.pageSize = value;
		this.pageNumber$.next(1);
		this.setScrollbarToTop();
	}

	public onPageChange(value: number) {
		this.pageNumber$.next(value);
		this.setScrollbarToTop();
	}

	public setScrollbarToTop() {
		this.renderer.setProperty(this.scrollContainer.nativeElement, 'scrollTop', 0);
	}
}
