<div
	*ngIf="component === loadedComponent.BUTTON"
	class="btn c-h-35px c-w-100px bg-light flash-animation border-0"
></div>
<div
	*ngIf="component === loadedComponent.TEXTBOX"
	class="form-control position-relative c-h-35px bg-light flash-animation border-0"
></div>
<div *ngIf="component === loadedComponent.TEXT" class="c-h-22px bg-light flash-animation border-0"></div>
<div *ngIf="component === loadedComponent.LOGO" class="c-h-42px c-w-150px bg-light flash-animation border-0"></div>
<div *ngIf="component === loadedComponent.LOGO" class="c-h-42px c-w-150px bg-light flash-animation border-0"></div>
