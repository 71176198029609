import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemInfoModule } from './modules/system-info.module';
import { MenuModule } from './modules/menu.module';
import { WizardModule } from './modules/wizard.module';
import { StatusModule } from './modules/status.module';

@NgModule({
	imports: [CommonModule, MenuModule, SystemInfoModule, WizardModule, StatusModule],
})
export class EvasysSharedCoreModule {}
