<div class="row gx-0 align-items-start p-2 m-0 text-nowrap" [ngClass]="collapsed ? 'invisible' : 'visible'">
	<evasys-accordion [alwaysOpen]="true" [id]="'configurationMenu'">
		<div evasys-accordion-item [id]="'actions'" [show]="true" [title]="'common.actions' | transloco">
			<evainsights-report-language-dropdown></evainsights-report-language-dropdown>
			<div class="d-flex flex-wrap gap-2">
				<evasys-anchor
					[id]="
						reportType === ReportTypeREPORT
							? 'view-report-' + reportId
							: 'view-reportTemplate-' + reportTemplateId
					"
					[link]="(reportType === ReportTypeREPORT ? reportId : reportTemplateId) | modify : getPreviewPage"
					[design]="buttonDesignLink"
					title="{{ 'common.preview.hover' | transloco }}"
				>
					<img
						evasysImage
						[isIcon]="true"
						[class]="'c-w-18px c-h-18px me-1'"
						[id]="'view-report'"
						[src]="'./assets/images/icons/document_view.svg'"
						alt="{{ 'common.preview.hover' | transloco }}"
					/>
					{{ 'common.preview' | transloco }}
				</evasys-anchor>
				<evainsights-report-export-menu></evainsights-report-export-menu>

				<evasys-button
					*ngIf="reportType === ReportTypeREPORT"
					[id]="'openPublishModal'"
					(click)="isPublishModalOpen = true"
					[design]="buttonDesignLink"
					[title]="'report.publish.hover' | transloco"
					>{{ 'report.publish.title' | transloco }}</evasys-button
				>

				<evasys-button
					*ngIf="reportType === ReportTypeREPORT"
					(click)="calculateItems()"
					[design]="buttonDesignLink"
					[title]="'report.calculateReportItems.hover' | transloco"
					[isDisabled]="reportProcessingService.isProcessingUpdate()"
					[id]="'calculateItems'"
				>
					{{ 'report.calculateReportItems' | transloco }}
				</evasys-button>

				<evainsights-report-publish-modal [(isOpen)]="isPublishModalOpen"></evainsights-report-publish-modal>
			</div>
		</div>

		<div
			evasys-accordion-item
			class="d-flex flex-column gap-2"
			*ngIf="reportType === ReportTypeREPORT"
			[id]="'survey-list'"
			[show]="true"
			[title]="'domain.surveys' | transloco"
		>
			<evainsights-report-survey-list></evainsights-report-survey-list>
		</div>
		<div
			evasys-accordion-item
			class="d-flex flex-column gap-2"
			*ngIf="reportType === ReportTypeREPORT"
			[id]="'template-list'"
			[show]="true"
			[title]="'domain.reportTemplates' | transloco"
		>
			<evainsights-report-template-list></evainsights-report-template-list>
			<evasys-loading-overlay
				class="c-z-index-1-important"
				[loading]="reportProcessingService.isProcessingTemplate()"
			></evasys-loading-overlay>
		</div>
		<div
			evasys-accordion-item
			class="d-flex flex-column gap-2"
			*ngIf="reportType === ReportTypeTEMPLATE"
			[id]="'template-form'"
			[title]="'common.form' | transloco"
			[show]="true"
		>
			<evainsights-report-template-form></evainsights-report-template-form>
		</div>
		<div
			evasys-accordion-item
			*ngIf="reportType === ReportTypeREPORT"
			class="d-flex flex-column gap-2"
			[id]="'respondentFilter-list'"
			[title]="'report.respondentFilter' | transloco"
			[show]="true"
		>
			<evainsights-respondent-filter></evainsights-respondent-filter>
		</div>
	</evasys-accordion>
	<evainsights-report-metadata *ngIf="!collapsed"></evainsights-report-metadata>
	<ng-content></ng-content>
</div>
