import { Component, inject, Input } from '@angular/core';
import { Required } from '@evasys/globals/shared/decorators/decorators';
import { ReportItemContentStatus } from '@evasys/globals/evainsights/constants/status';
import { ProfileLineChartContent } from '@evasys/globals/evainsights/models/report-item';
import { UiConfigService } from '@evasys/evainsights/shared/core';
import { ReportLanguage } from '@evasys/globals/evainsights/models/report/report-reportTemplate.model';

@Component({
	selector: 'evainsights-profile-line-chart-widget',
	templateUrl: './profile-line-chart-widget.component.html',
})
export class ProfileLineChartWidgetComponent {
	uiConfigService = inject(UiConfigService);

	@Input()
	id = '';

	@Input()
	@Required()
	content!: ProfileLineChartContent;

	@Input({ required: true })
	language!: ReportLanguage;

	@Input()
	reportItemStatus?: ReportItemContentStatus;

	uiConfig = this.uiConfigService.getUiConfig();
}
