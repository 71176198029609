import { inject, Pipe, PipeTransform } from '@angular/core';
import { HashMap, TranslateParams, TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';

@Pipe({
	name: 'inlineTransloco',
})
export class InlineTranslocoPipe implements PipeTransform {
	translocoService = inject(TranslocoService);
	transform(language: string | undefined, value: TranslateParams, params?: HashMap): Observable<string> {
		return this.translocoService.selectTranslate(value, params ?? {}, language);
	}
}
