<evasys-button
	[id]="'infoPopover-' + id"
	[design]="buttonDesignEnum.LINK"
	[ngbPopover]="popoverContent"
	container="body"
	[popoverClass]="'bg-white shadow c-w-30rem'"
	autoClose="outside"
	[popperOptions]="popperOptions"
	placement="left top bottom right"
>
	<img
		evasysImage
		[id]="'infoPopover-' + id"
		[class]="'c-h-20px c-w-20px'"
		[src]="'./assets/images/icons/information2.svg'"
		[alt]="language | inlineTransloco : 'domain.moreInformation' | async"
		[isIcon]="true"
		[altTextAsLabel]="true"
	/>
</evasys-button>
<ng-template #popoverContent>
	<div [attr.data-cy]="'infoPopover-content-' + id">
		<ng-content></ng-content>
	</div>
</ng-template>
