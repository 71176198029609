import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { D3BaseComponent, Size } from '../d3-base/d3-base.component';
import {
	AxisChartConfig,
	AxisChartDataBase,
	ReportItemContentBase,
} from '@evasys/globals/evainsights/models/report-item';
import { Required } from '@evasys/globals/shared/decorators/decorators';

@Component({
	selector: 'evainsights-axis-chart',
	templateUrl: '../d3-base/d3-base.component.html',
})
export class AxisChartComponent<Content extends ReportItemContentBase<AxisChartConfig, AxisChartDataBase>>
	extends D3BaseComponent
	implements OnChanges
{
	@Input()
	@Required()
	content!: Content;

	override ngOnChanges(changes: SimpleChanges) {
		super.ngOnChanges(changes);

		if (changes['content']) {
			if (this.renderElement !== undefined) {
				this.tryDraw();
			}
		}
	}

	override onResize() {
		this.tryDraw();
	}

	tryDraw() {
		if (!this.renderElement) {
			throw Error('Cannot draw bar chart without a render element');
		}

		const size = this.chartSize.value;
		if (!size) {
			throw Error('Cannot draw bar chart without a specified size');
		}

		this.draw(this.renderElement.nativeElement, size);
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	draw(host: HTMLElement, size: Size) {
		throw Error('Missing draw implementation');
	}
}
