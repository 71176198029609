import {
	Directive,
	EnvironmentInjector,
	inject,
	Input,
	OnInit,
	runInInjectionContext,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
	selector: '[evasysFoldable]',
})
export class FoldableDirective implements OnInit {
	//region Inputs and Outputs
	@Input()
	set evasysFoldableTemplateRef(templateRef: TemplateRef<unknown>) {
		this.foldableTemplateRef = templateRef;
	}

	@Input('evasysFoldableIsFolded')
	set isFolded(folded: boolean) {
		if (this.context.isFolded !== folded) {
			this.context.isFolded = folded;
			this.onFoldedChange();
		}
	}
	//endregion

	//region Injections
	private environmentInjector = inject(EnvironmentInjector);
	private viewContainerRef = inject(ViewContainerRef);
	private templateRef = inject(TemplateRef);
	//endregion

	//region Variables
	private foldableTemplateRef;
	private context = {
		isFolded: false,
	};
	//endregion

	//region Events
	ngOnInit() {
		const htmlElement = this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
		this.onFoldedChange();

		runInInjectionContext(this.environmentInjector, () => {
			fromEvent(htmlElement.rootNodes[0], 'click').pipe(takeUntilDestroyed()).subscribe(this.onClick);
		});
	}

	private onClick = () => {
		this.isFolded = !this.context.isFolded;
	};

	onFoldedChange = () => {
		if (!this.context.isFolded) {
			this.viewContainerRef.createEmbeddedView(this.foldableTemplateRef);
		} else if (this.viewContainerRef.get(1)) {
			this.viewContainerRef.remove(1);
		}
	};
	//endregion
}
